export class CountryItem {
  _code;
  _localeCode;
  _countryCode;
  _regionCode;
  _name;

  _currencyName;
  _currencyLocaleCode;
  _currencyExchangeRate;

  _temperatures;
  _groundTemperatures;
  _insolationS;
  _insolationWE;
  _insolationN;
  _optimalInsolation;

  _electricityPrice;
  _gasPrice;
  _heatingOilPrice;
  _coalPrice;
  _woodChipsPrice;
  _firewoodPrice;
  _pelletsPrice;

  _combinedPrice;
  _noRegions;
  _regions = [];
  _standardTypeConfiguration = [];

  _stationsConfig;
  _ignoreConfigPrices = false;

  get stationsConfig() {
    return this._stationsConfig;
  }

  set stationsConfig(value) {
    this._stationsConfig = value;
  }

  get ignoreConfigPrices() {
    return this._ignoreConfigPrices;
  }

  set ignoreConfigPrices(value) {
    this._ignoreConfigPrices = value;
  }

  get code() {
    return this._code;
  }

  set code(value) {
    this._code = value;
  }

  get localeCode() {
    return this._localeCode;
  }

  set localeCode(value) {
    this._localeCode = value;
  }

  get countryCode() {
    return this._countryCode;
  }

  set countryCode(value) {
    this._countryCode = value;
  }

  get regionCode() {
    return this._regionCode;
  }

  set regionCode(value) {
    this._regionCode = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get currencyName() {
    return this._currencyName;
  }

  set currencyName(value) {
    this._currencyName = value;
  }

  get currencyLocaleCode() {
    return this._currencyLocaleCode;
  }

  set currencyLocaleCode(value) {
    this._currencyLocaleCode = value;
  }

  get currencyExchangeRate() {
    return this._currencyExchangeRate;
  }

  set currencyExchangeRate(value) {
    this._currencyExchangeRate = value;
  }

  get temperatures() {
    return this._temperatures;
  }

  set temperatures(value) {
    this._temperatures = value;
  }

  get groundTemperatures() {
    return this._groundTemperatures;
  }

  set groundTemperatures(value) {
    this._groundTemperatures = value;
  }

  get insolationS() {
    return this._insolationS;
  }

  set insolationS(value) {
    this._insolationS = value;
  }

  get insolationWE() {
    return this._insolationWE;
  }

  set insolationWE(value) {
    this._insolationWE = value;
  }

  get insolationN() {
    return this._insolationN;
  }

  set insolationN(value) {
    this._insolationN = value;
  }

  get optimalInsolation() {
    return this._optimalInsolation;
  }

  set optimalInsolation(value) {
    this._optimalInsolation = value;
  }

  get electricityPrice() {
    return this._electricityPrice;
  }

  set electricityPrice(value) {
    this._electricityPrice = value;
  }

  get gasPrice() {
    return this._gasPrice;
  }

  set gasPrice(value) {
    this._gasPrice = value;
  }

  get heatingOilPrice() {
    return this._heatingOilPrice;
  }

  set heatingOilPrice(value) {
    this._heatingOilPrice = value;
  }

  get coalPrice() {
    return this._coalPrice;
  }

  set coalPrice(value) {
    this._coalPrice = value;
  }

  get woodChipsPrice() {
    return this._woodChipsPrice;
  }

  set woodChipsPrice(value) {
    this._woodChipsPrice = value;
  }

  get firewoodPrice() {
    return this._firewoodPrice;
  }

  set firewoodPrice(value) {
    this._firewoodPrice = value;
  }

  get pelletsPrice() {
    return this._pelletsPrice;
  }

  set pelletsPrice(value) {
    this._pelletsPrice = value;
  }

  get combinedPrice() {
    return this._combinedPrice;
  }

  set combinedPrice(value) {
    this._combinedPrice = value;
  }

  get noRegions() {
    return this._noRegions;
  }

  set noRegions(value) {
    this._noRegions = value;
  }

  get regions() {
    return this._regions;
  }

  set regions(value) {
    this._regions = value;
  }

  get standardTypeConfiguration() {
    return this._standardTypeConfiguration;
  }

  set standardTypeConfiguration(value) {
    this._standardTypeConfiguration = value;
  }
}
