import { BaseTypeConfig } from "./BaseTypeConfig";

export class ThermalBridgesTypeConfig extends BaseTypeConfig {
  _thermalBridgesTypes;

  get thermalBridgesTypes() {
    return this._thermalBridgesTypes;
  }

  set thermalBridgesTypes(value) {
    this._thermalBridgesTypes = value;
  }
}
