export class HouseModel {
  _houseType = -1;
  _houseTemp = -1;

  _floorCount = -1;
  _floorHeight = -1;
  _totalSurface = -1;

  _floorArea = -1;
  _roofSurface = -1;
  _wallSurface = -1;
  _insulatedWallSurface = -1;
  _nonInsulatedWallSurface = -1;
  _windowsSurfaceS = -1;
  _windowsSurfaceWE = -1;
  _windowsSurfaceN = -1;
  _doorType;

  constructor(
    houseType,
    floorCount,
    floorHeight,
    totalSurface,
    floorSurface,
    roofSurface,
    wallSurface,
    insulatedWallSurface,
    nonInsulatedWallSurface,
    windowsSurfaceS,
    windowsSurfaceWE,
    windowsSurfaceN,
    houseTemp
  ) {
    this._houseType = houseType;
    this._floorCount = floorCount;
    this._floorHeight = floorHeight;
    this._totalSurface = totalSurface;
    this._floorArea = floorSurface;
    this._roofSurface = roofSurface;
    this._wallSurface = wallSurface;
    this._insulatedWallSurface = insulatedWallSurface;
    this._nonInsulatedWallSurface = nonInsulatedWallSurface;
    this._windowsSurfaceS = windowsSurfaceS;
    this._windowsSurfaceWE = windowsSurfaceWE;
    this._windowsSurfaceN = windowsSurfaceN;
    this._houseTemp = houseTemp;
    this._floorSurface = floorSurface;
  }

  get floorArea() {
    return this._floorArea;
  }

  set floorArea(value) {
    this._floorArea = value;
  }

  get doorType() {
    return this._doorType;
  }

  set doorType(value) {
    this._doorType = value;
  }

  get houseType() {
    return this._houseType;
  }

  set houseType(value) {
    this._houseType = value;
  }

  get floorCount() {
    return this._floorCount;
  }

  set floorCount(value) {
    this._floorCount = value;
  }

  get floorHeight() {
    return this._floorHeight;
  }

  set floorHeight(value) {
    this._floorHeight = value;
  }

  get totalSurface() {
    return this._totalSurface;
  }

  set totalSurface(value) {
    this._totalSurface = value;
  }

  get floorSurface() {
    return this._floorSurface;
  }

  set floorSurface(value) {
    this._floorSurface = value;
  }

  get roofSurface() {
    return this._roofSurface;
  }

  set roofSurface(value) {
    this._roofSurface = value;
  }

  get wallSurface() {
    return this._wallSurface;
  }

  set wallSurface(value) {
    this._wallSurface = value;
  }

  get insulatedWallSurface() {
    return this._insulatedWallSurface;
  }

  set insulatedWallSurface(value) {
    this._insulatedWallSurface = value;
  }

  get nonInsulatedWallSurface() {
    return this._nonInsulatedWallSurface;
  }

  set nonInsulatedWallSurface(value) {
    this._nonInsulatedWallSurface = value;
  }

  get windowsSurfaceS() {
    return this._windowsSurfaceS;
  }

  set windowsSurfaceS(value) {
    this._windowsSurfaceS = value;
  }

  get windowsSurfaceWE() {
    return this._windowsSurfaceWE;
  }

  set windowsSurfaceWE(value) {
    this._windowsSurfaceWE = value;
  }

  get windowsSurfaceN() {
    return this._windowsSurfaceN;
  }

  set windowsSurfaceN(value) {
    this._windowsSurfaceN = value;
  }

  get houseTemp() {
    return this._houseTemp;
  }

  set houseTemp(value) {
    this._houseTemp = value;
  }
}
