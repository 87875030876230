/**
 * Created by Dom on 2016-06-20.
 */

export class StationItem {
  _code;
  _localeCode;
  _name;

  _defaultStation;

  _minCalcTemp;
  _temperatures;
  _groundTemperatures;
  _insolationS;
  _insolationWE;
  _insolationN;
  _optimalInsolation;

  _mapX;
  _mapY;

  get code() {
    return this._code;
  }

  set code(value) {
    this._code = value;
  }

  get localeCode() {
    return this._localeCode;
  }

  set localeCode(value) {
    this._localeCode = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get defaultStation() {
    return this._defaultStation;
  }

  set defaultStation(value) {
    this._defaultStation = value;
  }

  get minCalcTemp() {
    return this._minCalcTemp;
  }

  set minCalcTemp(value) {
    this._minCalcTemp = value;
  }

  get temperatures() {
    return this._temperatures;
  }

  set temperatures(value) {
    this._temperatures = value;
  }

  get groundTemperatures() {
    return this._groundTemperatures;
  }

  set groundTemperatures(value) {
    this._groundTemperatures = value;
  }

  get insolationS() {
    return this._insolationS;
  }

  set insolationS(value) {
    this._insolationS = value;
  }

  get insolationWE() {
    return this._insolationWE;
  }

  set insolationWE(value) {
    this._insolationWE = value;
  }

  get insolationN() {
    return this._insolationN;
  }

  set insolationN(value) {
    this._insolationN = value;
  }

  get optimalInsolation() {
    return this._optimalInsolation;
  }

  set optimalInsolation(value) {
    this._optimalInsolation = value;
  }

  get mapX() {
    return this._mapX;
  }

  set mapX(value) {
    this._mapX = value;
  }

  get mapY() {
    return this._mapY;
  }

  set mapY(value) {
    this._mapY = value;
  }

  isDefaultStation = () => {
    return this._defaultStation;
  };

  setDefaultStation = value => {
    this._defaultStation = value;
  };
}
