export class HouseStandardTypeModel {
    get actual() {
        return this._actual;
    }

    set actual(value) {
        this._actual = value;
    }

    _roofInsulationMaterialType;
    _roofUValue;

    _wallInsulationMaterialType;
    _wallUValue;
    _doorType;

    _floorInsulationMaterialType;
    _floorUValue;
    _foundationsType;

    _basementWallInsulationMaterialType;
    _basementWallUValue;

    _basementFloorInsulationMaterialType;
    _basementFloorUValue;

    _airConditioning;
    _pvType;
    _pvPower;


    _ventilationMethod;
    _airTightness;

    _thermalBridges;
    _windowsType;
    _shuttersEnabled;


    _multiFamilyHeatingSourceType;
    _warmWaterHeatingSourceType;
    _multiFamilyWarmWaterHeatingSourceType;
    _solarCollectorData;
    _warmWaterDistribution;
    _warmWaterStorage;

    _heatingSource;
    _heatingSourceType;
    _heatingSystem;
    _heatingFuel;
    _waterHeatingSource;
    _waterHeatingSourceLocation;
    _heatingEfficiency;
    _coolingEfficiency;
    _waterHeatingEfficiency;
    _waterHeatingFuel;

    _southWindowSurfaceFactor;
    _northWindowSurface;

    _carbonDioxideSensor;

    _latest;
    _actual;

    constructor(roofInsulationMaterialType, wallInsulationMaterialType, floorInsulationMaterialType,
                basementWallInsulationMaterialType, basementFloorInsulationMaterialType,
                ventilationMethod, foundationsType, thermalBridges, doorType, windowsType,
                heatingSource, heatingSourceType, heatingSystem, heatingFuel,
                heatingEfficiency, coolingEfficiency, waterHeatingEfficiency,
                waterHeatingSource, waterHeatingSourceLocation, waterHeatingFuel,
                
                
                solarCollectorData, warmWaterDistribution,
                warmWaterStorage, roofUValue, wallUValue, floorUValue,
                basementFloorUValue, basementWallUValue,
                pvType,pvPower,
                airTightness, southWindowSurfaceFactor, northWindowSurfaceFactor, shuttersEnabled,
                carbonDioxideSensor, latest, actual, airConditioning) {
        this._roofInsulationMaterialType = roofInsulationMaterialType;
        this._roofUValue = roofUValue;
        this._wallInsulationMaterialType = wallInsulationMaterialType;
        this._wallUValue = wallUValue;
        this._doorType = doorType;
        this._floorInsulationMaterialType = floorInsulationMaterialType;
        this._floorUValue = floorUValue;
        this._foundationsType = foundationsType;
        this._ventilationMethod = ventilationMethod;
        this._airTightness = airTightness;
        this._thermalBridges = thermalBridges;
        this._windowsType = windowsType;
        this._shuttersEnabled = shuttersEnabled;
        this._heatingSourceType = heatingSourceType;
        
        
        
        this._solarCollectorData = solarCollectorData;
        this._warmWaterDistribution = warmWaterDistribution;
        this._warmWaterStorage = warmWaterStorage;
        this._southWindowSurfaceFactor = southWindowSurfaceFactor;
        this._northWindowSurface = northWindowSurfaceFactor;
        this._carbonDioxideSensor = carbonDioxideSensor;
        this._northWindowSurfaceFactor = northWindowSurfaceFactor;
        this._latest = latest;
        this._basementWallInsulationMaterialType = basementWallInsulationMaterialType;
        this._basementFloorInsulationMaterialType = basementFloorInsulationMaterialType;
        this._heatingSource = heatingSource;
        this._heatingSystem = heatingSystem;
        this._heatingFuel = heatingFuel;
        this._waterHeatingSource = waterHeatingSource;
        this._waterHeatingSourceLocation = waterHeatingSourceLocation;
        this._waterHeatingFuel = waterHeatingFuel;
        this._basementFloorUValue = basementFloorUValue;
        this._basementWallUValue = basementWallUValue;
        this._airConditioning = airConditioning;
        this._pvType = pvType;
        this._pvPower = pvPower;
        this._heatingEfficiency = heatingEfficiency;
        this._coolingEfficiency = coolingEfficiency;
        this._waterHeatingEfficiency = waterHeatingEfficiency;
        this._actual = actual;

    }


    get heatingEfficiency() {
        return this._heatingEfficiency;
    }

    set heatingEfficiency(value) {
        this._heatingEfficiency = value;
    }

    get coolingEfficiency() {
        return this._coolingEfficiency;
    }

    set coolingEfficiency(value) {
        this._coolingEfficiency = value;
    }

    get waterHeatingEfficiency() {
        return this._waterHeatingEfficiency;
    }

    set waterHeatingEfficiency(value) {
        this._waterHeatingEfficiency = value;
    }

    get basementWallInsulationMaterialType() {
        return this._basementWallInsulationMaterialType;
    }

    set basementWallInsulationMaterialType(value) {
        this._basementWallInsulationMaterialType = value;
    }

    get basementFloorInsulationMaterialType() {
        return this._basementFloorInsulationMaterialType;
    }

    set basementFloorInsulationMaterialType(value) {
        this._basementFloorInsulationMaterialType = value;
    }

    get heatingSource() {
        return this._heatingSource;
    }

    set heatingSource(value) {
        this._heatingSource = value;
    }

    get heatingSystem() {
        return this._heatingSystem;
    }

    set heatingSystem(value) {
        this._heatingSystem = value;
    }

    get heatingFuel() {
        return this._heatingFuel;
    }

    set heatingFuel(value) {
        this._heatingFuel = value;
    }

    get waterHeatingSource() {
        return this._waterHeatingSource;
    }

    set waterHeatingSource(value) {
        this._waterHeatingSource = value;
    }

    get waterHeatingSourceLocation() {
        return this._waterHeatingSourceLocation;
    }

    set waterHeatingSourceLocation(value) {
        this._waterHeatingSourceLocation = value;
    }

    get basementFloorUValue() {
        return this._basementFloorUValue;
    }

    set basementFloorUValue(value) {
        this._basementFloorUValue = value;
    }

    get basementWallUValue() {
        return this._basementWallUValue;
    }

    set basementWallUValue(value) {
        this._basementWallUValue = value;
    }

    get airConditioning() {
        return this._airConditioning;
    }

    set airConditioning(value) {
        this._airConditioning = value;
    }

    get pvType() {
        return this._pvType;
    }

    set pvType(value) {
        this._pvType = value;
    }

    get pvPower() {
        return this._pvPower;
    }

    set pvPower(value) {
        this._pvPower = value;
    }

    get roofInsulationMaterialType() {
        return this._roofInsulationMaterialType;
    }

    set roofInsulationMaterialType(value) {
        this._roofInsulationMaterialType = value;
    }

    get wallInsulationMaterialType() {
        return this._wallInsulationMaterialType;
    }

    set wallInsulationMaterialType(value) {
        this._wallInsulationMaterialType = value;
    }

    get floorInsulationMaterialType() {
        return this._floorInsulationMaterialType;
    }

    set floorInsulationMaterialType(value) {
        this._floorInsulationMaterialType = value;
    }

    get ventilationMethod() {
        return this._ventilationMethod;
    }

    set ventilationMethod(value) {
        this._ventilationMethod = value;
    }

    get foundationsType() {
        return this._foundationsType;
    }

    set foundationsType(value) {
        this._foundationsType = value;
    }

    get thermalBridges() {
        return this._thermalBridges;
    }

    set thermalBridges(value) {
        this._thermalBridges = value;
    }

    get doorType() {
        return this._doorType;
    }

    set doorType(value) {
        this._doorType = value;
    }

    get windowsType() {
        return this._windowsType;
    }

    set windowsType(value) {
        this._windowsType = value;
    }

    get heatingSourceType() {
        return this._heatingSourceType;
    }

    set heatingSourceType(value) {
        this._heatingSourceType = value;
    }

    get multiFamilyHeatingSourceType() {
        return this._multiFamilyHeatingSourceType;
    }

    set multiFamilyHeatingSourceType(value) {
        this._multiFamilyHeatingSourceType = value;
    }

    get warmWaterHeatingSourceType() {
        return this._warmWaterHeatingSourceType;
    }

    set warmWaterHeatingSourceType(value) {
        this._warmWaterHeatingSourceType = value;
    }

    get multiFamilyWarmWaterHeatingSourceType() {
        return this._multiFamilyWarmWaterHeatingSourceType;
    }

    set multiFamilyWarmWaterHeatingSourceType(value) {
        this._multiFamilyWarmWaterHeatingSourceType = value;
    }

    get solarCollectorData() {
        return this._solarCollectorData;
    }

    set solarCollectorData(value) {
        this._solarCollectorData = value;
    }

    get warmWaterDistribution() {
        return this._warmWaterDistribution;
    }

    set warmWaterDistribution(value) {
        this._warmWaterDistribution = value;
    }

    get warmWaterStorage() {
        return this._warmWaterStorage;
    }

    set warmWaterStorage(value) {
        this._warmWaterStorage = value;
    }

    get roofUValue() {
        return this._roofUValue;
    }

    set roofUValue(value) {
        this._roofUValue = value;
    }

    get wallUValue() {
        return this._wallUValue;
    }

    set wallUValue(value) {
        this._wallUValue = value;
    }

    get floorUValue() {
        return this._floorUValue;
    }

    set floorUValue(value) {
        this._floorUValue = value;
    }

    get airTightness() {
        return this._airTightness;
    }

    set airTightness(value) {
        this._airTightness = value;
    }

    get southWindowSurfaceFactor() {
        return this._southWindowSurfaceFactor;
    }

    set southWindowSurfaceFactor(value) {
        this._southWindowSurfaceFactor = value;
    }

    get northWindowSurfaceFactor() {
        return this._northWindowSurfaceFactor;
    }

    set northWindowSurfaceFactor(value) {
        this._northWindowSurfaceFactor = value;
    }

    get shuttersEnabled() {
        return this._shuttersEnabled;
    }

    set shuttersEnabled(value) {
        this._shuttersEnabled = value;
    }

    get carbonDioxideSensor() {
        return this._carbonDioxideSensor;
    }

    set carbonDioxideSensor(value) {
        this._carbonDioxideSensor = value;
    }


    get latest() {
        return this._latest;
    }

    set latest(value) {
        this._latest = value;
    }

    get waterHeatingFuel() {
        return this._waterHeatingFuel;
    }

    set waterHeatingFuel(value) {
        this._waterHeatingFuel = value;
    }
}
