import { UPDATE_ALL, UPDATE_REPORTS } from "../actionTypes";

const initialState = {
  irr: 0,
  irrYears: 25,
  irrPriceChange: 0,
  irrFund: 50000,
  funding: 0,
  taxRelief: 32,
  myElectricity: 5000,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_REPORTS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
      };
    }
    case UPDATE_ALL: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
