import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { Popup } from "../Helpers/Popup";
import cx from "classnames";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";

export const LabelBaloon = ({ name, headerText, icon, message }) => {
  const [showBaloon, handleBaloonClick] = useState(window.innerWidth >= 1024);
  return (
    <div
      className={cx(
        "baloon  popover  d-flex align-items-center",
        { hidden: !showBaloon },
        { "insolation-baloon bs-popover-left": name === "insolation" },
        { "source-loss-baloon bs-popover-right": name === "source_loss" }
      )}
    >
      <FormattedMessage id={headerText} defaultMessage="">
        {message => (
          <span
            title={message}
            className={"baloon-icon"}
            onClick={() => handleBaloonClick(!showBaloon)}
          >
            <IcomoonReact iconSet={iconSet} size={22} icon={icon} />
          </span>
        )}
      </FormattedMessage>

      <div className="arrow m-0" />
      <div className={"popover-body"}>
        <div className={"content"}>
          <div className={"d-flex align-items-center"}>
            <IcomoonReact
              className={"custom-icon mr-2 "}
              iconSet={iconSet}
              size={16}
              icon={icon}
            />
            <FormattedMessage id={headerText} defaultMessage="">
              {message => <h3 className={"mb-0 px-0 col-10"}> {message}</h3>}
            </FormattedMessage>
          </div>
        </div>
        <Popup
          message={message}
          placement={cx({
            left: name === "source_loss",
            right: name === "insolation",
          })}
        />
      </div>
    </div>
  );
};
