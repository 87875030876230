import {
  UPDATE_INSULATION,
  UPDATE_INSULATION_BY_TYPE,
  UPDATE_INSULATION_BASEMENT_BY_TYPE,
  UPDATE_WINDOWS,
  UPDATE_VENTILATION,
  UPDATE_VENTILATION_BY_ID,
  UPDATE_PERSONS,
  UPDATE_HOUSE,
  UPDATE_HEATING,
  UPDATE_HEATING_BY_ID,
  UPDATE_HOUSE_BY_ID,
  UPDATE_HOUSE_ROOF_BY_ID,
  UPDATE_HEATING_AND_WATER,
  UPDATE_CONVERSION,
  UPDATE_HEATING_ALL,
  UPDATE_WATER,
  UPDATE_COLLECTORS,
  UPDATE_DISTRIBUTION,
  UPDATE_SURFACE,
  ADD_HOUSE_WALL,
  UPDATE_HOUSE_WALL,
  DELETE_HOUSE_WALL,
  REPLACE_HOUSE_WALLS,
  UPDATE_ALL,
  UPDATE_HOUSE_BASEMENT_BY_ID,
  UPDATE_HOUSE_BASEMENT,
  UPDATE_PHOTOVOLTAIC,
  UPDATE_PHOTOVOLTAIC_BY_ID,
  UPDATE_AIRCONDITIONING_BY_ID,
  UPDATE_AIRCONDITIONING,
  UPDATE_BY_ID,
  UPDATE_INSOLATION,
  UPDATE_REPORTS,
  UPDATE_COSTS,
  UPDATE_INSULATION_BY_TYPE_AND_ID,
  UPDATE_HOUSE_ROOF,
} from "./actionTypes";

export const updateWindows = (window, thermalBridge, shutters) => dispatch => {
  dispatch({
    type: UPDATE_WINDOWS,
    payload: {
      window: window,
      thermalBridge: thermalBridge,
      shutters: shutters,
    },
  });
  return Promise.resolve();
};
export const updateVentilation = payload => dispatch => {
  dispatch({
    type: UPDATE_VENTILATION,
    payload: {
      ventilationData: payload,
    },
  });
  return Promise.resolve();
};

export const updateVentilationById = payload => dispatch => {
  dispatch({
    type: UPDATE_VENTILATION_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updatePersons = payload => dispatch => {
  dispatch({
    type: UPDATE_PERSONS,
    payload: payload,
  });
  return Promise.resolve();
};

export const updateInsulationByType = insulation => dispatch => {
  dispatch({
    type: UPDATE_INSULATION_BY_TYPE,
    payload: {
      id: insulation.id,
      insulation: insulation.values,
    },
  });
  return Promise.resolve();
};

export const updateInsulationByTypeAndId = ({
  type,
  id,
  value,
}) => dispatch => {
  dispatch({
    type: UPDATE_INSULATION_BY_TYPE_AND_ID,
    payload: {
      id: id,
      type: type,
      value: value,
    },
  });
  return Promise.resolve();
};

export const updateInsulationBasementByType = insulation => dispatch => {
  dispatch({
    type: UPDATE_INSULATION_BASEMENT_BY_TYPE,
    payload: {
      id: insulation.id,
      insulation: insulation.values,
    },
  });
  return Promise.resolve();
};

export const updateInsulation = payload => dispatch => {
  dispatch({
    type: UPDATE_INSULATION,
    payload: {
      insulationData: payload.insulationData,
    },
  });
  return Promise.resolve();
};

export const updateSurface = payload => dispatch => {
  dispatch({
    type: UPDATE_SURFACE,
    payload: payload,
  });
  return Promise.resolve();
};

export const updateHouse = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE,
    payload: {
      houseData: payload.houseData,
    },
  });
  return Promise.resolve();
};

export const addHouseWall = payload => dispatch => {
  dispatch({
    type: ADD_HOUSE_WALL,
    payload: {
      wallsData: payload.wallsData,
    },
  });
  return Promise.resolve();
};

export const updateHouseWall = (
  index,
  direction,
  width,
  window,
  insulated
) => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_WALL,
    payload: {
      index: index,
      direction: direction,
      width: width,
      window: window,
      insulated: insulated,
    },
  });
  return Promise.resolve();
};

export const deleteHouseWall = index => dispatch => {
  dispatch({
    type: DELETE_HOUSE_WALL,
    payload: {
      index: index,
    },
  });
  return Promise.resolve();
};

export const replaceHouseWalls = payload => dispatch => {
  dispatch({
    type: REPLACE_HOUSE_WALLS,
    payload: payload,
  });
  return Promise.resolve();
};

export const updateHouseById = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateHouseRoof = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_ROOF,
    payload: {
      roofData: payload.roofData,
    },
  });
  return Promise.resolve();
};

export const updateHouseRoofById = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_ROOF_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateHouseBasementById = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_BASEMENT_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateHouseBasement = payload => dispatch => {
  dispatch({
    type: UPDATE_HOUSE_BASEMENT,
    payload: {
      basementData: payload.basementData,
    },
  });
  return Promise.resolve();
};

export const updateHeating = payload => dispatch => {
  dispatch({
    type: UPDATE_HEATING,
    payload: {
      heatingData: payload.heatingData,
    },
  });
  return Promise.resolve();
};

export const updateCollectors = payload => dispatch => {
  dispatch({
    type: UPDATE_COLLECTORS,
    payload: {
      collectorsData: payload.collectorsData,
    },
  });
  return Promise.resolve();
};

export const updateHeatingById = payload => dispatch => {
  dispatch({
    type: UPDATE_HEATING_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateHeatingAndWater = payload => dispatch => {
  dispatch({
    type: UPDATE_HEATING_AND_WATER,
    payload: {
      heatingData: payload.heatingData,
    },
  });
  return Promise.resolve();
};

export const updateWater = payload => dispatch => {
  dispatch({
    type: UPDATE_WATER,
    payload: {
      heatingData: payload.heatingData,
    },
  });
  return Promise.resolve();
};

export const updateDistribution = payload => dispatch => {
  dispatch({
    type: UPDATE_DISTRIBUTION,
    payload: {
      heatingData: payload.heatingData,
    },
  });
  return Promise.resolve();
};

export const updateConversion = payload => dispatch => {
  dispatch({
    type: UPDATE_CONVERSION,
    payload: {
      conversionData: payload.conversionData,
    },
  });
  return Promise.resolve();
};

export const updateHeatingAll = payload => dispatch => {
  dispatch({
    type: UPDATE_HEATING_ALL,
    payload: {
      heatingData: payload.heatingData,
    },
  });
  return Promise.resolve();
};

export const updateAll = payload => dispatch => {
  dispatch({
    type: UPDATE_ALL,
    payload: {
      data: payload.data,
    },
  });
  return Promise.resolve();
};

export const updatePhotovoltaicById = payload => dispatch => {
  dispatch({
    type: UPDATE_PHOTOVOLTAIC_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updatePhotovoltaic = payload => dispatch => {
  dispatch({
    type: UPDATE_PHOTOVOLTAIC,
    payload: {
      data: payload.data,
    },
  });
  return Promise.resolve();
};

export const updateAirconditioningById = payload => dispatch => {
  dispatch({
    type: UPDATE_AIRCONDITIONING_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateAirconditioning = payload => dispatch => {
  dispatch({
    type: UPDATE_AIRCONDITIONING,
    payload: {
      data: payload.data,
    },
  });
  return Promise.resolve();
};

export const updateById = payload => dispatch => {
  dispatch({
    type: UPDATE_BY_ID,
    payload: {
      id: payload.id,
      value: payload.value,
    },
  });
  return Promise.resolve();
};

export const updateInsolation = payload => dispatch => {
  dispatch({
    type: UPDATE_INSOLATION,
    payload: {
      data: payload.data,
    },
  });
  return Promise.resolve();
};

export const updateReports = payload => dispatch => {
  dispatch({
    type: UPDATE_REPORTS,
    payload: {
      data: payload.data,
    },
  });
  return Promise.resolve();
};

export const updateCosts = payload => dispatch => {
  dispatch({
    type: UPDATE_COSTS,
    payload: {
      data: payload,
    },
  });
  return Promise.resolve();
};
