export class HeatingSourceTypeConfig {
  _heatingSourceTypes;

  _detachedHeatingSourceTypes;
  _multiFamilyHeatingSourceTypes;

  _warmWaterDetachedHeatingSourceTypes;
  _warmWaterMultiFamilyHeatingSourceTypes;

  _electricityHeatingSourceType;

  _detachedHeatingSelectedIndex;
  _warmWaterDetachedSelectedIndex;
  _multiFamilySelectedIndex;
  _warmWaterMultiFamilySelectedIndex;

  get heatingSourceTypes() {
    return this._heatingSourceTypes;
  }

  set heatingSourceTypes(value) {
    this._heatingSourceTypes = value;
  }

  get detachedHeatingSourceTypes() {
    return this._detachedHeatingSourceTypes;
  }

  set detachedHeatingSourceTypes(value) {
    this._detachedHeatingSourceTypes = value;
  }

  get multiFamilyHeatingSourceTypes() {
    return this._multiFamilyHeatingSourceTypes;
  }

  set multiFamilyHeatingSourceTypes(value) {
    this._multiFamilyHeatingSourceTypes = value;
  }

  get warmWaterDetachedHeatingSourceTypes() {
    return this._warmWaterDetachedHeatingSourceTypes;
  }

  set warmWaterDetachedHeatingSourceTypes(value) {
    this._warmWaterDetachedHeatingSourceTypes = value;
  }

  get warmWaterMultiFamilyHeatingSourceTypes() {
    return this._warmWaterMultiFamilyHeatingSourceTypes;
  }

  set warmWaterMultiFamilyHeatingSourceTypes(value) {
    this._warmWaterMultiFamilyHeatingSourceTypes = value;
  }

  get electricityHeatingSourceType() {
    return this._electricityHeatingSourceType;
  }

  set electricityHeatingSourceType(value) {
    this._electricityHeatingSourceType = value;
  }

  get detachedHeatingSelectedIndex() {
    return this._detachedHeatingSelectedIndex;
  }

  set detachedHeatingSelectedIndex(value) {
    this._detachedHeatingSelectedIndex = value;
  }

  get warmWaterDetachedSelectedIndex() {
    return this._warmWaterDetachedSelectedIndex;
  }

  set warmWaterDetachedSelectedIndex(value) {
    this._warmWaterDetachedSelectedIndex = value;
  }

  get multiFamilySelectedIndex() {
    return this._multiFamilySelectedIndex;
  }

  set multiFamilySelectedIndex(value) {
    this._multiFamilySelectedIndex = value;
  }

  get warmWaterMultiFamilySelectedIndex() {
    return this._warmWaterMultiFamilySelectedIndex;
  }

  set warmWaterMultiFamilySelectedIndex(value) {
    this._warmWaterMultiFamilySelectedIndex = value;
  }
}
