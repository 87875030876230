import { fromWattsToKwhRatio } from "../../../components/Helpers/ConvertToUnit";
import { IrrCounter } from "../../../util/IrrCounter";
import clonedeep from "lodash.clonedeep";

export function WindowOptimizer(
  type,
  cancel,
  refDataContext,
  newDataContext,
  heatMasterController,
  reports,
  dictionaryConfig
) {
  const windowsList = dictionaryConfig.windowTypeConfiguration.windowTypes;
  const newHouseData = newDataContext.houseData;
  const oldElement = refDataContext.houseData.windowElement;
  const element = newDataContext.houseData.windowElement;
  const refHeatingEff = refDataContext.heatingData.heatingEfficiency;
  const refPricePerKWh =
    refDataContext.conversionData.pricePerKWh / (refHeatingEff / 100);

  const oldItem = windowsList.filter(
    item => item.id === oldElement.windowsType.id
  )[0];
  let energy = heatMasterController.performCalculationForOptimizer(
    refDataContext
  );
  energy =
    energy.yearlyAggregatedConvertedData.enWindows * fromWattsToKwhRatio();

  let newEnergy = 0;
  let energyDiff = 0;
  let newIrr = 0;

  let materialCost = oldItem.price;
  let cost = 0;

  let windowId = oldElement.windowsType.id;

  const surface = newHouseData.surfaceData.windowsSurface;

  for (let i = 0; i < windowsList.length; i++) {
    const work =
      newHouseData.buildingType === "current"
        ? calculateWork(windowsList[i].work, surface)
        : 0;
    const material =
      newHouseData.buildingType === "current"
        ? calculateMaterial(windowsList[i].price, surface)
        : calculateMaterialDifference(
            materialCost,
            windowsList[i].price,
            surface
          );
    cost = work + material;

    let el = clonedeep(element);

    el.uValue = windowsList[i].uValue;
    el.winGain = windowsList[i].winGain;

    newDataContext.houseData.windowElement = el;
    newEnergy = heatMasterController.performCalculationForOptimizer(
      newDataContext
    );
    newEnergy =
      newEnergy.yearlyAggregatedConvertedData.enWindows * fromWattsToKwhRatio();
    newIrr = IrrCounter(
      cost,
      energy,
      newEnergy,
      50,
      refPricePerKWh,
      refPricePerKWh,
      reports.irrPriceChange
    );

    if (energy - newEnergy > energyDiff && newIrr >= reports.irr) {
      energyDiff = energy - newEnergy;
      windowId = windowsList[i].id;
    }
  }

  return {
    window: !cancel ? windowId : oldElement.windowsType.id,
    thermalBridge: element.thermalBridgesType.id,
    shutters: element.shutters,
  };
}

const calculateMaterial = (price, surface) => {
  return price * surface;
};

const calculateWork = (work, surface) => {
  return work * surface;
};


const calculateMaterialDifference = (price, newPrice, surface) => {
  return newPrice * surface - price * surface;
};
