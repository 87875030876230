
export function CollectorsOptimizer (
    type, cancel, dataContext, heatMasterController, surface, pvType,
    defaultMaxPower, defaultPvType, defaultEnergyNetwork
) {
    let enWarmWater= 1;
    let enCollectorSolarGain = 0;
    let i = dataContext.solarCollectorData.surface;
    let k = 1;
    let energyResults;
    do {
        dataContext.solarCollectorData.surface = i;
        i++;
        k++;
        energyResults = heatMasterController.performCalculationForOptimizer(dataContext);
        enWarmWater = energyResults.yearlyAggregatedConvertedData.enWarmWater;
        enCollectorSolarGain = energyResults.yearlyAggregatedConvertedData.enCollectorSolarGain;

        if(k > 300){
            break;
        }
    }
    while(enWarmWater > enCollectorSolarGain);
    
}