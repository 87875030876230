export const HeatingSourceReducer = newDataContext => {
  const air_air = {
    conversionData: {
      heatingSource: "hm.dropdown.heating.source.heat_pump",
      houseHeatingEfficiency: 3.5 * 0.98,
      waterHeatingEfficiency: 0.9,
      waterHeatingSource: "hm.dropdown.heating.source.electricity.water",
      isElectric: true,
      naturalUnitPrice:
        newDataContext.conversionData.electricityPricePerKWhLocal,
      naturalUnitPriceLocal:
        newDataContext.conversionData.electricityPricePerKWhLocal,
      emissionCoefficient: 0.89,
      finalToPrimaryCoefficient: 3,
      waterEmissionCoefficient: 0.89,
      naturalUnitPriceChanged: 3,
      pricePerKWh: newDataContext.conversionData.electricityPricePerKWhLocal,
      isWaterElectric: true,
      warmWaterFinalToPrimaryCoefficient: 3,
      waterPricePerKWh:
        newDataContext.conversionData.electricityPricePerKWhLocal,
      warmWaterStorageId: "hm.water-storage.heat_node_without_storage",
    },
    newHeatingData: {
      heatingSource: "hm.dropdown.heating.source.heat_pump",
      heatingType: "hm.dropdown.heating.source.air_air",
      heatingSystem: undefined,
      heatingEfficiency: 350,
      coolingEfficiency: 350,
      heatingSystemEfficiency: 98,
      subdevicesEnergyConsumption: undefined,
      heatingFuel: "hm.dropdown.heating.fuel.electric",
      pricePerKWh: newDataContext.conversionData.electricityPricePerKWhLocal,
      naturalUnitPrice:
        newDataContext.conversionData.electricityPricePerKWhLocal,
      naturalUnit: "hm.natural_unit.kwh",
      calorificValue: 3.6,
      calorificValueUnit: undefined,
      finalToPrimaryCoefficient: 3,
      emissionCoefficient: 0.89,
      heatingValueEditable: false,
      showNaturalUnit: false,
      waterHeatingSource: "hm.dropdown.heating.source.electricity.water",
      waterHeatingEfficiency: 90,
      waterPricePerKWh:
        newDataContext.conversionData.electricityPricePerKWhLocal,
      waterHeatingFuel: "hm.dropdown.heating.fuel.electric",
      warmWaterFinalToPrimaryCoefficient: 3,
      waterEmissionCoefficient: 0.89,
      waterSubdevicesEnergyConsumption: undefined,
      warmWaterStorageId: "hm.water-storage.heat_node_without_storage",
      waterSavings: true,
    },
  };

  const air_water = {
    ...air_air,
    conversionData: {
      ...air_air.conversionData,
      warmWaterStorageId: "hm.water-storage.modern_storage",
      houseHeatingEfficiency: 4.5 * 0.85,
      waterHeatingEfficiency: 3,
      waterHeatingSource: "hm.dropdown.heating.source.the_same",
    },
    newHeatingData: {
      ...air_air.newHeatingData,
      heatingType: "hm.dropdown.heating.source.air_water",
      heatingSystem: "hm.label.heating.distribution.floor",
      heatingEfficiency: 450,
      waterHeatingEfficiency: 300,
      heatingSystemEfficiency: 85,
      warmWaterStorageId: "hm.water-storage.modern_storage",
      waterHeatingSource: "hm.dropdown.heating.source.the_same",
    },
  };

  return [
    { ...air_air },
    {
      ...air_air,
      conversionData: {
        ...air_air.conversionData,
        waterHeatingSource: "hm.dropdown.heating.source.heat_pump.cwu",
        waterHeatingEfficiency: 3,
        warmWaterStorageId: "hm.water-storage.modern_storage",
      },
      newHeatingData: {
        ...air_air.newHeatingData,
        waterHeatingSource: "hm.dropdown.heating.source.heat_pump.cwu",
        waterHeatingEfficiency: 300,
        warmWaterStorageId: "hm.water-storage.modern_storage",
      },
    },
    { ...air_water },
    {
      ...air_water,
      conversionData: {
        ...air_water.conversionData,
        houseHeatingEfficiency: 4.5 * 0.98,
        waterHeatingEfficiency: 3,
      },
      newHeatingData: {
        ...air_water.newHeatingData,
        heatingSystem: "hm.label.heating.distribution.capillary",
        heatingEfficiency: 450,
        coolingEfficiency: 450,
        heatingSystemEfficiency: 98,
        waterHeatingEfficiency: 300,
      },
    },
  ];
};
