import {UPDATE_VENTILATION_BY_ID, UPDATE_VENTILATION} from "../actionTypes";
import {VentilationFrequency} from "../../constants/combo/VentilationFrequency";
import {RecuperatorLocation} from "../../constants/combo/RecuperatorLocation";

const initialState = {
    ventilation: 'hm.ventilation-type.mechanical',
    airTightness: 'hm.tightness.tight_no_recup',
    co2Sensor: false,
    gwcSet: false,
    defrosting: false,
    humidification: false,
    isMechanical: true,
    recuperator: false,
    recuperatorLocation: RecuperatorLocation.DEFAULT,
    ventilationFreq: VentilationFrequency.STANDARD,
    n50: 3,
    patency: 100
};

export default function(state = initialState, action){
    switch(action.type){
        case UPDATE_VENTILATION: {
            const ventilationData = action.payload.ventilationData;
            return {
                ...state,
                ...ventilationData
            };
        }
        case UPDATE_VENTILATION_BY_ID: {
            const {id, value} = action.payload;
            return {
                ...state,
                [id]: value
            };
        }
        default:
            return state;
    }
}