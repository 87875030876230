import {UPDATE_PERSONS} from "../actionTypes";
import {GlobalValues} from "../../constants/GlobalValues";

const initialState = {
    persons: GlobalValues.INITIAL_PERSON_NUMBER,
    livingNeeds: 2000,
    livingNeedsChanged: false
};

export default function(state = initialState, action){
    switch(action.type){
        case UPDATE_PERSONS: {
            const data = action.payload;
            return {
                ...state,
                ...data,
            };
        }
        default:
            return state;
    }
}