import React from "react";
import { connect } from "react-redux";
import { updateHeatingById } from "../../redux/actions";
import { getHeating, getPersons } from "../../redux/selectors";

import { FormattedMessage, injectIntl } from "react-intl";
import "./BaseBaloon.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import { DropDownList } from "../Helpers/DropDownList";
import { DropDownType } from "../../constants/combo/DropDownType";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import { WarmWaterConsumptionType } from "../../config/dictionary/model/WarmWaterConsumptionType";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { Thermoderm } from "../Helpers/Thermoderm";

export class WaterBaloon extends HeatMaster {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      showBaloon: window.innerWidth >= 1024,
    };
    this.configurationRead(this.props.selectedIndex);
  }

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  configurationRead = selectedIndex => {
    if (this.props.type === DropDownType.WARM_WATER) {
      this.props.dispatch(
        updateHeatingById({
          id: "consumptionIntensityId",
          value: this.props.dataProvider[selectedIndex].data.id,
        })
      );
    } else if (this.type === DropDownType.VENTILATION_FREQUENCY) {
    }
  };

  valueChanged = e => {
    this.props
      .dispatch(
        updateHeatingById({
          id: "consumptionIntensityId",
          value: e,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  waterSavingsChanged = e => {
    this.props
      .dispatch(
        updateHeatingById({
          id: "waterSavings",
          value: e,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  render() {
    let worse;
    let better;
    if (this.props.invalidDataOptimizer) {
      worse = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.WORSE
      );
      worse = worse.includes(true);
      better = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.BETTER
      );
      better = better.includes(true);
    }
    return (
      <Card
        className={cx(
          "popover d-flex align-items-center baloon dropdown-baloon-water bs-popover-left",
          { hidden: !this.state.showBaloon },
          { warning: this.props.invalidDataOptimizer && worse },
          { success: this.props.invalidDataOptimizer && better && !worse }
        )}
      >
        <FormattedMessage id={this.props.headerText} defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={22}
                icon={this.props.icon}
              />
            </span>
          )}
        </FormattedMessage>
        <div className="arrow m-0" />
        <div className={"popover-body"}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={this.props.eventKey}
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2"}
                  iconSet={iconSet}
                  size={16}
                  icon={this.props.icon}
                />

                <FormattedMessage
                  id={this.props.headerText}
                  defaultMessage=""
                  tagName={"h3"}
                >
                  {message => <h3 className={"mb-0 pr-3"}> {message}</h3>}
                </FormattedMessage>
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1", {
                  reversed: !this.state.show,
                })}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={this.props.eventKey}>
              <Card.Body>
                <p className={"mt-0 mb-0"}>
                  <FormattedMessage
                    id="hm.label.consumption"
                    key={"hm.label.consumption"}
                  >
                    {message => message}
                  </FormattedMessage>
                  {35 *
                    (this.props.heating.waterSavings ? 0.5 : 1) *
                    WarmWaterConsumptionType.getWarmWaterConsumptionForIntensity(
                      this.props.heating.consumptionIntensityId
                    ).intensity *
                    this.props.persons.persons}
                  <FormattedMessage
                    id="hm.label.consumption2"
                    key={"hm.label.consumption2"}
                  >
                    {message => message}
                  </FormattedMessage>
                </p>

                <DropDownList
                  className="insulationDropDown"
                  x="0"
                  y="30"
                  width="164"
                  dataProvider={this.props.dataProvider}
                  labelField="name"
                  selectedIndex={this.props.heating.consumptionIntensityId}
                  onChange={e => this.valueChanged(e)}
                />
                {this.props.name === "water" && (
                  <label className={"mt-1 d-flex flex-wrap align-items-center"}>
                    <input
                      type="checkbox"
                      name={"water_savings"}
                      className={"mr-1"}
                      checked={this.props.heating.waterSavings}
                      onChange={e => this.waterSavingsChanged(e.target.checked)}
                    />
                    <FormattedMessage
                      id={"hm.label.warm_water.savings"}
                      key={"hm.label.warm_water.savings"}
                    >
                      {message => {
                        return <p className={"mt-0 mb-0 pb-0"}>{message}</p>;
                      }}
                    </FormattedMessage>
                  </label>
                )}
                {this.props.invalidDataOptimizer &&
                  this.props.invalidDataOptimizer.waterSavings.state ===
                    OptimizerResultsState.BETTER && (
                    <Thermoderm
                      data={[this.props.invalidDataOptimizer.waterSavings]}
                      className={"insulation-windows"}
                      writeCache={() => this.props.writeCache()}
                    />
                  )}
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={this.props.message}
            placement={cx({
              right: this.props.name !== "air_conditioning",
              left: this.props.name === "air_conditioning",
            })}
          />
        </div>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  const heating = getHeating(state);
  const persons = getPersons(state);
  return { heating, persons };
};
export default injectIntl(connect(mapStateToProps)(WaterBaloon));
