import { ConversionData } from "./converter/ConversionData";
import { EnvironmentalData } from "./model/EnvironmentalData";
import { HouseData } from "./model/HouseData";

export class DataContext {
  _lang;

  _conversionData = new ConversionData();
  _houseData = new HouseData();
  _heatingData;
  _environmentalData = new EnvironmentalData();
  _solarCollectorData;

  _includeWarmWater = false;
  _selectedMonth = 0;

  _selectedCountryRegion;
  _countryCode;
  _initialConfigItem;
  _selectedStation;

  _currencyLocaleCode;
  _localCurrency;
  costs;

  get lang() {
    return this._lang;
  }

  set lang(value) {
    this._lang = value;
  }

  get conversionData() {
    return this._conversionData;
  }

  set conversionData(value) {
    this._conversionData = value;
  }

  get houseData() {
    return this._houseData;
  }

  set houseData(value) {
    this._houseData = value;
  }

  get heatingData() {
    return this._heatingData;
  }

  set heatingData(value) {
    this._heatingData = value;
  }

  get environmentalData() {
    return this._environmentalData;
  }

  set environmentalData(value) {
    this._environmentalData = value;
  }

  get solarCollectorData() {
    return this._solarCollectorData;
  }

  set solarCollectorData(value) {
    this._solarCollectorData = value;
  }

  get includeWarmWater() {
    return this._includeWarmWater;
  }

  set includeWarmWater(value) {
    this._includeWarmWater = value;
  }

  get selectedMonth() {
    return this._selectedMonth;
  }

  set selectedMonth(value) {
    this._selectedMonth = value;
  }

  get selectedCountryRegion() {
    return this._selectedCountryRegion;
  }

  set selectedCountryRegion(value) {
    this._selectedCountryRegion = value;
  }

  get countryCode() {
    return this._countryCode;
  }

  set countryCode(value) {
    this._countryCode = value;
  }

  get initialConfigItem() {
    return this._initialConfigItem;
  }

  set initialConfigItem(value) {
    this._initialConfigItem = value;
  }

  get currencyLocaleCode() {
    return this._currencyLocaleCode;
  }

  set currencyLocaleCode(value) {
    this._currencyLocaleCode = value;
  }

  get localCurrency() {
    return this._localCurrency;
  }

  set localCurrency(value) {
    this._localCurrency = value;
  }

  get selectedStation() {
    return this._selectedStation;
  }

  set selectedStation(value) {
    this._selectedStation = value;
  }
}
