export class BaseTypeConfig {
  _selectedIndex;

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value) {
    this._selectedIndex = value;
  }
}
