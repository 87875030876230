import { CountryConfigurationReader } from "./CountryConfigurationReader";
import { StationConfigurationReader } from "./StationConfigurationReader";
import { StationsWrapper } from "./StationsWrapper";

export class CountryStationConfigurationReader {
  countryConfigReader;
  stationConfigReader;
  resManager;

  config;

  constructor(countryXML, stationsXML, resManager = null) {
    this.countryConfigReader = new CountryConfigurationReader(
      countryXML,
      resManager
    );
    this.stationConfigReader = new StationConfigurationReader(
      stationsXML,
      resManager
    );
    this.resManager = resManager;
  }

  readConfig = () => {
    if (this.config != null) {
      return this.config;
    }
    this.config = this.countryConfigReader.readConfig();
    this.config = this.applyStationConfig(
      new StationsWrapper(this.stationConfigReader.readConfig()),
      this.config
    );
    return this.config;
  };

  getEuroToPLNExchangeRate = () => {
    return this.countryConfigReader.getEuroToPLNExchangeRate();
  };

  applyStationConfig = (stationsWrapper, config) => {
    for (let i = 0; i < config.length; i++) {
      let countryItem = config[i];
      let stationConfig = stationsWrapper.getStationsForCountry(
        countryItem.code
      );

      if (stationConfig != null) {
        countryItem.stationsConfig = stationConfig;
        countryItem.noRegions = true;
        countryItem.regions = [];
        countryItem.ignoreConfigPrices = true;
      }
    }
    return config;
  };
}
