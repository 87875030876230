import { TemperatureCalculator } from "../../../core/calc/utils/TemperatureCalculator";

export class EnvironmentalDataPreparer {
  static applyTemperatureStats = environmentalData => {
    environmentalData.tMax = TemperatureCalculator.calcMaxTemperature(
      environmentalData.temperatures
    );
    environmentalData.tMin = TemperatureCalculator.calcMinTemperature(
      environmentalData.temperatures
    );
    environmentalData.tAvg = TemperatureCalculator.calcAvgTemperature(
      environmentalData.temperatures
    );
    environmentalData.tSigma = TemperatureCalculator.calcSigma(
      environmentalData.temperatures,
      environmentalData.tMin,
      environmentalData.tMax
    );
    return environmentalData;
  };

  static prepareFromConfig = (envData, envDataConfig) => {
    envData.minCalcTemp = envDataConfig.minCalcTemp;
    envData.temperatures = envDataConfig.temperatures;
    envData.groundTemperatures = envDataConfig.groundTemperatures;
    envData.insolationData.groundInsolation45 = envDataConfig.optimalInsolation;
    envData.insolationData.southInsolation = envDataConfig.insolationS;
    envData.insolationData.northInsolation = envDataConfig.insolationN;
    envData.insolationData.westEastInsolation = envDataConfig.insolationWE;
    envData.insolationData.groundInsolationSum = TemperatureCalculator.calcSumTemperature(
      envData.insolationData.groundInsolation45
    );

    envData = this.applyTemperatureStats(envData);
    return envData;
  };

  static prepareFromCache = (envData, envDataConfig) => {
    envData.minCalcTemp = envDataConfig.minCalcTemp;
    envData.temperatures = envDataConfig._temperatures;
    envData.groundTemperatures = envDataConfig._groundTemperatures;
    envData.insolationData.groundInsolation45 =
      envDataConfig._insolationData._groundInsolation45;
    envData.insolationData.southInsolation =
      envDataConfig._insolationData._southInsolation;
    envData.insolationData.northInsolation =
      envDataConfig._insolationData._northInsolation;
    envData.insolationData.westEastInsolation =
      envDataConfig._insolationData._westEastInsolation;
    envData.insolationData.groundInsolationSum = TemperatureCalculator.calcSumTemperature(
      envData.insolationData.groundInsolation45
    );

    envData = this.applyTemperatureStats(envData);
    return envData;
  };
}
