export class SplitColumnData {
  _name;
  _enHeatingSourceLoss;
  _enWarmWater;
  _enWalls;
  _enDoor;
  _enBasement;
  _enRoof;
  _enFloor;
  _enWindows;
  _enFoundations;
  _enVent;
  _enHumidification;
  _enAir;
  _enTightness;
  _enSolGain;
  _enProductGain;
  _enPersonGain;
  _enCollectorsGain;
  _enPhotovoltaicGain;
  _enPhotovoltaicGainNormalized;
  _enHeatingSourceGain;
  _heatingDeviceEnergy;
  _heatingWaterDeviceEnergy;
  _airConditioningDeviceEnergy;
  _electricDevices;
  _enElectricDevicesEnergyLossNormalized;
  _enElectricVentilationEnergyLoss;
  _enElectricDevicesEnergyLoss;
  _enWaterElectricDevicesEnergyLoss;

  _insulationAndWarmWaterGroup;
  _ventilationGroup;
  _enGainsGroup;

  _enBalance;

  _airConditioningLoss;

  get enPersonGain() {
    return this._enPersonGain;
  }

  set enPersonGain(value) {
    this._enPersonGain = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get enHeatingSourceLoss() {
    return this._enHeatingSourceLoss;
  }

  set enHeatingSourceLoss(value) {
    this._enHeatingSourceLoss = value;
  }

  get enWarmWater() {
    return this._enWarmWater;
  }

  set enWarmWater(value) {
    this._enWarmWater = value;
  }

  get enBasement() {
    return this._enBasement;
  }

  set enBasement(value) {
    this._enBasement = value;
  }

  get enWalls() {
    return this._enWalls;
  }

  set enWalls(value) {
    this._enWalls = value;
  }

  get enDoor() {
    return this._enDoor;
  }

  set enDoor(value) {
    this._enDoor = value;
  }

  get enRoof() {
    return this._enRoof;
  }

  set enRoof(value) {
    this._enRoof = value;
  }

  get enFloor() {
    return this._enFloor;
  }

  set enFloor(value) {
    this._enFloor = value;
  }

  get enWindows() {
    return this._enWindows;
  }

  set enWindows(value) {
    this._enWindows = value;
  }

  get enFoundations() {
    return this._enFoundations;
  }

  set enFoundations(value) {
    this._enFoundations = value;
  }

  get enVent() {
    return this._enVent;
  }

  set enVent(value) {
    this._enVent = value;
  }

  get enHumidification() {
    return this._enHumidification;
  }

  set enHumidification(value) {
    this._enHumidification = value;
  }

  get enAir() {
    return this._enAir;
  }

  set enAir(value) {
    this._enAir = value;
  }

  get enTightness() {
    return this._enTightness;
  }

  set enTightness(value) {
    this._enTightness = value;
  }

  get enSolGain() {
    return this._enSolGain;
  }

  set enSolGain(value) {
    this._enSolGain = value;
  }

  get enProductGain() {
    return this._enProductGain;
  }

  set enProductGain(value) {
    this._enProductGain = value;
  }

  get enCollectorsGain() {
    return this._enCollectorsGain;
  }

  set enCollectorsGain(value) {
    this._enCollectorsGain = value;
  }

  get enHeatingSourceGain() {
    return this._enHeatingSourceGain;
  }

  set enHeatingSourceGain(value) {
    this._enHeatingSourceGain = value;
  }

  get insulationAndWarmWaterGroup() {
    return this._insulationAndWarmWaterGroup;
  }

  set insulationAndWarmWaterGroup(value) {
    this._insulationAndWarmWaterGroup = value;
  }

  get ventilationGroup() {
    return this._ventilationGroup;
  }

  set ventilationGroup(value) {
    this._ventilationGroup = value;
  }

  get enGainsGroup() {
    return this._enGainsGroup;
  }

  set enGainsGroup(value) {
    this._enGainsGroup = value;
  }

  get enBalance() {
    return this._enBalance;
  }

  set enBalance(value) {
    this._enBalance = value;
  }

  get enPhotovoltaicGain() {
    return this._enPhotovoltaicGain;
  }

  set enPhotovoltaicGain(value) {
    this._enPhotovoltaicGain = value;
  }

  get enPhotovoltaicGainNormalized() {
    return this._enPhotovoltaicGainNormalized;
  }

  set enPhotovoltaicGainNormalized(value) {
    this._enPhotovoltaicGainNormalized = value;
  }

  get airConditioningLoss() {
    return this._airConditioningLoss;
  }

  set airConditioningLoss(value) {
    this._airConditioningLoss = value;
  }

  get heatingDeviceEnergy() {
    return this._heatingDeviceEnergy;
  }

  set heatingDeviceEnergy(value) {
    this._heatingDeviceEnergy = value;
  }

  get heatingWaterDeviceEnergy() {
    return this._heatingWaterDeviceEnergy;
  }

  set heatingWaterDeviceEnergy(value) {
    this._heatingWaterDeviceEnergy = value;
  }

  get airConditioningDeviceEnergy() {
    return this._airConditioningDeviceEnergy;
  }

  set airConditioningDeviceEnergy(value) {
    this._airConditioningDeviceEnergy = value;
  }

  get electricDevices() {
    return this._electricDevices;
  }

  set electricDevices(value) {
    this._electricDevices = value;
  }

  get enElectricDevicesEnergyLossNormalized() {
    return this._enElectricDevicesEnergyLossNormalized;
  }

  set enElectricDevicesEnergyLossNormalized(value) {
    this._enElectricDevicesEnergyLossNormalized = value;
  }

  get enElectricVentilationEnergyLoss() {
    return this._enElectricVentilationEnergyLoss;
  }

  set enElectricVentilationEnergyLoss(value) {
    this._enElectricVentilationEnergyLoss = value;
  }

  get enElectricDevicesEnergyLoss() {
    return this._enElectricDevicesEnergyLoss;
  }

  set enElectricDevicesEnergyLoss(value) {
    this._enElectricDevicesEnergyLoss = value;
  }

  get enWaterElectricDevicesEnergyLoss() {
    return this._enWaterElectricDevicesEnergyLoss;
  }

  set enWaterElectricDevicesEnergyLoss(value) {
    this._enWaterElectricDevicesEnergyLoss = value;
  }
}
