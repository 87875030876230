import { CalcUtils } from "./CalcUtils";
export class TemperatureCalculator {
  static calcMaxTemperature = temperatures => {
    return CalcUtils.maxValue(temperatures);
  };

  static calcSumTemperature = temperatures => {
    return CalcUtils.sum(temperatures);
  };

  static calcMinTemperature = temperatures => {
    return CalcUtils.minValue(temperatures);
  };

  static calcAvgTemperature = temperatures => {
    return CalcUtils.avgValue(temperatures);
  };

  static calcSigma = (temperatures, minTemp = NaN, maxTemp = NaN) => {
    if (isNaN(minTemp)) {
      minTemp = TemperatureCalculator.calcMinTemperature(temperatures);
    }
    if (isNaN(maxTemp)) {
      maxTemp = TemperatureCalculator.calcMaxTemperature(temperatures);
    }

    let tSigma = (maxTemp - minTemp) / 7;
    if (tSigma - Math.floor(tSigma) < 0.5) {
      tSigma = Math.floor(tSigma);
    } else {
      tSigma = Math.ceil(tSigma);
    }
    return tSigma;
  };

  static calcGroundTemperatures = temperatures => {
    let result = new Array(12);

    let tAvg = TemperatureCalculator.calcAvgTemperature(temperatures);
    let diffArray = new Array(12);

    for (let i = 0; i < temperatures.length; i++) {
      diffArray[i] = 0.85 * (temperatures[i] - tAvg);
    }

    for (let i = 0; i < diffArray.length; i++) {
      let previous = i === 0 ? diffArray.length - 1 : i - 1;

      result[i] =
        Math.round((tAvg + (2 * diffArray[previous] + diffArray[i]) / 3) * 10) /
        10;
    }

    return result;
  };
}
