import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getHouse } from "../../redux/selectors";
import connect from "react-redux/es/connect/connect";
import { HouseSpecsTableTd } from "./HouseSpecsTableTd";
import Table from "react-bootstrap/Table";
import PieEnergyChartGroup from "../Charts/PieEnergyChartGroup";
import { ConvertToUnit } from "../Helpers/ConvertToUnit";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

function HouseUserEnergyLossSpecs(props) {
  const dataResults = props.dataResults;
  const {
    heatingSourceLoss,
    heatingDeviceEnergy,
    heatingWaterDeviceEnergy,
    warmWaterHeatingSourceLoss,
    airConditioningDeviceEnergy,
    enElectricDevicesEnergyLossNormalized,
    enRecuperator,
    enHumidification,
    enElectricVentilationEnergyLoss,
    enWaterElectricDevicesEnergyLoss,
  } = dataResults.yearlyAggregatedConvertedData;
  const heatingDeviceUserEnergy = ConvertToUnit(
    heatingDeviceEnergy - heatingSourceLoss,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const heatingWaterEnergy = ConvertToUnit(
    heatingWaterDeviceEnergy - warmWaterHeatingSourceLoss,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const acEnergy = ConvertToUnit(
    airConditioningDeviceEnergy,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const electricDevicesEnergy = ConvertToUnit(
    enElectricDevicesEnergyLossNormalized +
      enRecuperator +
      enHumidification +
      enWaterElectricDevicesEnergyLoss +
      enElectricVentilationEnergyLoss,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  let energySum = Math.round(
    heatingDeviceUserEnergy +
      heatingWaterEnergy +
      acEnergy +
      electricDevicesEnergy
  );
  const totalSurface = props.house.surfaceData.totalSurface;
  return (
    <>
      <FormattedMessage id="hm.house_spec.energy.usable.year" defaultMessage="">
        {message => (
          <h2 className={"mb-0 text-center"}>
            {message} [kWh/(m<sup>2</sup>·rok)]
          </h2>
        )}
      </FormattedMessage>
      <Table striped bordered hover responsive className={"text-center w-100"}>
        <tbody>
          <tr>
            <HouseSpecsTableTd />
            <HouseSpecsTableTd message={"hm.house_spec.heating"} />
            <HouseSpecsTableTd message={"hm.house_spec.cwu"} />
            <HouseSpecsTableTd message={"hm.house_spec.cooling"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.auxiliary"} />
            <HouseSpecsTableTd message={"hm.house_spec.sum"} />
          </tr>
          <tr>
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·rok)]
                </>
              }
            />
            <HouseSpecsTableTd
              value={(heatingDeviceUserEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd
              value={(heatingWaterEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd value={(acEnergy / totalSurface).toFixed(1)} />
            <HouseSpecsTableTd
              value={(electricDevicesEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd value={(energySum / totalSurface).toFixed(1)} />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.participation"} />
            <HouseSpecsTableTd
              value={Math.round((heatingDeviceUserEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((heatingWaterEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((acEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((electricDevicesEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((energySum / energySum) * 100)}
            />
          </tr>
        </tbody>
      </Table>
      <PieEnergyChartGroup
        dataResults={props.dataResults}
        data={[
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.heating" }),
            value: parseFloat(
              (heatingDeviceUserEnergy / totalSurface).toFixed(1)
            ),
            fill: "#ec6d11",
          },
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.cwu" }),
            value: parseFloat((heatingWaterEnergy / totalSurface).toFixed(1)),
            fill: "#ff4b4b",
          },
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.cooling" }),

            value: parseFloat((acEnergy / totalSurface).toFixed(1)),
            fill: "#a800cc",
          },
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.auxiliary",
            }),

            value: parseFloat(
              (electricDevicesEnergy / totalSurface).toFixed(1)
            ),
            fill: "#888888",
          },
        ]}
        legend={true}
      />
    </>
  );
}

const mapStateToProps = state => {
  const house = getHouse(state);
  return { house };
};

export default injectIntl(connect(mapStateToProps)(HouseUserEnergyLossSpecs));
