import {OptimizerResultsState} from "../OptimizerResultsState";
import clonedeep from "lodash.clonedeep";

export class WallInsulationOptimizer {
    constructor(isInReferenceState, dictionary, buildingType,refPricePerKWh, newPricePerKWh){
        this.elementList = dictionary;
        this.buildingType = buildingType;
        this.refPricePerKWh = refPricePerKWh;
        this.newPricePerKWh = newPricePerKWh;
        this.isInReferenceState = isInReferenceState;
    }
    elementList;

    validate = (type, result, element, newElement, surface, newSurface, refCosts, newCosts) => {
        result.state = OptimizerResultsState.THE_SAME;
        let materialChange = false;

        result.refItem = this.elementList
            .filter(item => item.id === element.material)[0];
        result.newItem = this.elementList
            .filter(item => item.id === newElement.material)[0];

        if(element.uValue <  newElement.uValue ){
            result.state = OptimizerResultsState.WORSE;
            
        } else if(element.uValue > newElement.uValue){
            result.state = OptimizerResultsState.BETTER;
            result.refPricePerKWh = this.refPricePerKWh;
            result.newPricePerKWh = this.newPricePerKWh;

            result.refItem = clonedeep(result.refItem);

            result.refItem.uValue = element.uValue.toFixed(2);
            result.refItem.width = element.width;
            result.refItem.heatTransfer = element.heatTransfer;
            result.refItem.u0 = element.u0;
            

            result.newItem = clonedeep(result.newItem);
            
            result.newItem.width = newElement.width;
            result.newItem.uValue = newElement.uValue.toFixed(2);
            result.newItem.heatTransfer = newElement.heatTransfer;
            result.newItem.u0 = newElement.u0;

            if(type === 'roofInsulation' || (element.material !== newElement.material && type === 'wallInsulation')){
                materialChange = true;
            }

            let materialCost = result.refItem.price;
            let newMaterialCost = result.newItem.price;
            let workCost = this.buildingType === 'current' ? result.refItem.work : 0;
            let newWorkCost = this.buildingType === 'current' ? (
              materialChange ? result.newItem.work + 30 : result.newItem.work
            ) : 0;

            if(refCosts[type+'_material_changed']){
                materialCost = refCosts[type + '_material'];
            }
            if(newCosts[type+'_material_changed']){
                newMaterialCost = newCosts[type + '_material'];
            }
            if(refCosts[type+'_work_changed']){
                workCost = refCosts[type+'_work'];
            }
            if(newCosts[type+'_work_changed']){
                newWorkCost = newCosts[type+'_work'];
            }

            
            result.work = this.buildingType === 'current'
                ? this.calculateWork(newWorkCost, surface, newSurface, newElement.width)
                : 0;
            result.material = this.buildingType === 'current'
                ? this.calculateMaterial(newMaterialCost, surface, newSurface, newElement.width)
                : this.calculateMaterialDifference(materialCost, newMaterialCost,
                    surface, newSurface, element.width, newElement.width);
            result.cost = result.work + result.material;
            result.obsolescence = result.newItem.obsolescence;
            result.service = 0;
            result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
            result.materialPrice = this.isInReferenceState ? materialCost : newMaterialCost;
            result.costView = (
              this.buildingType === 'current' ? result.workPrice * surface : 0
            ) + result.materialPrice * surface * (
              this.isInReferenceState ? element.width/100 : newElement.width/100
            );

        }
        
        

        
        return result;
    };


    calculateMaterial = (price, surface, newSurface, width) => {
        return (price * surface * width/100);
    };

    calculateWork = (work, surface, newSurface) => {
        return (work * surface);
    };

    calculateWorkDifference = (work, newWork, surface) => {
        return ((newWork-work) * surface);
    };

    calculateMaterialDifference = (price, newPrice, surface, newSurface, width, newWidth) => {
        return (
            (newPrice * surface* newWidth/100)
            - (price * surface* width/100)
        );
    };
}