/**
 *
 * @param cancel
 * @param refDataContext
 * @param newDataContext
 * @param heatMasterController
 * @param reports
 * @param dictionaryConfig
 * @param isInRefState
 * @returns {{thermalBridge: (*), shutters: *, window: *}}
 * @constructor
 */
export function ThermalBridgesOptimizer(
    type, cancel, refDataContext, newDataContext, heatMasterController, reports, dictionaryConfig, isInRefState = false
) {
    const oldWindowType = refDataContext.houseData.windowElement;
    const newWindowType = newDataContext.houseData.windowElement;
    let preferredWindow = 'hm.thermal-bridge.min';
    if(oldWindowType.thermalBridgesType.id !== newWindowType.thermalBridgesType.id){
        preferredWindow = newWindowType.thermalBridgesType.id;
    }
    return {
        window: newWindowType.windowsType.id,
        thermalBridge:  !cancel ? preferredWindow : oldWindowType.thermalBridgesType.id,
        shutters: newWindowType.shutters,
    };
}
