
export function FoundationOptimizer(
    type, cancel, refDataContext, newDataContext, heatMasterController, reports, dictionaryConfig, isInRefState = false
) {
    
    const oldFoundation = refDataContext.houseData.foundationType.id;
    const newFoundation = newDataContext.houseData.foundationType.id;
    let preferredFoundation = 'hm.foundations.slab';
    let floorElement = newDataContext.houseData.floorElement;
    if (oldFoundation !== newFoundation) {
        preferredFoundation = newFoundation;
    }
    return {
        material: floorElement.material,
        heatTransfer: floorElement.heatTransfer,
        width: floorElement.width,
        uValue: floorElement.uValue,
        u0: floorElement.u0,
        foundations: !cancel ? preferredFoundation : oldFoundation
    };
}
