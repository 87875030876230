import { CompositeEnergyCalculator } from "../calc/monthly/CompositeEnergyCalculator";
import { WattsEnergyResult } from "../calc/WattsEnergyResult";
import { WallEnergyCalculator } from "../calc/monthly/WallEnergyCalculator";
import { RoofEnergyCalculator } from "../calc/monthly/RoofEnergyCalculator";
import { FloorEnergyCalculator } from "../calc/monthly/FloorEnergyCalculator";
import { FoundationsEnergyCalculator } from "../calc/monthly/FoundationsEnergyCalculator";
import { WindowsEnergyCalculator } from "../calc/monthly/WindowsEnergyCalculator";
import { VentilationEnergyCalculator } from "../calc/monthly/VentilationEnergyCalculator";
import { AiringEnergyCalculator } from "../calc/monthly/AiringEnergyCalculator";
import { TightnessEnergyCalculator } from "../calc/monthly/TightnessEnergyCalculator";
import { WarmWaterEnergyCalculator } from "../calc/monthly/WarmWaterEnergyCalculator";
import { SolarEnergyGainCalculator } from "../calc/monthly/SolarEnergyGainCalculator";
import { PersonEnergyGainCalculator } from "../calc/monthly/PersonEnergyGainCalculator";
import { ElectricityDevicesEnergyGainCalculator } from "../calc/monthly/ElectricityDevicesEnergyGainCalculator";
import { SolarCollectorEnergyGainCalculator } from "../calc/monthly/SolarCollectorEnergyGainCalculator";
import { MonthlyEnergyResultProvider } from "../calc/MonthlyEnergyResultProvider";
import { GaussianDistributor } from "../calc/utils/GaussianDistributor";
import { PhotovoltaicEnergyGainCalculator } from "../calc/monthly/PhotovoltaicEnergyGainCalculator";
import { BasementEnergyCalculator } from "../calc/monthly/BasementEnergyCalculator";
import { LivingNeedsCalculator } from "../calc/monthly/LivingNeedsCalculator";
import { ElectricityDevicesEnergyLossCalculator } from "../calc/monthly/ElectricityDevicesEnergyLossCalculator";

export class OptimizerEnergyCalculator {
  monthEnergyCalculator = new CompositeEnergyCalculator();

  constructor() {
    this.monthEnergyCalculator.addEnergyCalculator(new WallEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(new RoofEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(new FloorEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(
      new FoundationsEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new WindowsEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new VentilationEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new AiringEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new TightnessEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new WarmWaterEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new SolarEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new PersonEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new ElectricityDevicesEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new SolarCollectorEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new PhotovoltaicEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new BasementEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(new LivingNeedsCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(
      new ElectricityDevicesEnergyLossCalculator()
    );
  }

  calcEnergyDataWithGaussianBlurring = dataContext => {
    let environmentalData = dataContext.environmentalData;

    let tSigma = parseFloat(environmentalData.tSigma);

    let monthlyEnergyConsumption = [];
    for (let month = 0; month < 12; month++) {
      let tempMonth = parseFloat(environmentalData.temperatures[month]);
      let monthlyEnergyResultProvider = new MonthlyEnergyResultProvider(
        dataContext,
        month,
        this.monthEnergyCalculator
      );
      let gaussianDistribution = new GaussianDistributor(tempMonth, tSigma);
      gaussianDistribution.apply(monthlyEnergyResultProvider);

      let monthlyResult = monthlyEnergyResultProvider.getCalculatedResult();

      monthlyEnergyConsumption.push(monthlyResult);
    }

    let result = new WattsEnergyResult(monthlyEnergyConsumption);
    return result;
  };
}
