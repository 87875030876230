export class StateConstants {

    static INITIAL_STATE = 1;
    static NEW_SWITCHED = 2;
    static REFERENCE_SWITCHED = 3;


    static isInitialOrReference = (state) => {
        return state === StateConstants.INITIAL_STATE || state === StateConstants.REFERENCE_SWITCHED;
    };

    static isInReferenceState = (state) => {
        return state === StateConstants.REFERENCE_SWITCHED;
    };
}

