export class WaterStorageDistributionConfig {
  _waterDistributionTypes;
  _waterStorageTypes;

  _distributionSelectedIndex;
  _storageSelectedIndex;

  get waterDistributionTypes() {
    return this._waterDistributionTypes;
  }

  set waterDistributionTypes(value) {
    this._waterDistributionTypes = value;
  }

  get waterStorageTypes() {
    return this._waterStorageTypes;
  }

  set waterStorageTypes(value) {
    this._waterStorageTypes = value;
  }

  get distributionSelectedIndex() {
    return this._distributionSelectedIndex;
  }

  set distributionSelectedIndex(value) {
    this._distributionSelectedIndex = value;
  }

  get storageSelectedIndex() {
    return this._storageSelectedIndex;
  }

  set storageSelectedIndex(value) {
    this._storageSelectedIndex = value;
  }
}
