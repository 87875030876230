import { BaseVentilationEnergyCalculator } from "./BaseVentilationEnergyCalculator";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { VentilationFrequency } from "../../../constants/combo/VentilationFrequency";

export class AiringEnergyCalculator extends BaseVentilationEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInput) => {
    if (
      contextData.houseData.ventilationData.ventilationMethodObject.type ===
      VentilationMethod.GRAVITATIONAL
    ) {
      return this.superCalcEnergy(energyData, contextData, monthInput);
    }

    energyData.enAir = 0;
    return energyData;
  };

  applyCalculatedEnergy = (energy, energyData) => {
    energyData.enAir = Math.max(energy, 0);
    return energyData;
  };

  calcAirVolume = (contextData, tOut, tInEffective) => {
    let houseData = contextData.houseData;
    let surfaceData = houseData.surfaceData;
    let ventData = houseData.ventilationData;

    let airVolume = EnergyCalcHelper.calcStandardVentilationVolume(
      surfaceData.totalSurface
    );
    let gravitationalResidualVolume = 0;
    if (tOut > 12) {
      let tDiff = Math.max(0, houseData.tIn - tOut);
      gravitationalResidualVolume = Math.max(
        0,
        airVolume * (1 - Math.pow(tDiff / 8, 0.6))
      );
    }

    if (ventData.ventilationFreq === VentilationFrequency.SPORADICALLY) {
      airVolume = 0.0;
      if (tOut > 12) {
        airVolume += 0.25 * gravitationalResidualVolume;
      }
    } else if (ventData.ventilationFreq === VentilationFrequency.STANDARD) {
      airVolume = 0.1 * airVolume;
      if (tOut > 12) {
        airVolume += 0.5 * gravitationalResidualVolume;
      }
    } else if (ventData.ventilationFreq === VentilationFrequency.OFTEN) {
      airVolume = 0.25 * airVolume;
      if (tOut > 12) {
        airVolume += gravitationalResidualVolume;
      }
    } else if (ventData.ventilationFreq === VentilationFrequency.VERY_OFTEN) {
      airVolume = 0.5 * airVolume;
      if (tOut > 12) {
        airVolume += 1.5 * gravitationalResidualVolume;
      }
    } else if (ventData.ventilationFreq === VentilationFrequency.NON_STOP) {
      if (tOut > 12) {
        airVolume += 2 * gravitationalResidualVolume;
      }
    }
    return airVolume;
  };

  getType = () => 2;
}
