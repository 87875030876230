import { ConverterHelper } from "../../core/converter/ConverterHelper";
import { SplitColumnDataProvider } from "./SplitColumnDataProvider";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { ConvertToUnit, fromKwhToWattsRatio } from "../Helpers/ConvertToUnit";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class PlotDataProvider {
  getComparingPlotData = (refResultData, newResultData) => {
    let columnData = [];
    const refYearlyConvertedResult =
      refResultData.yearlyAggregatedConvertedData;
    const newYearlyConvertedResult =
      newResultData.yearlyAggregatedConvertedData;
    columnData.push(
      new SplitColumnDataProvider().getColumnData(
        refResultData,
        refYearlyConvertedResult,
        true
      )
    );
    columnData.push(
      new SplitColumnDataProvider().getColumnData(
        newResultData,
        newYearlyConvertedResult,
        true
      )
    );
    return columnData;
  };

  getMonthlyPlotDataInGroups = resultData => {
    return new SplitColumnDataProvider().getColumnDataInGroups(resultData);
  };

  getMonthlyPlotData = resultData => {
    let plotData = {};
    let columnData = [];
    const costInEuro = ConversionUnits.isEuroCostUnit(
      resultData.conversionData.selectedUnit
    );
    const costUnit = ConversionUnits.isCostUnit(
      resultData.conversionData.selectedUnit
    );
    const pvCalc = getPvEnergyCalculated(
      resultData,
      resultData.dataContext.houseData.livingNeedsChanged,
      resultData.dataContext.houseData.livingNeeds
    );
    const pv = Math.round(
      pvCalc.energyCo < pvCalc.basicEnergy
        ? pvCalc.energyCo
        : pvCalc.basicEnergy
    );

    for (let month = 0; month < 12; month++) {
      const monthlyConverterResult = resultData.monthlyConvertedData[month];
      const convertedResult = ConverterHelper.buildWithAbsoluteValues(
        monthlyConverterResult
      );
      plotData = new SplitColumnDataProvider().getColumnData(
        resultData,
        convertedResult,
        false
      );
      plotData.enPhotovoltaicGain = -Math.round(
        ConvertToUnit(
          (pv * fromKwhToWattsRatio()) / 12,
          resultData.conversionData.selectedUnit,
          costUnit && costInEuro
            ? resultData.conversionData.electricityPricePerKWh
            : resultData.conversionData.electricityPricePerKWhLocal,
          resultData.conversionData.electricityEmissionCoefficient
        )
      );
      plotData.name = month;
      columnData.push(plotData);
    }
    return columnData;
  };
}
