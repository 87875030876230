import {OptimizerResultsState} from "../OptimizerResultsState";

export class CollectorsOptimizer {
    constructor(isInReferenceState, dictionary, buildingType,refPricePerKWh, newPricePerKWh){
        this.elementList = dictionary;
        this.buildingType = buildingType;
        this.refPricePerKWh = refPricePerKWh;
        this.newPricePerKWh = newPricePerKWh;
        this.isInReferenceState = isInReferenceState;
    }

    validate = (type, result, element, newElement, energyData, newEnergyData, refCosts, newCosts) => {
        result.refItem = this.elementList
            .filter(item => item.id === element.collectorType.id)[0];
        result.newItem = this.elementList
            .filter(item => item.id === newElement.collectorType.id)[0];

        result.state = OptimizerResultsState.THE_SAME;
        result.obsolescence = result.newItem.obsolescence;

        if(result.refItem.efficiency > result.newItem.efficiency ){
            result.state = OptimizerResultsState.WORSE;
            
        } else if((result.refItem.efficiency  < result.newItem.efficiency && element.surface <= newElement.surface)
            || (result.refItem.efficiency  <= result.newItem.efficiency && element.surface < newElement.surface)){
            result.state = OptimizerResultsState.BETTER;
            result.refPricePerKWh = this.refPricePerKWh;
            result.newPricePerKWh = this.newPricePerKWh;


            let newRatio = 1;
            if(result.newItem.isFlatCollector()){
                newRatio = 1/1.25;
            }
            let ratio = 1;
            if(result.refItem.isFlatCollector()){
                ratio = 1/1.25;
            }

            let workCost = result.refItem.work;
            let newWorkCost = result.newItem.work;

            let newMaterialCost = this.calculateMaterial(3000, newElement.surface) * newRatio;
            let materialCost = this.calculateMaterial(3000,element.surface) * ratio;

            if(refCosts[type+'_material_changed']){
                materialCost = refCosts[type + '_material'];
            }
            if(newCosts[type+'_material_changed']){
                newMaterialCost = newCosts[type + '_material'];
            }
            if(refCosts[type+'_work_changed']){
                workCost = refCosts[type+'_work'];
            }
            if(newCosts[type+'_work_changed']){
                newWorkCost = newCosts[type+'_work'];
            }




            result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
            result.materialPrice = this.isInReferenceState ? materialCost : newMaterialCost;

            result.work = this.buildingType === 'current'
                ? this.calculateWork(newWorkCost, newElement.surface)
                : this.calculateWork(newWorkCost, newElement.surface) - this.calculateWork(workCost, element.surface);
            result.material = this.buildingType === 'current'
                ? newMaterialCost
                : newMaterialCost - materialCost;
            result.cost = result.work + result.material;
            result.costView = result.workPrice + result.materialPrice;
            result.obsolescence = result.newItem.obsolescence
        }


        return result;
    };

    calculateCosts = (price, work, surface) => price * surface + work;
    calculateDifference = (price, newPrice, work, newWork, surface, newSurface) => {
        return (newPrice*newSurface - price*surface) + (newWork - work);
    };

    calculateMaterialDifference = (price, newPrice, surface, newSurface) => {
        return newPrice * newSurface - price * surface;
    };
    calculateWork = (work, surface) => {
        if(surface < 4){
            return work;
        } else {
            return parseInt((surface/4)) * 500 + work;
        }

    };
    calculateMaterial = (price, surface) => {
        if(surface < 5){
            return price + (surface-2) * 1000;
        } else {
            return price + 2000 + parseInt(surface-4) * 500
                ;
        }
    };


    calculateWorkDifference = (work, newWork) => newWork - work;
}