export class StationsConfig {
  _countryCode;
  _mapImage;
  _stations;
  _defaultStation;

  get countryCode() {
    return this._countryCode;
  }

  set countryCode(value) {
    this._countryCode = value;
  }

  get mapImage() {
    return this._mapImage;
  }

  set mapImage(value) {
    this._mapImage = value;
  }

  get stations() {
    return this._stations;
  }

  set stations(value) {
    this._stations = value;
  }

  get defaultStation() {
    return this._defaultStation;
  }

  set defaultStation(value) {
    this._defaultStation = value;
  }
}
