import { Component } from "react";
import { BaseComboObject } from "../config/dictionary/model/BaseComboObject";
import { HeatMasterFormatter } from "../util/HeatMasterFormatter";
import { HeatingSourceTypeItem } from "../config/dictionary/model/HeatingSourceTypeItem";

export class HeatMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  configurationRead = businessConfiguration => {};

  langChanged = lang => {};

  initialLangChanged = lang => {};

  setupReferenceView = stateDataContext => {
    let currentDataContext = stateDataContext.getCurrentDataContext();
    this.setupView(currentDataContext);
  };

  setHeatMasterListener = heatMasterListener => {
    this.heatMasterListener = heatMasterListener;
  };

  setCalculationStateController = calculationStateController => {
    this.calculationStateController = calculationStateController;
  };

  formatHMValue = (value, precision = 0, useThousandSeparator = false) => {
    return HeatMasterFormatter.formatHMValue(
      value,
      precision,
      useThousandSeparator
    );
  };

  toComboObjectList = comboItems => {
    let result = [];
    for (let i = 0; i < comboItems.length; i++) {
      let comboObject = comboItems[i];
      let item = {
        name: comboObject.name,
        data: comboObject,
      };
      result.push(item);
    }
    return result;
  };

  translateComboItems = (comboItems, lang) => {
    for (let i = 0; i < comboItems.length; i++) {
      let item = comboItems[i];
      if (item.data instanceof BaseComboObject) {
        let comboObject = item.data;
        item = this.translateComboItem(item, comboObject, lang);
      }
    }
    return comboItems;
  };

  translateComboItem = (item, comboObject, lang) => {
    return item;
  };

  translateComboItemsAndRefresh = (comboItems, lang) => {
    comboItems = this.translateComboItems(comboItems, lang);
    comboItems.refresh();
    return comboItems;
  };

  getComboItemIndex = (dataObject, dataItemList) => {
    for (let i = 0; i < dataItemList.length; i++) {
      let item = dataItemList[i];
      if (item.data === dataObject) {
        return i;
      }
    }
    return -1;
  };

  getComboItemIndexForCollection = (dataObject, dataItemList) => {
    for (let i = 0; i < dataItemList.length; i++) {
      let item = dataItemList[i];
      if (item.data === dataObject) {
        return i;
      }
    }
    return -1;
  };

  getComboItemIndexForCollectionById = (id, dataItemList) => {
    for (let i = 0; i < dataItemList.length; i++) {
      let item = dataItemList[i];
      let data = item.data;
      if (data.id === id) {
        return i;
      }
    }
    return -1;
  };

  getComboItemIndexDataById = (id, dataItemList) => {
    for (let i = 0; i < dataItemList.length; i++) {
      let item = dataItemList[i];
      let data = item.data;
      if (data.id === id) {
        return data;
      }
    }
    return null;
  };

  toHeatingSourceObjectList = heatingSources => {
    let result = [];
    for (let i = 0; i < heatingSources.length; i++) {
      let heatingSourceType = heatingSources[i];
      result.push({
        name: heatingSourceType.id,
        data: new HeatingSourceTypeItem(
          heatingSourceType.id,
          heatingSourceType
        ),
      });
    }
    return result;
  };

  toHeatingDeviceObjectList = heatingSources => {
    let result = [];
    for (let i = 0; i < heatingSources.length; i++) {
      let heatingSourceType = heatingSources[i];
      result.push({
        name: heatingSourceType.name,
        data: {
          id: heatingSourceType.name,
          ...heatingSourceType,
        },
      });
    }
    return result;
  };

  getIndexOfHeatingItem = (heatingSourceType, list) => {
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.data.type === heatingSourceType) {
        return i;
      }
    }
    return -1;
  };

  getComboItemIndexForCollectionOfHeatingItems = (dataObject, dataItemList) => {
    for (let i = 0; i < dataItemList.length; i++) {
      let item = dataItemList[i];
      let itemType = item.data;
      if (itemType.type.id === dataObject.id) {
        return i;
      }
    }

    return -1;
  };
}
