import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { WarmWaterConsumptionConfig } from "../WarmWaterConsumptionConfig";
import { WarmWaterConsumptionType } from "../model/WarmWaterConsumptionType";

export class WarmWaterConsumptionConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let waterConsumptionItems = this.readWarmWaterConsumptionConfiguration(
      dictionaryXML.warm_water_consumption_configuration
    );
    let warmWaterConsumptionConfiguration = new WarmWaterConsumptionConfig(
      waterConsumptionItems
    );
    warmWaterConsumptionConfiguration.selectedIndex = this.getSelectedIndex(
      dictionaryXML.warm_water_consumption_configuration
    );

    dictionaryConfig.waterConsumptionConfiguration = warmWaterConsumptionConfiguration;
    return dictionaryConfig;
  };

  readWarmWaterConsumptionConfiguration = consumptionItemsXML => {
    let warmWaterConsumptionItems = [];
    consumptionItemsXML.warm_water_consumption.forEach(
      warmWaterConsumptionXML => {
        warmWaterConsumptionItems.push(
          this.readWarmWaterConsumptionItem(warmWaterConsumptionXML)
        );
      }
    );

    return warmWaterConsumptionItems;
  };

  readWarmWaterConsumptionItem = consumptionItemXML => {
    let id = consumptionItemXML.id;
    let intensity = Number(consumptionItemXML.intensity);

    let warmWaterConsumption = new WarmWaterConsumptionType(id, intensity);
    this.fillBaseProperties(consumptionItemXML, warmWaterConsumption);

    return warmWaterConsumption;
  };
}
