import React from "react";
import cx from "classnames";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import NumericInput from "react-numeric-input";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { updateCosts } from "../../redux/actions";
import { getCosts } from "../../redux/selectors";
import { connect } from "react-redux";

function ThermodermHeatingRow(props) {
  const { item } = props;
  const price = item.materialPrice;
  const work = item.workPrice;

  const costChanged = (id, e) => {
    props
      .dispatch(
        updateCosts({
          [id]: e,
          [`${id}_changed`]: true,
        })
      )
      .then(() => {
        props.writeCache();
      });
  };

  return (
    item.state === OptimizerResultsState.BETTER && (
      <div className={"thermoderm-row d-flex flex-wrap mb-2"}>
        <FormattedMessage
          id={"hm.optimizer.thermoderm." + item.type}
          defaultMessage=""
          tagName={"p"}
        >
          {message => (
            <p
              className={"col-12 mb-0 thermoderm-cost-header font-weight-bold"}
            >
              {message}
            </p>
          )}
        </FormattedMessage>
        <div className={"col-6 d-flex align-items-center pr-1"}>
          <FormattedMessage
            id={"hm.optimizer.thermoderm.cost.material"}
            defaultMessage=""
            tagName={"p"}
          >
            {message => <p className={"pb-0 mr-1 text-small"}>{message}</p>}
          </FormattedMessage>

          <NumericInput
            min={0}
            step={1}
            value={price}
            parse={e => e.replace(",", ".")}
            onChange={e => costChanged(`${item.type}_material`, e)}
            className={"thermoderm-input"}
            disabled={false}
          />
          <p className={cx("ml-1 my-0 d-inline-block text-small")}>
            <FormattedHTMLMessage
              id={item.priceUnit}
              defaultMessage=""
              tagName={"span"}
            />
          </p>
        </div>
        <div className={"col-6 d-flex align-items-center pl-2"}>
          <FormattedMessage
            id={"hm.optimizer.thermoderm.cost.work"}
            defaultMessage=""
          >
            {message => <p className={"pb-0 mr-1 text-small"}>{message}</p>}
          </FormattedMessage>

          <NumericInput
            min={0}
            step={1}
            value={work}
            parse={e => e.replace(",", ".")}
            onChange={e => costChanged(`${item.type}_work`, e)}
            className={"thermoderm-input"}
            disabled={false}
          />
          <p className={cx("ml-1 my-0 d-inline-block text-small")}>
            <FormattedHTMLMessage
              id={item.workUnit}
              defaultMessage=""
              tagName={"span"}
            />
          </p>
        </div>
      </div>
    )
  );
}

const mapStateToProps = state => {
  const costs = getCosts(state);
  return { costs };
};

export default injectIntl(connect(mapStateToProps)(ThermodermHeatingRow));
