import {UPDATE_BY_ID, UPDATE_INSOLATION} from "../actionTypes";

const initialState = {
    shading: false,
    blinds: false,
    blinds_val: [],
    curtains: false,
    curtains_val: [],
    awnings: false,
    awnings_val: [],
    buildings: false,
    buildings_period: 'year',
    buildings_level: 'strong',
    buildings_val: [],
    trees_coniferous: false,
    trees_coniferous_period: 'near',
    trees_coniferous_level: 'strong',
    trees_coniferous_val: [],
    trees_leafy: false,
    trees_leafy_period: 'near',
    trees_leafy_level: 'strong',
    trees_leafy_val: [],
    trees_mixed: false,
    trees_mixed_period: 'near',
    trees_mixed_level: 'strong',
    trees_mixed_val: [],
};

export default function(state = initialState, action){

    switch(action.type){
        case UPDATE_BY_ID: {
            const {id, value} = action.payload;
            return {
                ...state,
                [id]: value
            };
        }
        case UPDATE_INSOLATION: {
            const data = action.payload.data;
            return {
                ...state,
                ...data
            };
        }
        default:
            return state;
    }
}