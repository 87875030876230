import { DetachedHouseSurfaceCalculator } from "./DetachedHouseSurfaceCalculator";
import { MultiFamilyHouseSurfaceCalculator } from "./MultiFamilyHouseSurfaceCalculator";
import { BlockSurfaceCalculator } from "./BlockSurfaceCalculator";
import { TerracedHouseSurfaceCalculator } from "./TerracedHouseSurfaceCalculator";

export class SurfaceCalculator {
  surfaceCalculators = [];

  constructor() {
    this.surfaceCalculators.push(new DetachedHouseSurfaceCalculator());
    this.surfaceCalculators.push(new MultiFamilyHouseSurfaceCalculator());
    this.surfaceCalculators.push(new TerracedHouseSurfaceCalculator());
    this.surfaceCalculators.push(new BlockSurfaceCalculator());
  }

  calcSurface = (surfaceData, houseType, roofData) => {
    for (let i = 0; i < this.surfaceCalculators.length; i++) {
      let surfaceCalculator = this.surfaceCalculators[i];
      if (surfaceCalculator.supportsHouseType(houseType)) {
        return surfaceCalculator.calcSurface(surfaceData, houseType, roofData);
      }
    }
    return surfaceData;
  };
}
