import { fromWattsToKwhRatio } from "../../../components/Helpers/ConvertToUnit";
import { IrrCounter } from "../../../util/IrrCounter";
import clonedeep from "lodash.clonedeep";

export function DoorOptimizer(
  type,
  cancel,
  refDataContext,
  newDataContext,
  heatMasterController,
  reports,
  dictionaryConfig
) {
  const doorList = dictionaryConfig.doorTypeConfig.doorTypes;
  const newHouseData = newDataContext.houseData;
  const oldElement = refDataContext.houseData.doorType;
  const element = newDataContext.houseData.doorType;
  const refHeatingEff = refDataContext.heatingData.heatingEfficiency;
  const refPricePerKWh =
    refDataContext.conversionData.pricePerKWh / (refHeatingEff / 100);

  const oldItem = doorList.filter(item => item.id === oldElement.id)[0];
  let energy = heatMasterController.performCalculationForOptimizer(
    refDataContext
  );
  energy = energy.yearlyAggregatedConvertedData.enDoor * fromWattsToKwhRatio();

  let newEnergy = 0;
  let energyDiff = 0;
  let newIrr = 0;

  let materialCost = oldItem.price;
  let cost = 0;

  let doorId = oldElement.id;

  for (let i = 0; i < doorList.length; i++) {
    if (doorList[i].uValue <= 0) {
      continue;
    }
    const work =
      newHouseData.buildingType === "current"
        ? calculateWork(doorList[i].work, 1)
        : 0;
    const material =
      newHouseData.buildingType === "current"
        ? calculateMaterial(doorList[i].price, 1)
        : calculateMaterialDifference(materialCost, doorList[i].price, 1);
    cost = work + material;

    let el = clonedeep(element);
    el.uValue = doorList[i].uValue;
    newDataContext.houseData.doorType = el;
    newEnergy = heatMasterController.performCalculationForOptimizer(
      newDataContext
    );
    newEnergy =
      newEnergy.yearlyAggregatedConvertedData.enDoor * fromWattsToKwhRatio();
    newIrr = IrrCounter(
      cost,
      energy,
      newEnergy,
      50,
      refPricePerKWh,
      refPricePerKWh,
      reports.irrPriceChange
    );

    if (energy - newEnergy > energyDiff && newIrr >= reports.irr) {
      energyDiff = energy - newEnergy;
      doorId = doorList[i].id;
    }
  }

  return {
    doorType: !cancel ? doorId : oldElement.id,
  };
}

const calculateMaterial = (price, surface) => {
  return price * surface;
};

const calculateMaterialDifference = (price, newPrice, surface) => {
  return newPrice * surface - price * surface;
};

const calculateWork = (work, surface) => {
  return work * surface;
};
