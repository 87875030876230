import { SolarCollectorTypeConfig } from "../SolarCollectorTypeConfig";
import { SolarCollectorType } from "../model/SolarCollectorType";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";

export class SolarCollectorTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.solarCollectorsTypeConfig = this.readSolarCollectorTypeConfig(
      dictionaryXML.solar_collector_types
    );
    return dictionaryConfig;
  };

  readSolarCollectorTypeConfig = solarCollectorsXML => {
    let solarCollectorsTypeConfig = new SolarCollectorTypeConfig();
    solarCollectorsTypeConfig.selectedIndex = this.getSelectedIndex(
      solarCollectorsXML
    );

    let solarCollectorTypes = [];
    solarCollectorsXML.solar_collector_type.forEach(solarCollectorTypeXML => {
      solarCollectorTypes.push(
        this.readSolarCollectorType(solarCollectorTypeXML)
      );
    });

    solarCollectorsTypeConfig.solarCollectorTypes = solarCollectorTypes;
    return solarCollectorsTypeConfig;
  };

  readSolarCollectorType = solarCollectorTypeXML => {
    let id = solarCollectorTypeXML.id;
    let numericType = Number(solarCollectorTypeXML.type);
    let efficiency = Number(solarCollectorTypeXML.efficiency);
    let unitSurface = Number(solarCollectorTypeXML.unit_surface);
    let persons = Number(solarCollectorTypeXML.persons);
    let solarCollectorType = new SolarCollectorType(
      id,
      numericType,
      efficiency,
      unitSurface,
      persons
    );
    this.fillBaseProperties(solarCollectorTypeXML, solarCollectorType);

    return solarCollectorType;
  };
}
