import { BaseComboObject } from "./BaseComboObject";

export class DoorType extends BaseComboObject {
  static valuesMap = [];
  _uValue;

  constructor(id, value) {
    super(id);
    DoorType.valuesMap[id] = this;
    this._uValue = value;
  }

  static getDoorTypeById = id => {
    return this.valuesMap[id];
  };

  get uValue() {
    return this._uValue;
  }

  set uValue(uValue) {
    this._uValue = uValue;
  }
}
