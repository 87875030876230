import { StateConstants } from "../constants/StateConstants";

export class StateDataContext {
  _referenceDataContext;
  _newDataContext;
  _state;
  _environmentalData;
  _commonData;

  getCurrentDataContext = () => {
    if (
      this._newDataContext === null ||
      StateConstants.isInitialOrReference(this._state)
    ) {
      return this._referenceDataContext;
    }
    return this._newDataContext;
  };

  getPreviousDataContext = () => {
    if (this._newDataContext != null && this._referenceDataContext != null) {
      if (StateConstants.isInitialOrReference(this._state)) {
        return this._newDataContext;
      }
    }
    return this._referenceDataContext;
  };

  get referenceDataContext() {
    return this._referenceDataContext;
  }

  set referenceDataContext(value) {
    this._referenceDataContext = value;
  }

  get newDataContext() {
    return this._newDataContext;
  }

  set newDataContext(value) {
    this._newDataContext = value;
  }

  get state() {
    return parseInt(this._state);
  }

  set state(value) {
    this._state = parseInt(value);
  }

  get environmentalData() {
    return JSON.parse(this._environmentalData);
  }

  set environmentalData(value) {
    this._environmentalData = value;
  }

  get commonData() {
    return JSON.parse(this._commonData);
  }

  set commonData(value) {
    this._commonData = value;
  }
}
