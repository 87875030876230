import React from "react";
import "./HouseSpecs.scss";
import HouseEnergeticSpecs from "./HouseEnergeticSpecs";
import HouseEnergyLossSpecs from "./HouseEnergyLossSpecs";
import HouseUserEnergyLossSpecs from "./HouseUserEnergyLossSpecs";
import HouseFinalEnergyLossSpecs from "./HouseFinalEnergyLossSpecs";
import PrintButton from "./PrintButton";

export function HouseSpecs(props) {
  return (
    <div className={"house-specs"}>
      <div id={"house-specs"}>
        <HouseEnergeticSpecs
          dataResults={props.dataResults}
          selectedUnit={props.selectedUnit}
          finalUnitSurfaceYearlyKWh={props.finalUnitSurfaceYearlyKWh}
          unitSurfaceUserYearlyKWh={props.unitSurfaceUserYearlyKWh}
          unitSurfacePrimaryYearKWh={props.unitSurfacePrimaryYearKWh}
        />
        <HouseEnergyLossSpecs
          dataResults={props.dataResults}
          selectedUnit={props.selectedUnit}
          pricePerKWh={props.pricePerKWh}
          waterPricePerKWh={props.waterPricePerKWh}
          electricityPricePerKWh={props.electricityPricePerKWh}
          emissionCoefficient={props.emissionCoefficient}
          waterEmissionCoefficient={props.waterEmissionCoefficient}
          electricityEmissionCoefficient={props.electricityEmissionCoefficient}
        />
        <HouseUserEnergyLossSpecs
          dataResults={props.dataResults}
          selectedUnit={props.selectedUnit}
          pricePerKWh={props.pricePerKWh}
          waterPricePerKWh={props.waterPricePerKWh}
          electricityPricePerKWh={props.electricityPricePerKWh}
          emissionCoefficient={props.emissionCoefficient}
          waterEmissionCoefficient={props.waterEmissionCoefficient}
          electricityEmissionCoefficient={props.electricityEmissionCoefficient}
        />
        <HouseFinalEnergyLossSpecs
          dataResults={props.dataResults}
          selectedUnit={props.selectedUnit}
          airConditioningDeviceEnergy={props.airConditioningDeviceEnergy}
          electricDevicesEnergy={props.electricDevicesEnergy}
          heatingDeviceEnergy={props.heatingDeviceEnergy}
          heatingWaterDeviceEnergy={props.heatingWaterDeviceEnergy}
        />
      </div>
      <PrintButton id={"house-specs"} />
    </div>
  );
}
