import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";

export class ElectricityDevicesEnergyLossCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    const heatingData = contextData.heatingData;
    const houseData = contextData.houseData;
    const surfaceData = houseData.surfaceData;
    const ventData = contextData.houseData.ventilationData;
    let ventEnLoss = 0;
    const houseType = houseData.houseType;
    const isGround = HouseTypeHelper.isGroundType(houseType);
    const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);
    const isSingle =
      HouseTypeHelper.isDetachedType(houseType) ||
      HouseTypeHelper.isTerraceType(houseType);
    if (ventData.airTightness.value < 3) {
      ventEnLoss =
        (isGround
          ? ventData.co2Sensor
            ? isMulti
              ? 5
              : isSingle
              ? 2.5
              : 0
            : isMulti
            ? 8
            : isSingle
            ? 4
            : 0
          : 0) * surfaceData.totalSurface;
    } else if (
      ventData.airTightness.value >= 3 &&
      ventData.ventilationMethodObject.type === VentilationMethod.MECHANICAL
    ) {
      ventEnLoss = (isMulti ? 6 : isSingle ? 3 : 0) * surfaceData.totalSurface;
    }
    let enLoss = 0;
    let enWaterLoss = 0;
    ventEnLoss = ventEnLoss / 12;
    if (!ventData.isMechanical) {
      ventEnLoss = 0;
    }
    if (typeof heatingData.subdevicesEnergyConsumption !== "undefined") {
      enLoss +=
        heatingData.subdevicesEnergyConsumption * surfaceData.totalSurface;
    }
    if (contextData.includeWarmWater) {
      if (typeof heatingData.waterSubdevicesEnergyConsumption !== "undefined") {
        enWaterLoss +=
          heatingData.waterSubdevicesEnergyConsumption *
          surfaceData.totalSurface;
      }
    }

    energyData.enElectricDevicesEnergyLossNormalized = EnergyCalcHelper.convertkWhPerYearToWatts(
      enLoss
    );
    energyData.enElectricVentilationEnergyLoss = EnergyCalcHelper.convertkWhPerMonthToWatts(
      ventEnLoss
    );
    energyData.enWaterElectricDevicesEnergyLoss = EnergyCalcHelper.convertkWhPerYearToWatts(
      enWaterLoss
    );
    return energyData;
  };
}
