import { InsulationMaterialTypeConfig } from "../InsulationMaterialTypeConfig";
import { InsulationMaterialType } from "../model/InsulationMaterialType";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";

export class InsulationMaterialTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let insulationMaterialTypesMap = this.readInsulationMaterialTypes(
      dictionaryXML.insulation_material_types
    );
    let wallInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.wall_insulation_material_type,
      insulationMaterialTypesMap
    );
    let floorInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.floor_insulation_material_type,
      insulationMaterialTypesMap
    );
    let roofInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.roof_insulation_material_type,
      insulationMaterialTypesMap
    );
    let roofFlatInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.roof_flat_insulation_material_type,
      insulationMaterialTypesMap
    );
    let basementWallInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.basement_wall_insulation_material_type,
      insulationMaterialTypesMap
    );
    let basementFloorInsulationMaterialTypes = this.getReferencedInsulationMaterialTypes(
      dictionaryXML.basement_floor_insulation_material_type,
      insulationMaterialTypesMap
    );

    let insulationMaterialTypeConfiguration = new InsulationMaterialTypeConfig();
    insulationMaterialTypeConfiguration.insulationMaterialTypes = insulationMaterialTypesMap;
    insulationMaterialTypeConfiguration.wallInsulationMaterialTypes = wallInsulationMaterialTypes;
    insulationMaterialTypeConfiguration.floorInsulationMaterialTypes = floorInsulationMaterialTypes;
    insulationMaterialTypeConfiguration.roofInsulationMaterialTypes = roofInsulationMaterialTypes;
    insulationMaterialTypeConfiguration.roofFlatInsulationMaterialTypes = roofFlatInsulationMaterialTypes;
    insulationMaterialTypeConfiguration.basementWallInsulationMaterialTypes = basementWallInsulationMaterialTypes;
    insulationMaterialTypeConfiguration.basementFloorInsulationMaterialTypes = basementFloorInsulationMaterialTypes;

    insulationMaterialTypeConfiguration.wallInsulationSelectedIndex = this.getSelectedIndex(
      dictionaryXML.wall_insulation_material_type
    );
    insulationMaterialTypeConfiguration.floorInsulationSelectedIndex = this.getSelectedIndex(
      dictionaryXML.floor_insulation_material_type
    );
    insulationMaterialTypeConfiguration.roofInsulationSelectedIndex = this.getSelectedIndex(
      dictionaryXML.roof_insulation_material_type
    );
    insulationMaterialTypeConfiguration.roofFlatInsulationSelectedIndex = this.getSelectedIndex(
      dictionaryXML.roof_flat_insulation_material_type
    );
    insulationMaterialTypeConfiguration.basementWallSelectedIndex = this.getSelectedIndex(
      dictionaryXML.basement_wall_insulation_material_type
    );
    insulationMaterialTypeConfiguration.basementFloorSelectedIndex = this.getSelectedIndex(
      dictionaryXML.basement_floor_insulation_material_type
    );

    dictionaryConfig.insulationMaterialTypeConfig = insulationMaterialTypeConfiguration;

    return dictionaryConfig;
  };

  readInsulationMaterialTypes = insulationMaterialXML => {
    let insulationMaterialTypesMap = [];
    insulationMaterialXML.map(insulationMaterialTypeXML => {
      let insulationMaterialType = this.readInsulationMaterialType(
        insulationMaterialTypeXML
      );
      insulationMaterialTypesMap[
        insulationMaterialType.id
      ] = insulationMaterialType;
      return insulationMaterialTypeXML;
    });

    return insulationMaterialTypesMap;
  };

  getReferencedInsulationMaterialTypes = (
    referringInsulationMaterialTypesXML,
    insulationMaterialTypesMap
  ) => {
    let result = [];
    referringInsulationMaterialTypesXML.insulation_material_type.forEach(
      referringXML => {
        let insulationMaterialTypeRefId = referringXML.ref;
        let insulationMaterialType =
          insulationMaterialTypesMap[insulationMaterialTypeRefId];
        if (insulationMaterialType != null) {
          insulationMaterialType.work = referringXML.work;
          insulationMaterialType.price = referringXML.price;
          insulationMaterialType.obsolescence = referringXML.obsolescence;
          result.push(insulationMaterialType);
        }
      }
    );
    return result;
  };

  readInsulationMaterialType = insulationMaterialTypeXML => {
    let labelCode = insulationMaterialTypeXML.label_code;
    let id = insulationMaterialTypeXML.id;
    let heatTransfer = Number(insulationMaterialTypeXML.heat_transfer);

    let insulationMaterialType = new InsulationMaterialType(
      id,
      heatTransfer,
      labelCode
    );
    this.fillBaseProperties(insulationMaterialTypeXML, insulationMaterialType);

    return insulationMaterialType;
  };
}
