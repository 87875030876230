import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FormattedMessage } from "react-intl";

const PrintButton = ({ id }) => (
  <div className="tc mb4 mt2">
    <div id="myMm" style={{ height: "1mm" }} />

    <div
      className="btn btn-primary btn-lg float-right"
      onClick={() => {
        const input = document.getElementById(id);
        const rows = [...input.getElementsByClassName("d-none")];
        rows.map(item => item.classList.remove("d-none"));
        const charts = [...input.getElementsByClassName("chart")];
        charts.map(item => item.classList.add("d-none"));

        html2canvas(input).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 6, 20);
          pdf.save(`charakterystyka_energetyczna.pdf`);
        });

        rows.map(item => item.classList.add("d-none"));
        charts.map(item => item.classList.remove("d-none"));
      }}
    >
      <FormattedMessage id={"hm.label.print"} defaultMessage="">
        {message => message}
      </FormattedMessage>
    </div>
  </div>
);

export default PrintButton;
