import React from "react";
import { Image } from "./Image";
import { RoofType } from "../../constants/RoofType";

export const LoadRoofImg = React.memo(props => {
  const { roof } = props;
  let img = "";
  let className = "";
  let height = "auto";
  if (roof === RoofType.FLAT) {
    img = "plaski";
    className = "flat";
  } else if (roof === RoofType.GABLE) {
    img = "dwuspadowy";
    className = "gable";
  } else if (roof === RoofType.PITCHED) {
    img = "jednospadowy";
    className = "pitched";
  } else if (roof === RoofType.COMPLICATED) {
    img = "skomplikowany";
    className = "complicated";
  }
  return (
    <Image
      x="0"
      y="0"
      id="roofImg"
      className={className}
      visible={true}
      src={"./assets/images/house/" + img + ".svg"}
      width={1000}
      height={height}
    />
  );
});

LoadRoofImg.displayName = 'LoadRoofImg';
