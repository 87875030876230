export class MonthEnergyData {
  _enBasement = 0;
  _enWalls = 0;
  _enDoor = 0;
  _enRoof = 0;
  _enFloor = 0;
  _enFoundations = 0;
  _enWindows = 0;
  _enVent = 0;
  _enRecuperator = 0;
  _enHumidification = 0;
  _enAir = 0;
  _enTightness = 0;
  _enWarmWater = 0;
  _enPersonGain = 0;
  _enLivingNeeds = 0;
  _enWarmWaterGain = 0;
  _enSolGain = 0;
  _enElectricityGain = 0;
  _enCollectorSolarGain = 0;
  _enPhotovoltaicGain = 0;
  _enPhotovoltaicGainNormalized = 0;
  _enElectricDevicesEnergyLoss = 0;
  _enElectricDevicesEnergyLossNormalized = 0;
  _enElectricVentilationEnergyLoss = 0;
  _enWaterElectricDevicesEnergyLoss = 0;
  _electricDevicesEnergySum = 0;
  _airConditioningLoss = 0;
  _airConditioningDeviceEnergy = 0;

  _enWarmWaterNormalized = 0;

  toString = () =>
    "enWalls: " +
    this._enWalls +
    "enDoor: " +
    this._enDoor +
    " enFloor: " +
    this._enFloor +
    "\n" +
    "enRoof " +
    this._enRoof +
    "enWindows: " +
    this._enWindows +
    " enVent: " +
    this._enVent +
    "\n" +
    "enAir: " +
    this._enAir +
    " enWarmWater " +
    this._enWarmWater +
    " enPersonGain: " +
    this._enPersonGain +
    "\n" +
    "enSolGain: " +
    this._enSolGain +
    " enElectricityGain: " +
    this._enElectricityGain +
    " enWarmWater: \n" +
    this._enWarmWater +
    "enWarmWaterNormalized:  " +
    this._enWarmWaterNormalized;

  get enBasement() {
    return this._enBasement;
  }

  set enBasement(value) {
    this._enBasement = value;
  }

  get enWalls() {
    return this._enWalls;
  }

  set enWalls(value) {
    this._enWalls = value;
  }

  get enDoor() {
    return this._enDoor;
  }

  set enDoor(value) {
    this._enDoor = value;
  }

  get enRoof() {
    return this._enRoof;
  }

  set enRoof(value) {
    this._enRoof = value;
  }

  get enFloor() {
    return this._enFloor;
  }

  set enFloor(value) {
    this._enFloor = value;
  }

  get enFoundations() {
    return this._enFoundations;
  }

  set enFoundations(value) {
    this._enFoundations = value;
  }

  get enWindows() {
    return this._enWindows;
  }

  set enWindows(value) {
    this._enWindows = value;
  }

  get enVent() {
    return this._enVent;
  }

  set enVent(value) {
    this._enVent = value;
  }

  get enRecuperator() {
    return this._enRecuperator;
  }

  set enRecuperator(value) {
    this._enRecuperator = value;
  }

  get enHumidification() {
    return this._enHumidification;
  }

  set enHumidification(value) {
    this._enHumidification = value;
  }

  get enAir() {
    return this._enAir;
  }

  set enAir(value) {
    this._enAir = value;
  }

  get enTightness() {
    return this._enTightness;
  }

  set enTightness(value) {
    this._enTightness = value;
  }

  get enWarmWater() {
    return this._enWarmWater;
  }

  set enWarmWater(value) {
    this._enWarmWater = value;
  }

  get enPersonGain() {
    return this._enPersonGain;
  }

  set enPersonGain(value) {
    this._enPersonGain = value;
  }

  get enWarmWaterGain() {
    return this._enWarmWaterGain;
  }

  set enWarmWaterGain(value) {
    this._enWarmWaterGain = value;
  }

  get enSolGain() {
    return this._enSolGain;
  }

  set enSolGain(value) {
    this._enSolGain = value;
  }

  get enElectricityGain() {
    return this._enElectricityGain;
  }

  set enElectricityGain(value) {
    this._enElectricityGain = value;
  }

  get enCollectorSolarGain() {
    return this._enCollectorSolarGain;
  }

  set enCollectorSolarGain(value) {
    this._enCollectorSolarGain = value;
  }

  get enPhotovoltaicGain() {
    return this._enPhotovoltaicGain;
  }

  set enPhotovoltaicGain(value) {
    this._enPhotovoltaicGain = value;
  }

  get enPhotovoltaicGainNormalized() {
    return this._enPhotovoltaicGainNormalized;
  }

  set enPhotovoltaicGainNormalized(value) {
    this._enPhotovoltaicGainNormalized = value;
  }

  get enWarmWaterNormalized() {
    return this._enWarmWaterNormalized;
  }

  set enWarmWaterNormalized(value) {
    this._enWarmWaterNormalized = value;
  }

  get enLivingNeeds() {
    return this._enLivingNeeds;
  }

  set enLivingNeeds(value) {
    this._enLivingNeeds = value;
  }

  get enElectricDevicesEnergyLoss() {
    return this._enElectricDevicesEnergyLoss;
  }

  set enElectricDevicesEnergyLoss(value) {
    this._enElectricDevicesEnergyLoss = value;
  }

  get enWaterElectricDevicesEnergyLoss() {
    return this._enWaterElectricDevicesEnergyLoss;
  }

  set enWaterElectricDevicesEnergyLoss(value) {
    this._enWaterElectricDevicesEnergyLoss = value;
  }

  get electricDevicesEnergySum() {
    return this._electricDevicesEnergySum;
  }

  set electricDevicesEnergySum(value) {
    this._electricDevicesEnergySum = value;
  }

  get enElectricDevicesEnergyLossNormalized() {
    return this._enElectricDevicesEnergyLossNormalized;
  }

  set enElectricDevicesEnergyLossNormalized(value) {
    this._enElectricDevicesEnergyLossNormalized = value;
  }

  get enElectricVentilationEnergyLoss() {
    return this._enElectricVentilationEnergyLoss;
  }

  set enElectricVentilationEnergyLoss(value) {
    this._enElectricVentilationEnergyLoss = value;
  }

  get airConditioningLoss() {
    return this._airConditioningLoss;
  }

  set airConditioningLoss(value) {
    this._airConditioningLoss = value;
  }

  get airConditioningDeviceEnergy() {
    return this._airConditioningDeviceEnergy;
  }

  set airConditioningDeviceEnergy(value) {
    this._airConditioningDeviceEnergy = value;
  }
}
