import { OptimizerResultsState } from "../OptimizerResultsState";

export class PVOptimizer {
  constructor(
    isInReferenceState,
    buildingType,
    refPricePerKWh,
    newPricePerKWh
  ) {
    this.buildingType = buildingType;
    this.refPricePerKWh = refPricePerKWh;
    this.newPricePerKWh = newPricePerKWh;
    this.isInReferenceState = isInReferenceState;
  }

  validate = (
    type,
    result,
    element,
    newElement,
    newHouseData,
    dataResults,
    newDataResults,
    refCosts,
    newCosts
  ) => {
    element = {
      ...element,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      work: 0,
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      labelKey: "hm.label.photovoltaic.installation.pv_power",
      obsolescence: 25,
    };
    newElement = {
      ...newElement,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      work: 0,
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      labelKey: "hm.label.photovoltaic.installation.pv_power",
      obsolescence: 25,
    };
    result.state = OptimizerResultsState.THE_SAME;

    if (element.pv_type === 1) {
      element.pv_power = 0;
    }
    if (newElement.pv_type === 1) {
      newElement.pv_power = 0;
    }

    result.refItem = element;
    result.newItem = newElement;
    result.obsolescence = result.newItem.obsolescence;

    if (element.pv_power > newElement.pv_power) {
      result.state = OptimizerResultsState.WORSE;
    } else if (element.pv_power < newElement.pv_power) {
      result.state = OptimizerResultsState.BETTER;
      result.refPricePerKWh = this.refPricePerKWh;
      result.newPricePerKWh = this.newPricePerKWh;

      result.obsolescence = result.newItem.obsolescence;
      result.service = 100 * result.newItem.obsolescence;
      result.service = 0;
      let materialCost = parseInt(
        this.calculateCosts(element.pv_power, element.pv_type)
      );
      let newMaterialCost = parseInt(
        this.calculateCosts(newElement.pv_power, newElement.pv_type)
      );
      let workCost = 0;
      let newWorkCost = 0;

      if (refCosts[type + "_material_changed"]) {
        materialCost = refCosts[type + "_material"];
      }
      if (newCosts[type + "_material_changed"]) {
        newMaterialCost = newCosts[type + "_material"];
      }
      if (refCosts[type + "_work_changed"]) {
        workCost = refCosts[type + "_work"];
      }
      if (newCosts[type + "_work_changed"]) {
        newWorkCost = newCosts[type + "_work"];
      }

      result.work = this.buildingType === "current" ? newWorkCost : 0;
      result.material =
        this.buildingType === "current"
          ? newMaterialCost
          : newMaterialCost - (element.pv_power > 0 ? materialCost : 0);
      result.cost = result.material + result.service + result.work;
      result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
      result.materialPrice = this.isInReferenceState
        ? element.pv_power > 0
          ? materialCost
          : 0
        : newMaterialCost;
      result.costView = result.workPrice + result.materialPrice;
    }

    return result;
  };

  calculateCosts = (surface, type) => {
    const ratio = type === 3 ? 1.5 : 1;
    let price = 3300;
    let priceSum = 5000;
    return (priceSum + price * surface) * ratio;
  };
}
