import { BaseComboObject } from "./BaseComboObject";

export class WindowsType extends BaseComboObject {
  static valueMap = [];

  _uValue;
  _winGain;

  constructor(id, uValue, winGain) {
    super(id);
    WindowsType.valueMap[id] = this;
    this._uValue = uValue;
    this._winGain = winGain;
  }

  static getWindowsTypeById = id => {
    return WindowsType.valueMap[id];
  };

  get uValue() {
    return this._uValue;
  }

  set uValue(value) {
    this._uValue = value;
  }

  get winGain() {
    return this._winGain;
  }

  set winGain(value) {
    this._winGain = value;
  }
}
