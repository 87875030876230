import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class TightnessEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    const houseData = contextData.houseData;
    const ventilationData = houseData.ventilationData;
    const surfaceData = houseData.surfaceData;
    const airVolume =
      (0.05 *
        ventilationData.n50 *
        surfaceData.totalSurface *
        surfaceData.floorHeight) /
      3600;
    energyData.enTightness = EnergyCalcHelper.calcAirHeatEnergyTransfer(
      airVolume,
      houseData.tIn,
      monthInputData.tOut,
      1
    );
    return energyData;
  };
}
