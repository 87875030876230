import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getHouse } from "../../redux/selectors";
import connect from "react-redux/es/connect/connect";
import { HouseSpecsTableTd } from "./HouseSpecsTableTd";
import Table from "react-bootstrap/Table";
import PieEnergyChartGroup from "../Charts/PieEnergyChartGroup";
import { ConvertToUnit } from "../Helpers/ConvertToUnit";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

function HouseFinalEnergyLossSpecs(props) {

  const airConditioningDeviceEnergy = ConvertToUnit(
    props.airConditioningDeviceEnergy,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const electricDevicesEnergy = Math.round(
    ConvertToUnit(
      props.electricDevicesEnergy,
      ConversionUnits.ENERGY_KWH_PER_YEAR
    )
  );
  const heatingDeviceEnergy = ConvertToUnit(
    props.heatingDeviceEnergy,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const heatingWaterDeviceEnergy = ConvertToUnit(
    props.heatingWaterDeviceEnergy,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const energySum = Math.round(
    heatingDeviceEnergy +
      heatingWaterDeviceEnergy +
      airConditioningDeviceEnergy +
      electricDevicesEnergy
  );

  const totalSurface = props.house.surfaceData.totalSurface;
  return (
    <>
      <FormattedMessage id="hm.house_spec.energy.final.year" defaultMessage="">
        {message => (
          <h2 className={"mb-0 text-center"}>
            {message} [kWh/(m<sup>2</sup>·rok)]
          </h2>
        )}
      </FormattedMessage>
      <Table striped bordered hover responsive className={"text-center w-100"}>
        <tbody>
          <tr>
            <HouseSpecsTableTd />
            <HouseSpecsTableTd message={"hm.house_spec.heating"} />
            <HouseSpecsTableTd message={"hm.house_spec.cwu"} />
            <HouseSpecsTableTd message={"hm.house_spec.cooling"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.auxiliary"} />
            <HouseSpecsTableTd message={"hm.house_spec.sum"} />
          </tr>
          <tr>
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·rok)]
                </>
              }
            />
            <HouseSpecsTableTd
              value={(heatingDeviceEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd
              value={(heatingWaterDeviceEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd
              value={(airConditioningDeviceEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd
              value={(electricDevicesEnergy / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd value={(energySum / totalSurface).toFixed(1)} />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.participation"} />
            <HouseSpecsTableTd
              value={Math.round((heatingDeviceEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((heatingWaterDeviceEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round(
                (airConditioningDeviceEnergy / energySum) * 100
              )}
            />
            <HouseSpecsTableTd
              value={Math.round((electricDevicesEnergy / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((energySum / energySum) * 100)}
            />
          </tr>
        </tbody>
      </Table>
      <PieEnergyChartGroup
        dataResults={props.dataResults}
        data={[
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.heating" }),

            value: parseFloat((heatingDeviceEnergy / totalSurface).toFixed(1)),
            fill: "#ec6d11",
          },
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.cwu" }),

            value: parseFloat(
              (heatingWaterDeviceEnergy / totalSurface).toFixed(1)
            ),
            fill: "#ff4b4b",
          },
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.cooling" }),

            value: parseFloat(
              (airConditioningDeviceEnergy / totalSurface).toFixed(1)
            ),
            fill: "#a800cc",
          },
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.auxiliary",
            }),

            value: parseFloat(
              (electricDevicesEnergy / totalSurface).toFixed(1)
            ),
            fill: "#888888",
          },
        ]}
        legend={true}
      />
    </>
  );
}

const mapStateToProps = state => {
  const house = getHouse(state);
  return { house };
};

export default injectIntl(connect(mapStateToProps)(HouseFinalEnergyLossSpecs));
