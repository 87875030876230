import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";
import { BasementType } from "../../../constants/BasementType";

export class FloorEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInput) => {
    const houseData = contextData.houseData;
    const tAvg = contextData.environmentalData.tAvg;
    const uValue = houseData.floorElement.uValue;
    const basementData = houseData.basement;

    const sideLength = Math.sqrt(houseData.surfaceData.floorSurface);

    let enFloorCenter = 0;
    let enFloorEdge = 0;
    if (
      !basementData.enabled ||
      (basementData.enabled && basementData.type === BasementType.UNHEATED)
    ) {
      const isGroundType = HouseTypeHelper.isGroundType(houseData.houseType);

      const foundationsEnabled =
        isGroundType && houseData.foundationType.isFoundationsEnabled();

      if (foundationsEnabled) {
        enFloorCenter =
          (sideLength - 0.6) *
          (sideLength - 0.6) *
          uValue *
          (houseData.tIn - tAvg);
      } else if (isGroundType) {
        //plyta
        enFloorCenter =
          (sideLength - 2) * (sideLength - 2) * (houseData.tIn - tAvg) * uValue;
        enFloorEdge =
          (sideLength * sideLength - (sideLength - 2) * (sideLength - 2)) *
          (houseData.tIn - monthInput.tOut) *
          uValue;
      }
    }

    energyData.enFloor = enFloorCenter + enFloorEdge;

    return energyData;
  };
}
