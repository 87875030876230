export class WattsEnergyResult {
  _monthlyEnergyConsumption = [];
  _heatingSourceMaxPower;

  constructor(monthlyEnergyConsumption) {
    this._monthlyEnergyConsumption = monthlyEnergyConsumption;
  }

  get monthlyEnergyConsumption() {
    return this._monthlyEnergyConsumption;
  }

  get heatingSourceMaxPower() {
    return this._heatingSourceMaxPower;
  }

  set heatingSourceMaxPower(value) {
    this._heatingSourceMaxPower = value;
  }
}
