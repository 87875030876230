import { BaseComboObject } from "./BaseComboObject";

export class InsulationMaterialType extends BaseComboObject {
  static _values = [];
  static valueMap = [];

  _heatTransferCoefficient;
  _labelCode;

  static STANDARD = new InsulationMaterialType(
    "hm.insulation.material_type.average",
    0.04,
    "hm.dropdown.insulation.average"
  );

  constructor(id, heatTransferCoefficient, labelCode) {
    super(id);
    this._heatTransferCoefficient = heatTransferCoefficient;
    this._labelCode = labelCode;
    InsulationMaterialType._values.push(this);
    InsulationMaterialType.valueMap[id] = this;
  }

  static getMaterialInsulationTypeById = id => {
    return InsulationMaterialType.valueMap[id];
  };

  static getMaterialInsulationTypeForHeatTransferValue = value => {
    if (isNaN(value)) {
      return InsulationMaterialType.STANDARD;
    }
    for (let i = 0; i < InsulationMaterialType.values.length; i++) {
      let insulationQuality = InsulationMaterialType.values[i];
      if (insulationQuality.heatTransferCoefficient === value) {
        return insulationQuality;
      }
    }
    return InsulationMaterialType.STANDARD;
  };

  static get values() {
    return InsulationMaterialType._values;
  }

  static set values(value) {
    InsulationMaterialType._values = value;
  }

  get heatTransferCoefficient() {
    return this._heatTransferCoefficient;
  }

  set heatTransferCoefficient(value) {
    this._heatTransferCoefficient = value;
  }
}
