import React from "react";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Popup = React.memo(props => {
  const { placement, className, message, iconClassName } = props;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover key={placement} placement={placement} className={className}>
          <div className={"description"}>
            <FormattedHTMLMessage
              id={message}
              defaultMessage=""
              tagName={"div"}
            />
          </div>
        </Popover>
      }
    >
      <FontAwesomeIcon icon="info-circle" className={iconClassName} />
    </OverlayTrigger>
  );
});

Popup.displayName = "Popup";
