export class Directions {
  static NORTH = "hm.label.direction.north";
  static NORTHEAST = "hm.label.direction.north.east";
  static EAST = "hm.label.direction.east";
  static SOUTHEAST = "hm.label.direction.south.east";
  static SOUTH = "hm.label.direction.south";
  static SOUTHWEST = "hm.label.direction.south.west";
  static WEST = "hm.label.direction.west";
  static NORTHWEST = "hm.label.direction.north.west";

  static directionsArray = [
    Directions.NORTH,
    Directions.NORTHEAST,
    Directions.EAST,
    Directions.SOUTHEAST,
    Directions.SOUTH,
    Directions.SOUTHWEST,
    Directions.WEST,
    Directions.NORTHWEST,
  ];

  static getDirections = () => {
    return Directions.directionsArray;
  };
}
