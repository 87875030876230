import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getHouse } from "../../redux/selectors";
import connect from "react-redux/es/connect/connect";
import { HouseSpecsTableTd } from "./HouseSpecsTableTd";
import Table from "react-bootstrap/Table";
import { EnergyClassHelper } from "../../util/EnergyClassHelper";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { createFormattedValue } from "../Helpers/ConvertToUnit";
import { getConvertedCosts } from "../../util/getConvertedCosts";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

function HouseEnergeticSpecs(props) {
  const data = props.dataResults.yearlyAggregatedConvertedData;
  const energyClass = EnergyClassHelper.getEnergyClass(
    props.finalUnitSurfaceYearlyKWh
  );
  const maxPower = (
    props.dataResults.wattsEnergyResult.heatingSourceMaxPower / 1000
  ).toFixed(1);
  const totalSurface = props.house.surfaceData.totalSurface;
  const pvCalc = getPvEnergyCalculated(
    props.dataResults,
    props.dataResults.dataContext.houseData.livingNeedsChanged,
    props.dataResults.dataContext.houseData.livingNeeds
  );
  const costs = getConvertedCosts(
    props.dataResults,
    ConversionUnits.EMISSIONS_PER_YEAR,
    pvCalc
  );
  const pv = Math.round(
    pvCalc.energyCo < pvCalc.basicEnergy ? pvCalc.energyCo : pvCalc.basicEnergy
  );
  const heating = Math.round(data.heatingDeviceEnergy);
  const collectors = Math.round(data.enCollectorSolarGain);

  const water = Math.round(data.heatingWaterDeviceEnergy) + collectors;
  const cooling = Math.round(data.airConditioningDeviceEnergy);
  const electricDevices = Math.round(
    data.enElectricDevicesEnergyLossNormalized +
      data.enRecuperator +
      data.enHumidification +
      data.enWaterElectricDevicesEnergyLoss +
      data.enElectricVentilationEnergyLoss
  );
  const oze = Math.round(
    ((pv + collectors) / (heating + water + cooling + electricDevices)) * 100
  );
  const emissionValue = createFormattedValue(
    costs.yearlyHeatingCost +
      costs.yearlyWaterHeatingCost +
      costs.yearlyElectricCost -
      costs.pvCost,
    ConversionUnits.EMISSIONS_PER_YEAR
  );
  return (
    <>
      <FormattedMessage id="hm.house_spec.header" defaultMessage="">
        {message => <h2 className={"mb-0 text-center"}>{message}</h2>}
      </FormattedMessage>
      <Table striped bordered hover responsive className={"text-center"}>
        <tbody>
          <tr className={"d-none"}>
            <HouseSpecsTableTd message={"hm.house_spec.date"} />
            <td colSpan={2}></td>
          </tr>
          <tr className={"d-none"}>
            <HouseSpecsTableTd message={"hm.house_spec.building_type"} />
            <td colSpan={2}></td>
          </tr>
          <tr className={"d-none"}>
            <HouseSpecsTableTd message={"hm.house_spec.building_address"} />
            <td colSpan={2}></td>
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.area"} />
            <HouseSpecsTableTd colSpan={2} value={Math.round(totalSurface)} />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.grade"} />
            <HouseSpecsTableTd
              colSpan={1}
              message={"hm.house_spec.grade.building"}
              value={
                <p
                  className={"energy-class-value"}
                  style={{ color: energyClass.color }}
                >
                  <FormattedMessage id={energyClass.description} />
                </p>
              }
            />
            <HouseSpecsTableTd
              colSpan={1}
              message={"hm.house_spec.grade.unit"}
              value={
                <p
                  className={"energy-class-grade"}
                  style={{ color: energyClass.color }}
                >
                  {energyClass.code}
                </p>
              }
            />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.energy.usable.year"} />
            <HouseSpecsTableTd
              value={props.unitSurfaceUserYearlyKWh.toFixed(1)}
            />
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·rok)]
                </>
              }
            />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.energy.final.year"} />
            <HouseSpecsTableTd
              value={props.finalUnitSurfaceYearlyKWh.toFixed(1)}
            />
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·rok)]
                </>
              }
            />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.energy.primary.year"} />
            <HouseSpecsTableTd
              value={props.unitSurfacePrimaryYearKWh.toFixed(1)}
            />
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·rok)]
                </>
              }
            />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.emission"} />
            <td>{(emissionValue / totalSurface).toFixed(1)}</td>
            <HouseSpecsTableTd
              value={
                <>
                  kg CO<sub>2</sub>/(m<sup>2</sup>/rok)
                </>
              }
            />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.max_power"} />
            <HouseSpecsTableTd value={maxPower} />
            <HouseSpecsTableTd value={"kW"} />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.energy.oze"} />
            <HouseSpecsTableTd value={oze} />
            <HouseSpecsTableTd value={"%"} />
          </tr>
        </tbody>
      </Table>
    </>
  );
}

const mapStateToProps = state => {
  const house = getHouse(state);
  return { house };
};

export default injectIntl(connect(mapStateToProps)(HouseEnergeticSpecs));
