import {ConversionUnits} from "../constants/combo/ConversionUnits";

export default function getRawUnitName(selectedUnit, currencyCode){
    if (ConversionUnits.isEmisionUnit(selectedUnit)) {
        return "CO2";
    }
    else if (ConversionUnits.isGJUnit(selectedUnit)) {
        return "GJ";
    }
    else if (ConversionUnits.isKiloWattsUnit(selectedUnit)) {
        return "kWh";
    }
    else if (ConversionUnits.isCostUnit(selectedUnit)) {
        return currencyCode;
    }
    return "W";
}

