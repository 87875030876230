export class HeatingType {

    static values = [];

    static PELLETS = new HeatingType("pellets");
    static WOOD = new HeatingType("wood");
    static COAL = new HeatingType("coal");
    static GAS = new HeatingType("gas");
    static OIL = new HeatingType("oil");
    static COMBINED = new HeatingType("combined_heat");
    static ELECTRIC = new HeatingType("electric");

    _heatingType;

    constructor(heatingType) {
        HeatingType.values[heatingType] = this;
        this._heatingType = heatingType;
    }

    get heatingType() {
        return this._heatingType;
    }

    static of = (code) => {
        return HeatingType.values[code];
    }
}
