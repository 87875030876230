import {UPDATE_WINDOWS} from "../actionTypes";

const initialState = {
    window: '',
    thermalBridge:"hm.thermal-bridge.avg",
    shutters: false,
};

export default function(state = initialState, action){

    switch(action.type){
        case UPDATE_WINDOWS: {
            const { window, thermalBridge, shutters } = action.payload;
            return {
                ...state,
                window: window,
                thermalBridge: thermalBridge,
                shutters: shutters,
            };
        }
        default:
            return state;
    }
}