import React from "react";
import { connect } from "react-redux";
import { updateCosts, updateWindows } from "../../redux/actions";
import { getCosts, getInsulation, getWindows } from "../../redux/selectors";

import { FormattedMessage, injectIntl } from "react-intl";
import { BuildingAge } from "../../config/dictionary/model/BuildingAge";

import { DropDownList } from "../Helpers/DropDownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { Thermoderm } from "../Helpers/Thermoderm";

class WindowsBaloon extends HeatMaster {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showBaloon: window.innerWidth >= 1024,
    };
    this.configurationRead(this.props.businessConfig);
  }

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  windowsList = {};

  thermalBridgesList = {};

  configurationRead = businessConfiguration => {
    this.windowsList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.windowTypeConfiguration.windowTypes
    );
    this.thermalBridgesList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.thermalBridgesTypeConfig
        .thermalBridgesTypes
    );
    this.props.dispatch(
      updateWindows(
        this.windowsList[
          businessConfiguration.dictionaryConfig.windowTypeConfiguration
            .selectedIndex
        ].data.id,
        this.thermalBridgesList[
          businessConfiguration.dictionaryConfig.thermalBridgesTypeConfig
            .selectedIndex
        ].data.id,
        false
      )
    );
  };

  buildingAgeChanged = (buildingAge, calculationRequired = true) => {
    if (buildingAge === BuildingAge.UP_TO_1984) {
      if (calculationRequired) {
        this.windowChanged();
      }
    }
  };

  windowChanged = e => {
    this.props
      .dispatch(
        updateWindows(
          e,
          this.props.windows.thermalBridge,
          this.props.windows.shutters
        )
      )
      .then(() => {
        this.props
          .dispatch(
            updateCosts({
              windows_material: undefined,
              windows_work: undefined,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };
  thermalBridgeChanged = e => {
    this.props
      .dispatch(
        updateWindows(this.props.windows.window, e, this.props.windows.shutters)
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  render() {
    const thermalBridgesList = this.thermalBridgesList.filter(item => {
      return (
        (item.name !== "hm.dropdown.windows.thermal_bridges_min" &&
          this.props.insulation.wall.width < 1) ||
        this.props.insulation.wall.width > 0
      );
    });
    return (
      <Card
        className={cx(
          "baloon window-baloon popover bs-popover-left d-flex align-items-center",
          { hidden: !this.state.showBaloon },
          {
            warning:
              this.props.invalidDataOptimizer &&
              (this.props.invalidDataOptimizer.windows.state ===
                OptimizerResultsState.WORSE ||
                this.props.invalidDataOptimizer.thermalBridges.state ===
                  OptimizerResultsState.WORSE),
          },
          {
            success:
              this.props.invalidDataOptimizer &&
              (this.props.invalidDataOptimizer.windows.state ===
                OptimizerResultsState.BETTER ||
                this.props.invalidDataOptimizer.thermalBridges.state ===
                  OptimizerResultsState.BETTER),
          }
        )}
      >
        <FormattedMessage id="hm.label.windows" defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact iconSet={iconSet} size={22} icon="window_icon" />
            </span>
          )}
        </FormattedMessage>

        <div className={cx("arrow m-0")} />
        <div className={cx("popover-body")}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="2"
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2"}
                  iconSet={iconSet}
                  size={16}
                  icon="window_icon"
                />
                <FormattedMessage
                  id="hm.label.windows"
                  defaultMessage=""
                  tagName={"h3"}
                >
                  {message => <h3 className={"mb-0"}>{message}</h3>}
                </FormattedMessage>
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1", {
                  reversed: !this.state.show,
                })}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className={"mb-1"}>
                  <DropDownList
                    id="windowDropDownList"
                    dataProvider={this.windowsList}
                    labelField="name"
                    selectedIndex={this.props.windows.window}
                    onChange={e => this.windowChanged(e)}
                  />
                </div>
                <div className={"d-flex align-items-center"}>
                  <label className={"mb-0 d-flex align-items-center mr-3"}>
                    <input
                      type="checkbox"
                      name={"blinds"}
                      checked={this.props.windows.shutters}
                      onChange={e =>
                        this.props.shuttersChanged(e.target.checked)
                      }
                    />
                    <FormattedMessage id="hm.label.blinds" defaultMessage="">
                      {message => <p className={"mb-0 ml-1"}>{message}</p>}
                    </FormattedMessage>
                  </label>
                  <DropDownList
                    id="thermalBridgesDropDownList"
                    dataProvider={thermalBridgesList}
                    labelField="name"
                    selectedIndex={this.props.windows.thermalBridge}
                    onChange={e => this.thermalBridgeChanged(e)}
                  />
                </div>
                {this.props.invalidDataOptimizer &&
                  (this.props.invalidDataOptimizer.windows.state ===
                    OptimizerResultsState.BETTER ||
                    this.props.invalidDataOptimizer.thermalBridges.state ===
                      OptimizerResultsState.BETTER ||
                    this.props.invalidDataOptimizer.shutters.state ===
                      OptimizerResultsState.BETTER) && (
                    <Thermoderm
                      data={[
                        this.props.invalidDataOptimizer.windows,
                        this.props.invalidDataOptimizer.thermalBridges,
                        this.props.invalidDataOptimizer.shutters,
                      ]}
                      className={"insulation-windows"}
                      writeCache={() => this.props.writeCache()}
                    />
                  )}
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={"hm.tooltip.windows"}
            placement={"right"}
          />
        </div>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  const windows = getWindows(state);
  const cost = getCosts(state);
  const insulation = getInsulation(state);
  return { windows, cost, insulation };
};
export default injectIntl(connect(mapStateToProps)(WindowsBaloon));
