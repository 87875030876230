export class HeatMasterFormatter {

    static formatHMValue = (value,precision = 0,useThousandSeparator = false) => {
        if(precision > 0) {
            return parseFloat(value).toFixed(precision);
        } else {
            return parseInt(value);
        }
    };

    static getStepSize = (value) => {
        if(value < 10.0){
           return 0.01;
        }
        if(value >= 10.0 && value < 100.0){
           return 0.1;
        }
        return 1.0;
    };

    static getDecimalSize = (value) => {
        if(value < 10.0){
            return parseFloat(value).toFixed(2);
        }
        if(value >= 10.0 && value < 100.0){
            return parseFloat(value).toFixed(1);
        }
        return Math.round(value);
    };

    static formatHMValueRoundUp = (value,precision = 0) => {
        let number = Math.ceil(value);
        return number.toFixed(precision);
    };

    createNearestPrecisionFormatter = (precision = 0) => {
        
    };

    static createFormatter=(precision = 0,rounding) =>{
    };
}
