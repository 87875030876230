export class VentilationData {
  _gwcSet;
  _ventilationFreq;
  _co2Sensor;
  _airTightness;
  _ventilationMethodObject;
  _humidification;
  _recuperatorLocation;
  _n50;
  _patency;
  _recuperator;
  _isMechanical;

  toString = () => "VentilationData";

  get gwcSet() {
    return this._gwcSet;
  }

  set gwcSet(value) {
    this._gwcSet = value;
  }

  get ventilationFreq() {
    return this._ventilationFreq;
  }

  set ventilationFreq(value) {
    this._ventilationFreq = value;
  }

  get co2Sensor() {
    return this._co2Sensor;
  }

  set co2Sensor(value) {
    this._co2Sensor = value;
  }

  get airTightness() {
    return this._airTightness;
  }

  set airTightness(value) {
    this._airTightness = value;
  }

  get ventilationMethodObject() {
    return this._ventilationMethodObject;
  }

  set ventilationMethodObject(value) {
    this._ventilationMethodObject = value;
  }

  get humidification() {
    return this._humidification;
  }

  set humidification(value) {
    this._humidification = value;
  }

  get recuperatorLocation() {
    return this._recuperatorLocation;
  }

  set recuperatorLocation(value) {
    this._recuperatorLocation = value;
  }

  get n50() {
    return this._n50;
  }

  set n50(value) {
    this._n50 = value;
  }

  get patency() {
    return this._patency;
  }

  set patency(value) {
    this._patency = value;
  }

  get recuperator() {
    return this._recuperator;
  }

  set recuperator(value) {
    this._recuperator = value;
  }

  get isMechanical() {
    return this._isMechanical;
  }

  set isMechanical(value) {
    this._isMechanical = value;
  }
}
