import React, { lazy, Suspense } from 'react';
import {connect} from "react-redux";
import './HousePanel.scss';

import Welcome from './../Welcome/Welcome';

import {HeatMaster} from "../HeatMaster";
import {VentilationMethod} from "../../config/dictionary/model/VentilationMethod";
import {VentilationFrequency} from "../../constants/combo/VentilationFrequency";
import {DropDownType} from "../../constants/combo/DropDownType";
import {FoundationsType} from "../../config/dictionary/model/FoundationsType";
import {HouseType} from "../../constants/combo/HouseType";
import {InsulationElementType} from "../../constants/combo/InsulationElementType";
import {SolarCollectorData} from "../../core/model/SolarCollectorData";
import {HouseTypeHelper} from "../../util/HouseTypeHelper";
import {CountryItemHelper} from "../../config/CountryItemHelper";
import {HeatingSourceTypeItem} from "../../config/dictionary/model/HeatingSourceTypeItem";
import {HeatingSourceType} from "../../config/dictionary/model/HeatingSourceType";
import {HeatingSourceData} from "../../core/model/HeatingSourceData";
import {GlobalValues} from "../../constants/GlobalValues";
import {SolarCollectorType} from "../../config/dictionary/model/SolarCollectorType";
import {InsulationElementPreparer} from "../../ui/builder/prepare/element/InsulationElementPreparer";
import {UValueBasedElementPreparer} from "../../ui/builder/prepare/element/UValueBasedElementPreparer";
import {SurfaceCalculator} from "../../ui/builder/prepare/surface/SurfaceCalculator";
import {HouseModel} from "../../ui/form/HouseModel";
import {Image} from "../Helpers/Image";
import {WarmWaterDistribution} from "../../config/dictionary/model/WarmWaterDistribution";
import {WarmWaterStorage} from "../../config/dictionary/model/WarmWaterStorage";

import backgroundGrass from "./../../assets/images/backgrounds/background_grass.svg";
import backgroundSky from "./../../assets/images/backgrounds/background_sky.svg";
import WindowsBaloon from "../Baloons/WindowsBaloon";
import VentilationBaloon from "../Baloons/VentilationBaloon";
import PersonalGainBaloon from "../Baloons/PersonalGainBaloon";
import InsulationBaloon from "../Baloons/InsulationBaloon";
import {ThermalBridgesType} from "../../config/dictionary/model/ThermalBridgesType";
import DropdownBaloon from "../Baloons/DropdownBaloon";
import { Scrollbars } from 'react-custom-scrollbars';
import Accordion from 'react-bootstrap/Accordion';
import { AutoSizer } from "react-virtualized";
import {LoadHeatingDeviceImg} from "../Helpers/LoadHeatingDeviceImg";

import {
    getInsulation,
    getVentilation,
    getWindows,
    getHouse,
    getHeating, getPersons, getConversion, getAC, getPV, getReports, getInsolation
} from "../../redux/selectors";
import cx from 'classnames';
import {
  updateHeatingById,
  updateSurface,
  updateHouseById,
  updateVentilation,
  updateWindows,
  updateWater,
  updateCollectors,
  updateDistribution,
  updateConversion,
  updateHeatingAll,
  updateInsulation,
  updatePhotovoltaic,
  updateAirconditioning,
  updateAirconditioningById,
  updateInsulationByType,
  updateInsolation,
  updateHouseRoofById,
  updateCosts,
  updateInsulationByTypeAndId, updateHouseRoof, replaceHouseWalls
} from "../../redux/actions";
import {FormattedMessage, injectIntl} from "react-intl";
import {WarmWaterData} from "../../core/model/WarmWaterData";
import EnergyMeter from "../EnergyMeter/EnergyMeter";
import {ConversionUnits} from "../../constants/combo/ConversionUnits";
import {ConverterHelper} from "../../core/converter/ConverterHelper";
import PlotPanel from "../PlotPanel/PlotPanel";
import ComparingPlotPanel from "../PlotPanel/ComparingPlotPanel";
import InsolationBaloon from "../Baloons/InsolationBaloon";
import PhotovoltaicPopup from "../PhotovoltaicPopup/PhotovoltaicPopup";
import AirConditioningBaloon from "../Baloons/AirConditioningBaloon";
import {BasementType} from "../../constants/BasementType";
import {LoadRoofImg} from "../Helpers/LoadRoofImg";
import {RoofInsulationOptimizer} from "../../core/optimizer/selectors/RoofInsulationOptimizer";
import {FloorInsulationOptimizer} from "../../core/optimizer/selectors/FloorInsulationOptimizer";
import {WallInsulationOptimizer} from "../../core/optimizer/selectors/WallInsulationOptimizer";
import {DoorOptimizer} from "../../core/optimizer/selectors/DoorOptimizer";
import {FoundationOptimizer} from "../../core/optimizer/selectors/FoundationOptimizer";
import {WindowOptimizer} from "../../core/optimizer/selectors/WindowOptimizer";
import {ShuttersOptimizer} from "../../core/optimizer/selectors/ShuttersOptimizer";
import {ThermalBridgesOptimizer} from "../../core/optimizer/selectors/ThermalBridgesOptimizer";
import {PVOptimizer} from "../../core/optimizer/selectors/PVOptimizer";
import {VentilationOptimizer} from "../../core/optimizer/selectors/VentilationOptimizer";
import {HeatingSourceOptimizer} from "../../core/optimizer/selectors/HeatingSourceOptimizer";
import {AirTightness} from "../../config/dictionary/model/AirTightness";
import {WallsToSurfaceCalculator} from "../../ui/builder/prepare/surface/WallsToSurfaceCalculator";
import {CollectorsOptimizer} from "../../core/optimizer/selectors/CollectorsOptimizer";
import WaterBaloon from "../Baloons/WaterBaloon";
import {RoofType} from "../../constants/RoofType";

import {LoadArrows} from "../Arrows/Arrows";
const OptimizerPanel = lazy(() => import("../OptimizerPanel/OptimizerPanel"));
const HeatingPopupContainer = lazy(() => import("../HeatingPopup/HeatingPopupContainer"));
const HousePopup = lazy(() => import("../HousePopup/HousePopup"));
class HousePanel extends HeatMaster {
    constructor(props, context) {
        super(props, context);

        this.state = {
            wallRightInsulationImgHeight: 574,
            wallDownLeftInsulationImgHeight: 221,
            localCurrency: false,
            unitName: 'hm.unit.kwh.year',
            prevSelectedUnit: ConversionUnits.ENERGY_KWH_PER_YEAR,
            shortUnitName: 'hm.currency.euro.year_cost',
            showNaturalUnit: true,
            naturalUnitCalorificValue: this.adjustNaturalUnitText({code: 'hm.natural_unit.m3'}),
            naturalUnitCostLabel: this.getNaturalUnitPriceLabelText({code: 'hm.natural_unit.m3'}),
            calorificValInputEnabled: false,
            solarSurfaceChanged: false,
            stationsVisibility: true,
            showRegions: false,
            showBackdrop: false,
            showPlotPanel: false,
            showComparingPlotPanel: false,
            heatingFuelDropDownList: [],
        };
        
        this.configurationRead(this.props.businessConfig);
        this.setInsulationVisibility( HouseTypeHelper.isGroundType(this.props.house.houseType),
            HouseTypeHelper.hasNeighboursUp(this.props.house.houseType));
    }

    previousHouseType = HouseType.DETACHED;
    surfaceCalculator = new SurfaceCalculator();
    wallsToSurfaceCalculator = new WallsToSurfaceCalculator();
    configItem;
    regionDropDownList = [];

    ventilationFreqList = this.toComboObjectList([
        {
            "id": VentilationFrequency.SPORADICALLY,
            "name": 'hm.dropdown.airing.sporadic',
            "u_value": VentilationFrequency.SPORADICALLY,
        },
        {
            "id": VentilationFrequency.STANDARD,
            "name": 'hm.dropdown.airing.standard',
            "u_value": VentilationFrequency.STANDARD,
        },
        {
            "id": VentilationFrequency.OFTEN,
            "name": 'hm.dropdown.airing.often',
            "u_value": VentilationFrequency.OFTEN,
        },
        {
            "id": VentilationFrequency.VERY_OFTEN,
            "name": 'hm.dropdown.airing.very_often',
            "u_value": VentilationFrequency.VERY_OFTEN,
        },
        {
            "id": VentilationFrequency.NON_STOP,
            "name": 'hm.dropdown.airing.non_stop',
            "u_value": VentilationFrequency.NON_STOP,
        },
    ]);

    airConditioningList = this.toComboObjectList([
        {
            "id": 1,
            "name": 'hm.dropdown.air_conditioning.multi_split',
            "u_value": 1,
        },
        {
            "id": 2,
            "name": 'hm.dropdown.air_conditioning.compact_split',
            "u_value": 1,
        },
        {
            "id": 3,
            "name": 'hm.dropdown.air_conditioning.heat_pump',
            "u_value": 1,
        }]);

    heatingWaterDropDownList=[];
    ventilationMethodList = {};
    warmWaterConsumptionList = {};
    shadingList = {};
    warmWaterConsumptionListSelectedIndex;
    ventilationFreqListSelectedIndex = 1;

    originalInsulationLeftDownHeight = 168;
    originalInsulationRightHeight = 521;
    originalInsulationLeftUpHeight;
    originalInsulationLeftUpY;
    originalInsulationRightY;
    originalFlatRoofWidth;
    originalInsulationBridgeX;
    originalInsulationBrideWidth;

    INSULATION_TOP = 167;
    INSULATION_BOTTOM = 99;
    WALL_HOLE_WIDTH = 24;
    FOUNDATIONS_TRAD_INSULATED_WALL_EXTENSION = 52;

    CURRENCY_EURO_LOCALE_CODE = 'hm.currency.euro';
    CURRENCY_EURO = 'EUR';

    componentWillMount() {

    }
    componentDidMount(){
        this.configurationRead(this.props.businessConfig);
        this.setInsulationVisibility( HouseTypeHelper.isGroundType(this.props.house.houseType),
            HouseTypeHelper.hasNeighboursUp(this.props.house.houseType));
    }


    configurationRead = (businessConfiguration) => {
        this.countryList = businessConfiguration.countryItems;

        this.warmWaterConsumptionList = this.toComboObjectList(businessConfiguration.dictionaryConfig.waterConsumptionConfiguration.consumptionIntensities);
        this.warmWaterConsumptionListSelectedIndex = businessConfiguration.dictionaryConfig.waterConsumptionConfiguration.selectedIndex;
        
        const heatingDeviceTypes = businessConfiguration.dictionaryConfig.heatingDeviceTypeConfiguration.heatingDeviceTypes;

        const heatingFuelsTypes = businessConfiguration.dictionaryConfig.heatingFuelsTypeConfiguration.heatingFuelTypes;
        this.heatingDropDownList = this.toHeatingDeviceObjectList(heatingDeviceTypes.sources);
        this.heatingTypeDropDownList = this.toHeatingDeviceObjectList(heatingDeviceTypes.types);
        this.heatingSystemDropDownList = this.toHeatingDeviceObjectList(heatingDeviceTypes.systems);
        this.heatingFuelDropDownList = this.toHeatingSourceObjectList(heatingFuelsTypes);

        this.houseStandardTypeList = this.toComboObjectList(businessConfiguration.dictionaryConfig.houseStandardTypeConfiguration.houseStandardTypes);
        this.houseStandardList = this.houseStandardTypeList;
        this.electricityHeatingSourceType = this.toComboObjectList(businessConfiguration.dictionaryConfig.heatingDeviceTypeConfiguration.electricityHeatingSourceType);
        this.electricityHeatingSourceType = heatingFuelsTypes.filter(item => item.heatingType.heatingType === 'electric')[0];
        
        this.shadingList = businessConfiguration.dictionaryConfig.shadingConfiguration;
        this.warmWaterStorageList = this.toComboObjectList(businessConfiguration.dictionaryConfig.waterStorageDistributionConfiguration.waterStorageTypes);

    };

    prepareDropdownLists = (sources, types, subtypes, systems) => {

    };

    prepareHeatingTypeDropdownList = (list, parent) => {
        return list.filter(item => item.data.parent === parent)
    };

    prepareHeatingSystemDropdownList = (list, heatingType, heatingSource) => {
        let results = this.prepareHeatingTypeDropdownList(list, heatingType);
        if(results.length === 0){
            results = this.prepareHeatingTypeDropdownList(list, heatingSource);
        }
        return results;
    };

    getDefaultDataTreeFromSource = (e, newHatingType = false) => {
        const houseType = this.props.house.houseType;
        const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);
        const isSingle = HouseTypeHelper.isDetachedType(houseType) || HouseTypeHelper.isTerraceType(houseType);
        let subdevicesEnergyConsumption = 0;
        let waterSubdevicesEnergyConsumption = 0;

        const heatingTypes = this.prepareHeatingTypeDropdownList(this.heatingTypeDropDownList, e);
        let heatingData = new HeatingSourceData();
        
        heatingData.waterHeatingSource = e;
        
        const heatingSource = this.heatingDropDownList.filter(item => item.name === e);
        if(typeof heatingSource[0].data.fuel !== 'undefined'){
            
            
            heatingData.heatingFuel = heatingSource[0].data.fuel[0].ref.name;
            const fuelData = this.heatingFuelDropDownList
                .filter(item => item.name === heatingData.heatingFuel)
                .map(item => item.data)[0];
            heatingData.pricePerKWh = fuelData.pricePerKWh;
            heatingData.heatingSource = heatingSource[0].data.id;
            heatingData.naturalUnitPrice = fuelData.naturalUnitPrice;
            heatingData.naturalUnit = fuelData.type.naturalUnit.code;
            heatingData.calorificValue = fuelData.heatingValueMJ;
            heatingData.calorificValueUnit = fuelData.type.calorificValueUnit;
            heatingData.finalToPrimaryCoefficient = fuelData.type.finalToPrimaryCoefficient;
            heatingData.emissionCoefficient = fuelData.type.emissionPerKWh;
            heatingData.heatingValueEditable = fuelData.type.heatingValueEditable;
            heatingData.showNaturalUnit = fuelData.type.showNaturalUnit;
        }

        if(typeof heatingSource[0].data.cwu !== 'undefined') {
            this.heatingWaterDropDownList = this.toHeatingDeviceObjectList(heatingSource[0].data.cwu.map(item => item.ref));
        }
        if(typeof heatingSource[0].data.subdevices_energy_consumption !== 'undefined') {
            subdevicesEnergyConsumption = heatingSource[0].data.subdevices_energy_consumption;
            if(isMulti && typeof subdevicesEnergyConsumption.multi !== "undefined") {
                heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.multi;
            } else if(isSingle && typeof subdevicesEnergyConsumption.single !== "undefined"){
                heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.single;
            }
        }

        if(heatingTypes.length > 0) {
            let heatingType;
            if (newHatingType){
              heatingType = this.heatingTypeDropDownList.filter(i => i.name === newHatingType)[0];
            } else {
              heatingType = heatingTypes[0];
              heatingData.heatingType = heatingType.name;
            }
            if(typeof heatingType.data.efficiency !== 'undefined'){
                
                heatingData.heatingEfficiency = heatingType.data.efficiency.heat;
                heatingData.coolingEfficiency = heatingType.data.efficiency.cool;
                heatingData.heatingSystemEfficiency = heatingType.data.efficiency.system;
                heatingData.waterHeatingEfficiency = typeof heatingType.data.efficiency.cwu !== "undefined"
                    ? heatingType.data.efficiency.cool : heatingData.heatingEfficiency
            }


            if(typeof heatingType.data.subdevices_energy_consumption !== "undefined") {
                subdevicesEnergyConsumption = heatingType.data.subdevices_energy_consumption;
                if (isMulti && typeof subdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.multi;
                } else if (isSingle && typeof subdevicesEnergyConsumption.single !== "undefined") {
                    heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.single;
                }
            }
            if(typeof heatingType.data.cwu !== 'undefined') {
                this.heatingWaterDropDownList = [];
                this.heatingWaterDropDownList = this.toHeatingDeviceObjectList(heatingType.data.cwu.map(item => item.ref));
            }
        }
        const heatingSystem = this.prepareHeatingSystemDropdownList(
            this.heatingSystemDropDownList
            , heatingData.heatingType, e
        );
        if(heatingSystem.length > 0) {
            heatingData.heatingSystem = heatingSystem[0].name;

            if(typeof heatingSystem[0].data.efficiency !== 'undefined'){
                
                heatingData.heatingEfficiency = heatingSystem[0].data.efficiency.heat;
                heatingData.coolingEfficiency = heatingSystem[0].data.efficiency.cool;
                heatingData.heatingSystemEfficiency = heatingSystem[0].data.efficiency.system;
                heatingData.waterHeatingEfficiency = typeof heatingSystem[0].data.efficiency.cwu !== "undefined"
                    ? heatingSystem[0].data.efficiency.cool : heatingData.heatingEfficiency
            }
        }
        if(this.heatingWaterDropDownList[0].data.name === 'hm.dropdown.heating.source.the_same'){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            
            heatingData.waterHeatingFuel = heatingData.heatingFuel;
            heatingData.waterPricePerKWh = heatingData.pricePerKWh;
            heatingData.warmWaterFinalToPrimaryCoefficient = heatingData.finalToPrimaryCoefficient;
            heatingData.waterEmissionCoefficient = heatingData.emissionCoefficient;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.heat_pump.cwu"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 300;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.electricity.water"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.227;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.boiler_gas.non_condensed"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 75;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.202;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        }
        heatingData.waterSavings = this.props.heating.waterSavings;
        return heatingData;
    };

    heatingSourceChanged = (e) => {

        let heatingData = this.getDefaultDataTreeFromSource(e);
        this.updateHeatingSourceAndConversion(heatingData);
        this.updateWarmWaterStorage();
    };

    updateHeatingSourceAndConversion = heatingData => {
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
        
        let conversionData = {};

        conversionData.houseHeatingEfficiency = heatingData.heatingEfficiency * heatingData.heatingSystemEfficiency;
        conversionData.warmWaterHeatingEfficiency = heatingData.waterHeatingEfficiency;
        conversionData.heatingSource = heatingData.heatingSource;
        conversionData.waterHeatingSource = heatingData.waterHeatingSource;

        const fuelData = this.heatingFuelDropDownList
            .filter(item => item.name === heatingData.heatingFuel)
            .map(item => item.data.type.heatingType)[0];
        conversionData.isElectric = fuelData.heatingType === 'electric';
        conversionData.naturalUnitPrice =  conversionData.isElectric ? this.props.conversion.electricityPricePerKWh
            : heatingData.naturalUnitPrice / this.configItem.currencyExchangeRate;
        conversionData.naturalUnitPriceLocal =  conversionData.isElectric ? this.props.conversion.electricityPricePerKWhLocal
            : heatingData.naturalUnitPrice;
        conversionData.emissionCoefficient = heatingData.emissionCoefficient;
        conversionData.finalToPrimaryCoefficient = heatingData.finalToPrimaryCoefficient;
        conversionData.waterEmissionCoefficient = heatingData.waterEmissionCoefficient;
        conversionData.naturalUnitPriceChanged =  false;
        if(costUnit && costInEuro){
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.isElectric ? this.props.conversion.electricityPricePerKWh
                    : conversionData.naturalUnitPrice, heatingData.calorificValue, heatingData.naturalUnit,
                heatingData.calorificValueUnit));
        } else {
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.isElectric ? this.props.conversion.electricityPricePerKWhLocal
                    : conversionData.naturalUnitPriceLocal, heatingData.calorificValue, heatingData.naturalUnit,
                heatingData.calorificValueUnit));
        }

        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === heatingData.waterHeatingFuel)
            .map(item => item.data)[0];
        conversionData.isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        conversionData.warmWaterFinalToPrimaryCoefficient = heatingData.warmWaterFinalToPrimaryCoefficient;
        
        if(costUnit && costInEuro){
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWh
                    : waterFuelData.naturalUnitPrice/this.configItem.currencyExchangeRate,
                waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit, waterFuelData.type.calorificValueUnit));
        } else {
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWhLocal
                    : waterFuelData.naturalUnitPrice, waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit, waterFuelData.type.calorificValueUnit));
        }

        this.props.dispatch(updateConversion({
            conversionData: conversionData,
        })).then(() => {
            
            
            
            this.props.writeCache();
            
        });
        this.props.dispatch(updateHeatingAll({
            heatingData: {
                heatingSource: heatingData.heatingSource,
                heatingType: heatingData.heatingType,
                heatingSystem: heatingData.heatingSystem,
                heatingEfficiency: heatingData.heatingEfficiency,
                coolingEfficiency: heatingData.coolingEfficiency,
                heatingSystemEfficiency: heatingData.heatingSystemEfficiency,
                subdevicesEnergyConsumption: heatingData.subdevicesEnergyConsumption,
                heatingFuel: heatingData.heatingFuel,
                pricePerKWh: heatingData.pricePerKWh,
                naturalUnitPrice: heatingData.naturalUnitPrice,
                naturalUnit: heatingData.naturalUnit,
                calorificValue: heatingData.calorificValue,
                calorificValueUnit: heatingData.calorificValueUnit,
                finalToPrimaryCoefficient: heatingData.finalToPrimaryCoefficient,
                emissionCoefficient: heatingData.emissionCoefficient,
                heatingValueEditable: heatingData.heatingValueEditable,
                showNaturalUnit: heatingData.showNaturalUnit,
                waterHeatingSource: heatingData.waterHeatingSource,
                waterHeatingFuel: heatingData.waterHeatingFuel,
                waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
                waterPricePerKWh: heatingData.waterPricePerKWh,
                warmWaterFinalToPrimaryCoefficient: heatingData.warmWaterFinalToPrimaryCoefficient,
                waterEmissionCoefficient: heatingData.waterEmissionCoefficient,
                waterSubdevicesEnergyConsumption: heatingData.waterSubdevicesEnergyConsumption,
                waterSavings: heatingData.waterSavings,
            }
        })).then(() => {

            
            
            
            this.resetHeatingSourceCosts();
            
        });
    };

    updateWarmWaterStorage = () => {
        if(SolarCollectorType.getSolarCollectorTypeById(this.props.heating.collectorsTypeId).type
            === SolarCollectorType.NONE_TYPE) {
            for (let i = 0; i < this.warmWaterStorageList.length; i++) {
                const item = this.warmWaterStorageList[i];
                const usage = item.data.usage;
                if (typeof usage !== "undefined") {
                    const cwu = usage.filter(item => item.cwu === this.props.heating.waterHeatingSource)[0];
                    if (typeof cwu !== "undefined") {
                        if (typeof cwu.blocked !== "undefined") {
                            this.warmWaterStorageChanged(item.data.id)
                            break;
                        } else {
                            for (let i = 0; i < cwu.sources.length; i++) {
                                const sources = cwu.sources[i];
                                if (typeof sources.source !== "undefined") {
                                    if (sources.source === this.props.heating.heatingSource) {
                                        this.warmWaterStorageChanged(item.data.id)
                                        break;
                                    }
                                } else if (typeof sources.type !== "undefined") {
                                    if (sources.type === this.props.heating.heatingType) {
                                        this.warmWaterStorageChanged(item.data.id)
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    heatingSourceTypeChanged = (e, ifUpdateStorage) => {
      let heatingData = new HeatingSourceData();
      const heatingSource = this.heatingDropDownList.filter(item => item.name === this.props.heating.heatingSource);
      if(typeof heatingSource[0].data.fuel !== 'undefined'){
        
        
        heatingData.heatingFuel = heatingSource[0].data.fuel[0].ref.name;
        const fuelData = this.heatingFuelDropDownList
          .filter(item => item.name === heatingData.heatingFuel)
          .map(item => item.data)[0];
        heatingData.pricePerKWh = fuelData.pricePerKWh;
        heatingData.heatingSource = heatingSource[0].data.id;
        heatingData.naturalUnitPrice = fuelData.naturalUnitPrice;
        heatingData.naturalUnit = fuelData.type.naturalUnit.code;
        heatingData.calorificValue = fuelData.heatingValueMJ;
        heatingData.calorificValueUnit = fuelData.type.calorificValueUnit;
        heatingData.finalToPrimaryCoefficient = fuelData.type.finalToPrimaryCoefficient;
        heatingData.emissionCoefficient = fuelData.type.emissionPerKWh;
        heatingData.heatingValueEditable = fuelData.type.heatingValueEditable;
        heatingData.showNaturalUnit = fuelData.type.showNaturalUnit;
      }
        const houseType = this.props.house.houseType;
        const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);
        const isSingle = HouseTypeHelper.isDetachedType(houseType) || HouseTypeHelper.isTerraceType(houseType);
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
       
        let subdevicesEnergyConsumption = 0;
        let waterSubdevicesEnergyConsumption = 0;
        this.heatingWaterDropDownList = [];
        const heatingType = this.heatingTypeDropDownList.filter(item => item.name === e);
        if(typeof heatingType[0].data.efficiency !== 'undefined'){
            heatingData.heatingEfficiency = heatingType[0].data.efficiency.heat;
            heatingData.coolingEfficiency = heatingType[0].data.efficiency.cool;
            heatingData.heatingSystemEfficiency = heatingType[0].data.efficiency.system;
            heatingData.subdevicesEnergyConsumption = heatingType[0].data.subdevices_energy_consumption;
        }
        if(typeof heatingType[0].data.subdevices_energy_consumption !== 'undefined'){
            subdevicesEnergyConsumption = heatingType[0].data.subdevices_energy_consumption;
            if(isMulti && typeof subdevicesEnergyConsumption.multi !== "undefined") {
                heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.multi;
            } else if(isSingle && typeof subdevicesEnergyConsumption.single !== "undefined"){
                heatingData.subdevicesEnergyConsumption = subdevicesEnergyConsumption.single;
            }
        }
        

        if(typeof heatingType[0].data.cwu !== 'undefined') {
            this.heatingWaterDropDownList = this.toHeatingDeviceObjectList(heatingType[0].data.cwu.map(item => item.ref));
        }


        const heatingSystem = this.prepareHeatingSystemDropdownList(
            this.heatingSystemDropDownList
            , e, this.props.heating.heatingSource
        );
        if(heatingSystem.length > 0) {
            heatingData.heatingSystem = heatingSystem[0].name;
            if(typeof heatingSystem[0].data.efficiency !== 'undefined'){
                heatingData.heatingEfficiency = heatingSystem[0].data.efficiency.heat;
                heatingData.coolingEfficiency = heatingSystem[0].data.efficiency.cool;
                heatingData.heatingSystemEfficiency = heatingSystem[0].data.efficiency.system;
                heatingData.waterHeatingEfficiency = typeof heatingSystem[0].data.efficiency.cwu !== "undefined"
                    ? heatingSystem[0].data.efficiency.cwu : heatingData.heatingEfficiency
            }
        } else {
            heatingData.waterHeatingEfficiency = heatingData.heatingEfficiency;
        }

        if(this.heatingWaterDropDownList[0].data.name === 'hm.dropdown.heating.source.the_same'){

            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingFuel = this.props.heating.heatingFuel;
            heatingData.waterPricePerKWh = this.props.heating.pricePerKWh;
            heatingData.warmWaterFinalToPrimaryCoefficient = this.props.heating.finalToPrimaryCoefficient;
            heatingData.waterEmissionCoefficient = this.props.heating.emissionCoefficient;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.electricity.water"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.heat_pump.cwu"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 300;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        } else if (this.heatingWaterDropDownList[0].data.name === "hm.dropdown.heating.source.boiler_gas.non_condensed"){
            heatingData.waterHeatingSource = this.heatingWaterDropDownList[0].data.name;
            heatingData.waterHeatingEfficiency = 75;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.202;
            if(typeof this.heatingWaterDropDownList[0].data.subdevices_energy_consumption !== 'undefined'){
                waterSubdevicesEnergyConsumption = this.heatingWaterDropDownList[0].data.subdevices_energy_consumption;
                if(isMulti && typeof waterSubdevicesEnergyConsumption.multi !== "undefined") {
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.multi;
                } else if(isSingle && typeof waterSubdevicesEnergyConsumption.single !== "undefined"){
                    heatingData.waterSubdevicesEnergyConsumption = waterSubdevicesEnergyConsumption.single;
                }
            }
        }

        let conversionData = {};
        conversionData.houseHeatingEfficiency = heatingData.heatingEfficiency;
        conversionData.heatingSystemEfficiency = heatingData.heatingSystemEfficiency;
        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === heatingData.waterHeatingFuel)
            .map(item => item.data)[0];
        
        
        
        conversionData.isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        conversionData.warmWaterFinalToPrimaryCoefficient = heatingData.warmWaterFinalToPrimaryCoefficient;
        if(costUnit && costInEuro){
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                waterFuelData.naturalUnitPrice/this.configItem.currencyExchangeRate,
                waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        } else {
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                waterFuelData.naturalUnitPrice, waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        }

        this.props.dispatch(updateConversion({
            conversionData: conversionData,
        }));
        this.props.dispatch(updateHeatingAll({
            heatingData: {
                heatingType: e,
                heatingSystem: heatingData.heatingSystem,
                heatingEfficiency: heatingData.heatingEfficiency,
                coolingEfficiency: heatingData.coolingEfficiency,
                heatingSystemEfficiency: heatingData.heatingSystemEfficiency,
                subdevicesEnergyConsumption: heatingData.subdevicesEnergyConsumption,
                waterHeatingSource: heatingData.waterHeatingSource,
                waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
                waterPricePerKWh: heatingData.waterPricePerKWh,
                waterHeatingFuel: heatingData.waterHeatingFuel,
                warmWaterFinalToPrimaryCoefficient: heatingData.warmWaterFinalToPrimaryCoefficient,
                waterEmissionCoefficient: heatingData.waterEmissionCoefficient,
                waterSubdevicesEnergyConsumption: heatingData.waterSubdevicesEnergyConsumption

            }
        })).then(() => {
            if(ifUpdateStorage) {
                this.updateWarmWaterStorage();
            }
            
            
            
            this.resetHeatingSourceCosts();
            
        });


    };


    heatingSourceSystemChanged = (e) => {
        let heatingData = new HeatingSourceData();
        const heatingSystemDropDownList = this.prepareHeatingSystemDropdownList(
            this.heatingSystemDropDownList
            , this.props.heating.heatingType, this.props.heating.heatingSource
        );
        const heatingSystem = heatingSystemDropDownList.filter(item => item.name === e);
        if(typeof heatingSystem[0].data.efficiency !== 'undefined'){
            heatingData.heatingEfficiency = heatingSystem[0].data.efficiency.heat;
            heatingData.coolingEfficiency = heatingSystem[0].data.efficiency.cool;
            heatingData.heatingSystemEfficiency = heatingSystem[0].data.efficiency.system;
            heatingData.waterHeatingEfficiency = typeof heatingSystem[0].data.efficiency.cwu !== "undefined"
                ? heatingSystem[0].data.efficiency.cwu : heatingData.heatingEfficiency
        }

        let conversionData = {};
        if(this.props.heating.waterHeatingSource === 'hm.dropdown.heating.source.the_same'){
            
            
            
            
            
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.electricity.water"){
            
            heatingData.waterHeatingEfficiency = 90;
            
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.heat_pump.cwu"){
            
            heatingData.waterHeatingEfficiency = 300;
            
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"){
            
            heatingData.waterHeatingEfficiency = 90;
            
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.boiler_gas.non_condensed"){
            
            heatingData.waterHeatingEfficiency = 75;
            

        }
        conversionData.houseHeatingEfficiency = heatingData.heatingEfficiency;
        conversionData.heatingSystemEfficiency = heatingData.heatingSystemEfficiency;
        this.props.dispatch(updateConversion({
            conversionData: conversionData,
        }));
        this.props.dispatch(updateHeatingAll({
            heatingData: {
                heatingSystem: e,
                heatingEfficiency: heatingData.heatingEfficiency,
                coolingEfficiency: heatingData.coolingEfficiency,
                heatingSystemEfficiency: heatingData.heatingSystemEfficiency,
                subdevicesEnergyConsumption: heatingData.subdevicesEnergyConsumption,
                waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
            }
        })).then(() => {
            
            
            
                this.props.writeCache();
            
        });
    };

    acChanged = (id, e) => {
        this.props.dispatch(updateAirconditioningById(
            {
                id: id,
                value: e,
            }
        )).then(() => {
            
            
            
            this.props.writeCache();
        });
    };

    heatingFuelChanged = (e) => {
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);

        let heatingData = new HeatingSourceData();
        let conversionData = {};
        const fuelData = this.heatingFuelDropDownList
            .filter(item => item.name === e)
            .map(item => item.data)[0];

        
        
        
        
        
        heatingData.heatingFuel = e;
        heatingData.pricePerKWh = fuelData.pricePerKWh;
        heatingData.naturalUnit = fuelData.type.naturalUnit.code;
        heatingData.naturalUnitPrice = fuelData.naturalUnitPrice;
        heatingData.calorificValue = fuelData.heatingValueMJ;
        heatingData.calorificValueUnit = fuelData.type.calorificValueUnit;
        heatingData.finalToPrimaryCoefficient = fuelData.type.finalToPrimaryCoefficient;
        heatingData.emissionCoefficient = fuelData.type.emissionPerKWh;
        heatingData.heatingValueEditable = fuelData.type.heatingValueEditable;
        heatingData.showNaturalUnit = fuelData.type.showNaturalUnit;
        conversionData.emissionCoefficient =  heatingData.emissionCoefficient;
        conversionData.finalToPrimaryCoefficient = heatingData.finalToPrimaryCoefficient;
        conversionData.isElectric = fuelData.type.heatingType.heatingType === 'electric';
        conversionData.naturalUnitPriceLocal =  heatingData.naturalUnitPrice;
        conversionData.naturalUnitPrice =  heatingData.naturalUnitPrice / this.configItem.currencyExchangeRate;
        conversionData.naturalUnitPriceChanged =  false;
        if(costUnit && costInEuro){
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.naturalUnitPrice, heatingData.calorificValue, heatingData.naturalUnit,
                heatingData.calorificValueUnit));
        } else {
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                conversionData.naturalUnitPriceLocal, heatingData.calorificValue, heatingData.naturalUnit,
                heatingData.calorificValueUnit));
        }

        if(this.props.heating.waterHeatingSource === 'hm.dropdown.heating.source.the_same'){
            
            heatingData.warmWaterFinalToPrimaryCoefficient = heatingData.finalToPrimaryCoefficient;
            heatingData.waterEmissionCoefficient = heatingData.emissionCoefficient;
            
            heatingData.waterHeatingFuel = heatingData.heatingFuel;
            heatingData.waterPricePerKWh = heatingData.pricePerKWh;
            
            
            
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.electricity.water"){
            heatingData.waterPricePerKWh = 0.6;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.heat_pump.cwu"){
            heatingData.waterPricePerKWh = 0.6;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"){
            heatingData.waterPricePerKWh = 0.2;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
        } else if (this.props.heating.waterHeatingSource === "hm.dropdown.heating.source.boiler_gas.non_condensed"){
            heatingData.waterPricePerKWh = 0.2;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.202;
        }
        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === heatingData.waterHeatingFuel)
            .map(item => item.data)[0];
        conversionData.isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        conversionData.warmWaterFinalToPrimaryCoefficient = heatingData.warmWaterFinalToPrimaryCoefficient;
        if(costUnit && costInEuro){
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                waterFuelData.naturalUnitPrice/this.configItem.currencyExchangeRate,
                waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        } else {
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                waterFuelData.naturalUnitPrice, waterFuelData.heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        }

        this.props.dispatch(updateHeatingAll({
            heatingData: {
                heatingFuel: heatingData.heatingFuel,
                pricePerKWh: heatingData.pricePerKWh,
                naturalUnit: heatingData.naturalUnit,
                naturalUnitPrice: heatingData.naturalUnitPrice,
                calorificValue: heatingData.calorificValue,
                calorificValueUnit: heatingData.calorificValueUnit,
                finalToPrimaryCoefficient: heatingData.finalToPrimaryCoefficient,
                emissionCoefficient: heatingData.emissionCoefficient,
                heatingValueEditable: heatingData.heatingValueEditable,
                showNaturalUnit: heatingData.showNaturalUnit,
                waterPricePerKWh: heatingData.waterPricePerKWh,
                waterHeatingFuel: heatingData.waterHeatingFuel,
                warmWaterFinalToPrimaryCoefficient: heatingData.warmWaterFinalToPrimaryCoefficient,
                waterEmissionCoefficient: heatingData.waterEmissionCoefficient,

            }
        })).then(() => {
            this.props.dispatch(updateConversion({
                conversionData: conversionData,
            })).then(() => {
                this.props.writeCache();
            });
        });
    };

    warmWaterHeatingSourceChanged = (e) => {
        let heatingData = new HeatingSourceData();
        const heatingType = this.heatingTypeDropDownList.filter(item => item.name === this.props.heating.heatingType);
        if(typeof heatingType[0].data.cwu !== 'undefined') {
            this.heatingWaterDropDownList = this.toHeatingDeviceObjectList(heatingType[0].data.cwu.map(item => item.ref));
        }
        

        heatingData.waterHeatingSource = e;
        let conversionData = {};
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
        let ratio = 1;
        if(costUnit && costInEuro){
            ratio = 1 / this.configItem.currencyExchangeRate;
        }
        if(e === 'hm.dropdown.heating.source.the_same'){
            heatingData.waterHeatingEfficiency = this.props.heating.heatingEfficiency;
            heatingData.waterPricePerKWh = this.props.conversion.naturalUnitPriceLocal;
            heatingData.waterHeatingFuel = this.props.heating.heatingFuel;
            heatingData.warmWaterFinalToPrimaryCoefficient = this.props.heating.finalToPrimaryCoefficient;
            heatingData.waterEmissionCoefficient = this.props.heating.emissionCoefficient;
            conversionData.waterPricePerKWh = heatingData.waterPricePerKWh * ratio;
        } else if (e === "hm.dropdown.heating.source.electricity.water"){
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
            conversionData.waterPricePerKWh = heatingData.waterPricePerKWh * ratio;
        } else if (e === "hm.dropdown.heating.source.heat_pump.cwu"){
            heatingData.waterHeatingEfficiency = 300;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.electric";
            heatingData.waterPricePerKWh = 0.6;
            heatingData.warmWaterFinalToPrimaryCoefficient = 3;
            heatingData.waterEmissionCoefficient = 0.89;
            conversionData.waterPricePerKWh = heatingData.waterPricePerKWh * ratio;
        } else if (e === "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"){
            heatingData.waterHeatingEfficiency = 90;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.227;
            conversionData.waterPricePerKWh = heatingData.waterPricePerKWh * ratio;
        } else if (e === "hm.dropdown.heating.source.boiler_gas.non_condensed"){
            heatingData.waterHeatingEfficiency = 75;
            heatingData.waterHeatingFuel = "hm.dropdown.heating.fuel.propan";
            heatingData.waterPricePerKWh = 0.2;
            heatingData.warmWaterFinalToPrimaryCoefficient = 1.1;
            heatingData.waterEmissionCoefficient = 0.202;
            conversionData.waterPricePerKWh = heatingData.waterPricePerKWh * ratio;
        }
        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === heatingData.waterHeatingFuel)
            .map(item => item.data)[0];
        conversionData.isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        conversionData.warmWaterFinalToPrimaryCoefficient = heatingData.warmWaterFinalToPrimaryCoefficient;
        let naturalUnitPrice = waterFuelData.naturalUnitPrice;
        let heatingValueMJ = waterFuelData.heatingValueMJ;
        if(e === 'hm.dropdown.heating.source.the_same'){
            naturalUnitPrice = this.props.conversion.naturalUnitPriceLocal;
            heatingValueMJ = this.props.heating.calorificValue;
        }
        if(costUnit && costInEuro){
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                (conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWh
                    : naturalUnitPrice/this.configItem.currencyExchangeRate),
                heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        } else {
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                (conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWhLocal
                    : naturalUnitPrice), heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        }
        this.props.dispatch(updateHeatingAll({
            heatingData: {
                waterHeatingSource: heatingData.waterHeatingSource,
                waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
                waterPricePerKWh: heatingData.waterPricePerKWh,
                waterHeatingFuel: heatingData.waterHeatingFuel,
                warmWaterFinalToPrimaryCoefficient: heatingData.warmWaterFinalToPrimaryCoefficient,
                waterEmissionCoefficient: heatingData.waterEmissionCoefficient,

            }
        })).then(() => {
            this.props.dispatch(updateConversion({
                conversionData: conversionData,
            })).then(() => {
                this.updateWarmWaterStorage();
            });
        });
        this.resetHeatingSourceCosts();
    };

    resetHeatingSourceCosts = () => {
        this.props.dispatch(updateCosts({
            heatingSource_material_changed: false,
            heatingSource_work_changed: false,
            waterHeatingSource_material_changed: false,
            waterHeatingSource_work_changed: false,
            chimney_changed: false,
            storeroom_changed: false
        })).then(() => {
            this.props.writeCache();

        });
    }
    
    getOrder = () => 100;
    
    houseTypeChanged = () => {
        let houseType = this.props.house.houseType;
        let upFloor = HouseTypeHelper.hasNeighboursUp(houseType);
        let isGroundType = HouseTypeHelper.isGroundType(houseType);
        let regularRoof = HouseTypeHelper.hasRegularRoof(houseType);
        if (!regularRoof) {
            this.collectorsTypeChanged('hm.solar-collector.none');
        }
        
        this.setInsulationVisibility(isGroundType, upFloor);

        if (houseType === HouseType.DETACHED) {
            this.setState({
                heatingTypeDropDownList: this.detachedHeatingSourceList,
                warmWaterHeatingTypeDropDownList: this.warmWaterDetachedHeatingSourceList
            });

            this.warmWaterDistributionDropDownList = this.detachedWarmWaterDistributionList;
        }
        else if (this.previousHouseType === HouseType.DETACHED) {

            this.setState({
                heatingTypeDropDownList: this.multiFamilyHeatingSourceList,
                warmWaterHeatingTypeDropDownList: this.warmWaterMultiFamilyHeatingSourceList
            });

            this.warmWaterDistributionDropDownList = this.multiFamilyWarmWaterDistributionList;
        }

        if(HouseTypeHelper.isMultiFamilyType(houseType) || HouseTypeHelper.isBlockType(houseType)){
            this.doorTypeChanged('hm.door.none');
        }
        this.previousHouseType = houseType;

        this.surfaceData = this.createPrimarySurfaceData();
        this.surfaceData = this.surfaceCalculator.calcSurface(this.surfaceData, houseType, this.props.house.roofData);

        this.applySecondarySurfaceDataToControls(this.surfaceData);

        this.props.writeCache();
        return this.createWarmWaterData(this.props.heating.waterHeatingTypeId,
            this.props.heating.warmWaterDistributionId, this.props.heating.warmWaterStorageId);


    };

    createWarmWaterData = (waterHeatingTypeId, warmWaterDistributionId, warmWaterStorageId) => {
        let warmWaterDistribution = WarmWaterDistribution.getWarmWaterDistributionById(warmWaterDistributionId);
        let warmWaterStorage = WarmWaterStorage.getWarmWaterStorageById(warmWaterStorageId);
        let warmWaterData = new WarmWaterData();
        warmWaterData.heatingSourceType = waterHeatingTypeId;
        warmWaterData.distributorEfficiency = warmWaterDistribution.efficiency;
        warmWaterData.storeEfficiency = warmWaterStorage.efficiency;
        warmWaterData.warmWaterDistribution = warmWaterDistribution.id;
        warmWaterData.warmWaterStorage = warmWaterStorage.id;
        return warmWaterData;
    };


    insulationElementChanged = (type) => {
        if (type === InsulationElementType.FLOOR) {
            let isGroundType = HouseTypeHelper.isGroundType(this.props.house.houseType);
            let upFloor = HouseTypeHelper.hasNeighboursUp(this.props.house.houseType);
            this.setInsulationVisibility(isGroundType, upFloor);
        }
    };



    setInsulationVisibility = (isGroundType, upFloor) => {
       let foundationsInsulated = FoundationsType.isFoundationsInsulated(this.props.insulation.floor.foundations);
        let newWidth = 0.75 * this.props.insulation.floor.width;
        
        
        
        if (!isGroundType && upFloor) {

        }
        else if (!isGroundType) {

        }
        else if (upFloor) {

            if (!foundationsInsulated) {
                this.setState({
                    wallDownLeftInsulationImgHeight: this.originalInsulationLeftDownHeight + newWidth,
                    wallRightInsulationImgHeight: this.originalInsulationRightHeight + 41 + this.INSULATION_TOP + newWidth,
                });
            }
        }
        else {

            if (!foundationsInsulated) {
                this.setState({
                    wallDownLeftInsulationImgHeight: this.originalInsulationLeftDownHeight + newWidth,
                    wallRightInsulationImgHeight: this.originalInsulationRightHeight + newWidth,
                });
            }
        }
    };


    applyConfigPrices = (heatingTypes, configItem, localCurrency) => {
        if (configItem.ignoreConfigPrices) {
            let ignoreConfigRate = localCurrency ? 1.0 : 1 / configItem.currencyExchangeRate;
            ignoreConfigRate = 1;
            
            for (let i = 0; i < heatingTypes.length; i++) {
                let heatingSourceTypeItem = heatingTypes[i].data;
                heatingSourceTypeItem.resetOriginalPrice(ignoreConfigRate);
                heatingTypes[i].data = heatingSourceTypeItem;
            }
        }
        else {
            let rate = localCurrency ? configItem.currencyExchangeRate : 1.0;
            rate = 1;
            for (let i = 0; i < heatingTypes.length; i++) {
                let heatingSourceTypeItem = heatingTypes[i].data;

                if (HeatingSourceType.isOfCoalType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.coalPrice * rate;
                }
                else if (HeatingSourceType.isOfGasType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.gasPrice * rate;
                }
                else if (HeatingSourceType.isOfOilType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.heatingOilPrice * rate;
                }
                else if (HeatingSourceType.isOfPelletsType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.pelletsPrice * rate;
                }
                else if (HeatingSourceType.isOfWoodType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.firewoodPrice * rate;
                }
                else if (HeatingSourceType.isOfElectricType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.electricityPrice * rate;
                }
                else if (HeatingSourceType.isOfCombinedType(heatingSourceTypeItem.type)) {
                    heatingSourceTypeItem.pricePerKWh = configItem.combinedPrice * rate;
                }
                else {
                    
                }
                heatingSourceTypeItem.type.pricePerKWh = heatingSourceTypeItem.pricePerKWh;
                heatingSourceTypeItem.naturalUnitPrice = HeatingSourceType.toNaturalPrice(heatingSourceTypeItem.pricePerKWh, heatingSourceTypeItem.type.heatingValueMJ, heatingSourceTypeItem.type.naturalUnit, heatingSourceTypeItem.type.calorificValueUnit);
                heatingSourceTypeItem.type.naturalUnitPrice = HeatingSourceType.toNaturalPrice(heatingSourceTypeItem.pricePerKWh, heatingSourceTypeItem.type.heatingValueMJ, heatingSourceTypeItem.type.naturalUnit, heatingSourceTypeItem.type.calorificValueUnit);
                heatingTypes[i].data = heatingSourceTypeItem;
            }
        }
        return heatingTypes;
    };

    createHeatingData = () => {
        let heatingSourceTypeItem = HeatingSourceTypeItem.getHeatingSourceTypeItemById(this.props.heating.heatingTypeId);
        let warmWaterHeatingSourceTypeItem = HeatingSourceTypeItem.getHeatingSourceTypeItemById(this.props.heating.waterHeatingTypeId);
        let heatingData = new HeatingSourceData();
        heatingData.heatingTypeId = heatingSourceTypeItem.type;
        heatingData.heatingEfficiency =  heatingSourceTypeItem.type.efficiency;
        heatingData.pricePerKWh = heatingSourceTypeItem.pricePerKWh;
        heatingData.naturalUnitPrice = heatingSourceTypeItem.naturalUnitPrice;
        heatingData.naturalUnit = heatingSourceTypeItem.type.naturalUnit;
        heatingData.calorificValue = heatingSourceTypeItem.heatingValueMJ;
        heatingData.calorificValueUnit = heatingSourceTypeItem.calorificValueUnit;
        heatingData.finalToPrimaryCoefficient = heatingSourceTypeItem.type.finalToPrimaryCoefficient;
        heatingData.emissionCoefficient = heatingSourceTypeItem.type.emissionPerKWh;

        heatingData.waterHeatingType = warmWaterHeatingSourceTypeItem.type;
        heatingData.waterHeatingEfficiency = warmWaterHeatingSourceTypeItem.type.efficiency;
        heatingData.waterPricePerKWh = warmWaterHeatingSourceTypeItem.pricePerKWh;
        heatingData.warmWaterFinalToPrimaryCoefficient = warmWaterHeatingSourceTypeItem.type.finalToPrimaryCoefficient;
        heatingData.waterEmissionCoefficient = warmWaterHeatingSourceTypeItem.type.emissionPerKWh;

        return heatingData;
    };

    detachedHeatingSourceListUpdate = (detachedHeatingSourceTypes) => {
        this.detachedHeatingSourceList = this.toHeatingSourceObjectList(detachedHeatingSourceTypes);

    };


    configChanged = (localCurrency, preserveLocallyChangedValues = false, writeCacheRequired = true) => {

        this.configItem = CountryItemHelper.getCountryByCode(this.countryList, this.props.calculationStateController.getCurrentDataContext().countryCode);

        this.applyConfigPrices(this.heatingFuelDropDownList, this.configItem, localCurrency);
        this.setState({
            heatingTypeDropDownList: this.previousHouseType === HouseType.DETACHED ? this.detachedHeatingSourceList : this.multiFamilyHeatingSourceList,
            warmWaterHeatingTypeDropDownList: this.previousHouseType === HouseType.DETACHED ? this.warmWaterDetachedHeatingSourceList : this.warmWaterMultiFamilyHeatingSourceList,
        }, () => {
            const localCurrency = ConversionUnits.isLocalCurrencyCostUnit(this.props.conversion.selectedUnit);
            const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
            const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
            let heatingSourceTypeItem = this.getComboItemIndexDataById(this.props.heating.heatingFuel, this.heatingFuelDropDownList);
            let isRegion = this.props.calculationStateController.getCurrentDataContext().selectedCountryRegion !== false;
            this.updateCountryVisibility(isRegion, !isRegion);

            let regionCode = null;
            let showRegions = false;
            if (this.configItem.regions.length > 0) {
                showRegions = true;
                this.regionDropDownList = this.configItem.regions;
                regionCode = this.configItem.regions[0].code;
            }

            let stationsVisibility = this.configItem.stationsConfig != null;
            this.updateCountryVisibility(showRegions, stationsVisibility);

            let inArray = false;
            this.regionDropDownList.forEach(value => {
                    if(value.code === this.props.calculationStateController.getCurrentDataContext().selectedCountryRegion){
                        inArray = true;
                    }
                }
            );
            if (!inArray) {
                this.props.dispatch(updateHouseById({
                    id: 'region',
                    value: regionCode,
                })).then(() => {

                });
            }
            let conversionData = {};
            if(ConversionUnits.isLocalCurrencyCostUnit(this.props.conversion.selectedUnit)){
                conversionData.currencyLocaleCode =  this.configItem.currencyLocaleCode;
                conversionData.localCurrency = true;
                conversionData.unitName =  this.configItem.currencyLocaleCode + (ConversionUnits.isYearUnit(this.props.conversion.selectedUnit) ? '.year_cost' : '.month_cost');
            }
            else {
                conversionData.currencyLocaleCode = HeatMaster.CURRENCY_EURO_LOCALE_CODE;
                conversionData.localCurrency = false;
                conversionData.unitName = this.props.unitList.filter(item => item.data === this.props.conversion.selectedUnit)[0].shortUnit;
            }
            this.setState({
                localCurrency: localCurrency,
                unitName: conversionData.unitName,
            });


            conversionData.currencyName = costUnit && costInEuro ? this.CURRENCY_EURO : this.configItem.currencyName ;
            conversionData.toPLNCurrencyExchangeRate = ConverterHelper.calcToPLNExchangeRate(this.props.conversion.selectedUnit , this.props.businessConfig.polishExchangeRate, this.configItem.currencyExchangeRate);
            this.props.dispatch(updateConversion({
                conversionData: conversionData,
            }));
            
            if(!preserveLocallyChangedValues) {
                this.naturalUnitPriceChanged(heatingSourceTypeItem.naturalUnitPrice);
            } else {
                this.props.writeCache();
            }
            if(writeCacheRequired) {
                
            }
        });

    };

    updateRegionDropdownList = (regions) => {
        this.setState({
            regionDropDownList: regions
        });
    };


    applyWarmWaterPriceValuesToControls = (pricePerKWh) => {
        this.warmWaterPriceChanged(pricePerKWh);
    };

    priceChanged = pricePerKWh => {
        pricePerKWh = parseFloat(pricePerKWh);
        this.props.dispatch(updateHeatingById({
            id: "pricePerKWh",
            value: pricePerKWh,
        }))
    };

    electricityPriceChanged = e => {
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
        let conversionData = {};
        if(costUnit && costInEuro){
            conversionData.electricityPricePerKWh = GlobalValues.parseFloatValue(e);
            conversionData.electricityPricePerKWhLocal = GlobalValues.parseFloatValue(e * this.configItem.currencyExchangeRate);
        } else {
            conversionData.electricityPricePerKWh = GlobalValues.parseFloatValue(e / this.configItem.currencyExchangeRate);
            conversionData.electricityPricePerKWhLocal = GlobalValues.parseFloatValue(e);
        }

        if(this.props.conversion.isElectric){
            conversionData.naturalUnitPrice = conversionData.electricityPricePerKWh;
            conversionData.naturalUnitPriceLocal = conversionData.electricityPricePerKWhLocal;
            conversionData.pricePerKWh = costUnit && costInEuro
                ? conversionData.electricityPricePerKWh : conversionData.electricityPricePerKWhLocal;
        }
        if(this.props.conversion.isWaterElectric){
            conversionData.waterPricePerKWh = costUnit && costInEuro
                ? conversionData.electricityPricePerKWh : conversionData.electricityPricePerKWhLocal;
        }

        this.props.dispatch(updateConversion({
            conversionData: conversionData
        })).then(() => {
            this.props.writeCache();
        });
    };



    naturalUnitPriceChanged = (e) => {
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
        
        let conversionData = {};
        
        conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(e, this.props.heating.calorificValue,
            this.props.heating.naturalUnit, this.props.heating.calorificValueUnit));
        if(costUnit && costInEuro){
            conversionData.naturalUnitPrice = GlobalValues.parseFloatValue(e);
            conversionData.naturalUnitPriceLocal = GlobalValues.parseFloatValue(e * this.configItem.currencyExchangeRate);
        } else {
            conversionData.naturalUnitPrice = GlobalValues.parseFloatValue(e / this.configItem.currencyExchangeRate);
            conversionData.naturalUnitPriceLocal = GlobalValues.parseFloatValue(e);
        }
        conversionData.naturalUnitPriceChanged = true;
        
        if(this.props.heating.waterHeatingSource === 'hm.dropdown.heating.source.the_same'){
            conversionData.waterPricePerKWh = conversionData.pricePerKWh;
            
            
            
            
            
        }

        if (this.props.conversion.isElectric) {
            if (this.props.conversion.isWaterElectric) {
                conversionData.waterPricePerKWh = conversionData.pricePerKWh;
            }
            conversionData.electricityPricePerKWh = conversionData.naturalUnitPrice;
            conversionData.electricityPricePerKWhLocal = conversionData.naturalUnitPriceLocal;
        }


        this.props.dispatch(updateConversion({
            conversionData: conversionData
        })).then(() => {
            this.props.writeCache();
        });

    };

    warmWaterPriceChanged = (pricePerKWh) => {
        pricePerKWh = parseFloat(pricePerKWh);
        this.props.dispatch(updateHeatingById({
            id: "waterPricePerKWh",
            value: pricePerKWh,
        }));
    };

    createSolarCollectorData = (collectorsTypeId) => {
        let solarCollectorType = SolarCollectorType.getSolarCollectorTypeById(collectorsTypeId);
        let solarSurface = this.props.heating.collectorsSurface;
        let solarCollectorData = new SolarCollectorData();
        solarCollectorData.collectorType = solarCollectorType;
        if (solarCollectorType.type === SolarCollectorType.NONE_TYPE) {
            solarCollectorData.surface = 0;
            solarCollectorData.count = 0;
        }
        else {
            solarCollectorData.surface = solarSurface;
            solarCollectorData.count = solarSurface / solarCollectorType.unitSurface;
        }
        return solarCollectorData;
    };

    collectorsTypeChanged = (e) => {
        let solarCollectorData = this.createSolarCollectorData(e);
        if (solarCollectorData.collectorType.type !== SolarCollectorType.NONE_TYPE) {
            let collectorSurface = this.calcSolarSurfaceByPersonCount(solarCollectorData.collectorType, this.props.persons.persons);
            solarCollectorData.surface = collectorSurface;
            solarCollectorData.count = collectorSurface / solarCollectorData.collectorType.unitSurface;
            this.warmWaterStorageChanged('hm.water-storage.modern_storage');
        }
        else {
            solarCollectorData.surface = 0;
            solarCollectorData.count = 0;
        }
        this.props.dispatch(updateCollectors({
            collectorsData: solarCollectorData,
        })).then(() => {
            this.props.writeCache();
        });

    };

    calcSolarSurfaceByPersonCount = (solarType, personCount) => {
        return personCount / solarType.personPerCollector * solarType.unitSurface;
    };


    comboStandardChanged = (e) => {
        this.props.dispatch(updateHouseById({
            id: "standardTypeId",
            value: e,
        }));
        let countryItem = CountryItemHelper.getCountryByCode(this.countryList, this.props.house.country);
        if(countryItem.standardTypeConfiguration.length > 0) {
            
            this.houseStandardList = this.toComboObjectList(countryItem.standardTypeConfiguration);
            
        }
        this.houseStandardType = this.houseStandardList.filter(item => item.data.id === e)[0].data.houseStandardTypeModel;

        let insulationElement = InsulationElementPreparer.prepare(InsulationElementType.WALL);
        insulationElement.uValue = this.houseStandardType.wallUValue;
        insulationElement.material = this.houseStandardType.wallInsulationMaterialType.id;
        insulationElement.heatTransfer = this.houseStandardType.wallInsulationMaterialType.heatTransferCoefficient;
        insulationElement.u0 = this.props.insulation.wall.u0;
        insulationElement = UValueBasedElementPreparer.prepare(insulationElement);

        let insulationData = {};
        insulationData.wall = {
            material: insulationElement.material,
            width: insulationElement.width,
            uValue: insulationElement.uValue,
            doorType: (HouseTypeHelper.isMultiFamilyType(this.props.house.houseType)
            || HouseTypeHelper.isBlockType(this.props.house.houseType)
             ? 'hm.door.none' : this.houseStandardType.doorType.id),
            heatTransfer: insulationElement.heatTransfer,
            u0: insulationElement.u0,
        };

        insulationElement = InsulationElementPreparer.prepare(InsulationElementType.ROOF);
        insulationElement.uValue = this.houseStandardType.roofUValue;
        insulationElement.material = this.houseStandardType.roofInsulationMaterialType.id;
        insulationElement.heatTransfer = this.houseStandardType.roofInsulationMaterialType.heatTransferCoefficient;
        insulationElement.u0 = this.props.insulation.roof.u0;
        insulationElement = UValueBasedElementPreparer.prepare(insulationElement);

        insulationData.roof = {
            material: insulationElement.material,
            width: insulationElement.width,
            uValue: insulationElement.uValue,
            heatTransfer: insulationElement.heatTransfer,
            u0: insulationElement.u0,
        };

        insulationElement = InsulationElementPreparer.prepare(InsulationElementType.FLOOR);
        insulationElement.uValue = this.houseStandardType.floorUValue;
        insulationElement.material = this.houseStandardType.floorInsulationMaterialType.id;
        insulationElement.heatTransfer = this.houseStandardType.floorInsulationMaterialType.heatTransferCoefficient;
        insulationElement.u0 = this.props.insulation.floor.u0;
        insulationElement = UValueBasedElementPreparer.prepare(insulationElement);

        insulationData.floor = {
            material: insulationElement.material,
            width: insulationElement.width,
            uValue: insulationElement.uValue,
            foundations: this.houseStandardType.foundationsType.id,
            heatTransfer: insulationElement.heatTransfer,
            u0: insulationElement.u0,
        };

        insulationElement = InsulationElementPreparer.prepare(InsulationElementType.BASEMENT_FLOOR);
        insulationElement.uValue = this.houseStandardType.basementFloorUValue;
        insulationElement.material = this.houseStandardType.basementFloorInsulationMaterialType.id;
        insulationElement.heatTransfer = this.houseStandardType.basementFloorInsulationMaterialType.heatTransferCoefficient;
        insulationElement = UValueBasedElementPreparer.prepare(insulationElement);

        insulationData.basement = {};
        insulationData.basement.floor = {
            material: insulationElement.material,
            width: insulationElement.width,
            uValue: insulationElement.uValue,
            heatTransfer: insulationElement.heatTransfer,
        };

        insulationElement = InsulationElementPreparer.prepare(InsulationElementType.BASEMENT_WALL);
        insulationElement.uValue = this.houseStandardType.basementWallUValue;
        insulationElement.material = this.houseStandardType.basementWallInsulationMaterialType.id;
        insulationElement.heatTransfer =
            this.houseStandardType.basementWallInsulationMaterialType.heatTransferCoefficient;
        insulationElement = UValueBasedElementPreparer.prepare(insulationElement);

        insulationData.basement.wall = {
            material: insulationElement.material,
            width: insulationElement.width,
            uValue: insulationElement.uValue,
            heatTransfer: insulationElement.heatTransfer,
        };

        this.props.dispatch(updateInsulation({
            insulationData: insulationData
        })).then(() => {
            this.setInsulationVisibility(HouseTypeHelper.isGroundType(this.props.house.houseType),
                HouseTypeHelper.hasNeighboursUp(this.props.house.houseType));
        });

        const ventilationData = {
            ventilation: this.houseStandardType.ventilationMethod.id,
            airTightness: this.houseStandardType.airTightness.id,
            co2Sensor:  this.houseStandardType.carbonDioxideSensor,
            gwcSet: this.props.ventilation.gwcSet,
            isMechanical: VentilationMethod.getVentilationMethodById(
                this.houseStandardType.ventilationMethod.id).type === VentilationMethod.MECHANICAL,
            recuperator: VentilationMethod.getVentilationMethodById(
                this.houseStandardType.ventilationMethod.id).recuperator,
            n50: AirTightness.getAirTightnessById(this.houseStandardType.airTightness.id).value
        };
        
        this.props.dispatch(updateVentilation(ventilationData));

        this.props.dispatch(updateWindows(
            this.houseStandardType.windowsType.id,
            this.houseStandardType.thermalBridges.id,
            this.houseStandardType.shuttersEnabled,
        ));
        if(HouseTypeHelper.hasRegularRoof(this.props.house.houseType)) {
            this.collectorsTypeChanged(this.houseStandardType.solarCollectorData.collectorType.id);
        }
        const fuelData = this.heatingFuelDropDownList
            .filter(item => item.name === this.houseStandardType.heatingFuel)
            .map(item => item.data)[0];
        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === this.houseStandardType.waterHeatingFuel)
            .map(item => item.data)[0];
        const heatingData = this.getDefaultDataTreeFromSource(this.houseStandardType.heatingSource, this.houseStandardType.heatingSourceType);
        
        this.props.dispatch(updateHeatingAll({
            heatingData: {
                warmWaterDistributionId: this.houseStandardType.warmWaterDistribution.id,
                warmWaterStorageId: this.houseStandardType.warmWaterStorage.id,
                heatingSource: this.houseStandardType.heatingSource,
                subdevicesEnergyConsumption: heatingData.subdevicesEnergyConsumption,
                waterSubdevicesEnergyConsumption: heatingData.waterSubdevicesEnergyConsumption,
                heatingType: this.houseStandardType.heatingSourceType,
                heatingSystem: this.houseStandardType.heatingSystem,
                heatingFuel: this.houseStandardType.heatingFuel,
                heatingEfficiency: this.houseStandardType.heatingEfficiency,
                coolingEfficiency: this.houseStandardType.coolingEfficiency,
                showNaturalUnit: fuelData.type.showNaturalUnit,
                naturalUnit: fuelData.type.naturalUnit.code,
                naturalUnitPrice: fuelData.type.naturalUnitPrice,
                pricePerKWh: fuelData.type.pricePerKWh,
                finalToPrimaryCoefficient: fuelData.type.finalToPrimaryCoefficient,
                emissionCoefficient: fuelData.type.emissionPerKWh,
                calorificValue: fuelData.type.heatingValueMJ,
                calorificValueUnit: fuelData.type.calorificValueUnit,
                heatingValueEditable: fuelData.type.heatingValueEditable,
                waterHeatingSource: this.houseStandardType.waterHeatingSource,
                waterHeatingFuel: this.houseStandardType.waterHeatingFuel,
                waterHeatingEfficiency: this.houseStandardType.waterHeatingEfficiency,
                waterHeatingSourceLocation: this.houseStandardType.waterHeatingSourceLocation,
                distributorEfficiency: this.houseStandardType.warmWaterDistribution.efficiency,
                storeEfficiency: this.houseStandardType.warmWaterStorage.efficiency,
            }
        }));

        
        const isElectric = fuelData.type.heatingType.heatingType === 'electric';
        const isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);

        this.props.dispatch(updateConversion({
            conversionData: {
                naturalUnitPrice: isElectric ? this.props.conversion.electricityPricePerKWh : fuelData.type.naturalUnitPrice / countryItem.currencyExchangeRate,
                naturalUnitPriceLocal: isElectric ? this.props.conversion.electricityPricePerKWhLocal : fuelData.type.naturalUnitPrice,
                pricePerKWh: costUnit && costInEuro
                    ? isElectric ? this.props.conversion.electricityPricePerKWh : fuelData.type.pricePerKWh / countryItem.currencyExchangeRate
                    : isElectric ? this.props.conversion.electricityPricePerKWhLocal : fuelData.type.pricePerKWh,
                waterPricePerKWh: costUnit && costInEuro
                    ? isWaterElectric ? this.props.conversion.electricityPricePerKWh : waterFuelData.type.pricePerKWh / countryItem.currencyExchangeRate
                    : isWaterElectric ? this.props.conversion.electricityPricePerKWhLocal : waterFuelData.type.pricePerKWh,
                isElectric: isElectric,
                isWaterElectric: isWaterElectric
            }
        }));
        this.props.dispatch(updatePhotovoltaic({
            data: {
                pv_type: this.houseStandardType.pvType,
                pv_power: this.houseStandardType.pvPower,
            }
        }));


        this.props.dispatch(updateAirconditioning({
            data: {
                airconditioning: this.houseStandardType.airConditioning,
            }
        }));

        this.props.writeCache();
    };

    applySecondarySurfaceDataToControls = (surfaceData) => {
        surfaceData.windowsSurface = surfaceData.windowsSurfaceN + surfaceData.windowsSurfaceWE + surfaceData.windowsSurfaceS;
        this.props.dispatch(updateSurface(surfaceData)).then(() => {
            this.props.writeDataToBothCache({
                surfaceData: surfaceData
            });
            this.props.writeCache();
        });
        this.surfaceData = surfaceData;
    };


    createHouseModel = () => {
        return new HouseModel(this.getHouseType(),
            this.props.house.surfaceData.floorCount, this.props.house.surfaceData.floorHeight, this.props.house.surfaceData.totalSurface,
            this.props.house.surfaceData.floorSurface, this.props.house.surfaceData.roofSurface, this.props.house.surfaceData.wallSurface,
            this.props.house.surfaceData.insulatedWallSurface,this.props.house.surfaceData.nonInsulatedWallSurface,
            this.props.house.surfaceData.windowsSurfaceS, this.props.house.surfaceData.windowsSurfaceWE,
            this.props.house.surfaceData.windowsSurfaceN, this.props.house.inHouseTemp);
    };

    createPrimarySurfaceData = () => {
        return {...this.props.house.surfaceData};
    };

    getHouseType = () => {
        return this.props.house.houseType;
    };

    roofChanged = (id, e) => {
        this.props.dispatch(updateHouseRoofById({
            id: id,
            value: e
        })).then(() => {
            const houseType = this.props.house.houseType;
            let surfaceData = this.createPrimarySurfaceData();
            surfaceData = this.surfaceCalculator.calcSurface(surfaceData, houseType, this.props.house.roofData);
            
          this.props.dispatch(
            replaceHouseWalls({
              wallsData: surfaceData.wallsData,
            })
          );
            
            
            this.props.dispatch(updateSurface(surfaceData)).then(() => {
                this.props.writeDataToBothCache({
                    surfaceData: surfaceData,
                    roofData: this.props.house.roofData
                });
                this.props.writeCache();
            });
        });
    };

    roofTypeChanged = e => {
        this.props.dispatch(updateHouseRoof({
            roofData: {
                type: e,
                attic: e === RoofType.FLAT ? 0 : this.props.house.roofData.attic
            }
        })).then(() => {

            const houseType = this.props.house.houseType;
            let surfaceData = this.createPrimarySurfaceData();
            surfaceData = this.surfaceCalculator.calcSurface(surfaceData, houseType, this.props.house.roofData);
          this.props.dispatch(
            replaceHouseWalls({
              wallsData: surfaceData.wallsData,
            })
          );
            
            
            this.props.dispatch(updateSurface(surfaceData)).then(() => {
                
                this.props.writeDataToBothCache({
                    surfaceData: surfaceData,
                    roofData: this.props.house.roofData
                });
                this.props.writeCache();
            });

        });
    };



    shuttersChanged = (e) => {
        this.props.dispatch(updateWindows(
            this.props.windows.window,
            this.props.windows.thermalBridge,
            e
        )).then(() => {
            this.props.dispatch(updateInsolation(
                {
                    data: {
                        shading: e ? e : this.props.insolation.shading,
                        blinds: e,
                        blinds_val: this.shadingList['blinds'],
                    }
                }
            )).then(() => {
                this.props.writeCache();
            });
        });
    };

    insolationChanged = (id, e) => {
        this.props.dispatch(updateInsolation(
            {
                data: {
                    [id]: e,
                }
            }
        )).then(() => {
            if(id === "blinds") {
                this.props.dispatch(updateWindows(
                    this.props.windows.window,
                    this.props.windows.thermalBridge,
                    e
                ));
            }
            this.props.dispatch(updateInsolation(
                {
                    data: {
                        blinds_val: this.shadingList['blinds'],
                        curtains_val: this.shadingList['curtains'],
                        awnings_val: this.shadingList['awnings'],
                        buildings_val: this.shadingList['buildings'][this.props.insolation.buildings_period][this.props.insolation.buildings_level],
                        trees_coniferous_val: this.shadingList['trees_coniferous'][this.props.insolation.trees_coniferous_period][this.props.insolation.trees_coniferous_level],
                        trees_leafy_val: this.shadingList['trees_leafy'][this.props.insolation.trees_leafy_period][this.props.insolation.trees_leafy_level],
                        trees_mixed_val: this.shadingList['trees_mixed'][this.props.insolation.trees_mixed_period][this.props.insolation.trees_mixed_level],
                    }
                }
            )).then(() => {
                this.props.writeCache();
            });
        });
    };

    roofSurfaceChanged = (e) => {
        let surfaceData = {...this.props.house.surfaceData};
        surfaceData['roofSurface'] = Math.round(e);
        this.props.dispatch(updateSurface(surfaceData)).then(() => {
            this.props.writeDataToBothCache({
                surfaceData: surfaceData
            });
            this.props.writeCache();
        });

    };

    atticSurfaceChanged = (e) => {
        let surfaceData = {...this.props.house.surfaceData};
        surfaceData['atticWallSurface'] = Math.round(e);
        this.props.dispatch(updateSurface(surfaceData)).then(() => {
            this.props.writeDataToBothCache({
                surfaceData: surfaceData
            });
            this.props.writeCache();
        });

    };

    warmWaterHeatingTypeChanged = (e) => {
        this.props.dispatch(updateHeatingById({
            id: "waterHeatingTypeId",
            value: e,
        })).then(() => {
            let warmWaterHeatingSourceTypeItem = this.getComboItemIndexDataById(e, this.state.warmWaterHeatingTypeDropDownList);
            let heatingData = this.createHeatingData();
            let rate = this.getCurrentExchangeRate();
            heatingData.waterHeatingType = warmWaterHeatingSourceTypeItem;
            heatingData.waterHeatingEfficiency = warmWaterHeatingSourceTypeItem.type.efficiency;
            heatingData.waterPricePerKWh = warmWaterHeatingSourceTypeItem.pricePerKWh * rate;
            heatingData.warmWaterFinalToPrimaryCoefficient = warmWaterHeatingSourceTypeItem.type.finalToPrimaryCoefficient;
            heatingData.waterEmissionCoefficient = warmWaterHeatingSourceTypeItem.type.emissionPerKWh;

            this.props.dispatch(updateWater({
                heatingData: heatingData,
            }));
            this.props.writeCache();
        })

    };


    applyWarmWaterValuesToControls = (warmWaterHeatingSourceType, pricePerKWh) => {
        this.warmWaterHeatingEfficiencyChanged(warmWaterHeatingSourceType.efficiency);
        this.warmWaterPriceChanged(pricePerKWh);
    };

    warmWaterDistributionChanged = (e) => {
        let warmWaterData = this.createWarmWaterData(this.props.heating.waterHeatingTypeId,
            e, this.props.heating.warmWaterStorageId);
        this.props.dispatch(updateDistribution({
            heatingData: warmWaterData,
        })).then(() => {
            this.props.writeCache();
         });
    };

    warmWaterStorageChanged = e => {
        let warmWaterData = this.createWarmWaterData(this.props.heating.waterHeatingTypeId,
            this.props.heating.warmWaterDistributionId, e);
        this.props.dispatch(updateDistribution({
            heatingData: warmWaterData,
        })).then(() => {
            this.props.writeCache();
        });
    };


    warmWaterHeatingEfficiencyChanged = (e) => {
        this.props.dispatch(updateHeatingById({
            id: "waterHeatingEfficiency",
            value: e,
        }));
        this.props.writeCache();
    };
    
    getCurrentExchangeRate = () => {
        if (this.state.localCurrency) {
            return this.configItem.ignoreConfigPrices ? 1.0 : this.configItem.currencyExchangeRate;
        }
        else {
            return this.configItem.ignoreConfigPrices ? 1 / this.configItem.currencyExchangeRate : 1.0;
        }
    };


    efficiencyChanged = (id, e) => {
        this.props.dispatch(updateHeatingById({
            id: id,
            value: parseInt(e),
        })).then(() => {
            this.props.writeCache();
        });
    };


    calorificValueChanged = (e) => {

        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);

        let heatingData = {};
        heatingData.calorificValue = GlobalValues.parseFloatValue(e);
        if(this.props.heating.waterHeatingSource === 'hm.dropdown.heating.source.the_same'){
            heatingData.waterPricePerKWh = heatingData.pricePerKWh;
        }
        let conversionData = {};
        if(costUnit && costInEuro){
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                this.props.conversion.naturalUnitPrice, e,
                this.props.heating.naturalUnit, this.props.heating.calorificValueUnit));
        } else {
            conversionData.pricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                this.props.conversion.naturalUnitPriceLocal, e,
                this.props.heating.naturalUnit, this.props.heating.calorificValueUnit));
        }

        heatingData.calorificValue = GlobalValues.parseFloatValue(e);
        if(this.props.heating.waterHeatingSource === 'hm.dropdown.heating.source.the_same'){
            conversionData.waterPricePerKWh = conversionData.pricePerKWh;

        }
        
        this.props.dispatch(updateConversion({
            conversionData: conversionData
        })).then(() => {
            
        });

        this.props.dispatch(updateHeatingAll({
            heatingData: heatingData
        })).then(() => {
            this.props.writeCache();
        });

    };


    collectorsSurfaceChanged = (e) => {
        this.props.dispatch(updateHeatingById({
            id: "collectorsSurface",
            value: e,
        })).then(() => {
            this.setState({
                solarSurfaceChanged: true,
            });
            this.props.writeCache();
        });
    };


    doorTypeChanged = (e) => {
        this.props.dispatch(updateInsulationByTypeAndId({
            type: 'wall',
            id: 'doorType',
            value: e,
        })).then(() => {
            this.props.dispatch(updateCosts({
                doorType_material: undefined,
                doorType_work: undefined
            })).then(() => {
                this.props.writeCache();
            });
        });
    };



    getNaturalUnitPriceLabelText = (naturalUnit) => {
        return <div className={''}>
            <FormattedMessage
                id="hm.label.natural-unit.price.prefix"
                defaultMessage=""
                tagName={'span'}
            />
            <FormattedMessage
                id={naturalUnit.code}
                defaultMessage=""
                tagName={'span'}
            />
        </div>;
    };

    adjustNaturalUnitText = (naturalUnit) => {
        return <div className={'text-small'}>MJ/<FormattedMessage
            id={naturalUnit.code}
            defaultMessage=""
            tagName={'span'}
        />
        </div>
    };

    updateCountryVisibility = (showRegions, stationsVisibility) => {
        this.setState({
            showRegions: showRegions,
            stationsVisibility: stationsVisibility
        });
    };

    propagateResults = (value, yearValue, selectedUnit) => {
        return ConversionUnits.getValueByUnit(value, yearValue, selectedUnit);
    };


    unitUIChanged = unit => {
        let getPreviousDataContext = this.props.calculationStateController.getPreviousDataContext();
        const localCurrency = ConversionUnits.isLocalCurrencyCostUnit(unit);
        const exchangeRate = ConverterHelper.calcToPLNExchangeRate(unit,this.props.businessConfig.polishExchangeRate,this.configItem.currencyExchangeRate);

        this.setState({
            localCurrency: localCurrency,
            prevSelectedUnit: this.props.conversion.selectedUnit,
        });


        let previousState = this.props.calculationStateController.getPreviousState();
        const costInEuro = ConversionUnits.isEuroCostUnit(unit);
        const costUnit = ConversionUnits.isCostUnit(unit);
        let ratio = 1;
        if(costUnit && costInEuro){
            ratio = 1 / this.configItem.currencyExchangeRate;
        }
        let dataContextToChange = {};

        const conversionData = this.conversionChanged(unit);
        dataContextToChange._currencyLocaleCode = conversionData.currencyLocaleCode;
        dataContextToChange._currencyName = conversionData.currencyName;
        dataContextToChange._selectedUnit = conversionData.selectedUnit;
        dataContextToChange._shortUnitName = conversionData.shortUnitName;
        dataContextToChange._localCurrency = conversionData.localCurrency;

        dataContextToChange._toPLNCurrencyExchangeRate = exchangeRate;

        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === getPreviousDataContext.heatingData.waterHeatingFuel)
            .map(item => item.data)[0];

        let heatingValueMJ = waterFuelData.heatingValueMJ;
        let naturalUnitPrice = waterFuelData.naturalUnitPrice; 
        
        if(getPreviousDataContext.heatingData.heatingFuel === getPreviousDataContext.heatingData.waterHeatingFuel){
          heatingValueMJ = getPreviousDataContext.heatingData.calorificValue;
          naturalUnitPrice = getPreviousDataContext.conversionData.naturalUnitPriceLocal;
        }
     
        
        
        if(costUnit && costInEuro) {
            dataContextToChange._conversionPricePerKWh = HeatingSourceType.toPricePerKWh(
                getPreviousDataContext.conversionData.naturalUnitPrice, getPreviousDataContext.heatingData.calorificValue,
                getPreviousDataContext.heatingData.naturalUnit, getPreviousDataContext.heatingData.calorificValueUnit
            );
            dataContextToChange._waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                naturalUnitPrice/this.configItem.currencyExchangeRate,
                heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        } else {
            dataContextToChange._conversionPricePerKWh = HeatingSourceType.toPricePerKWh(
                getPreviousDataContext.conversionData.naturalUnitPriceLocal,
                getPreviousDataContext.heatingData.calorificValue, getPreviousDataContext.heatingData.naturalUnit,
                getPreviousDataContext.heatingData.calorificValueUnit
            );
            dataContextToChange._waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                naturalUnitPrice, heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        }
        
        let conversionPvPricePerKWh = getPreviousDataContext.houseData.pvData.price * ratio;
        dataContextToChange._conversionPvPricePerKWh = conversionPvPricePerKWh;
        dataContextToChange._conversionElectricityPricePerKWh = CountryItemHelper.getCountryElectricityPrice(CountryItemHelper.getCountryByCode(this.countryList, this.props.house.country) ,ratio);
        

        this.props.writeToSelectedStateCache(dataContextToChange, previousState);

        this.props.writeDataToBothCache({
            _currencyLocaleCode: conversionData.currencyLocaleCode,
            _localCurrency: conversionData.localCurrency,
            _currencyName: conversionData.currencyName,
            _toPLNCurrencyExchangeRate: exchangeRate,
            _conversionPvPricePerKWh: conversionPvPricePerKWh,
            _pvPricePerKWh: conversionPvPricePerKWh,
            
            
        });
        conversionData.pvPricePerKWh = conversionPvPricePerKWh;
        this.props.dispatch(updateConversion({
            conversionData: conversionData,
        })).then(() => {
            
            this.props.writeCache();
        });

    };

    ratioChanged = (unit) => {
        const localCurrency = ConversionUnits.isLocalCurrencyCostUnit(unit);
        const costInEuro = ConversionUnits.isEuroCostUnit(unit);
        const costUnit = ConversionUnits.isCostUnit(unit);
        const oldUnit = this.props.conversion.selectedUnit;
        const oldCostInEuro = ConversionUnits.isEuroCostUnit(oldUnit);
        const isOldLocal = ConversionUnits.isLocalCurrencyCostUnit(oldUnit);
        let ratio = 1;
        if(costUnit) {
            if(costInEuro){
                if(oldCostInEuro){
                    ratio = 1;
                } else {
                    ratio = 1 / this.configItem.currencyExchangeRate;
                }
            } else if (localCurrency) {
                if(oldCostInEuro){
                    ratio = this.configItem.currencyExchangeRate;
                } else if(isOldLocal){
                    ratio = 1;
                }
            }
        } else {
            if (oldCostInEuro) {
                ratio = this.configItem.currencyExchangeRate;
            } else if (isOldLocal) {
                ratio = 1;
            }
        }
        return ratio;
    };

    conversionChanged = (unit) => {
        const localCurrency = ConversionUnits.isLocalCurrencyCostUnit(unit);
        const costUnit= ConversionUnits.isCostUnit(unit);
        const costInEuro = ConversionUnits.isEuroCostUnit(unit);
        const exchangeRate = ConverterHelper.calcToPLNExchangeRate(unit,this.props.businessConfig.polishExchangeRate,this.configItem.currencyExchangeRate);
        let unitName = localCurrency
            ? (this.configItem.currencyLocaleCode + (ConversionUnits.isYearUnit(unit) ? '.year_cost' : '.month_cost'))
            : this.props.unitList.filter(item => item.data === unit)[0].shortUnit;

        const waterFuelData = this.heatingFuelDropDownList
            .filter(item => item.name === this.props.heating.waterHeatingFuel)
            .map(item => item.data)[0];

        let conversionData = {};
        conversionData.isWaterElectric = waterFuelData.type.heatingType.heatingType === 'electric';
        conversionData.currencyLocaleCode = this.configItem.currencyLocaleCode;
        conversionData.currencyName = this.configItem.currencyName;
        conversionData.selectedUnit = unit;
        conversionData.shortUnitName = unitName;
        conversionData.localCurrency = localCurrency;
        if(costInEuro) {
            conversionData.currencyLocaleCode = this.CURRENCY_EURO_LOCALE_CODE;
            conversionData.currencyName = this.CURRENCY_EURO;
        }
        if(localCurrency){
            conversionData.unitName =  this.configItem.currencyLocaleCode + (ConversionUnits.isYearUnit(unit) ? '.year_cost' : '.month_cost');
        }
        else {
            conversionData.unitName = this.props.unitList.filter(item => item.data === unit)[0].shortUnit;
        }

      let heatingValueMJ = waterFuelData.heatingValueMJ;
      let naturalUnitPrice = waterFuelData.naturalUnitPrice;

      if(this.props.heating.waterHeatingFuel === this.props.heating.heatingFuel){
        heatingValueMJ = this.props.heating.calorificValue;
        naturalUnitPrice = this.props.conversion.naturalUnitPriceLocal;
      }

        if(costUnit && costInEuro){
            conversionData.pricePerKWh = HeatingSourceType.toPricePerKWh(
                this.props.conversion.naturalUnitPrice, this.props.heating.calorificValue,
                this.props.heating.naturalUnit, this.props.heating.calorificValueUnit
            );
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                (conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWh
                    : naturalUnitPrice / this.configItem.currencyExchangeRate),
                heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        } else {
            conversionData.pricePerKWh = HeatingSourceType.toPricePerKWh(
                this.props.conversion.naturalUnitPriceLocal, this.props.heating.calorificValue,
                this.props.heating.naturalUnit, this.props.heating.calorificValueUnit
            );
            conversionData.waterPricePerKWh = parseFloat(HeatingSourceType.toPricePerKWh(
                (conversionData.isWaterElectric ? this.props.conversion.electricityPricePerKWhLocal : 
                  naturalUnitPrice),
                heatingValueMJ,
                waterFuelData.type.naturalUnit.code, waterFuelData.type.calorificValueUnit));
        }
        
         conversionData.toPLNCurrencyExchangeRate = exchangeRate;

        return conversionData;

    };

    togglePlotPanel = (showPlotPanel) => {
        this.setState({
            showPlotPanel: showPlotPanel,
            showBackdrop: showPlotPanel
        })
    };


    toggleComparingPlotPanel = (showPlotPanel) => {
        this.setState({
            showComparingPlotPanel: showPlotPanel,
            showBackdrop: showPlotPanel
        })
    };


    showCollector = (type, count) => {
        let collectorData = this.createSolarCollectorData(this.props.heating.collectorsTypeId);
        let collectorCount = collectorData.count;
        return collectorData.collectorType.type === type && collectorCount >= count
    };


    newCalculationWithStandard = (standardId) => {
        this.newCalculation('new');
        standardId.map(item => this.comboStandardChanged(item));
    };

    toggleFirstCalculation = (e) => {
        this.props.toggleFirstCalculation(e);
    };

    toggleBackdrop = () => {
        this.setState({
            showBackdrop: !this.state.showBackdrop
        })
    };



    newCalculation=(buildingType)=> {
        
        this.toggleBackdrop();
        this.props.dispatch(updateHouseById({
            id: 'buildingType',
            value: buildingType
        })).then(() => {
            this.props.newCalculation();
        })
    };


    optimize = (type, cancel) => {
        let isInRefState = this.props.calculationStateController.isInReferenceOrInitialState();
        let newDataContext = this.props.calculationStateController.getNewStateDataContext();
        let refDataContext = this.props.calculationStateController.getReferenceDataContext();

        this.chooseOptimizer(isInRefState, refDataContext, newDataContext, type, cancel,
            this.props.heatMasterController, this.props.reports, this.props.businessConfig.dictionaryConfig
        );
    };

    chooseOptimizer = (isInRefState, refDataContext, newDataContext, type, cancel,
                       heatMasterController, reports, dictionaryConfig) => {
        switch(type){
            case 'roofInsulation': {
                const data = RoofInsulationOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateInsulationByType({
                    id: 'roof',
                    values: {
                        material: data.material,
                        width: data.width,
                        uValue: data.uValue,
                        heatTransfer: data.heatTransfer,
                        u0: data.u0,
                    }
                })).then(() => {
                    this.props.dispatch(updateCosts({
                        roofInsulation_material: undefined,
                        roofInsulation_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'floorInsulation': {
                const data = FloorInsulationOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateInsulationByType({
                    id: 'floor',
                    values: {
                        material: data.material,
                        width: data.width,
                        uValue: data.uValue,
                        heatTransfer: data.heatTransfer,
                        foundations: data.foundations,
                        u0: data.u0,
                    }
                })).then(() => {
                    this.props.dispatch(updateCosts({
                        floorInsulation_material: undefined,
                        floorInsulation_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'wallInsulation': {
                const data = WallInsulationOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateInsulationByType({
                    id: 'wall',
                    values: {
                        material: data.material,
                        width: data.width,
                        uValue: data.uValue,
                        heatTransfer: data.heatTransfer,
                        doorType: data.doorType,
                        u0: data.u0,
                    }
                })).then(() => {
                    this.props.dispatch(updateCosts({
                        wallInsulation_material: undefined,
                        wallInsulation_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'collectors': {
                CollectorsOptimizer(type, cancel, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                break;
            }
            case 'doorType': {
                const data = DoorOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateInsulationByTypeAndId({
                    type: 'wall',
                    id: 'doorType',
                    value: data.doorType,
                })).then(() => {
                    this.props.dispatch(updateCosts({
                        doorType_material: undefined,
                        doorType_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'foundationType': {
                const data = FoundationOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateInsulationByType({
                    id: 'floor',
                    values: {
                        material: data.material,
                        width: data.width,
                        uValue: data.uValue,
                        heatTransfer: data.heatTransfer,
                        foundations: data.foundations,
                        u0: data.u0,
                    }
                })).then(() => {
                    this.props.dispatch(updateCosts({
                        foundationType_material: undefined,
                        foundationType_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'windows': {
                const data = WindowOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateWindows(
                    data.window, data.thermalBridge, data.shutters
                )).then(() => {
                    this.props.dispatch(updateCosts({
                        windows_material: undefined,
                        windows_work: undefined
                    })).then(() => {
                        this.props.writeCache();
                    });
                });
                break;
            }
            case 'shutters': {
                const data = ShuttersOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.shuttersChanged(data.shutters);
                this.props.dispatch(updateCosts({
                    shutters_material: undefined,
                    shutters_work: undefined
                })).then(() => {
                    this.props.writeCache();
                });
                break;
            }
            case 'thermalBridges': {
                const data = ThermalBridgesOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                this.props.dispatch(updateWindows(
                    data.window, data.thermalBridge, data.shutters
                ));
                this.props.dispatch(updateCosts({
                    thermalBridges_material: undefined,
                    thermalBridges_work: undefined
                })).then(() => {
                    this.props.writeCache();
                });
                break;
            }
            case 'pvType': {

                const data = PVOptimizer(type, cancel, newDataContext, heatMasterController,
                    this.props.photovoltaic.pv_power,this.props.photovoltaic.pv_type,
                    refDataContext.houseData.pvData.pv_power, refDataContext.houseData.pvData.pv_type,
                    refDataContext.houseData.pvData.energy_network
                );
                this.props.dispatch(updatePhotovoltaic({data: data})).then(() => {
                    this.props.dispatch(updateCosts({
                        pvType_material: undefined,
                        pvType_work: undefined,
                        pvType_material_changed: false,
                        pvType_work_changed: false
                    })).then(() => {
                        this.props.writeCache();
                    });
                });

                break;
            }
            case 'heatingSource': {

                const data = HeatingSourceOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                if(data.heatingType) {
                    this.heatingSourceTypeChanged(data.heatingType, false);
                }
                this.updateHeatingSourceAndConversion(data);
                this.warmWaterStorageChanged(data.warmWaterStorageId);
                break;
            }
            case 'ventilation': {

                const data = VentilationOptimizer(type, cancel, refDataContext, newDataContext,
                    heatMasterController, reports, dictionaryConfig);
                if(data) {
                    this.props.dispatch(updateVentilation({
                        ventilation: data.ventilation,
                        airTightness: data.airTightness,
                        co2Sensor: data.co2Sensor,
                        gwcSet: data.gwcSet,
                        isMechanical: data.isMechanical,
                        n50: data.n50
                    })).then(() => {
                        this.props.dispatch(updateCosts({
                            ventilation_material: undefined,
                            ventilation_work: undefined
                        })).then(() => {
                            this.props.writeCache();
                        });
                    });
                }

                break;
            }
            default:
        }
    };


    render() {
        this.configItem = CountryItemHelper.getCountryByCode(this.countryList, this.props.house.country);
        this.applyConfigPrices(this.heatingFuelDropDownList, this.configItem, ConversionUnits.isLocalCurrencyCostUnit(this.props.conversion.selectedUnit));
        
        if(this.configItem.standardTypeConfiguration.length > 0) {
            this.houseStandardList = this.toComboObjectList(this.configItem.standardTypeConfiguration);
        } else {
            this.houseStandardList = this.houseStandardTypeList;
        }
        const costInEuro = ConversionUnits.isEuroCostUnit(this.props.conversion.selectedUnit);
        const costUnit = ConversionUnits.isCostUnit(this.props.conversion.selectedUnit);
        
        return (<>
            <Welcome onChangeLanguage={(lang) => this.props.onChangeLanguage(lang)}
                         continueCalculation={()=> this.props.continueCalculation()}
                         newCalculation={(buildingType)=> this.newCalculation(buildingType)}
                         newCalculationWithStandard={(withStandard)=> this.newCalculationWithStandard(withStandard)}
                         houseStandardTypeList={this.houseStandardList}
                         cachedData={this.props.cachedData}
                     initEnvironmentalData={() => this.props.initEnvironmentalData()}
            />
            <div className={cx("row",
                    {'d-none': !this.props.dataResults}
                )}>

                <div id="content_container"
                     className={cx(`col-12`,
                         {'hidePanels': this.props.showPanels},
                         {'showPanels': !this.props.showPanels},
                         {'firstCalculation': this.props.firstCalculation},
                         {
                             'groundType': HouseTypeHelper.isGroundType(this.props.house.houseType),
                             'skyType': HouseTypeHelper.isSkyType(this.props.house.houseType),
                             'upFloor': HouseTypeHelper.hasNeighboursUp(this.props.house.houseType),
                             'downFloor': HouseTypeHelper.hasNeighboursDown(this.props.house.houseType),
                         },
                         )}
                     style={{
                    backgroundImage: `url(${(HouseTypeHelper.isGroundType(this.props.house.houseType) ? backgroundGrass : backgroundSky)})`,
                    backgroundSize: "auto 880px",
                    backgroundRepeat: "repeat-x",
                    backgroundColor: (HouseTypeHelper.isGroundType(this.props.house.houseType) ? '#72624C' : '#b4e3f3'),
                }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <EnergyMeter
                        prevSelectedUnit={this.state.prevSelectedUnit}
                        showOptimizerPanel={this.props.showOptimizerPanel}
                        businessConfig={this.props.businessConfig}
                        dataResults={this.props.dataResults}
                        unitList={this.props.unitList}
                        calculationStateController={this.props.calculationStateController}
                        cachedData={this.props.cachedData}
                        heatMasterController={this.props.heatMasterController}
                        toggleCompareView={() => this.props.toggleCompareView()}
                        togglePlotPanel={(show) => this.togglePlotPanel(show)}
                        toggleComparingPlotPanel={(show) => this.toggleComparingPlotPanel(show)}
                        toggleOptimizerPanel={() => this.props.toggleOptimizerPanel()}
                        inCompareView={this.props.inCompareView}
                        toggleReferenceState={(state) => this.props.toggleReferenceState(state)}
                        referenceState={this.props.referenceState}

                        newProcessingResult={this.props.newProcessingResult}
                        refProcessingResult={this.props.refProcessingResult}

                        formattedNewValue={this.props.formattedNewValue}
                        formattedReferenceValue={this.props.formattedReferenceValue}
                        unitUIChanged={unit => this.unitUIChanged(parseInt(unit))}
                        writeCache={() => this.props.writeCache()}

                        configChanged={(localCurrency, preserveLocallyChangedValues, writeCacheRequired) => this.configChanged(localCurrency, preserveLocallyChangedValues, writeCacheRequired)}

                    />
                    {this.props.dataResults && <OptimizerPanel
                        heatMasterController={this.props.heatMasterController}
                        referenceState={this.props.referenceState}
                        showOptimizerPanel={this.props.showOptimizerPanel}
                        toggleOptimizerPanel={() => this.props.toggleOptimizerPanel()}
                        calculationStateController={this.props.calculationStateController}
                        
                        invalidDataOptimizer={this.props.invalidDataOptimizer}
                        businessConfig={this.props.businessConfig}
                        houseStandardTypeList={this.houseStandardList}
                        dataResults={this.props.dataResults}
                        newProcessingResult={this.props.newProcessingResult}
                        refProcessingResult={this.props.refProcessingResult}
                        month={this.props.month}
                        selectedUnit={this.props.conversion.selectedUnit}
                        prevSelectedUnit={this.state.prevSelectedUnit}
                        unitUIChanged={unit => this.unitUIChanged(parseInt(unit))}
                        pricePerKWh={this.props.conversion.pricePerKWh}
                        stationsVisibility={this.state.stationsVisibility}
                        showRegions={this.state.showRegions}
                        formattedNewValue={this.props.formattedNewValue}
                        formattedReferenceValue={this.props.formattedReferenceValue}
                        configItem={this.configItem}
                        writeCache={() => this.props.writeCache()}
                        optimize = {(type, cancel) => this.optimize(type, cancel)}

                        houseType={this.props.house.houseType}
                        buildingType={this.props.house.buildingType}
                    />}

                    <div className={'custom-accordion'}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <Scrollbars style={{ width, height }}>

                            <Accordion defaultActiveKey="0">

                                <HousePopup
                                    id={"HousePopup1"}
                                    dataResults={this.props.dataResults}
                                    businessConfig={this.props.businessConfig}
                                    houseTypeChanged={(houseType) => this.houseTypeChanged(houseType)}
                                    stationChanged={(station, temperatures) => this.stationChanged(station, temperatures)}
                                    comboStandardChanged={(e) => this.comboStandardChanged(e)}
                                    
                                    
                                    roofSurfaceChanged={(e) => this.roofSurfaceChanged(e)}
                                    atticSurfaceChanged={(e) => this.atticSurfaceChanged(e)}
                                    roofChanged={(id, e) => this.roofChanged(id, e)}
                                    roofTypeChanged={(e) => this.roofTypeChanged(e)}
                                    
                                    applySecondarySurfaceDataToControls={(surfaceData) => this.applySecondarySurfaceDataToControls(surfaceData)}
                                    createPrimarySurfaceData={() => this.createPrimarySurfaceData()}
                                    updateCountryVisibility={(showRegions, stationsVisibility) => this.updateCountryVisibility(showRegions, stationsVisibility)}
                                    stationsVisibility={this.state.stationsVisibility}
                                    showRegions={this.state.showRegions}
                                    regionDropDownList={this.regionDropDownList}
                                    updateRegionDropdownList={(regions) => this.updateRegionDropdownList(regions)}

                                    houseStandardTypeList={this.houseStandardList}
                                    configChanged={(localCurrency, preserveLocallyChangedValues, writeCacheRequired) => this.configChanged(localCurrency, preserveLocallyChangedValues, writeCacheRequired)}
                                    writeCache={() => this.props.writeCache()}
                                    writeEnvironmentalData={(envData) => this.props.writeEnvironmentalData(envData)}
                                    writeDataToBothCache={(data) => this.props.writeDataToBothCache(data)}
                                    cachedData={this.props.cachedData}

                                    inCompareView={this.props.inCompareView}
                                    referenceState={this.props.referenceState}
                                    firstCalculation={this.props.firstCalculation}
                                    firstCalculationStep1={this.props.firstCalculationStep1}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    heatingSourceChanged={(e) => this.heatingSourceChanged(e)}
                                    electricityPricePerKWh={costUnit && costInEuro
                                        ? this.props.conversion.electricityPricePerKWh
                                        : this.props.conversion.electricityPricePerKWhLocal
                                    }
                                    electricityEmissionCoefficient={this.electricityHeatingSourceType.emissionPerKWh}
                                />


                                <LoadInsulationBaloon ifLoad={!HouseTypeHelper.hasNeighboursUp(this.props.house.houseType)}
                                                      insulationElementType={InsulationElementType.ROOF}
                                                      heatMasterController={this.props.heatMasterController}
                                                      insulationName="roof"
                                                      eventKey="5"
                                                      icon="roof_isolation_icon-color"
                                                      headerText="hm.label.insulation.roof"
                                                      message="hm.tooltip.insulation.roof"
                                                      businessConfig={this.props.businessConfig}
                                                      insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                                      writeCache={() => this.props.writeCache()}
                                                      invalidDataOptimizer={this.props.invalidDataOptimizer}
                                                      dataOptimizer={[this.props.invalidDataOptimizer.roofInsulation]}
                                                      doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                                      referenceState={this.props.referenceState}

                                />
                                <InsulationBaloon
                                    insulationElementType={InsulationElementType.WALL}
                                    heatMasterController={this.props.heatMasterController}
                                    writeCache={() => this.props.writeCache()}
                                    insulationName="wall"
                                    eventKey="7"
                                    icon="wall_isolation_icon-color"
                                    maximumWidth="40"
                                    headerText="hm.label.insulation.wall"
                                    message="hm.tooltip.insulation.walls"
                                    businessConfig={this.props.businessConfig}
                                    insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[this.props.invalidDataOptimizer.wallInsulation,
                                        this.props.invalidDataOptimizer.doorType
                                    ]}
                                    doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                    referenceState={this.props.referenceState}
                                />
                                <WindowsBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    shuttersChanged={(e) => this.shuttersChanged(e)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                />
                                <LoadInsulationBaloon ifLoad={
                                    !HouseTypeHelper.hasNeighboursDown(this.props.house.houseType)
                                    && (!this.props.house.basement.enabled
                                    || (this.props.house.basement.enabled && this.props.house.basement.type === BasementType.UNHEATED))
                                }
                                                      insulationElementType={InsulationElementType.FLOOR}
                                                      heatMasterController={this.props.heatMasterController}
                                                      insulationName="floor"
                                                      eventKey="6"
                                                      icon="floor_isolation_icon-color"
                                                      maximumWidth="50"
                                                      headerText="hm.label.insulation.floor"
                                                      message="hm.tooltip.insulation.floor"
                                                      businessConfig={this.props.businessConfig}
                                                      insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                                      writeCache={() => this.props.writeCache()}
                                                      invalidDataOptimizer={this.props.invalidDataOptimizer}
                                                      dataOptimizer={
                                                          [this.props.invalidDataOptimizer.floorInsulation,
                                                          this.props.invalidDataOptimizer.foundationType]
                                                      }
                                                      doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                                      referenceState={this.props.referenceState}
                                                      buildingType={this.props.house.buildingType}
                                />
                                <VentilationBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    referenceState={this.props.referenceState}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                    dataOptimizer={[this.props.invalidDataOptimizer.ventilation]}
                                />
                                  <DropdownBaloon
                                    id="airingBaloon"
                                    headerText="hm.label.airing"
                                    subHeaderText="hm.label.airing.sub"
                                    dataProvider={this.ventilationFreqList}
                                    selectedIndex={this.ventilationFreqListSelectedIndex}
                                    type={DropDownType.VENTILATION_FREQUENCY}
                                    eventKey="8"
                                    name={"airing"}
                                    icon={"airing_icon"}
                                    message="hm.tooltip.insulation.walls"
                                    writeCache={() => this.props.writeCache()}
                                  />

                                <PersonalGainBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                />
                                <InsolationBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    insolationChanged={(id, e) => this.insolationChanged(id, e)}
                                    shadingList={this.shadingList}

                                 />
                                <HeatingPopupContainer
                                    id={"HeatingPopup1"}
                                    version={1}
                                    prepareHeatingTypeDropdownList={(list, parent) => this.prepareHeatingTypeDropdownList(list, parent)}
                                    prepareHeatingSystemDropdownList={(list, heatingType, heatingSource) => this.prepareHeatingSystemDropdownList(list, heatingType, heatingSource)}
                                    getDefaultDataTreeFromSource={(heatingSource) => this.getDefaultDataTreeFromSource(heatingSource)}

                                    heatingDropDownList={this.heatingDropDownList}
                                    heatingTypeDropDownList={this.heatingTypeDropDownList}
                                    heatingSystemDropDownList={this.heatingSystemDropDownList}
                                    heatingFuelDropDownList={this.heatingFuelDropDownList}
                                    heatingWaterDropDownList={this.heatingWaterDropDownList}
                                    heatingSourceChanged={(e) => this.heatingSourceChanged(e)}
                                    heatingSourceTypeChanged={(e, ifUpdateStorage) => this.heatingSourceTypeChanged(e, ifUpdateStorage)}
                                    heatingSourceSubtypeChanged={(e) => this.heatingSourceSubtypeChanged(e)}
                                    heatingSourceSystemChanged={(e) => this.heatingSourceSystemChanged(e)}
                                    heatingFuelChanged={(e) => this.heatingFuelChanged(e)}
                                    warmWaterHeatingSourceChanged={(e) => this.warmWaterHeatingSourceChanged(e)}

                                    businessConfig={this.props.businessConfig}
                                    dataResults={this.props.dataResults}
                                    detachedHeatingSourceList={this.detachedHeatingSourceList}
                                    multiFamilyHeatingSourceList={this.multiFamilyHeatingSourceList}
                                    
                                    warmWaterDetachedHeatingSourceList={this.warmWaterDetachedHeatingSourceList}
                                    warmWaterMultiFamilyHeatingSourceList={this.warmWaterMultiFamilyHeatingSourceList}
                                    warmWaterHeatingTypeDropDownList={this.state.warmWaterHeatingTypeDropDownList}
                                    configChanged={(localCurrency, preserveLocallyChangedValues, writeCacheRequired) => this.configChanged(localCurrency, preserveLocallyChangedValues, writeCacheRequired)}
                                    createSolarCollectorData={(e) => this.createSolarCollectorData(e)}
                                    collectorsTypeChanged={(e) => this.collectorsTypeChanged(e)}
                                    calorificValueChanged={(e) => this.calorificValueChanged(e)}
                                    collectorsSurfaceChanged={(e) => this.collectorsSurfaceChanged(e)}
                                    warmWaterDistributionChanged={(e) => this.warmWaterDistributionChanged(e)}
                                    warmWaterStorageChanged={(e) => this.warmWaterStorageChanged(e)}
                                    naturalUnitPriceChanged={(e) => this.naturalUnitPriceChanged(e)}
                                    warmWaterPriceChanged={(e) => this.warmWaterPriceChanged(e)}
                                    priceChanged={(e) => this.priceChanged(e)}
                                    heatingTypeChanged={(e, forceWarmWaterUpdate) => this.heatingTypeChanged(e, forceWarmWaterUpdate)}
                                    warmWaterHeatingTypeChanged={(e) => this.warmWaterHeatingTypeChanged(e)}
                                    warmWaterHeatingEfficiencyChanged={(e) => this.warmWaterHeatingEfficiencyChanged(e)}
                                    efficiencyChanged={(id, e) => this.efficiencyChanged(id, e)}
                                    createHeatingData={() => this.createHeatingData()}
                                    previousHouseType={this.previousHouseType}
                                    regularRoof={HouseTypeHelper.hasRegularRoof(this.props.house.houseType)}
                                    naturalUnitCostLabel={this.state.naturalUnitCostLabel}
                                    naturalUnitCalorificValue={this.state.naturalUnitCalorificValue}
                                    showNaturalUnit={this.state.showNaturalUnit}
                                    calorificValInputEnabled={this.state.calorificValInputEnabled}
                                    firstCalculationStep2={this.props.firstCalculationStep2}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    toggleBackdrop={() => this.toggleBackdrop()}

                                    writeCache={() => this.props.writeCache()}

                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[
                                        this.props.invalidDataOptimizer.heatingSource,
                                        this.props.invalidDataOptimizer.collectors,
                                    ]}
                                />
                                
                                <AirConditioningBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    acChanged={(id, e) => this.acChanged(id, e)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                    dataOptimizer={[this.props.invalidDataOptimizer.airConditioning]}
                                />
                                {this.props.heating.includeWarmWater
                                && <WaterBaloon
                                    id="warmWaterBaloon"
                                    headerText="hm.label.warm_water"
                                    subHeaderText="hm.label.consumption"
                                    dataProvider={this.warmWaterConsumptionList}
                                    selectedIndex={this.warmWaterConsumptionListSelectedIndex}
                                    type={DropDownType.WARM_WATER}
                                    eventKey="9"
                                    icon={"water_icon_1"}
                                    name={"water"}
                                    propagateResults={(value, yearValue, selectedUnit) => this.propagateResults(value, yearValue, selectedUnit)}
                                    month={this.props.month}
                                    dataResults={this.props.dataResults}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[this.props.invalidDataOptimizer.waterSavings]}
                                    message="hm.tooltip.warmwater"
                                    writeCache={() => this.props.writeCache()}
                                />
                                }
                                {this.props.dataResults && <PhotovoltaicPopup
                                    id={"PhotovoltaicPopup1"}
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    firstCalculationStep3={this.props.firstCalculationStep3}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    toggleBackdrop={() => this.toggleBackdrop()}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[this.props.invalidDataOptimizer.pvType]}
                                    dataResults = {this.props.dataResults}
                                    heatMasterController = {this.props.heatMasterController}
                                    roofChanged={(id, e) => this.roofChanged(id, e)}
                                    roofTypeChanged={(e) => this.roofTypeChanged(e)}
                                    electricityPriceChanged={(e) => this.electricityPriceChanged(e)}
                                />}

                            </Accordion>
                        </Scrollbars>
                                )}}
                                </AutoSizer>

                    </div>
                    <div className={'housePanel-wrapper'}>
                        <div className={'housePanel-container'}>
                            <div id={"housePanel"} className={cx({
                                'groundType': HouseTypeHelper.isGroundType(this.props.house.houseType),
                                'skyType': HouseTypeHelper.isSkyType(this.props.house.houseType),
                                'upFloor': HouseTypeHelper.hasNeighboursUp(this.props.house.houseType),
                                'downFloor': HouseTypeHelper.hasNeighboursDown(this.props.house.houseType),
                                'foundationsInsulated': FoundationsType.isFoundationsInsulated(
                                    this.props.insulation.floor.foundations
                                )
                            })}>
                                <Image
                                    alt={""}
                                    id={"innerHouseImg"}
                                    className={'house-img'}
                                    style={{zIndex: 2, position: 'relative', width: '915px'}}
                                    src="./assets/images/house/inner_house2.svg"
                                    visible={HouseTypeHelper.isDetachedType(this.props.house.houseType) || HouseTypeHelper.isTerraceType(this.props.house.houseType)}
                                />
                                <Image
                                    alt={""}
                                    id={"lowerHouseImg"}
                                    className={'house-img'}
                                    style={{zIndex: 2, position: 'relative', width: '915px'}}
                                    src="./assets/images/house/lower_floor.svg"
                                    visible={HouseTypeHelper.hasNeighboursDown(this.props.house.houseType) && !HouseTypeHelper.hasNeighboursUp(this.props.house.houseType)}
                                />
                                <Image
                                    alt={""}
                                    id={"upperHouseImg"}
                                    className={'house-img'}
                                    style={{zIndex: 2, position: 'relative', width: '915px'}}
                                    src="./assets/images/house/upper_floor.svg"
                                    visible={HouseTypeHelper.hasNeighboursUp(this.props.house.houseType) && !HouseTypeHelper.hasNeighboursDown(this.props.house.houseType)}
                                />
                                <Image
                                    alt={""}
                                    id={"lowerUpperHouseImg"}
                                    className={'house-img'}
                                    style={{zIndex: 2, position: 'relative', width: '915px'}}
                                    src="./assets/images/house/upper_lower_floor.svg"
                                    visible={HouseTypeHelper.hasNeighboursUp(this.props.house.houseType) && HouseTypeHelper.hasNeighboursDown(this.props.house.houseType)}
                                />
                                <Image
                                    src={"./assets/images/rolety_mostek.svg"}
                                    alt={""}
                                    id={"shuttersWithoutBridgeImg"}
                                    style={{position:'absolute',
                                        top:290+(!ThermalBridgesType.getThermalBridgesTypeById(this.props.windows.thermalBridge).max ? 10 : 0)}}
                                    width={93}
                                    height={150}
                                    visible={this.props.windows.shutters}
                                />

                                <div style={{top: (53 - (0.75 * this.props.insulation.roof.width))}}
                                     id="roofGroup"
                                     className={cx({'d-none': !HouseTypeHelper.hasRoof(this.props.house.houseType)})}
                                >
                                    <LoadRoofImg
                                        roof={this.props.house.roofData.type}
                                    />

                                    <Image id="flatCollector1"
                                           visible={
                                               this.showCollector(SolarCollectorType.FLAT_TYPE, 1)
                                               && this.props.heating.includeWarmWater && this.props.photovoltaic.pv_type === 1
                                               && !this.showCollector(SolarCollectorType.VACUUM_TYPE, 1)}
                                           src="./assets/images/collectors/kolektory-plaskie.svg"
                                    />
                                    <Image id="flatCollector1"
                                           visible={
                                               this.props.photovoltaic.pv_type > 1}
                                           src="./assets/images/collectors/panele.svg"
                                    />
                                    <Image id="vacuumCollector1"
                                           visible={
                                               this.showCollector(SolarCollectorType.VACUUM_TYPE, 1)
                                           && this.props.heating.includeWarmWater && this.props.photovoltaic.pv_type === 1
                                           && !this.showCollector(SolarCollectorType.FLAT_TYPE, 1)}
                                           src="./assets/images/collectors/kolektory.svg"
                                    />
                                </div>
                                <LoadHeatingDeviceImg
                                    heatingSource = {this.props.heating.heatingSource}
                                    waterHeatingSource = {this.props.heating.waterHeatingSource}
                                    includeWarmWater = {this.props.heating.includeWarmWater}
                                    heatingType = {this.props.heating.heatingType}
                                    heatingFuel = {this.props.heating.heatingFuel}
                                    airconditioning = {this.props.airconditioning.airconditioning}
                                    wallInsulation = {0.75 * this.props.insulation.wall.width}
                                    isGrounded={HouseTypeHelper.isGroundType(this.props.house.houseType)}
                                />
                                <div  id="wallRightInsulationImg"
                                      className={cx('isolation position-absolute',{
                                    'd-none': this.props.insulation.wall.width === 0
                                })}
                                style={{
                                    'height': this.state.wallRightInsulationImgHeight,
                                    width: 0.75 * this.props.insulation.wall.width,

                                }}> </div>

                                <div  id="floorInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': (this.props.insulation.floor.width === 0)
                                      })}
                                      style={{
                                          width: 916,
                                          'height': 0.75 * this.props.insulation.floor.width,

                                      }}> </div>

                                <div  id="wallDownLeftInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': this.props.insulation.wall.width === 0
                                      })}
                                      style={{
                                          width: 0.75 * this.props.insulation.wall.width,
                                          'height': this.state.wallDownLeftInsulationImgHeight,

                                      }}> </div>

                                <div  id="wallUpLeftInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': this.props.insulation.wall.width === 0
                                      })}
                                      style={{
                                          width: 0.75 * this.props.insulation.wall.width,
                                          'height': 81,

                                      }}> </div>

                                <div  id="roofInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': this.props.insulation.roof.width === 0
                                          || HouseTypeHelper.hasNeighboursUp(this.props.house.houseType),
                                      })}
                                      style={{
                                          width: 914 + 1.5 * this.props.insulation.wall.width,
                                          'height': 0.75 * this.props.insulation.roof.width,
                                          left: -(0.75 * this.props.insulation.wall.width)

                                      }}> </div>

                                <div  id="bridgeUpInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': this.props.windows.thermalBridge === "hm.thermal-bridge.max"
                                      })}
                                      style={{
                                          width: 22 + 0.75 * this.props.insulation.wall.width,
                                          'height': 12,
                                          left: -(0.75 * this.props.insulation.wall.width)

                                      }}> </div>

                                <div  id="bridgeDownInsulationImg"
                                      className={cx('isolation position-absolute',{
                                          'd-none': this.props.windows.thermalBridge === "hm.thermal-bridge.max"
                                      })}
                                      style={{
                                          width: 22 + 0.75 * this.props.insulation.wall.width,
                                          'height': 12,
                                          left: -(0.75 * this.props.insulation.wall.width)

                                      }}> </div>


                                <Image id="rollBlind2Img"
                                       visible={false}
                                       src="./assets/images/roller_blind2.svg"
                                />
                                <Image  id="openWindowImg"
                                       visible={!this.props.ventilation.recuperator && (
                                           parseInt(this.props.ventilation.ventilationFreq) === VentilationFrequency.OFTEN ||
                                           parseInt(this.props.ventilation.ventilationFreq) === VentilationFrequency.VERY_OFTEN ||
                                           parseInt(this.props.ventilation.ventilationFreq) === VentilationFrequency.NON_STOP
                                       )}
                                       src="./assets/images/open_window.svg"
                                       width={90}
                                />
                                <Image id="bathImage"
                                       visible={this.props.heating.includeWarmWater}
                                       src="./assets/images/wanna.svg"
                                       width={204}
                                       height={89}
                                />
                                <Image id="groundHeatExchangerImg"
                                       visible={this.props.ventilation.recuperator && this.props.ventilation.gwcSet && HouseTypeHelper.isGroundType(this.props.house.houseType)}
                                       src="./assets/images/exchanger.svg"

                                       height={140}
                                />
                                <Image id="recuperatorImg"
                                       visible={this.props.ventilation.recuperator}
                                       src="./assets/images/recuperator.svg"
                                       width={124}
                                       height={112}
                                />
                                <Image id="mechanicalImg"
                                       visible={!this.props.ventilation.recuperator && this.props.ventilation.isMechanical}
                                       src="./assets/images/wiatraczek-wentylacji.svg"
                                       width={16}
                                       height={26}
                                />
                                <div id="wallHoleImg"
                                     style={{
                                         width: 32 + 0.75 * this.props.insulation.wall.width,
                                         left: -(0.75 * this.props.insulation.wall.width),
                                         height: 29,
                                         backgroundImage: 'url("./assets/images/wall_hole.svg")',
                                         backgroundRepeat: "repeat-x",

                                     }}/>
                                <div id="wallHoleRecInImg"
                                     className={cx({'d-none': !this.props.ventilation.recuperator})}
                                     style={{
                                         width: 32 + 0.75 * this.props.insulation.wall.width,
                                         left: -(0.75 * this.props.insulation.wall.width),
                                         height: 29,
                                         backgroundImage: 'url("./assets/images/wall_hole.svg")',
                                         backgroundRepeat: "repeat-x",

                                     }}/>


                                <Image id="leftFoundationImg"
                                       visible={HouseTypeHelper.isGroundType(this.props.house.houseType) && FoundationsType.getFoundationTypeById(this.props.insulation.floor.foundations) !== FoundationsType.SLAB_TYPE}
                                       src="./assets/images/fundament.svg"
                                       width={52}
                                       height={68}
                                />
                                <Image id="rightFoundationImg"
                                       visible={HouseTypeHelper.isGroundType(this.props.house.houseType) && FoundationsType.getFoundationTypeById(this.props.insulation.floor.foundations) !== FoundationsType.SLAB_TYPE}
                                       src="./assets/images/fundament.svg"
                                       width={52}
                                       height={68}
                                />
                            </div>

                            <Accordion defaultActiveKey="0">
                                <HousePopup

                                    id={"HousePopup2"}
                                    dataResults={this.props.dataResults}
                                    businessConfig={this.props.businessConfig}
                                    houseTypeChanged={(houseType) => this.houseTypeChanged(houseType)}
                                    stationChanged={(station, temperatures) => this.stationChanged(station, temperatures)}
                                    comboStandardChanged={(e) => this.comboStandardChanged(e)}
                                    
                                    
                                    roofSurfaceChanged={(e) => this.roofSurfaceChanged(e)}
                                    atticSurfaceChanged={(e) => this.atticSurfaceChanged(e)}
                                    roofChanged={(id, e) => this.roofChanged(id, e)}
                                    roofTypeChanged={(e) => this.roofTypeChanged(e)}
                                    
                                    applySecondarySurfaceDataToControls={(surfaceData) => this.applySecondarySurfaceDataToControls(surfaceData)}
                                    createPrimarySurfaceData={() => this.createPrimarySurfaceData()}
                                    updateCountryVisibility={(showRegions, stationsVisibility) => this.updateCountryVisibility(showRegions, stationsVisibility)}
                                    stationsVisibility={this.state.stationsVisibility}
                                    showRegions={this.state.showRegions}
                                    regionDropDownList={this.regionDropDownList}
                                    updateRegionDropdownList={(regions) => this.updateRegionDropdownList(regions)}

                                    houseStandardTypeList={this.houseStandardList}
                                    configChanged={(localCurrency, preserveLocallyChangedValues, writeCacheRequired) => this.configChanged(localCurrency, preserveLocallyChangedValues, writeCacheRequired)}
                                    writeCache={() => this.props.writeCache()}
                                    writeEnvironmentalData={(envData) => this.props.writeEnvironmentalData(envData)}
                                    writeDataToBothCache={(data) => this.props.writeDataToBothCache(data)}
                                    cachedData={this.props.cachedData}

                                    inCompareView={this.props.inCompareView}
                                    referenceState={this.props.referenceState}
                                    firstCalculation={this.props.firstCalculation}
                                    firstCalculationStep1={this.props.firstCalculationStep1}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    heatingSourceChanged={(e) => this.heatingSourceChanged(e)}
                                    electricityPricePerKWh={costUnit && costInEuro
                                        ? this.props.conversion.electricityPricePerKWh
                                        : this.props.conversion.electricityPricePerKWhLocal
                                    }
                                    electricityEmissionCoefficient={this.electricityHeatingSourceType.emissionPerKWh}
                                />
                            </Accordion>
                            <Accordion>
                                <HeatingPopupContainer
                                    id={"HeatingPopup2"}
                                    version={2}
                                    prepareHeatingTypeDropdownList={(list, parent) => this.prepareHeatingTypeDropdownList(list, parent)}
                                    prepareHeatingSystemDropdownList={(list, heatingType, heatingSource) => this.prepareHeatingSystemDropdownList(list, heatingType, heatingSource)}
                                    getDefaultDataTreeFromSource={(heatingSource) => this.getDefaultDataTreeFromSource(heatingSource)}

                                    heatingDropDownList={this.heatingDropDownList}
                                    heatingTypeDropDownList={this.heatingTypeDropDownList}
                                    heatingSystemDropDownList={this.heatingSystemDropDownList}
                                    heatingFuelDropDownList={this.heatingFuelDropDownList}
                                    heatingWaterDropDownList={this.heatingWaterDropDownList}
                                    heatingSourceChanged={(e) => this.heatingSourceChanged(e)}
                                    heatingSourceTypeChanged={(e, ifUpdateStorage) => this.heatingSourceTypeChanged(e, ifUpdateStorage)}
                                    heatingSourceSubypeChanged={(e) => this.heatingSourceSubypeChanged(e)}
                                    heatingSourceSystemChanged={(e) => this.heatingSourceSystemChanged(e)}
                                    heatingFuelChanged={(e) => this.heatingFuelChanged(e)}
                                    warmWaterHeatingSourceChanged={(e) => this.warmWaterHeatingSourceChanged(e)}

                                    
                                    businessConfig={this.props.businessConfig}
                                    dataResults={this.props.dataResults}
                                    detachedHeatingSourceList={this.detachedHeatingSourceList}
                                    multiFamilyHeatingSourceList={this.multiFamilyHeatingSourceList}
                                    
                                    warmWaterDetachedHeatingSourceList={this.warmWaterDetachedHeatingSourceList}
                                    warmWaterMultiFamilyHeatingSourceList={this.warmWaterMultiFamilyHeatingSourceList}
                                    warmWaterHeatingTypeDropDownList={this.state.warmWaterHeatingTypeDropDownList}
                                    configChanged={(localCurrency, preserveLocallyChangedValues, writeCacheRequired) => this.configChanged(localCurrency, preserveLocallyChangedValues, writeCacheRequired)}
                                    createSolarCollectorData={(e) => this.createSolarCollectorData(e)}
                                    collectorsTypeChanged={(e) => this.collectorsTypeChanged(e)}
                                    calorificValueChanged={(e) => this.calorificValueChanged(e)}
                                    collectorsSurfaceChanged={(e) => this.collectorsSurfaceChanged(e)}
                                    warmWaterDistributionChanged={(e) => this.warmWaterDistributionChanged(e)}
                                    warmWaterStorageChanged={(e) => this.warmWaterStorageChanged(e)}
                                    naturalUnitPriceChanged={(e) => this.naturalUnitPriceChanged(e)}
                                    warmWaterPriceChanged={(e) => this.warmWaterPriceChanged(e)}
                                    priceChanged={(e) => this.priceChanged(e)}
                                    heatingTypeChanged={(e, forceWarmWaterUpdate) => this.heatingTypeChanged(e, forceWarmWaterUpdate)}
                                    warmWaterHeatingTypeChanged={(e) => this.warmWaterHeatingTypeChanged(e)}
                                    warmWaterHeatingEfficiencyChanged={(e) => this.warmWaterHeatingEfficiencyChanged(e)}
                                    efficiencyChanged={(id, e) => this.efficiencyChanged(id,e)}
                                    createHeatingData={() => this.createHeatingData()}
                                    previousHouseType={this.previousHouseType}
                                    regularRoof={HouseTypeHelper.hasRegularRoof(this.props.house.houseType)}
                                    naturalUnitCostLabel={this.state.naturalUnitCostLabel}
                                    naturalUnitCalorificValue={this.state.naturalUnitCalorificValue}
                                    showNaturalUnit={this.state.showNaturalUnit}
                                    calorificValInputEnabled={this.state.calorificValInputEnabled}

                                    writeCache={() => this.props.writeCache()}
                                    firstCalculationStep2={this.props.firstCalculationStep2}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    toggleBackdrop={() => this.toggleBackdrop()}

                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[
                                        this.props.invalidDataOptimizer.heatingSource,
                                        this.props.invalidDataOptimizer.collectors,
                                    ]}
                                />
                            </Accordion>
                            <Accordion>
                                {this.props.dataResults && <PhotovoltaicPopup
                                    id={"PhotovoltaicPopup2"}
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    firstCalculationStep3={this.props.firstCalculationStep3}
                                    toggleFirstCalculation={(e) => this.toggleFirstCalculation(e)}
                                    toggleBackdrop={() => this.toggleBackdrop()}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[this.props.invalidDataOptimizer.pvType]}
                                    dataResults = {this.props.dataResults}
                                    heatMasterController = {this.props.heatMasterController}
                                    roofChanged={(id, e) => this.roofChanged(id, e)}
                                    roofTypeChanged={(e) => this.roofTypeChanged(e)}
                                    electricityPriceChanged={(e) => this.electricityPriceChanged(e)}
                                />}
                            </Accordion>
                            <Accordion defaultActiveKey="2" activeKey="2" className={'no-accordion-styles'}>
                                <WindowsBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    shuttersChanged={(e) => this.shuttersChanged(e)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                />
                            </Accordion>

                            <Accordion defaultActiveKey="3" activeKey="3" className={'no-accordion-styles'}>
                                <VentilationBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    referenceState={this.props.referenceState}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                    dataOptimizer={[this.props.invalidDataOptimizer.ventilation]}

                                />
                            </Accordion>
                            <Accordion defaultActiveKey="14" activeKey="14" className={'no-accordion-styles'}>

                                <AirConditioningBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    acChanged={(id, e) => this.acChanged(id, e)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer ?
                                        this.props.invalidDataOptimizer : false}
                                    dataOptimizer={[this.props.invalidDataOptimizer.airConditioning]}
                                />
                            </Accordion>
                            <Accordion defaultActiveKey="4" activeKey="4" className={'no-accordion-styles'}>
                                <PersonalGainBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                />
                            </Accordion>
                            <Accordion defaultActiveKey="13" activeKey="13" className={'no-accordion-styles'}>
                                <InsolationBaloon
                                    businessConfig={this.props.businessConfig}
                                    writeCache={() => this.props.writeCache()}
                                    insolationChanged={(id, e) => this.insolationChanged(id, e)}
                                    shadingList={this.shadingList}
                                />
                            </Accordion>


                            <LoadInsulationBaloon ifLoad={!HouseTypeHelper.hasNeighboursUp(this.props.house.houseType)}
                                                  insulationElementType={InsulationElementType.ROOF}
                                                  heatMasterController={this.props.heatMasterController}
                                                  insulationName="roof"
                                                  icon="roof_isolation_icon-color"
                                                  headerText="hm.label.insulation.roof"
                                                  message="hm.tooltip.insulation.roof"
                                                  businessConfig={this.props.businessConfig}
                                                  insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                                  writeCache={() => this.props.writeCache()}
                                                  invalidDataOptimizer={this.props.invalidDataOptimizer}
                                                  dataOptimizer={[this.props.invalidDataOptimizer.roofInsulation]}
                                                  doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                                  referenceState={this.props.referenceState}
                            />
                            <LoadInsulationBaloon ifLoad={
                                !HouseTypeHelper.hasNeighboursDown(this.props.house.houseType)
                                && (!this.props.house.basement.enabled
                                    || (this.props.house.basement.enabled && this.props.house.basement.type === BasementType.UNHEATED))
                            }
                                                  insulationElementType={InsulationElementType.FLOOR}
                                                  heatMasterController={this.props.heatMasterController}
                                                  insulationName="floor"
                                                  icon="floor_isolation_icon-color"
                                                  maximumWidth="50"
                                                  headerText="hm.label.insulation.floor"
                                                  message="hm.tooltip.insulation.floor"
                                                  businessConfig={this.props.businessConfig}
                                                  insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                                  writeCache={() => this.props.writeCache()}
                                                  invalidDataOptimizer={this.props.invalidDataOptimizer}
                                                  dataOptimizer={
                                                      [this.props.invalidDataOptimizer.floorInsulation,
                                                          this.props.invalidDataOptimizer.foundationType]
                                                  }
                                                  doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                                  referenceState={this.props.referenceState}
                                                  buildingType={this.props.house.buildingType}
                            />
                            <Accordion defaultActiveKey="7" activeKey="7" className={'no-accordion-styles'}>
                             <InsulationBaloon
                                    insulationElementType={InsulationElementType.WALL}
                                    heatMasterController={this.props.heatMasterController}
                                    writeCache={() => this.props.writeCache()}
                                    eventKey="7"
                                    insulationName="wall"
                                    icon="wall_isolation_icon-color"
                                    maximumWidth="40"
                                    headerText="hm.label.insulation.wall"
                                    message="hm.tooltip.insulation.walls"
                                    businessConfig={this.props.businessConfig}
                                    insulationElementChanged={(type) => this.insulationElementChanged(type)}
                                    invalidDataOptimizer={this.props.invalidDataOptimizer}
                                    dataOptimizer={[this.props.invalidDataOptimizer.wallInsulation,
                                        this.props.invalidDataOptimizer.doorType
                                    ]}
                                    doorTypeChanged={(type) => this.doorTypeChanged(type)}
                                    referenceState={this.props.referenceState}
                                />
                            </Accordion>
                          {!this.props.ventilation.isMechanical && <Accordion defaultActiveKey="0" activeKey="0" className={'no-accordion-styles'}>
                            <DropdownBaloon
                              id="airingBaloon"
                              headerText="hm.label.airing"
                              subHeaderText="hm.label.airing.sub"
                              dataProvider={this.ventilationFreqList}
                              selectedIndex={this.ventilationFreqListSelectedIndex}
                              type={DropDownType.VENTILATION_FREQUENCY}
                              eventKey="0"
                              icon={"airing_icon"}
                              name={"airing"}
                              message="hm.tooltip.insulation.walls"
                              writeCache={() => this.props.writeCache()}
                            />
                          </Accordion>}
                            
                            {this.props.heating.includeWarmWater
                            && <Accordion defaultActiveKey="0" activeKey="0" className={'no-accordion-styles'}>
                            <WaterBaloon
                                id="warmWaterBaloon"
                                headerText="hm.label.warm_water"
                                subHeaderText="hm.label.consumption"
                                dataProvider={this.warmWaterConsumptionList}
                                selectedIndex={this.warmWaterConsumptionListSelectedIndex}
                                type={DropDownType.WARM_WATER}
                                eventKey="0"
                                icon={"water_icon_1"}
                                name={"water"}
                                propagateResults={(value, yearValue, selectedUnit) => this.propagateResults(value, yearValue, selectedUnit)}
                                month={this.props.month}
                                dataResults={this.props.dataResults}
                                invalidDataOptimizer={this.props.invalidDataOptimizer}
                                dataOptimizer={[this.props.invalidDataOptimizer.waterSavings]}
                                message="hm.tooltip.warmwater"
                                writeCache={() => this.props.writeCache()}
                            />
                            </Accordion>}
                            {this.props.dataResults && <LoadArrows
                                        dataResults={this.props.dataResults}
                                        propagateResults={(value, yearValue, selectedUnit) => this.propagateResults(value, yearValue, selectedUnit)}
                                        month={this.props.month}
                                        includeWarmWater={this.props.heating.includeWarmWater}
                                        recuperator={this.props.ventilation.recuperator}
                                        houseType={this.props.house.houseType}
                                        basementType={this.props.house.basement.type}
                                        basementEnabled={this.props.house.basement.enabled}
                                        acData={this.props.airconditioning}
                                        selectedUnit={this.props.conversion.selectedUnit}
                                        pricePerKWh={this.props.conversion.pricePerKWh}
                                        waterPricePerKWh={this.props.conversion.waterPricePerKWh}
                                        electricityPricePerKWh={costUnit && costInEuro
                                            ? this.props.conversion.electricityPricePerKWh
                                            : this.props.conversion.electricityPricePerKWhLocal
                                        }
                                        emissionCoefficient={this.props.heating.emissionCoefficient}
                                        waterEmissionCoefficient={this.props.heating.waterEmissionCoefficient}
                                        electricityEmissionCoefficient={this.electricityHeatingSourceType.emissionPerKWh}
                                        livingNeedsChanged={this.props.livingNeedsChanged}
                                        livingNeeds={this.props.livingNeeds}

                            />}



                        </div>
                        {this.props.dataResults && this.state.showPlotPanel &&
                        <PlotPanel
                            ifLoad={this.props.dataResults && this.state.showPlotPanel}
                            dataResults={this.props.dataResults}
                            month={this.props.month}
                            togglePlotPanel={(show) => this.togglePlotPanel(show)}
                            referenceState={this.props.referenceState}
                            formattedNewValue={this.props.formattedNewValue}
                            formattedReferenceValue={this.props.formattedReferenceValue}
                            refProcessingResult={this.props.refProcessingResult}
                            newProcessingResult={this.props.newProcessingResult}
                            selectedUnit={this.props.conversion.selectedUnit}
                            pricePerKWh={this.props.conversion.pricePerKWh}
                            waterPricePerKWh={this.props.conversion.waterPricePerKWh}
                            electricityPricePerKWh={costUnit && costInEuro
                                ? this.props.conversion.electricityPricePerKWh
                                : this.props.conversion.electricityPricePerKWhLocal
                            }
                            emissionCoefficient={this.props.heating.emissionCoefficient}
                            waterEmissionCoefficient={this.props.heating.waterEmissionCoefficient}
                            electricityEmissionCoefficient={this.electricityHeatingSourceType.emissionPerKWh}
                        />}
                        {this.props.dataResults && this.state.showComparingPlotPanel &&
                        <ComparingPlotPanel
                            ifLoad={this.props.dataResults && this.state.showComparingPlotPanel}
                            dataResults={this.props.dataResults}
                            newProcessingResult={this.props.newProcessingResult}
                            refProcessingResult={this.props.refProcessingResult}
                            month={this.props.month}
                            toggleComparingPlotPanel={(show) => this.toggleComparingPlotPanel(show)}
                            selectedUnit={this.props.conversion.selectedUnit}
                            pricePerKWh={this.props.conversion.pricePerKWh}
                            waterPricePerKWh={this.props.conversion.waterPricePerKWh}
                            electricityPricePerKWh={costUnit && costInEuro
                                ? this.props.conversion.electricityPricePerKWh
                                : this.props.conversion.electricityPricePerKWhLocal
                            }
                            emissionCoefficient={this.props.heating.emissionCoefficient}
                            waterEmissionCoefficient={this.props.heating.waterEmissionCoefficient}
                            electricityEmissionCoefficient={this.electricityHeatingSourceType.emissionPerKWh}
                        />}
                    </div>
                </Suspense>
                </div>
                <div className={cx("fade modal-backdrop show",
                    {'d-none': !this.state.showBackdrop}
                    )}
                />
            </div>
            </>

        );
    }
}



function LoadInsulationBaloon(props) {
    if (props.eventKey == null) {
        return props.ifLoad && <Accordion defaultActiveKey="0" activeKey="0" className={'no-accordion-styles'}>
            <InsulationBaloon
                insulationElementType={props.insulationElementType}
                heatMasterController={props.heatMasterController}
                insulationName={props.insulationName}
                eventKey="0"
                icon={props.icon}
                maximumWidth="50"
                headerText={props.headerText}
                message={props.message}
                businessConfig={props.businessConfig}
                insulationElementChanged={props.insulationElementChanged}
                writeCache={() => props.writeCache()}
                invalidDataOptimizer={props.invalidDataOptimizer}
                dataOptimizer={props.dataOptimizer}
                referenceState={props.referenceState}
                buildingType={props.buildingType}
                doorTypeChanged={(type) => props.doorTypeChanged(type)}
            />
        </Accordion>
    } else {
        return props.ifLoad && <InsulationBaloon
            insulationElementType={props.insulationElementType}
            heatMasterController={props.heatMasterController}
            insulationName={props.insulationName}
            eventKey={props.eventKey}
            icon={props.icon}
            maximumWidth="50"
            headerText={props.headerText}
            message={props.message}
            businessConfig={props.businessConfig}
            insulationElementChanged={props.insulationElementChanged}
            writeCache={() => props.writeCache()}
            invalidDataOptimizer={props.invalidDataOptimizer}
            dataOptimizer={props.dataOptimizer}
            referenceState={props.referenceState}
            buildingType={props.buildingType}
            doorTypeChanged={(type) => props.doorTypeChanged(type)}
        />
    }
}


const mapStateToProps = state => {
    const ventilation = getVentilation(state);
    const insulation = getInsulation(state);
    const insolation = getInsolation(state);
    const windows = getWindows(state);
    const house = getHouse(state);
    const heating = getHeating(state);
    const persons = getPersons(state);
    const conversion = getConversion(state);
    const airconditioning = getAC(state);
    const reports = getReports(state);
    const photovoltaic = getPV(state);
    return { reports, house, ventilation, insulation, insolation, windows, heating, persons, conversion, airconditioning, photovoltaic};
};

export default  injectIntl(connect(mapStateToProps)(HousePanel));

