import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class WarmWaterEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    let enWarmWater = 0;
    const warmWaterData = contextData.houseData.warmWaterData;
    const heatingData = contextData.heatingData;

    if (contextData.includeWarmWater) {
      enWarmWater = EnergyCalcHelper.calcWarmWaterEnergyLoss(
        contextData.houseData.personNumber
      );
      enWarmWater =
        enWarmWater /
        (warmWaterData.distributorEfficiency * warmWaterData.storeEfficiency);
    }

    const ratio =
      this.getRatioForWaterDevice(contextData) *
      (heatingData.waterSavings ? 2 : 1);

    energyData.enWarmWaterNormalized = enWarmWater / ratio;
    energyData.enWarmWater =
      (enWarmWater * warmWaterData.consumptionIntensityType.intensity) / ratio;
    return energyData;
  };

  getRatioForWaterDevice = contextData => {
    const heatingData = contextData.heatingData;
    const warmWaterData = contextData.houseData.warmWaterData;
    let ratio = 1;
    if (warmWaterData.distributorEfficiency > 0.9) {
      ratio = 0.95;
    } else {
      if (
        heatingData.heatingSource ===
        "hm.dropdown.heating.source.solid_fuel_boiler"
      ) {
        if (
          heatingData.waterHeatingSource ===
          "hm.dropdown.heating.source.the_same"
        ) {
          //kotłownia
          ratio = 0.6;
        } else {
          //łazienka
          ratio = 0.8;
        }
        if (
          heatingData.waterHeatingSource ===
          "hm.dropdown.heating.source.electricity.water"
        ) {
          //na miejscu elektryczne
          ratio = 0.95;
        }
      } else if (
        heatingData.heatingSource === "hm.dropdown.heating.source.boiler_oil"
      ) {
        //kotłownia
        ratio = 0.6;
      } else if (
        heatingData.heatingType ===
          "hm.dropdown.heating.source.condensed.non_pulse" ||
        heatingData.heatingType === "hm.dropdown.heating.source.non_condensed"
      ) {
        if (
          heatingData.waterHeatingSource ===
          "hm.dropdown.heating.source.the_same"
        ) {
          //kotlownia lub lazienka
          if (heatingData.waterHeatingPlacement === "2") {
            ratio = 0.6;
          } else {
            ratio = 0.8;
          }
        }
      } else {
        ratio = 0.95;
      }
    }
    return ratio;
  };
}
