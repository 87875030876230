import { BaseTypeConfig } from "./BaseTypeConfig";

export class InsulationMaterialTypeConfig extends BaseTypeConfig {
  _insulationMaterialTypes;
  _wallInsulationMaterialTypes;
  _floorInsulationMaterialTypes;
  _roofFlatInsulationMaterialTypes;
  _roofInsulationMaterialTypes;
  _basementWallInsulationMaterialTypes;
  _basementFloorInsulationMaterialTypes;

  get insulationMaterialTypes() {
    return this._insulationMaterialTypes;
  }

  set insulationMaterialTypes(value) {
    this._insulationMaterialTypes = value;
  }

  get wallInsulationMaterialTypes() {
    return this._wallInsulationMaterialTypes;
  }

  set wallInsulationMaterialTypes(value) {
    this._wallInsulationMaterialTypes = value;
  }

  get floorInsulationMaterialTypes() {
    return this._floorInsulationMaterialTypes;
  }

  set floorInsulationMaterialTypes(value) {
    this._floorInsulationMaterialTypes = value;
  }

  get roofFlatInsulationMaterialTypes() {
    return this._roofFlatInsulationMaterialTypes;
  }

  set roofFlatInsulationMaterialTypes(value) {
    this._roofFlatInsulationMaterialTypes = value;
  }

  get roofInsulationMaterialTypes() {
    return this._roofInsulationMaterialTypes;
  }

  set roofInsulationMaterialTypes(value) {
    this._roofInsulationMaterialTypes = value;
  }

  get basementWallInsulationMaterialTypes() {
    return this._basementWallInsulationMaterialTypes;
  }

  set basementWallInsulationMaterialTypes(value) {
    this._basementWallInsulationMaterialTypes = value;
  }

  get basementFloorInsulationMaterialTypes() {
    return this._basementFloorInsulationMaterialTypes;
  }

  set basementFloorInsulationMaterialTypes(value) {
    this._basementFloorInsulationMaterialTypes = value;
  }
}
