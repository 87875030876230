import { SplitColumnData } from "./SplitColumnData";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { ConvertToUnit, fromKwhToWattsRatio } from "../Helpers/ConvertToUnit";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class SplitColumnDataProvider {
  getColumnData = (results, convertedResult, yearlyMode) => {
    let splitColumnData = new SplitColumnData();
    const conversionData = results.conversionData;
    const costInEuro = ConversionUnits.isEuroCostUnit(
      conversionData.selectedUnit
    );
    const costUnit = ConversionUnits.isCostUnit(conversionData.selectedUnit);
    let enProductGain =
      convertedResult.enPersonGain + convertedResult.enElectricityGain;
    let enSolGain = convertedResult.enSolGain;
    const enCollectorsGain = convertedResult.enCollectorSolarGain;
    const enPhotovoltaicGain = convertedResult.enPhotovoltaicGain;
    const enPhotovoltaicGainNormalized =
      convertedResult.enPhotovoltaicGainNormalized;

    const electricityPricePerKWh =
      costUnit && costInEuro
        ? conversionData.electricityPricePerKWh
        : conversionData.electricityPricePerKWhLocal;

    const enHeatingSourceLoss = convertedResult.getEnHeatingSourceLoss();
    const enHeatingSourceGain = convertedResult.getEnHeatingSourceGains();
    if (yearlyMode) {
      enProductGain = convertedResult.enHeatingProductAggregated;
      enSolGain = convertedResult.enSolGainAggregated;
    }
    splitColumnData.enHeatingSourceLoss = Math.round(
      ConvertToUnit(
        enHeatingSourceLoss,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enWarmWater = Math.round(
      ConvertToUnit(
        convertedResult.enWarmWater,
        conversionData.selectedUnit,
        conversionData.waterPricePerKWh,
        conversionData.waterEmissionCoefficient
      )
    );
    splitColumnData.enWalls = Math.round(
      ConvertToUnit(
        convertedResult.enWalls,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enDoor = Math.round(
      ConvertToUnit(
        convertedResult.enDoor,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enBasement = Math.round(
      ConvertToUnit(
        convertedResult.enBasement,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enRoof = Math.round(
      ConvertToUnit(
        convertedResult.enRoof,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enFloor = Math.round(
      ConvertToUnit(
        convertedResult.enFloor,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enWindows = Math.round(
      ConvertToUnit(
        convertedResult.enWindows,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enFoundations = Math.round(
      ConvertToUnit(
        convertedResult.enFoundations,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enVent = Math.round(
      ConvertToUnit(
        convertedResult.enVent,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enRecuperator = Math.round(
      ConvertToUnit(
        convertedResult.enRecuperator,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enAir = Math.round(
      ConvertToUnit(
        convertedResult.enAir,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enTightness = Math.round(
      ConvertToUnit(
        convertedResult.enTightness,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.airConditioningLoss = Math.round(
      ConvertToUnit(
        convertedResult.airConditioningLoss,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.airConditioningDeviceEnergy = Math.round(
      ConvertToUnit(
        convertedResult.airConditioningDeviceEnergy,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enPhotovoltaicGain = Math.round(
      ConvertToUnit(
        -enPhotovoltaicGain,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enPhotovoltaicGainNormalized = Math.round(
      ConvertToUnit(
        -enPhotovoltaicGainNormalized,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enSolGain = Math.round(
      ConvertToUnit(
        -enSolGain,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enProductGain = Math.round(
      ConvertToUnit(
        -enProductGain,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enPersonGain = Math.round(
      ConvertToUnit(
        -convertedResult.enPersonGain,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enCollectorsGain = Math.round(
      ConvertToUnit(
        -enCollectorsGain,
        conversionData.selectedUnit,
        conversionData.waterPricePerKWh,
        conversionData.waterEmissionCoefficient
      )
    );
    splitColumnData.enHeatingSourceGain = Math.round(
      ConvertToUnit(
        -enHeatingSourceGain,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.enHumidification = Math.round(
      ConvertToUnit(
        convertedResult.enHumidification,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enElectricDevicesEnergyLoss = Math.round(
      ConvertToUnit(
        (results.yearlyAggregatedConvertedData
          .enElectricDevicesEnergyLossNormalized *
          convertedResult.heatingDeviceEnergy) /
          results.yearlyAggregatedConvertedData.heatingDeviceEnergy,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enWaterElectricDevicesEnergyLoss = Math.round(
      ConvertToUnit(
        (results.yearlyAggregatedConvertedData
          .enWaterElectricDevicesEnergyLoss *
          convertedResult.heatingWaterDeviceEnergy) /
          results.yearlyAggregatedConvertedData.heatingWaterDeviceEnergy,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enElectricVentilationEnergyLoss = Math.round(
      ConvertToUnit(
        convertedResult.enElectricVentilationEnergyLoss,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.heatingDeviceEnergy = Math.round(
      ConvertToUnit(
        convertedResult.heatingDeviceEnergy,
        conversionData.selectedUnit,
        conversionData.pricePerKWh,
        conversionData.emissionCoefficient
      )
    );
    splitColumnData.heatingWaterDeviceEnergy = Math.round(
      ConvertToUnit(
        convertedResult.heatingWaterDeviceEnergy,
        conversionData.selectedUnit,
        conversionData.waterPricePerKWh,
        conversionData.waterEmissionCoefficient
      )
    );
    splitColumnData.airConditioningDeviceEnergy = Math.round(
      ConvertToUnit(
        convertedResult.airConditioningDeviceEnergy,
        conversionData.selectedUnit,
        electricityPricePerKWh,
        conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.enVent =
      splitColumnData.enVent + splitColumnData.enRecuperator;
    splitColumnData.insulationAndWarmWaterGroup =
      splitColumnData.enHeatingSourceLoss +
      splitColumnData.enWarmWater +
      splitColumnData.enWalls +
      splitColumnData.enDoor +
      splitColumnData.enBasement +
      splitColumnData.enRoof +
      splitColumnData.enFloor +
      splitColumnData.enWindows +
      splitColumnData.enFoundations;

    splitColumnData.ventilationGroup =
      splitColumnData.enVent +
      splitColumnData.enTightness +
      splitColumnData.enAir;

    splitColumnData.enGainsGroup =
      splitColumnData.enSolGain +
      splitColumnData.enProductGain +
      splitColumnData.enCollectorsGain +
      splitColumnData.enHeatingSourceGain +
      splitColumnData.enPhotovoltaicGain;

    splitColumnData.heatingWaterDeviceEnergy = Math.round(
      splitColumnData.heatingWaterDeviceEnergy +
        splitColumnData.enCollectorsGain
    );
    splitColumnData.electricDevices = Math.round(
      splitColumnData.enElectricDevicesEnergyLoss +
        splitColumnData.enWaterElectricDevicesEnergyLoss +
        splitColumnData.enElectricVentilationEnergyLoss
    );
    splitColumnData.enBalance = Math.round(
      Math.max(
        0,
        splitColumnData.insulationAndWarmWaterGroup +
          splitColumnData.ventilationGroup +
          splitColumnData.enGainsGroup -
          splitColumnData.electricDevices
      )
    );

    return splitColumnData;
  };

  getColumnDataInGroups = resultData => {
    const data = resultData.yearlyAggregatedConvertedData;

    const pvCalc = getPvEnergyCalculated(
      resultData,
      resultData.dataContext.houseData.livingNeedsChanged,
      resultData.dataContext.houseData.livingNeeds
    );
    const costInEuro = ConversionUnits.isEuroCostUnit(
      resultData.conversionData.selectedUnit
    );
    const costUnit = ConversionUnits.isCostUnit(
      resultData.conversionData.selectedUnit
    );
    const electricityPricePerKWh =
      costUnit && costInEuro
        ? resultData.conversionData.electricityPricePerKWh
        : resultData.conversionData.electricityPricePerKWhLocal;
    let splitColumnData = [];
    const pv = Math.round(
      ConvertToUnit(
        (pvCalc.energyCo < pvCalc.basicEnergy
          ? pvCalc.energyCo
          : pvCalc.basicEnergy) * fromKwhToWattsRatio(),
        resultData.conversionData.selectedUnit,
        electricityPricePerKWh,
        resultData.conversionData.electricityEmissionCoefficient
      )
    );
    const heating = Math.round(
      ConvertToUnit(
        data.heatingDeviceEnergy,
        resultData.conversionData.selectedUnit,
        resultData.conversionData.pricePerKWh,
        resultData.conversionData.emissionCoefficient
      )
    );
    const collectors = Math.round(
      ConvertToUnit(
        data.enCollectorSolarGain,
        resultData.conversionData.selectedUnit,
        resultData.conversionData.waterPricePerKWh,
        resultData.conversionData.waterEmissionCoefficient
      )
    );

    const water =
      Math.round(
        ConvertToUnit(
          data.heatingWaterDeviceEnergy,
          resultData.conversionData.selectedUnit,
          resultData.conversionData.waterPricePerKWh,
          resultData.conversionData.waterEmissionCoefficient
        )
      ) + collectors;
    const cooling = Math.round(
      ConvertToUnit(
        data.airConditioningDeviceEnergy,
        resultData.conversionData.selectedUnit,
        electricityPricePerKWh,
        resultData.conversionData.electricityEmissionCoefficient
      )
    );
    const electricDevices = Math.round(
      ConvertToUnit(
        data.enElectricDevicesEnergyLossNormalized +
          data.enRecuperator +
          data.enHumidification +
          data.enWaterElectricDevicesEnergyLoss +
          data.enElectricVentilationEnergyLoss,
        resultData.conversionData.selectedUnit,
        electricityPricePerKWh,
        resultData.conversionData.electricityEmissionCoefficient
      )
    );
    splitColumnData.push({
      type: "loss",
      fill: "#ec6d11",
      class: "heating",
      name: "hm.label.heating",
      value: heating,
    });
    splitColumnData.push({
      type: "loss",
      fill: "#ff4b4b",
      class: "warm_water",
      name: "hm.label.warm_water",
      value: water,
    });
    splitColumnData.push({
      type: "loss",
      fill: "#0088FE",
      class: "cooling",
      name: "hm.label.cooling",
      value: cooling,
    });
    splitColumnData.push({
      type: "loss",
      fill: "#888888",
      class: "technicalSystems",
      name: "hm.label.technicalSystems",
      value: electricDevices,
    });
    splitColumnData.push({
      type: "gain",
      fill: "#ffe400",
      class: "photovoltaic",
      name: "hm.label.photovoltaic",
      value: pv,
    });
    splitColumnData.push({
      type: "gain",
      fill: "#ff9797",
      class: "solar_collectors",
      name: "hm.label.solar_collectors",
      value: collectors,
    });
    return splitColumnData;
  };
}
