import {OptimizerResultsState} from "../OptimizerResultsState";
import store from "../../../redux/store";

export class WaterStorageOptimizer {
    constructor(isInReferenceState, dictionary, buildingType,refPricePerKWh, newPricePerKWh){
        this.elementList = dictionary;
        this.buildingType = buildingType;
        this.refPricePerKWh = refPricePerKWh;
        this.newPricePerKWh = newPricePerKWh;
        this.isInReferenceState = isInReferenceState;
    }
    elementList;

    validate = (type, result, element, newElement, refCosts, newCosts) => {
        result.state = OptimizerResultsState.THE_SAME;
        result.refItem = this.elementList
            .filter(item => item.id === element.id)[0];
        result.newItem = this.elementList
            .filter(item => item.id === newElement.id)[0];
        result.obsolescence = result.newItem.obsolescence;

        if(element.efficiency >  newElement.efficiency ){
            result.state = OptimizerResultsState.WORSE;
            
        } else if(element.efficiency < newElement.efficiency ){
            result.state = OptimizerResultsState.BETTER;
            result.refPricePerKWh = this.refPricePerKWh;
            result.newPricePerKWh = this.newPricePerKWh;

            let price = result.newItem.price;
            let work = result.newItem.work;

            const costs = store.getState().costs;
            if(typeof costs[type+'_material'] !== "undefined"){
                price = costs[type+'_material'];
            }
            if(typeof costs[type+'_work'] !== "undefined"){
                work = costs[type+'_work'];
            }

            result.cost = this.buildingType === 'current'
                ? this.calculateCosts(price, work)
                : this.calculateDifference(result.refItem.price, price,
                result.refItem.work, work);
            result.work = this.buildingType === 'current'
                ? this.calculateWork(work)
                : 0;
                
            result.material = this.buildingType === 'current'
                ? this.calculateMaterial(price)
                : this.calculateMaterialDifference(result.refItem.price, price);
            result.obsolescence = result.newItem.obsolescence;
            result.service = 0;
            result.workPrice = work;
            result.materialPrice = price;
        }
        return result;
    };

    calculateCosts = (price, work) => {
        return (price  + work);
    };

    calculateDifference = (price, newPrice, work, newWork) => {
        return (newPrice - price)
            
    };

    calculateMaterial = (price) => {
        return (price);
    };

    calculateWork = (work) => {
        return (work);
    };

    calculateWorkDifference = (work, newWork) => {
        return (newWork-work);
    };

    calculateMaterialDifference = (price, newPrice) => {
        return newPrice - price;
    };
}