import { ConvertedResult } from "./ConvertedResult";
import { ConvertedValue } from "./ConvertedValue";
import { RatioClusterFactory } from "./RatioClusterFactory";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class ConverterHelper {
  static buildWithAbsoluteValues = converterResult => {
    let result = new ConvertedResult();
    result.enWalls = Math.max(0, converterResult.enWalls);
    result.enDoor = Math.max(0, converterResult.enDoor);
    result.enBasement = Math.max(0, converterResult.enBasement);
    result.enRoof = Math.max(0, converterResult.enRoof);
    result.enFloor = Math.max(0, converterResult.enFloor);
    result.enFoundations = Math.max(0, converterResult.enFoundations);
    result.enWindows = Math.max(0, converterResult.enWindows);
    result.enVent = Math.max(0, converterResult.enVent);
    result.enRecuperator = Math.max(0, converterResult.enRecuperator);
    result.enHumidification = Math.max(0, converterResult.enHumidification);
    result.enAir = Math.max(0, converterResult.enAir);
    result.enTightness = Math.max(0, converterResult.enTightness);
    result.enWarmWater = Math.max(0, converterResult.enWarmWater);
    result.enElectricDevicesEnergyLoss = Math.max(
      0,
      converterResult.enElectricDevicesEnergyLoss
    );
    result.enElectricDevicesEnergyLossNormalized = Math.max(
      0,
      converterResult.enElectricDevicesEnergyLossNormalized
    );
    result.enElectricVentilationEnergyLoss = Math.max(
      0,
      converterResult.enElectricVentilationEnergyLoss
    );
    result.enWaterElectricDevicesEnergyLoss = Math.max(
      0,
      converterResult.enWaterElectricDevicesEnergyLoss
    );
    result.electricDevicesEnergySum = Math.max(
      0,
      converterResult.electricDevicesEnergySum
    );

    result.enSolGain = Math.max(0, converterResult.enSolGain);
    result.enPersonGain = Math.max(0, converterResult.enPersonGain);
    result.enLivingNeeds = Math.max(0, converterResult.enLivingNeeds);
    result.enElectricityGain = Math.max(0, converterResult.enElectricityGain);
    result.enCollectorSolarGain = Math.max(
      0,
      converterResult.enCollectorSolarGain
    );
    result.enPhotovoltaicGain = Math.max(0, converterResult.enPhotovoltaicGain);
    result.enWarmWaterGain = Math.max(0, converterResult.enWarmWaterGain);

    result.heatingSourceLoss = converterResult.heatingSourceLoss;
    result.heatingDeviceEnergy = converterResult.heatingDeviceEnergy;
    result.heatingWaterDeviceEnergy = converterResult.heatingWaterDeviceEnergy;
    result.airConditioningDeviceEnergy = Math.abs(
      converterResult.airConditioningDeviceEnergy
    );
    result.enElectricDevicesEnergyLossNormalized =
      converterResult.enElectricDevicesEnergyLossNormalized;
    result.enElectricVentilationEnergyLoss =
      converterResult.enElectricVentilationEnergyLoss;
    result.enWaterElectricDevicesEnergyLoss =
      converterResult.enWaterElectricDevicesEnergyLoss;
    result.electricDevicesEnergySum = converterResult.electricDevicesEnergySum;
    result.airConditioningLoss = converterResult.airConditioningLoss;
    result.warmWaterHeatingSourceLoss =
      converterResult.warmWaterHeatingSourceLoss;
    result.enWarmWaterNormalized = converterResult.enWarmWaterNormalized;
    result.enWarmWaterNormalizedHeatingSourceLoss =
      converterResult.enWarmWaterNormalizedHeatingSourceLoss;

    return result;
  };

  static toConvertedValue = (value, conversionData, water = false) => {
    let convertedValue = new ConvertedValue();
    let ratioCluster = RatioClusterFactory.getRatioCluster(
      conversionData,
      water
    );

    convertedValue.wattsValue = value * ratioCluster.toWattsRatio;
    convertedValue.kWhValue = value * ratioCluster.tokWhRatio;
    convertedValue.zlotyValue = value * ratioCluster.toCostRatio;
    convertedValue.gjValue = value * ratioCluster.toGJRatio;
    convertedValue.emissionValue = value * ratioCluster.toEmissionRatio;
    convertedValue.waterEmissionValue =
      value * ratioCluster.toWarmWaterEmissionRatio;

    return convertedValue;
  };

  static toConvertedValueWater = (value, conversionData, water = false) => {
    let convertedValue = new ConvertedValue();
    let ratioCluster = RatioClusterFactory.getRatioCluster(
      conversionData,
      water
    );

    convertedValue.wattsValue = value * ratioCluster.toWattsRatio;
    convertedValue.kWhValue = value * ratioCluster.tokWhRatio;
    convertedValue.zlotyValue = value * ratioCluster.toCostRatio;
    convertedValue.gjValue = value * ratioCluster.toGJRatio;
    convertedValue.emissionValue = value * ratioCluster.toEmissionRatio;
    convertedValue.waterEmissionValue =
      value * ratioCluster.toWarmWaterEmissionRatio;

    return convertedValue;
  };

  static toConvertedValuePV = (value, conversionData) => {
    let convertedValue = new ConvertedValue();
    conversionData.pricePerKWh = conversionData.electricityPricePerKWh;

    let ratioCluster = RatioClusterFactory.getRatioCluster(conversionData);

    convertedValue.wattsValue = value * ratioCluster.toWattsRatio;
    convertedValue.kWhValue = value * ratioCluster.tokWhRatio;
    convertedValue.zlotyValue = value * ratioCluster.toCostRatio;
    convertedValue.gjValue = value * ratioCluster.toGJRatio;
    convertedValue.emissionValue = value * ratioCluster.toEmissionRatio;
    convertedValue.waterEmissionValue =
      value * ratioCluster.toWarmWaterEmissionRatio;

    return convertedValue;
  };

  static toConvertedValueAC = (value, conversionData) => {
    let convertedValue = new ConvertedValue();
    conversionData.pricePerKWh = conversionData.electricityPricePerKWh;
    let ratioCluster = RatioClusterFactory.getRatioCluster(conversionData);

    convertedValue.wattsValue = value * ratioCluster.toWattsRatio;
    convertedValue.kWhValue = value * ratioCluster.tokWhRatio;
    convertedValue.zlotyValue = value * ratioCluster.toCostRatio;
    convertedValue.gjValue = value * ratioCluster.toGJRatio;
    convertedValue.emissionValue = value * ratioCluster.toEmissionRatio;
    convertedValue.waterEmissionValue =
      value * ratioCluster.toWarmWaterEmissionRatio;

    return convertedValue;
  };

  static calcToPLNExchangeRate = (
    unit,
    euroToPLNExchangeRate,
    toLocalCurrencyExchangeRate
  ) => {
    let result = euroToPLNExchangeRate / toLocalCurrencyExchangeRate;

    if (ConversionUnits.isEuroCostUnit(unit)) {
      result = euroToPLNExchangeRate;
    }

    return result;
  };

  static getEnergyRatio = unit => {
    let ratio = 1;
    if (unit === ConversionUnits.ENERGY_WATTS) {
      ratio = 1;
    }
    if (unit === ConversionUnits.ENERGY_KWH_PER_MONTH) {
      ratio = 0.72;
    } else if (unit === ConversionUnits.ENERGY_KWH_PER_YEAR) {
      ratio = (24 * 365) / 1000;
    }
    return ratio;
  };

  static getProperConvertedValue = (convertedValue, unit) => {
    if (unit === ConversionUnits.ENERGY_WATTS) {
      return convertedValue.wattsValue;
    } else if (unit === ConversionUnits.ENERGY_GJ_PER_YEAR) {
      return convertedValue.gjValue;
    } else if (unit === ConversionUnits.EMISSIONS_PER_YEAR) {
      return convertedValue.emissionValue;
    } else if (
      unit === ConversionUnits.ENERGY_KWH_PER_MONTH ||
      unit === ConversionUnits.ENERGY_KWH_PER_YEAR
    ) {
      return convertedValue.kWhValue;
    } else if (ConversionUnits.isCostUnit(unit)) {
      if (ConversionUnits.isEuroCostUnit(unit)) {
        return convertedValue.emissionValue;
      }
      return convertedValue.zlotyValue;
    }
  };
}
