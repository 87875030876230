
export function ShuttersOptimizer(
    type, cancel, refDataContext, newDataContext, heatMasterController, reports, dictionaryConfig, isInRefState = false
) {
    const newWindowType = newDataContext.houseData.windowElement;
    return {
        window: newWindowType.windowsType.id,
        thermalBridge:  newWindowType.thermalBridgesType.id,
        shutters: !cancel,
    };
}
