import { BaseTypeConfig } from "./BaseTypeConfig";

export class SolarCollectorTypeConfig extends BaseTypeConfig {
  _solarCollectorTypes;

  get solarCollectorTypes() {
    return this._solarCollectorTypes;
  }

  set solarCollectorTypes(value) {
    this._solarCollectorTypes = value;
  }
}
