import React from "react";
import { connect } from "react-redux";
import { getInsulation } from "../../redux/selectors";
import {
  updateCosts,
  updateInsulationByType,
  updateInsulationByTypeAndId,
} from "../../redux/actions";

import { FormattedMessage, injectIntl } from "react-intl";
import { UValueBasedElementPreparer } from "../../ui/builder/prepare/element/UValueBasedElementPreparer";
import { InsulationElement } from "../../core/model/InsulationElement";
import { InsulationElementType } from "../../constants/combo/InsulationElementType";
import { InsulationMaterialType } from "../../config/dictionary/model/InsulationMaterialType";

import { DropDownList } from "../Helpers/DropDownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import cx from "classnames";
import NumericInput from "react-numeric-input";
import { InsulationElementPreparer } from "../../ui/builder/prepare/element/InsulationElementPreparer";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { Thermoderm } from "../Helpers/Thermoderm";
import { FormatNumericInput } from "../Helpers/FormatNumericInput";

class InsulationBaloon extends HeatMaster {
  constructor(props) {
    super(props);

    this.state = {
      showBaloon: window.innerWidth >= 1024,
      show: true,
    };
    this.configurationRead(this.props.businessConfig);
  }

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  insulationList;
  doorTypeList;
  foundationsList;
  insulationElement = new InsulationElement();

  configurationRead = businessConfiguration => {
    switch (this.props.insulationName) {
      case "roof": {
        this.insulationList = this.toComboObjectList(
          businessConfiguration.dictionaryConfig.insulationMaterialTypeConfig
            .roofFlatInsulationMaterialTypes
        );
        break;
      }
      case "wall": {
        this.insulationList = this.toComboObjectList(
          businessConfiguration.dictionaryConfig.insulationMaterialTypeConfig
            .wallInsulationMaterialTypes
        );
        break;
      }
      case "floor": {
        this.insulationList = this.toComboObjectList(
          businessConfiguration.dictionaryConfig.insulationMaterialTypeConfig
            .floorInsulationMaterialTypes
        );
        break;
      }
      default:
        break;
    }
    this.doorTypeList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.doorTypeConfig.doorTypes
    );
    this.foundationsList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.foundationConfiguration
        .foundationsType
    );
    this.insulationElement = this.initInsulationElement(
      this.props.insulationElementType
    );

    this.ventilationMethodList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.ventilationTypeConfiguration
        .ventilationTypes
    );
    this.airTightnessList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.tightnessConfiguration
        .tightnessItems
    );
  };

  insulationTypeChanged = e => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );
    this.insulationElement.width = this.props.insulation[
      this.props.insulationName
    ].width;
    this.insulationElement.u0 = this.props.insulation[
      this.props.insulationName
    ].u0;
    this.insulationElement.material = e;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      e
    ).heatTransferCoefficient;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );

    this.props
      .dispatch(
        updateInsulationByType({
          id: this.props.insulationName,
          values: {
            material: e,
            width: this.props.insulation[this.props.insulationName].width,
            uValue: this.insulationElement.uValue,
            u0: this.insulationElement.u0,
            doorType: this.props.insulation[this.props.insulationName].doorType,
            foundations: this.props.insulation[this.props.insulationName]
              .foundations,
            heatTransfer: this.insulationElement.heatTransfer,
          },
        })
      )
      .then(() => {
        this.widthOrMaterialTypeChanged();
        this.props
          .dispatch(
            updateCosts({
              [this.props.insulationName +
              "Insulation_material_changed"]: false,
              [this.props.insulationName + "Insulation_work_changed"]: false,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };

  widthChanged = e => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );
    this.insulationElement.width = e;
    this.insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    this.insulationElement.u0 = this.props.insulation[
      this.props.insulationName
    ].u0;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );

    this.props
      .dispatch(
        updateInsulationByType({
          id: this.props.insulationName,
          values: {
            material: this.props.insulation[this.props.insulationName].material,
            width: e,
            uValue: this.insulationElement.uValue,
            u0: this.insulationElement.u0,
            doorType: this.props.insulation[this.props.insulationName].doorType,
            foundations: this.props.insulation[this.props.insulationName]
              .foundations,
            heatTransfer: this.insulationElement.heatTransfer,
          },
        })
      )
      .then(() => {
        this.widthOrMaterialTypeChanged();
      });
  };

  uValueChanged = e => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );

    this.insulationElement.uValue = e;
    this.insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    this.insulationElement.u0 = this.props.insulation[
      this.props.insulationName
    ].u0;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );

    this.props
      .dispatch(
        updateInsulationByType({
          id: this.props.insulationName,
          values: {
            material: this.props.insulation[this.props.insulationName].material,
            width: this.insulationElement.width,
            uValue: e,
            u0: this.insulationElement.u0,
            doorType: this.props.insulation[this.props.insulationName].doorType,
            foundations: this.props.insulation[this.props.insulationName]
              .foundations,
            heatTransfer: this.insulationElement.heatTransfer,
          },
        })
      )
      .then(() => {
        this.insulationElementChanged(
          this.insulationElement,
          this.props.insulationElementType,
          this.props.insulation.floor.foundations,
          true,
          true
        );
      });
  };

  u0ValueChanged = e => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );

    this.insulationElement.uValue = this.props.insulation[
      this.props.insulationName
    ].uValue;
    this.insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    this.insulationElement.u0 = e;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );

    this.props
      .dispatch(
        updateInsulationByType({
          id: this.props.insulationName,
          values: {
            material: this.props.insulation[this.props.insulationName].material,
            width: this.insulationElement.width,
            uValue: this.insulationElement.uValue,
            u0: this.insulationElement.u0,
            doorType: this.props.insulation[this.props.insulationName].doorType,
            foundations: this.props.insulation[this.props.insulationName]
              .foundations,
            heatTransfer: this.insulationElement.heatTransfer,
          },
        })
      )
      .then(() => {
        this.insulationElementChanged(
          this.insulationElement,
          this.props.insulationElementType,
          this.props.insulation.floor.foundations,
          true,
          true
        );
      });
  };

  widthOrMaterialTypeChanged = () => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );
    this.insulationElement.width = this.props.insulation[
      this.props.insulationName
    ].width;
    this.insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    this.insulationElement.u0 = this.props.insulation[
      this.props.insulationName
    ].u0;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );
    this.insulationElementChanged(
      this.insulationElement,
      this.props.insulationElementType,
      this.props.insulation.floor.foundations,
      true,
      true
    );
  };

  insulationElementChanged = (
    insulationElement,
    type,
    foundationsType,
    calculationRequired = true,
    writeCacheRequired = true
  ) => {
    this.props.insulationElementChanged(type);

    if (calculationRequired) {
      this.props.heatMasterController.calculate();
    }
    if (writeCacheRequired) {
      this.props.writeCache();
    }
  };

  foundationsChanged = e => {
    this.insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );
    this.insulationElement.uValue = this.props.insulation[
      this.props.insulationName
    ].uValue;
    this.insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    this.insulationElement.u0 = this.props.insulation[
      this.props.insulationName
    ].u0;
    this.insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    this.insulationElement = UValueBasedElementPreparer.prepare(
      this.insulationElement
    );

    this.props
      .dispatch(
        updateInsulationByTypeAndId({
          type: "floor",
          id: "foundations",
          value: e,
        })
      )
      .then(() => {
        this.props
          .dispatch(
            updateCosts({
              foundationType_material: undefined,
              foundationType_work: undefined,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
        this.insulationElementChanged(
          this.insulationElement,
          this.props.insulationElementType,
          this.props.insulation.floor.foundations,
          true,
          true
        );
      });
  };

  initInsulationElement = type => {
    let width = 0.0;
    if (type === InsulationElementType.WALL) {
      width = 15.0;
    } else if (type === InsulationElementType.FLOOR) {
      width = 5.0;
    } else if (type === InsulationElementType.ROOF) {
      width = 20.0;
    }
    let insulationElement = InsulationElementPreparer.prepare(
      this.props.insulationElementType
    );
    insulationElement.width = width;
    insulationElement.material = this.props.insulation[
      this.props.insulationName
    ].material;
    insulationElement.u0 = this.props.insulation[this.props.insulationName].u0;
    insulationElement.heatTransfer = InsulationMaterialType.getMaterialInsulationTypeById(
      this.props.insulation[this.props.insulationName].material
    ).heatTransferCoefficient;
    return UValueBasedElementPreparer.prepare(insulationElement);
  };

  set insulationElementType(value) {
    this.props.insulationElementType = value;
  }

  render() {
    let worse;
    let better;
    if (this.props.invalidDataOptimizer) {
      worse = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.WORSE
      );
      worse = worse.includes(true);
      better = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.BETTER
      );
      better = better.includes(true);
    }
    const floorCantBeChanged =
      this.props.insulationName === "floor" &&
      !this.props.referenceState &&
      this.props.buildingType === "current";
    return (
      <Card
        className={cx(
          "baloon insulation-baloon popover d-flex align-items-center",
          { "bs-popover-right": this.props.insulationName === "roof" },
          { "bs-popover-right": this.props.insulationName === "floor" },
          {
            "bs-popover-right bs-popover-top-right":
              this.props.insulationName === "wall",
          },
          `insulation-baloon-${this.props.insulationName}`,
          { hidden: !this.state.showBaloon },
          { warning: this.props.invalidDataOptimizer && worse },
          { success: this.props.invalidDataOptimizer && better && !worse }
        )}
      >
        <FormattedMessage id={this.props.headerText} defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={22}
                icon={this.props.icon}
              />
            </span>
          )}
        </FormattedMessage>
        <div className="arrow m-0" />
        <div className={"popover-body"}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={this.props.eventKey}
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2"}
                  iconSet={iconSet}
                  size={16}
                  icon={this.props.icon}
                />

                <FormattedMessage
                  id={this.props.headerText}
                  defaultMessage=""
                  tagName={"h3"}
                >
                  {message => <h3 className={"mb-0"}>{message}</h3>}
                </FormattedMessage>
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1", {
                  reversed: !this.state.show,
                })}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={this.props.eventKey}>
              <Card.Body>
                <div className={"row insulation-row"}>
                  <div
                    className={cx("col-12 mw-100 mb-2 mb-2 mb-lg-1 u0 d-flex")}
                  >
                    <FormattedMessage
                      id={`hm.label.insulation.${this.props.insulationName}.u0`}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => <p className={"mb-0 pr-2"}>{message}:</p>}
                    </FormattedMessage>
                    <NumericInput
                      min={0}
                      max={5.0}
                      step={0.01}
                      precision={2}
                      value={
                        this.props.insulation[this.props.insulationName].u0
                      }
                      onChange={e => this.u0ValueChanged(e)}
                      className={"insulation-input short-input"}
                      parse={e => e.replace(",", ".")}
                      format={FormatNumericInput}
                      disabled={
                        !this.props.referenceState || floorCantBeChanged
                      }
                    />
                    <a
                      href={
                        "https://www.ziemianarozdrozu.pl/dl/Ekodom/Ekodom-kalkulatory-U0.pdf"
                      }
                      className={
                        "d-flex ml-2 align-items-center justify-content-center"
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon="calculator" />
                    </a>
                  </div>
                  <div className="col-12 insulation-material">
                    <FormattedMessage
                      id={"hm.label.insulation.material.quality"}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <p className={"mb-0"}>
                          {message}
                          <br />
                          (λ=
                          {
                            this.props.insulation[this.props.insulationName]
                              .heatTransfer
                          }
                          )
                        </p>
                      )}
                    </FormattedMessage>
                    <DropDownList
                      className="insulationDropDown"
                      dataProvider={this.insulationList}
                      labelField="name"
                      selectedIndex={
                        this.props.insulation[this.props.insulationName]
                          .material
                      }
                      onChange={e => this.insulationTypeChanged(e)}
                      disabled={floorCantBeChanged}
                    />
                  </div>
                  <div className="col-6 pr-1 pt-0 insulation-thickness">
                    <FormattedMessage
                      id={"hm.dropdown.insulation.thickness"}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <p className={"mb-0 text-right"}>
                          {message}
                          <br />
                          (cm)
                        </p>
                      )}
                    </FormattedMessage>
                    <NumericInput
                      min={0}
                      max={parseInt(this.props.maximumWidth)}
                      precision={0}
                      value={
                        this.props.insulation[this.props.insulationName].width
                      }
                      onChange={e => this.widthChanged(e)}
                      className={"insulation-input"}
                      parse={e => e.replace(",", ".")}
                      format={FormatNumericInput}
                      disabled={floorCantBeChanged}
                    />
                  </div>
                  <div className="col-6 pl-1 pt-0 insulation-coefficient">
                    <FormattedMessage
                      id={"hm.dropdown.insulation.coefficient"}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <p className={"mb-0 mx-0 text-right"}>
                          {message}
                          <br />(<span>W/(m</span>
                          <sup>2</sup>*K))
                        </p>
                      )}
                    </FormattedMessage>
                    <NumericInput
                      min={0}
                      max={this.props.insulation[this.props.insulationName].u0}
                      step={0.01}
                      precision={2}
                      value={
                        this.props.insulation[this.props.insulationName].uValue
                      }
                      onChange={e => this.uValueChanged(e)}
                      className={"insulation-input"}
                      parse={e => e.replace(",", ".")}
                      format={FormatNumericInput}
                      disabled={floorCantBeChanged}
                    />
                  </div>
                </div>
                <div
                  className={cx("d-none align-items-center doors pt-1", {
                    "d-flex": this.props.insulationName === "wall",
                  })}
                >
                  <div className={"mr-2"}>
                    <FormattedMessage
                      id="hm.label.door"
                      defaultMessage=""
                      tagName={"p"}
                    />
                  </div>
                  <div>
                    <DropDownList
                      className="doorTypeDropDownList"
                      dataProvider={this.doorTypeList}
                      labelField="name"
                      selectedIndex={
                        this.props.insulation[this.props.insulationName]
                          .doorType
                      }
                      onChange={e => this.props.doorTypeChanged(e)}
                    />
                  </div>
                </div>
                <div
                  className={cx("d-none align-items-center foundations pt-1", {
                    "d-flex": this.props.insulationName === "floor",
                  })}
                >
                  <div className={"mr-2"}>
                    <FormattedMessage
                      id="hm.label.foundations"
                      defaultMessage=""
                      tagName={"p"}
                    />
                  </div>
                  <div>
                    <DropDownList
                      className="foundationsTypeDropDownList"
                      dataProvider={this.foundationsList}
                      labelField="name"
                      selectedIndex={
                        this.props.insulation[this.props.insulationName]
                          .foundations
                      }
                      onChange={e => this.foundationsChanged(e)}
                      disabled={floorCantBeChanged}
                    />
                  </div>
                </div>
                {this.props.invalidDataOptimizer && better && !worse && (
                  <Thermoderm
                    data={this.props.dataOptimizer}
                    className={"insulation-" + this.props.insulationName}
                    type={"insulation-" + this.props.insulationName}
                    writeCache={() => this.props.writeCache()}
                  />
                )}
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={this.props.message}
            placement={cx(
              { left: this.props.insulationName === "roof" },
              { left: this.props.insulationName !== "roof" }
            )}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  const insulation = getInsulation(state);
  return { insulation };
};

export default injectIntl(connect(mapStateToProps)(InsulationBaloon));
