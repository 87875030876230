import { BaseComboObject } from "./BaseComboObject";

export class HouseStandardTypeItem extends BaseComboObject {
  static valuesMap = [];
  _houseStandardTypeModel;

  constructor(id, houseStandardTypeModel) {
    super(id);
    HouseStandardTypeItem.valuesMap[id] = this;
    this._houseStandardTypeModel = houseStandardTypeModel;
  }

  static getHouseStandardTypeItemById = id => {
    return this.valuesMap[id];
  };

  get houseStandardTypeModel() {
    return this._houseStandardTypeModel;
  }

  set houseStandardTypeModel(value) {
    this._houseStandardTypeModel = value;
  }
}
