
export class InsulationElement {
   
    _width = -1;
    _uValue = -1;
    _insulationQuality = null;
    _heatTransfer;
    _material;
    _u0;

    toString = () =>"[uValue: " + this._uValue + ",width: " + this._width + ", isolationType: " + this._insulationQuality + "]";

    getMaxWidth = () => 40.0;

    get width() {
        return this._width;
    }

    set width(value) {
        this._width = value;
    }

    get uValue() {
        return this._uValue;
    }

    set uValue(value) {
        this._uValue = value;
    }

    get insulationQuality() {
        return this._insulationQuality;
    }

    set insulationQuality(value) {
        this._insulationQuality = value;
    }

    getElementType = () => -1;

    get heatTransfer() {
        return this._heatTransfer;
    }

    set heatTransfer(value) {
        this._heatTransfer = value;
    }

    get material() {
        return this._material;
    }

    set material(value) {
        this._material = value;
    }

    get u0() {
        return this._u0;
    }

    set u0(value) {
        this._u0 = value;
    }
}

