import React from "react";
import { FormattedMessage } from "react-intl";
import ThermodermHeatingRow from "./ThermodermHeatingRow";
import ThermodermHeatingRowColumn from "./ThermodermHeatingRowColumn";

export function ThermodermVentilation(props) {
  const { ventilation, className } = props;
  
  let cost = 0;
  if (ventilation.cost) {
    cost += parseInt(ventilation.costView, 10);
  }

  return (
    <div className={"thermoderm row pt-2 mt-2 " + className}>
      <h3 className={"col-12 mb-2"}>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.cost"}
          defaultMessage=""
          tagName={"span"}
        />
      </h3>
      <ThermodermHeatingRow
        key={`${ventilation.ventilation.type}`}
        item={ventilation.ventilation}
        writeCache={() => props.writeCache()}
      />
      {ventilation.tightness.show && (
        <ThermodermHeatingRowColumn
          key={`${ventilation.tightness.type}`}
          item={ventilation.tightness}
          writeCache={() => props.writeCache()}
        />
      )}
      {ventilation.co2Sensor.show && (
        <ThermodermHeatingRowColumn
          key={`${ventilation.co2Sensor.type}`}
          item={ventilation.co2Sensor}
          writeCache={() => props.writeCache()}
        />
      )}

      <div className={"col-12"}>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.investment.cost"}
          defaultMessage=""
          tagName={"span"}
        >
          {message => <span className={"cost-header"}>{message}:</span>}
        </FormattedMessage>
        <span className={"mx-1 amount-currency"}>{cost}</span>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.cost.material.unit.zl"}
          defaultMessage=""
          tagName={"span"}
        >
          {message => <span className={"cost-currency"}>{message}</span>}
        </FormattedMessage>
      </div>
    </div>
  );
}
