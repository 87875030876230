import { CompositeEnergyCalculator } from "./monthly/CompositeEnergyCalculator";
import { MonthEnergyData } from "./model/MonthEnergyData";
import { WattsEnergyResult } from "./WattsEnergyResult";
import { WallEnergyCalculator } from "./monthly/WallEnergyCalculator";
import { RoofEnergyCalculator } from "./monthly/RoofEnergyCalculator";
import { FloorEnergyCalculator } from "./monthly/FloorEnergyCalculator";
import { FoundationsEnergyCalculator } from "./monthly/FoundationsEnergyCalculator";
import { WindowsEnergyCalculator } from "./monthly/WindowsEnergyCalculator";
import { VentilationEnergyCalculator } from "./monthly/VentilationEnergyCalculator";
import { AiringEnergyCalculator } from "./monthly/AiringEnergyCalculator";
import { TightnessEnergyCalculator } from "./monthly/TightnessEnergyCalculator";
import { WarmWaterEnergyCalculator } from "./monthly/WarmWaterEnergyCalculator";
import { SolarEnergyGainCalculator } from "./monthly/SolarEnergyGainCalculator";
import { PersonEnergyGainCalculator } from "./monthly/PersonEnergyGainCalculator";
import { ElectricityDevicesEnergyGainCalculator } from "./monthly/ElectricityDevicesEnergyGainCalculator";
import { SolarCollectorEnergyGainCalculator } from "./monthly/SolarCollectorEnergyGainCalculator";
import { MonthlyEnergyResultProvider } from "./MonthlyEnergyResultProvider";
import { GaussianDistributor } from "./utils/GaussianDistributor";
import { CalcUtils } from "./utils/CalcUtils";
import { MonthInputData } from "./model/MonthInputData";
import { TemperatureCalculator } from "./utils/TemperatureCalculator";
import { BalanceValueHelper } from "./utils/BalanceValueHelper";
import { PhotovoltaicEnergyGainCalculator } from "./monthly/PhotovoltaicEnergyGainCalculator";
import { BasementEnergyCalculator } from "./monthly/BasementEnergyCalculator";
import { LivingNeedsCalculator } from "./monthly/LivingNeedsCalculator";
import { ElectricityDevicesEnergyLossCalculator } from "./monthly/ElectricityDevicesEnergyLossCalculator";

export class HeatMasterWattsCalculator {
  monthEnergyCalculator = new CompositeEnergyCalculator();

  constructor() {
    this.monthEnergyCalculator.addEnergyCalculator(new WallEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(new RoofEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(new FloorEnergyCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(
      new FoundationsEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new WindowsEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new VentilationEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new AiringEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new TightnessEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new WarmWaterEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new SolarEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new PersonEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new ElectricityDevicesEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new SolarCollectorEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new PhotovoltaicEnergyGainCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(
      new BasementEnergyCalculator()
    );
    this.monthEnergyCalculator.addEnergyCalculator(new LivingNeedsCalculator());
    this.monthEnergyCalculator.addEnergyCalculator(
      new ElectricityDevicesEnergyLossCalculator()
    );
  }

  calcEnergyDataWithGaussianBlurring = dataContext => {
    let environmentalData = dataContext.environmentalData;

    let tSigma = parseFloat(environmentalData.tSigma);

    let monthlyEnergyConsumption = [];
    for (let month = 0; month < 12; month++) {
      let tempMonth = parseFloat(environmentalData.temperatures[month]);
      let monthlyEnergyResultProvider = new MonthlyEnergyResultProvider(
        dataContext,
        month,
        this.monthEnergyCalculator
      );
      let gaussianDistribution = new GaussianDistributor(tempMonth, tSigma);
      gaussianDistribution.apply(monthlyEnergyResultProvider);

      let monthlyResult = monthlyEnergyResultProvider.getCalculatedResult();

      monthlyEnergyConsumption.push(monthlyResult);
    }

    let result = new WattsEnergyResult(monthlyEnergyConsumption);
    result.heatingSourceMaxPower = this.calcMaxHeatingPower(dataContext);
    return result;
  };

  calcMaxHeatingPower = dataContext => {
    let environmentalData = dataContext.environmentalData;
    if (isNaN(environmentalData.minCalcTemp)) {
      return Number.NaN;
    }
    let tMinCalc = environmentalData.minCalcTemp;
    let tGroundMin = TemperatureCalculator.calcMinTemperature(
      environmentalData.groundTemperatures
    );
    let insolationGround45Min = CalcUtils.minValue(
      environmentalData.insolationData.groundInsolation45
    );
    let insolationSouthMin = CalcUtils.minValue(
      environmentalData.insolationData.southInsolation
    );
    let insolationWestEastMin = CalcUtils.minValue(
      environmentalData.insolationData.westEastInsolation
    );
    let insolationNorthMin = CalcUtils.minValue(
      environmentalData.insolationData.northInsolation
    );

    let monthInput = new MonthInputData(
      tMinCalc,
      tGroundMin,
      insolationGround45Min,
      0,
      insolationSouthMin,
      insolationWestEastMin,
      insolationNorthMin
    );
    let energyData = this.monthEnergyCalculator.calcEnergy(
      new MonthEnergyData(),
      dataContext,
      monthInput
    );

    return BalanceValueHelper.calcBaseNormalizedBalanceValue(energyData);
  };
}
