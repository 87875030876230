import { OptimizerResultsItemData } from "./OptimizerResultsItemData";

export class OptimizerResultsItem {
  _state;
  _refItem = new OptimizerResultsItemData();
  _newItem = new OptimizerResultsItemData();
  _cost = 0;
  _difference = 0;
  _material = 0;
  _work = 0;
  _obsolescence;
  _service = 0;

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  get refItem() {
    return this._refItem;
  }

  set refItem(value) {
    this._refItem = value;
  }

  get newItem() {
    return this._newItem;
  }

  set newItem(value) {
    this._newItem = value;
  }

  get cost() {
    return this._cost;
  }

  set cost(value) {
    this._cost = value;
  }

  get difference() {
    return this._difference;
  }

  set difference(value) {
    this._difference = value;
  }

  get material() {
    return this._material;
  }

  set material(value) {
    this._material = value;
  }

  get work() {
    return this._work;
  }

  set work(value) {
    this._work = value;
  }

  get obsolescence() {
    return this._obsolescence;
  }

  set obsolescence(value) {
    this._obsolescence = value;
  }

  get service() {
    return this._service;
  }

  set service(value) {
    this._service = value;
  }
}
