export class OptimizerResultsItemData {
  _price;
  _work;
  _obsolescence;

  get price() {
    return this._price;
  }

  set price(value) {
    this._price = value;
  }

  get work() {
    return this._work;
  }

  set work(value) {
    this._work = value;
  }

  get obsolescence() {
    return this._obsolescence;
  }

  set obsolescence(value) {
    this._obsolescence = value;
  }
}
