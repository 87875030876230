import { CountryItem } from "./CountryItem";
import { RegionItem } from "./RegionItem";
import { GlobalValues } from "../constants/GlobalValues";
import { HouseStandardTypeConfigReader } from "./dictionary/reader/HouseStandardTypeConfigReader";

export class CountryConfigurationReader {
  countriesXML;

  countryList = null;

  resourceManager;

  polishExchangeRate;

  constructor(countriesXML, resManager = null) {
    this.countriesXML = countriesXML;
    this.resourceManager = resManager;
  }

  readConfig = () => {
    if (this.countryList == null) {
      try {
        this.countryList = this.doReadConfigHierarchical();
      } catch (e) {}
    }
    return this.countryList;
  };

  doReadConfigHierarchical = () => {
    let result = [];

    for (let i = 0; i < this.countriesXML.countries.country.length; i++) {
      const country = this.countriesXML.countries.country[i];

      const countryCode = country.code;

      const electricityPrice = this.getPrice(
        country.prices.electricity,
        GlobalValues.PRICE_ELECTRICITY_KWH_DEFAULT
      );
      const gasPrice = this.getPrice(
        country.prices.gas,
        GlobalValues.PRICE_ELECTRICITY_KWH_DEFAULT
      );
      const heatingOilPrice = this.getPrice(
        country.prices.heating_oil,
        GlobalValues.PRICE_OIL_KWH_DEFAULT
      );
      const coalPrice = this.getPrice(
        country.prices.coal,
        GlobalValues.PRICE_COAL_KWH_DEFAULT
      );
      const pelletsPrice = this.getPrice(
        country.prices.pellets,
        GlobalValues.PRICE_PELLETS_KWH_DEFAULT
      );
      const woodChipsPrice = this.getPrice(
        country.prices.wood_chips,
        GlobalValues.PRICE_WOOD_CHIPS_KWH_DEFAULT
      );
      const firewoodPrice = this.getPrice(
        country.prices.firewood,
        GlobalValues.PRICE_WOOD_KWH_DEFAULT
      );
      const combinedPrice =
        country.prices.firewood != null ? country.prices.combined : NaN;

      let countryItem = new CountryItem();
      countryItem.electricityPrice = electricityPrice;
      countryItem.gasPrice = gasPrice;
      countryItem.heatingOilPrice = heatingOilPrice;
      countryItem.coalPrice = coalPrice;
      countryItem.woodChipsPrice = woodChipsPrice;
      countryItem.firewoodPrice = firewoodPrice;
      countryItem.pelletsPrice = pelletsPrice;
      countryItem.combinedPrice = isNaN(combinedPrice)
        ? 0.9 * gasPrice
        : combinedPrice;

      const currencyLocaleCode = country.currency_name;
      const exchangeRate = country.exchange_rate;

      if (countryCode === "pl") {
        this.polishExchangeRate = exchangeRate;
      }

      countryItem.currencyLocaleCode = currencyLocaleCode;
      countryItem.currencyExchangeRate = exchangeRate;

      countryItem.localeCode = country.name;
      if (this.resourceManager != null) {
        countryItem.name = this.resourceManager[country.name];
        countryItem.currencyName = this.resourceManager[
          countryItem.currencyLocaleCode
        ];
      }

      countryItem.code = countryCode;
      if (country.regions.region.length === 1) {
        let mainRegion = country.regions.region[0];
        this.fillCountryEnvironmentalData(countryItem, mainRegion);
        countryItem.noRegions = true;
      } else {
        for (let i = 0; i < country.regions.region.length; i++) {
          const region = country.regions.region[i];
          const regionCode = region.code;
          const localeCode = region.name;
          const code = countryCode + "_" + regionCode;
          let name = localeCode;

          if (this.resourceManager != null) {
            name = this.resourceManager[localeCode];
            if (name == null || name === "") {
              name = region.name;
            }
          }

          let regionItem = new RegionItem();
          regionItem.regionCode = regionCode;
          regionItem.localeCode = localeCode;
          regionItem.code = code;
          regionItem.name = name;

          this.fillRegionEnvironmentalData(regionItem, region);

          regionItem.country = countryItem;
          countryItem.regions.push(regionItem);
        }
      }
      if (
        typeof country.standard_type_configuration !== "undefined" &&
        country.standard_type_configuration.length > 1
      ) {
        let dictionaryConfig = {};
        this.houseStandardConfigurationReader = new HouseStandardTypeConfigReader();
        countryItem.standardTypeConfiguration = this.houseStandardConfigurationReader.readXMLConfig(
          dictionaryConfig,
          country
        ).houseStandardTypeConfiguration._houseStandardTypes;
      }
      result.push(countryItem);
    }
    return result;
  };

  getEuroToPLNExchangeRate = () => {
    return this.polishExchangeRate;
  };

  fillCountryEnvironmentalData = (countryItem, regionXML) => {
    let temperatures = new Array(12);
    let groundTemperatures = new Array(12);
    let insolationS = new Array(12);
    let insolationWE = new Array(12);
    let insolationN = new Array(12);
    let optimalInsolation = new Array(12);

    for (let k = 0; k < 12; k++) {
      temperatures[k] = regionXML.temperatures.t[k] + 1.5;
      groundTemperatures[k] = regionXML.temperaturesGround.t[k];
      optimalInsolation[k] = regionXML.insolation.optimal.i[k];
      insolationS[k] = regionXML.insolation.south.i[k];
      insolationWE[k] = regionXML.insolation.west_east.i[k];
      insolationN[k] = regionXML.insolation.north.i[k];
    }

    countryItem.temperatures = temperatures;
    countryItem.groundTemperatures = groundTemperatures;
    countryItem.optimalInsolation = optimalInsolation;
    countryItem.insolationS = insolationS;
    countryItem.insolationN = insolationN;
    countryItem.insolationWE = insolationWE;
  };

  fillRegionEnvironmentalData = (regionItem, regionXML) => {
    let temperatures = new Array(12);
    let groundTemperatures = new Array(12);
    let insolationS = new Array(12);
    let insolationWE = new Array(12);
    let insolationN = new Array(12);
    let optimalInsolation = new Array(12);
    for (let k = 0; k < 12; k++) {
      temperatures[k] = regionXML.temperatures.t[k];
      groundTemperatures[k] = regionXML.temperaturesGround.t[k];
      optimalInsolation[k] = regionXML.insolation.optimal.i[k];
      insolationS[k] = regionXML.insolation.south.i[k];
      insolationWE[k] = regionXML.insolation.west_east.i[k];
      insolationN[k] = regionXML.insolation.north.i[k];
    }
    regionItem.temperatures = temperatures;
    regionItem.groundTemperatures = groundTemperatures;
    regionItem.optimalInsolation = optimalInsolation;
    regionItem.insolationS = insolationS;
    regionItem.insolationN = insolationN;
    regionItem.insolationWE = insolationWE;
  };

  getPrice = (xmlPrice, defaultPrice) => {
    if (xmlPrice == null || xmlPrice.length === 0) {
      return defaultPrice;
    }
    return xmlPrice;
  };

  getNumberValue = str => {
    str = str.replace(new RegExp(",", "g"), ".");
    return str;
  };

  isNotEmpty = s => {
    if (s == null || s.trim()) {
      return false;
    }
    let trimmed = s.trim();
    for (let i = 0; i < trimmed.length; i++) {
      if (!trimmed.charAt(i)) {
        return true;
      }
    }
    return false;
  };
}
