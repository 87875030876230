import React from "react";
import { FormattedHTMLMessage } from "react-intl";

export function HouseSpecsTableTd(props) {
  return (
    <td colSpan={props.colSpan ? props.colSpan : 1}>
      {props.message && (
        <FormattedHTMLMessage id={props.message} defaultMessage="" />
      )}
      {props.value}
    </td>
  );
}
