import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import { Popup } from "../Helpers/Popup";
import cx from "classnames";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { ShadowLevel } from "../Helpers/ShadowLevel";
import { Trees } from "../Helpers/Trees";
import { updateInsolation } from "../../redux/actions";
import { getInsolation } from "../../redux/selectors";

class InsolationBaloon extends HeatMaster {
  constructor(props) {
    super(props);
    this.state = {
      showBaloon: window.innerWidth >= 1024,
    };
  }
  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  countChecked() {
    return (
      (this.props.insolation.blinds ? 1 : 0) +
        (this.props.insolation.curtains ? 1 : 0) +
        (this.props.insolation.awnings ? 1 : 0) +
        (this.props.insolation.buildings ? 1 : 0) +
        (this.props.insolation.trees_coniferous ? 1 : 0) +
        (this.props.insolation.trees_leafy ? 1 : 0) +
        (this.props.insolation.trees_mixed ? 1 : 0) >=
      3
    );
  }
  countTreesChecked = () => {
    return (
      (this.props.insolation.trees_coniferous ? 1 : 0) +
        (this.props.insolation.trees_leafy ? 1 : 0) +
        (this.props.insolation.trees_mixed ? 1 : 0) >=
      1
    );
  };
  shadingChanged = e => {
    this.props
      .dispatch(
        updateInsolation({
          data: {
            shading: e,
            blinds: false,
            curtains: false,
            awnings: false,
            buildings: false,
            trees_coniferous: false,
            trees_leafy: false,
            trees_mixed: false,
          },
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  render() {
    return (
      <Card
        className={cx(
          "baloon  popover insolation-baloon bs-popover-left d-flex align-items-center",
          { hidden: !this.state.showBaloon }
        )}
      >
        <FormattedMessage id={"hm.label.insolation"} defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact iconSet={iconSet} size={22} icon="sun_icon" />
            </span>
          )}
        </FormattedMessage>

        <div className="arrow m-0" />
        <div className={"popover-body"}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="13"
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2 "}
                  iconSet={iconSet}
                  size={16}
                  icon={"sun_icon"}
                />
                <FormattedMessage id={"hm.label.insolation"} defaultMessage="">
                  {message => (
                    <h3 className={"mb-0 px-0 col-10"}> {message}</h3>
                  )}
                </FormattedMessage>
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1")}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="13">
              <Card.Body>
                <div className={"mb-1"}>
                  <div
                    className={
                      "d-flex align-items-center justify-content-between"
                    }
                  >
                    <label className={"mb-0 d-flex align-items-center mr-3"}>
                      <FormattedMessage id="hm.label.shading" defaultMessage="">
                        {message => <p className={"mb-0 mr-1"}>{message}</p>}
                      </FormattedMessage>
                      <input
                        type="checkbox"
                        name={"shading"}
                        checked={this.props.insolation.shading}
                        onChange={e => this.shadingChanged(e.target.checked)}
                      />
                    </label>
                  </div>
                </div>
                <div
                  className={cx("row", {
                    "d-none": !this.props.insolation.shading,
                  })}
                >
                  <div className={"col-12"}>
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"blinds"}
                        disabled={
                          !this.props.insolation.blinds && this.countChecked()
                        }
                        checked={this.props.insolation.blinds}
                        onChange={e =>
                          this.props.insolationChanged(
                            "blinds",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.blinds"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"curtains"}
                        disabled={
                          !this.props.insolation.curtains && this.countChecked()
                        }
                        checked={this.props.insolation.curtains}
                        onChange={e =>
                          this.props.insolationChanged(
                            "curtains",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.curtains"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"awnings"}
                        disabled={
                          !this.props.insolation.awnings && this.countChecked()
                        }
                        checked={this.props.insolation.awnings}
                        onChange={e =>
                          this.props.insolationChanged(
                            "awnings",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.awnings"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>

                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"buildings"}
                        disabled={
                          !this.props.insolation.buildings &&
                          this.countChecked()
                        }
                        checked={this.props.insolation.buildings}
                        onChange={e =>
                          this.props.insolationChanged(
                            "buildings",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.buildings"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <div
                      className={cx("row align-items-center mb-1", {
                        "d-none": !this.props.insolation.buildings,
                      })}
                    >
                      <div className={"offset-1 col-4 pr-0"}>
                        <FormattedMessage
                          id="hm.label.shading.period"
                          defaultMessage=""
                        >
                          {message => (
                            <p className={"mb-0 lh-100"}>{message}</p>
                          )}
                        </FormattedMessage>
                      </div>
                      <div className={"col-7"}>
                        <select
                          className={"form-control"}
                          onChange={e =>
                            this.props.insolationChanged(
                              "buildings_period",
                              e.target.value
                            )
                          }
                          value={this.props.insolation.buildings_period}
                        >
                          <FormattedMessage
                            id="hm.label.shading.period.year"
                            defaultMessage=""
                          >
                            {message => (
                              <option
                                key={"shading-period-year"}
                                value={"year"}
                              >
                                {message}
                              </option>
                            )}
                          </FormattedMessage>
                          <FormattedMessage
                            id="hm.label.shading.period.winter"
                            defaultMessage=""
                          >
                            {message => (
                              <option
                                key={"shading-period-winter"}
                                value={"winter"}
                              >
                                {message}
                              </option>
                            )}
                          </FormattedMessage>
                        </select>
                      </div>
                    </div>
                    <div className={"row"}>
                      <ShadowLevel
                        showDetailedData={this.props.insolation.buildings}
                        insolationChanged={(id, val) =>
                          this.props.insolationChanged(id, val)
                        }
                        id={"buildings"}
                        valueLevel={this.props.insolation.buildings_level}
                      />
                    </div>
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"trees_coniferous"}
                        disabled={
                          (!this.props.insolation.trees_coniferous &&
                            this.countChecked()) ||
                          (!this.props.insolation.trees_coniferous &&
                            this.countTreesChecked())
                        }
                        checked={this.props.insolation.trees_coniferous}
                        onChange={e =>
                          this.props.insolationChanged(
                            "trees_coniferous",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.trees.coniferous"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <Trees
                      showDetailedData={this.props.insolation.trees_coniferous}
                      insolationChanged={(id, val) =>
                        this.props.insolationChanged(id, val)
                      }
                      id={"trees_coniferous"}
                      valueLevel={this.props.insolation.trees_coniferous_level}
                      valueDistance={
                        this.props.insolation.trees_coniferous_period
                      }
                    />
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"trees_leafy"}
                        disabled={
                          (!this.props.insolation.trees_leafy &&
                            this.countChecked()) ||
                          (!this.props.insolation.trees_leafy &&
                            this.countTreesChecked())
                        }
                        checked={this.props.insolation.trees_leafy}
                        onChange={e =>
                          this.props.insolationChanged(
                            "trees_leafy",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.trees.leafy"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <Trees
                      showDetailedData={this.props.insolation.trees_leafy}
                      insolationChanged={(id, val) =>
                        this.props.insolationChanged(id, val)
                      }
                      id={"trees_leafy"}
                      valueLevel={this.props.insolation.trees_leafy_level}
                      valueDistance={this.props.insolation.trees_leafy_period}
                    />
                    <label className={"mb-1 d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        name={"trees_mixed"}
                        disabled={
                          (!this.props.insolation.trees_mixed &&
                            this.countChecked()) ||
                          (!this.props.insolation.trees_mixed &&
                            this.countTreesChecked())
                        }
                        checked={this.props.insolation.trees_mixed}
                        onChange={e =>
                          this.props.insolationChanged(
                            "trees_mixed",
                            e.target.checked
                          )
                        }
                      />
                      <FormattedMessage
                        id="hm.label.shading.trees.mixed"
                        defaultMessage=""
                      >
                        {message => <p className={"mb-0 ml-1"}>{message}</p>}
                      </FormattedMessage>
                    </label>
                    <Trees
                      showDetailedData={this.props.insolation.trees_mixed}
                      insolationChanged={(id, val) =>
                        this.props.insolationChanged(id, val)
                      }
                      valueLevel={this.props.insolation.trees_mixed_level}
                      valueDistance={this.props.insolation.trees_mixed_period}
                      id={"trees_mixed"}
                    />
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={"hm.tooltip.insolation"}
            placement={"right"}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  let insolation = getInsolation(state);
  return { insolation };
};

export default injectIntl(connect(mapStateToProps)(InsolationBaloon));
