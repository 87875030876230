import { BusinessConfiguration } from "./BusinessConfiguration";
import { DictionaryConfigReader } from "./dictionary/DictionaryConfigReader";
import { CountryStationConfigurationReader } from "./CountryStationConfigurationReader";
import { CountryItemHelper } from "./CountryItemHelper";

export class BusinessConfigurationReader {
  dictionaryConfigurationReader;
  countryStationConfigurationReader;

  constructor(countryXML, stationsXML, dictionaryXML, resManager) {
    this.countryStationConfigurationReader = new CountryStationConfigurationReader(
      countryXML,
      stationsXML,
      resManager
    );
    this.dictionaryConfigurationReader = new DictionaryConfigReader(
      dictionaryXML,
      resManager
    );
  }

  readBusinessConfiguration = () => {
    let countryItems = this.countryStationConfigurationReader.readConfig();
    let dictionaryConfig = this.dictionaryConfigurationReader.readConfig();
    let businessConfiguration = new BusinessConfiguration();
    businessConfiguration.countryItems = countryItems;
    businessConfiguration.dictionaryConfig = dictionaryConfig;
    businessConfiguration.polishExchangeRate = this.countryStationConfigurationReader.getEuroToPLNExchangeRate();

    let countryRegionCode = CountryItemHelper.getInitialConfigItemCode();
    let regionItem = CountryItemHelper.getRegionByCodeFromCountryList(
      countryItems,
      countryRegionCode
    );
    let countryItem =
      regionItem != null
        ? regionItem.country
        : CountryItemHelper.getCountryByCode(countryItems, countryRegionCode);

    businessConfiguration.countryRegionCode = countryRegionCode;
    businessConfiguration.regionItem = regionItem;
    businessConfiguration.countryItem = countryItem;

    return businessConfiguration;
  };
}
