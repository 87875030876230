import { BaseComboObject } from "./BaseComboObject";

export class ThermalBridgesType extends BaseComboObject {
  static valuesMap = [];

  _uValue;
  _max;

  constructor(id, uValue, max = false) {
    super(id);
    this._uValue = uValue;
    this._max = max;
    ThermalBridgesType.valuesMap[id] = this;
  }

  static getThermalBridgesTypeById = id => {
    return ThermalBridgesType.valuesMap[id];
  };

  get uValue() {
    return this._uValue;
  }

  get max() {
    return this._max;
  }
}
