export class InsolationData {

    _southInsolation;
    _westEastInsolation;
    _northInsolation;

    _groundInsolation45;
    _groundInsolationSum;


    get southInsolation() {
        return this._southInsolation;
    }

    set southInsolation(value) {
        this._southInsolation = value;
    }

    get westEastInsolation() {
        return this._westEastInsolation;
    }

    set westEastInsolation(value) {
        this._westEastInsolation = value;
    }

    get northInsolation() {
        return this._northInsolation;
    }

    set northInsolation(value) {
        this._northInsolation = value;
    }

    get groundInsolation45() {
        return this._groundInsolation45;
    }

    set groundInsolation45(value) {
        this._groundInsolation45 = value;
    }

    get groundInsolationSum() {
        return this._groundInsolationSum;
    }

    set groundInsolationSum(value) {
        this._groundInsolationSum = value;
    }
}