import { OptimizerResultsState } from "../OptimizerResultsState";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";

export class HeatingSourceOptimizer {
  constructor(
    isInReferenceState,
    dictionary,
    buildingType,
    refPricePerKWh,
    newPricePerKWh
  ) {
    this.elementList = dictionary;
    this.buildingType = buildingType;
    this.refPricePerKWh = refPricePerKWh;
    this.newPricePerKWh = newPricePerKWh;
    this.isInReferenceState = isInReferenceState;
  }

  elementList;

  validate = (
    type,
    result,
    element,
    newElement,
    houseData,
    newHouseData,
    ifWarmWater,
    ifNewWarmWater,
    maxPower,
    newMaxPower,
    energyData,
    newEnergyData,
    surface,
    refCosts,
    newCosts
  ) => {
    const warmWaterData = houseData.warmWaterData;
    const newWarmWaterData = newHouseData.warmWaterData;
    const isBlock = HouseTypeHelper.isBlockType(houseData.houseType);
    const isGas =
      element.heatingFuel === "hm.dropdown.heating.fuel.metan" ||
      element.waterHeatingFuel === "hm.dropdown.heating.fuel.propan";
    const isGasNew =
      newElement.heatingFuel === "hm.dropdown.heating.fuel.metan" ||
      newElement.waterHeatingFuel === "hm.dropdown.heating.fuel.propan";
    const isHeatingNetwork =
      element.heatingFuel === "hm.dropdown.heating.fuel.combined_electric_heat";
    const isHeatingNetworkNew =
      newElement.heatingFuel ===
      "hm.dropdown.heating.fuel.combined_electric_heat";
    
    result.state = OptimizerResultsState.THE_SAME;

    let oldDevice = "";
    let newDevice = "";
    if (typeof element.heatingType !== "undefined") {
      oldDevice = this.elementList.filter(
        item => item.name === element.heatingType
      )[0];
    } else {
      oldDevice = this.elementList.filter(
        item => item.name === element.heatingSource
      )[0];
    }

    if (typeof newElement.heatingType !== "undefined") {
      newDevice = this.elementList.filter(
        item => item.name === newElement.heatingType
      )[0];
    } else {
      newDevice = this.elementList.filter(
        item => item.name === newElement.heatingSource
      )[0];
    }

    let devPrice = 0;
    let newDevPrice = 0;
    let chimney = 0;
    let storeroom = 0;
    let systemPrice = 0;

    element = {
      ...element,
      ...warmWaterData,
      labelKey: element.heatingSource,
    };
    newElement = {
      ...newElement,
      ...newWarmWaterData,
      labelKey: newElement.heatingSource,
    };
    
    if (typeof oldDevice !== "undefined") {
      let system = false;
      if (typeof element.heatingSystem !== "undefined") {
        system = element.heatingSystem.split(".");
        system = system[system.length - 1];
      }
      if (typeof oldDevice[system] !== "undefined") {
        systemPrice = oldDevice[system];
      }

      let dev = 0;
      let work = 0;
      if (typeof oldDevice.storeroom !== "undefined") {
        storeroom = oldDevice.storeroom;
      }
      if (typeof oldDevice.chimney !== "undefined") {
        chimney = oldDevice.chimney;
      }
      if (typeof oldDevice.versions !== "undefined") {
        devPrice = this.selectProperDevice(
          ifWarmWater,
          element,
          element._waterHeatingSource,
          maxPower,
          oldDevice
        );
        dev = devPrice.dev;
        work = devPrice.work;
      }

      element = {
        ...element,
        price: dev,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        work: work,
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        pricePerKWh: this.refPricePerKWh,
        
        obsolescence: 25,
        chimney: chimney > 0,
        chimneyCost: chimney,
        storeroom: storeroom > 0,
        storeroomCost: storeroom,
        system: systemPrice > 0 ? system : false,
        systemPrice: systemPrice,
        
        gasTerminal: !isBlock && isGas,
        gasTerminalCost: !isBlock && isGas ? 10000 : 0,
        heatingNetwork: !isBlock && isHeatingNetwork,
        heatingNetworkCost: !isBlock && isHeatingNetwork ? 10000 : 0,
      };
    }
    chimney = 0;
    storeroom = 0;
    systemPrice = 0;
    if (typeof newDevice !== "undefined") {
      let system = false;
      if (typeof newElement.heatingSystem !== "undefined") {
        system = newElement.heatingSystem.split(".");
        system = system[system.length - 1];
      }
      if (typeof newDevice.chimney !== "undefined") {
        
        chimney = newDevice.chimney;
      }
      if (typeof newDevice.storeroom !== "undefined") {
        storeroom = newDevice.storeroom;
      }
      if (typeof newDevice[system] !== "undefined") {
        systemPrice = newDevice[system];
      }
      let dev = 0;
      let work = 0;
      if (typeof newDevice.versions !== "undefined") {
        
        newDevPrice = this.selectProperDevice(
          ifNewWarmWater,
          newElement,
          newElement._waterHeatingSource,
          newMaxPower,
          newDevice
        );
        dev = newDevPrice.dev;
        work = newDevPrice.work;
      }

      newElement = {
        ...newElement,
        price: dev,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        work: work,
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        pricePerKWh: this.newPricePerKWh,
        
        obsolescence: 25,
        chimney: chimney > 0,
        chimneyCost: chimney,
        storeroom: storeroom > 0,
        storeroomCost: storeroom,
        system: systemPrice > 0 ? system : false,
        systemPrice: systemPrice,
        
        gasTerminal: !isBlock && isGasNew,
        gasTerminalCost: !isBlock && isGasNew ? 10000 : 0,
        heatingNetwork: !isBlock && isHeatingNetworkNew,
        heatingNetworkCost: !isBlock && isHeatingNetworkNew ? 10000 : 0,
      };
    }

    const waterDevice = this.selectProperWaterDevice(
      true,
      element._waterHeatingSource
    );
    let newWaterDevice = this.selectProperWaterDevice(
      false,
      newElement._waterHeatingSource
    );

    if (element._waterHeatingSource === newElement._waterHeatingSource) {
      newWaterDevice = { dev: 0, work: 0 };
    }

    

    
    result.refItem = element;
    result.newItem = newElement;
    result.obsolescence = newElement.obsolescence;

    if (element.heatingEfficiency > newElement.heatingEfficiency) {
      result.state = OptimizerResultsState.WORSE;
    }
    result.state = OptimizerResultsState.BETTER;

    let materialCost = result.refItem.price;
    let newMaterialCost = result.newItem.price;
    let workCost = result.refItem.work;
    let newWorkCost = result.newItem.work;

    let waterMaterialCost = waterDevice.dev;
    let newWaterMaterialCost = newWaterDevice.dev;
    let waterWorkCost = waterDevice.work;
    let newWaterWorkCost = newWaterDevice.work;

    
    
    if (refCosts[type + "_material_changed"]) {
      materialCost = refCosts[type + "_material"];
    }
    if (newCosts[type + "_material_changed"]) {
      newMaterialCost = newCosts[type + "_material"];
    }
    if (refCosts[type + "_work_changed"]) {
      workCost = refCosts[type + "_work"];
    }
    if (newCosts[type + "_work_changed"]) {
      newWorkCost = newCosts[type + "_work"];
    }

    
    
    if (refCosts["waterHeatingSource_material_changed"]) {
      waterMaterialCost = refCosts["waterHeatingSource_material"];
    }
    if (newCosts["waterHeatingSource_material_changed"]) {
      newWaterMaterialCost = newCosts["waterHeatingSource_material"];
    }
    if (refCosts["waterHeatingSource_work_changed"]) {
      waterWorkCost = refCosts["waterHeatingSource_work"];
    }
    if (newCosts["waterHeatingSource_work_changed"]) {
      newWaterWorkCost = newCosts["waterHeatingSource_work"];
    }
    let storeroomCost = 0;
    let storeroomCostNew = 0;
    if (element._heatingType !== newElement._heatingType) {
      storeroomCost = result.refItem.storeroomCost;
      storeroomCostNew = result.newItem.storeroomCost;

      if (refCosts["storeroom_changed"]) {
        storeroomCost = refCosts["storeroom"];
      }
      if (newCosts["storeroom_changed"]) {
        storeroomCostNew = newCosts["storeroom"];
      }

    }
    let waterSavingsMaterialCost = 0;
    let waterSavingsWorkCost = 0;
    
    
    if (newElement._waterSavings) {
      waterSavingsMaterialCost = this.buildingType === "current" ? 1200 : 600;
      waterSavingsWorkCost = this.buildingType === "current" ? 150 : 0;

      
      if (newCosts["waterSavings_material_changed"]) {
        waterSavingsMaterialCost = newCosts["waterSavings_material"];
      }
      if (newCosts["waterSavings_work_changed"]) {
        waterSavingsWorkCost = newCosts["waterSavings_work"];
      }
      
    }
    let chimneyCost = 0;
    let chimneyCostNew = 0;
    if (element._heatingType !== newElement._heatingType) {
      chimneyCost = result.refItem.chimneyCost;
      chimneyCostNew = result.newItem.chimneyCost;

      if (refCosts["chimney_changed"]) {
        chimneyCost = refCosts["chimney"];
      }
      if (newCosts["chimney_changed"]) {
        chimneyCostNew = newCosts["chimney"];
      }
    }

    let systemCost = result.refItem.systemPrice;
    let systemCostNew = result.newItem.systemPrice;

    if (refCosts["system_changed"]) {
      systemCost = refCosts["system"];
    }
    if (newCosts["system_changed"]) {
      systemCostNew = newCosts["system"];
    }

    let gasTerminalCost = result.refItem.gasTerminalCost;
    let gasTerminalCostNew = result.newItem.gasTerminalCost;

    if (refCosts["gasTerminal_changed"]) {
      gasTerminalCost = refCosts["gasTerminal"];
    }
    if (newCosts["gasTerminal_changed"]) {
      gasTerminalCostNew = newCosts["gasTerminal"];
    }

    let heatingNetwork = result.refItem.heatingNetworkCost;
    let heatingNetworkNew = result.newItem.heatingNetworkCost;

    if (refCosts["heatingNetwork_changed"]) {
      heatingNetwork = refCosts["heatingNetwork"];
    }
    if (newCosts["heatingNetwork_changed"]) {
      heatingNetworkNew = newCosts["heatingNetwork"];
    }

    let storageCost =
      warmWaterData.warmWaterStorage.id === "hm.water-storage.modern_storage"
        ? 800
        : 0;
    let storageCostNew =
      newWarmWaterData.warmWaterStorage.id === "hm.water-storage.modern_storage"
        ? 800
        : 0;

    if (refCosts["storage_changed"]) {
      storageCost = refCosts["storage"];
    }
    if (newCosts["storage_changed"]) {
      storageCostNew = newCosts["storage"];
    }

    
    result.workOld = workCost + waterWorkCost;
    result.workNew = newWorkCost + newWaterWorkCost + waterSavingsWorkCost;
    result.work =
      this.buildingType === "current"
        ? result.workNew
        : result.workNew - result.workOld;
    result.materialOld =
      materialCost +
      waterMaterialCost +
      chimneyCost +
      storeroomCost +
      systemCost * surface +
      storageCost +
      gasTerminalCost +
      heatingNetwork;
    result.materialNew =
      newMaterialCost +
      newWaterMaterialCost +
      chimneyCostNew +
      storeroomCostNew +
      systemCostNew * surface +
      storageCostNew +
      gasTerminalCostNew +
      heatingNetworkNew +
      waterSavingsMaterialCost;
    result.material =
      this.buildingType === "current"
        ? result.materialNew
        : result.materialNew - result.materialOld;

    result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
    result.materialPrice = this.isInReferenceState
      ? materialCost
      : newMaterialCost;
    result.waterWorkPrice = this.isInReferenceState
      ? waterWorkCost
      : newWaterWorkCost;
    result.waterMaterialPrice = this.isInReferenceState
      ? waterMaterialCost
      : newWaterMaterialCost;

    result.system = {
      type: "system",
      show: this.isInReferenceState
        ? result.refItem.system
        : result.newItem.system,
      state: result.state,
      materialPrice: this.isInReferenceState ? systemCost : systemCostNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zlm2",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.storage = {
      type: "storage",
      show: this.isInReferenceState
        ? warmWaterData.warmWaterStorage.id ===
          "hm.water-storage.modern_storage"
        : newWarmWaterData.warmWaterStorage.id ===
          "hm.water-storage.modern_storage",
      state: result.state,
      materialPrice: this.isInReferenceState ? storageCost : storageCostNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.storeroom = {
      type: "storeroom",
      show: this.isInReferenceState
        ? result.refItem.storeroom
        : result.newItem.storeroom,
      state: result.state,
      materialPrice: this.isInReferenceState ? storeroomCost : storeroomCostNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.waterSavings = {
      type: "waterSavings",
      show: this.isInReferenceState
        ? result.refItem._waterSavings
        : result.newItem._waterSavings,
      state: result.state,
      materialPrice: this.isInReferenceState ? 0 : waterSavingsMaterialCost,
      workPrice: this.isInReferenceState ? 0 : waterSavingsWorkCost,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.chimney = {
      type: "chimney",
      show: this.isInReferenceState
        ? result.refItem.chimney
        : result.newItem.chimney,
      state: result.state,
      materialPrice: this.isInReferenceState ? chimneyCost : chimneyCostNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.gasTerminal = {
      type: "gasTerminal",
      show: this.isInReferenceState
        ? result.refItem.gasTerminal
        : result.newItem.gasTerminal,
      state: result.state,
      materialPrice: this.isInReferenceState
        ? gasTerminalCost
        : gasTerminalCostNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.heatingNetwork = {
      type: "heatingNetwork",
      show: this.isInReferenceState
        ? result.refItem.heatingNetwork
        : result.newItem.heatingNetwork,
      state: result.state,
      materialPrice: this.isInReferenceState
        ? heatingNetwork
        : heatingNetworkNew,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };
    result.heatingSource = {
      type: "heatingSource",
      state: result.state,
      workPrice: result.workPrice,
      materialPrice: result.materialPrice,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };

    result.waterHeatingSource = {
      type: "waterHeatingSource",
      state: result.state,
      workPrice: result.waterWorkPrice,
      materialPrice: result.waterMaterialPrice,
      priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
    };

    result.obsolescence = result.newItem.obsolescence;
    result.service =
      this.buildingType === "current" ? result.material * 0.05 : 0;
    result.service = 0;
    result.costView = this.isInReferenceState
      ? result.materialOld + result.workOld + result.service
      : result.materialNew + result.workNew;
    result.cost =
      this.buildingType === "current"
        ? result.material + result.work + result.service
        : result.material + result.work;
    
    if (
      isNaN(result.cost) ||
      (result.cost === 0 && result.material === 0 && result.work === 0)
    ) {
      result.cost = 0;
      result.material = 0;
      result.work = 0;
      result.service = 0;
      result.obsolescence = 0;
      result.state = OptimizerResultsState.WORSE;
    }
    if (
      element.labelKey === newElement.labelKey &&
      element._heatingType === newElement._heatingType &&
      element._heatingFuel === newElement._heatingFuel &&
      element._waterHeatingSource === newElement._waterHeatingSource &&
      element._heatingSystem === newElement._heatingSystem
    ) {
      result.cost = 0;
      result.material = 0;
      result.work = 0;
      result.service = 0;
      result.obsolescence = 0;
      result.state = OptimizerResultsState.THE_SAME;
      if (element._waterSavings !== newElement._waterSavings) {
        result.cost = waterSavingsWorkCost + waterSavingsMaterialCost;
        result.material = waterSavingsMaterialCost;
        result.work = waterSavingsWorkCost;
        result.state = OptimizerResultsState.BETTER;
      }
    }

    return result;
  };

  calculateCosts = (price, work) => {
    return price + work;
  };

  calculateDifference = (price, newPrice, work, newWork, surface) => {
    return newPrice - price + (newWork - work);
  };

  calculateMaterial = (price, work, surface) => {
    return price;
  };

  calculateMaterialDifference = (price, work, surface) => {
    return price;
  };

  calculateWork = (price, work, surface) => {
    return work;
  };

  calculateWorkDifference = (price, newPrice) => {
    return newPrice - price;
  };

  selectProperDevice = (
    ifWarmWater,
    heatingDevice,
    waterHeatingSource,
    maxPower,
    devicePricesList
  ) => {
    let properPrice = false;
    
    if (typeof devicePricesList.versions !== "undefined") {
      for (let i = 0; i < devicePricesList.versions.length; i++) {
        const version = devicePricesList.versions[i];
        
        if (
          waterHeatingSource === "hm.dropdown.heating.source.the_same"
          
        ) {
          
          
          if (version.power >= maxPower / 1000) {
            
            if (typeof version.both !== "undefined") {
              properPrice = version.both;
              break;
            }
          }
        } else {
          
          
          if (version.power >= maxPower / 1000) {
            
            if (typeof version.heat !== "undefined") {
              properPrice = version.heat;
              break;
            }
          }
        }
      }
    }
    return properPrice;
  };

  selectProperWaterDevice = (isOld, waterHeatingSource) => {
    let properPrice = false;
    if (waterHeatingSource === "hm.dropdown.heating.source.the_same") {
      properPrice = {
        dev: 0,
        work: 0,
      };
    } else if (
      waterHeatingSource ===
      "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"
    ) {
      properPrice = {
        dev: 3000,
        work: 1000,
      };
    } else if (
      waterHeatingSource === "hm.dropdown.heating.source.electricity.water"
    ) {
      properPrice = {
        dev: 60,
        work: 150,
      };
    } else if (
      waterHeatingSource ===
      "hm.dropdown.heating.source.boiler_gas.non_condensed"
    ) {
      if (isOld) {
        properPrice = {
          dev: 0,
          work: 0,
        };
      }
    } else if (
      waterHeatingSource === "hm.dropdown.heating.source.heat_pump.cwu"
    ) {
      properPrice = {
        dev: 8000,
        work: 1200,
        service: 8000 * 0.05,
      };
    }
    return properPrice;
  };
}
