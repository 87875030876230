import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";

export class SolarEnergyGainCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    let houseData = contextData.houseData;
    let insolationS = monthInputData.southInsolation;
    let insolationWE = monthInputData.westEastInsolation;
    let insolationN = monthInputData.northInsolation;
    let shadingData = monthInputData.shadingData;

    energyData.enSolGain = this.calcSolEnergyGain(
      houseData.surfaceData,
      insolationS,
      insolationWE,
      insolationN,
      houseData.windowElement.winGain,
      shadingData
    );

    return energyData;
  };

  calcSolEnergyGain = (
    surfaceData,
    insolationS,
    insolationWE,
    insolationN,
    winGain,
    shadingData
  ) => {
    return (
      this.calcSolEnergyGainForWindow(
        surfaceData.windowsSurfaceS,
        insolationS,
        winGain,
        shadingData,
        true
      ) +
      this.calcSolEnergyGainForWindow(
        surfaceData.windowsSurfaceWE,
        insolationWE,
        winGain,
        shadingData,
        false
      ) +
      this.calcSolEnergyGainForWindow(
        surfaceData.windowsSurfaceN,
        insolationN,
        winGain,
        shadingData,
        false
      )
    );
  };

  calcShadingRatio = (shadingData, onlySouth) => {
    let ratio = 1;
    for (let key in shadingData) {
      if (shadingData.hasOwnProperty(key)) {
        if (key !== "awnings") {
          ratio = ratio * (1 - shadingData[key] / 100);
        } else if (key === "awnings" && onlySouth) {
          ratio = ratio * (1 - shadingData[key] / 100);
        }
      }
    }
    return ratio;
  };

  calcSolEnergyGainForWindow = (
    windowsSurface,
    insolation,
    winGain,
    shadingData,
    onlySouth
  ) => {
    return (
      0.7 *
      winGain *
      (this.calcShadingRatio(shadingData, onlySouth) * winGain) *
      (1000 / 24 / 30) *
      (insolation * windowsSurface)
    );
  };
}
