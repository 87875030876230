import { WindowTypeConfig } from "../WindowTypeConfig";
import { WindowsType } from "../model/WindowsType";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";

export class WindowTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let windowTypeConfiguration = new WindowTypeConfig(
      this.readWindowTypes(dictionaryXML.window_types)
    );
    windowTypeConfiguration.selectedIndex = this.getSelectedIndex(
      dictionaryXML.window_types
    );
    dictionaryConfig.windowTypeConfiguration = windowTypeConfiguration;
    return dictionaryConfig;
  };

  readWindowTypes = windowTypesXML => {
    let windowTypes = [];
    windowTypesXML.window_type.forEach(windowTypeXML => {
      windowTypes.push(this.readWindowType(windowTypeXML));
    });

    return windowTypes;
  };

  readWindowType = windowTypeXML => {
    let id = windowTypeXML.id;
    let uValue = Number(windowTypeXML.u_value);
    let winGain = Number(windowTypeXML.win_gain);

    let windowType = new WindowsType(id, uValue, winGain);
    this.fillBaseProperties(windowTypeXML, windowType);

    return windowType;
  };
}
