import { AbstractSurfaceCalculator } from "./AbstractSurfaceCalculator";
import { HouseType } from "../../../../constants/combo/HouseType";
import { HouseTypeHelper } from "../../../../util/HouseTypeHelper";
import { RoofType } from "../../../../constants/RoofType";
import { Directions } from "../../../../constants/Directions";

export class TerracedHouseSurfaceCalculator extends AbstractSurfaceCalculator {
  doCalculation = (surfaceData, type, roofData) => {
    const strop = 0.3;
    const floorCount = roofData.attic
      ? surfaceData.floorCount - 1
      : surfaceData.floorCount;
    let roofWallW = 0;
    let roofWallE = 0;
    let roofWallS = 0;
    const totalSurface = surfaceData.totalSurface * 1.12;

    surfaceData.floorArea = totalSurface / surfaceData.floorCount;
    surfaceData.floorSurface = surfaceData.floorArea;
    surfaceData.atticSurface = surfaceData.floorArea;

    let floorSurfaceData = this.calcFloorSurfaceData(
      surfaceData.floorArea,
      type
    );
    const roofHeight = this.calcRoofHeight(floorSurfaceData.b, roofData.angle);
    let wallLength = 2 * floorSurfaceData.a + floorSurfaceData.b;
    if (type === HouseType.TERRACED_CENTER) {
      wallLength = 2 * floorSurfaceData.a;
    }
    surfaceData.wallSurface =
      wallLength * surfaceData.floorHeight * floorCount +
      wallLength * strop * (surfaceData.floorCount - 1);
    surfaceData.roofSurface = 1.2 * surfaceData.floorArea;
    surfaceData.windowsSurface = 0.18 * totalSurface;

    if (type !== HouseType.TERRACED_CENTER) {
      surfaceData.windowsSurfaceS = surfaceData.windowsSurface * 0.35;
      surfaceData.windowsSurfaceN = 0;
      surfaceData.windowsSurfaceWE = surfaceData.windowsSurface * 0.65;
    } else {
      surfaceData.windowsSurfaceS = 0;
      surfaceData.windowsSurfaceWE = surfaceData.windowsSurface;
      surfaceData.windowsSurfaceN = 0;
    }

    surfaceData.floorSurfaceC =
      (Math.sqrt(surfaceData.floorSurface) - 2) *
      (Math.sqrt(surfaceData.floorSurface) - 2);
    surfaceData.floorSurfaceR =
      surfaceData.floorSurface - surfaceData.floorSurfaceC;

    surfaceData.wallSurface = Math.max(
      surfaceData.wallSurface - surfaceData.windowsSurface,
      0
    );
    surfaceData.insulatedWallSurface = surfaceData.wallSurface;
    surfaceData.nonInsulatedWallSurface = 0;

    if (roofData.type === RoofType.FLAT) {
      surfaceData.atticWallSurface = 0;
      surfaceData.roofWallSurface = 0;
      surfaceData.roofSurface = surfaceData.floorArea;
      surfaceData.insulatedAtticWall = 0;
      surfaceData.nonInsulatedAtticWall = 0;
    } else if (roofData.type === RoofType.COMPLICATED) {
      surfaceData.roofSurface = Math.abs(
        (surfaceData.floorArea /
          Math.cos(this.degreesToRadians(roofData.angle))) *
          1.05 *
          1.2
      );
      surfaceData.atticWallSurface = roofData.attic
        ? wallLength * roofData.kneewall
        : 0;
      surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
      surfaceData.nonInsulatedAtticWall = 0;
      surfaceData.roofWallSurface = 0;
    } else {
      surfaceData.roofSurface = Math.abs(
        (surfaceData.floorArea /
          Math.cos(this.degreesToRadians(roofData.angle))) *
          1.05
      );
      surfaceData.atticWallSurface = roofData.attic
        ? wallLength * roofData.kneewall
        : 0;
      surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
      surfaceData.nonInsulatedAtticWall = 0;
      if (roofData.type === RoofType.PITCHED) {
        roofWallE = floorSurfaceData.a * roofHeight;
        roofWallS = (floorSurfaceData.b * roofHeight) / 2;
        if (type === HouseType.TERRACED_CENTER) {
          roofWallS = 0;
        }
        surfaceData.roofWallSurface = roofWallW + roofWallS;
      } else if (roofData.type === RoofType.GABLE) {
        roofWallS = (floorSurfaceData.b * roofHeight) / 4;
        if (type === HouseType.TERRACED_CENTER) {
          roofWallS = 0;
        }
        surfaceData.roofWallSurface = roofWallS;
      }
    }

    surfaceData.insulatedWallSurface +=
      surfaceData.insulatedAtticWall + surfaceData.roofWallSurface;

    surfaceData.wallsCount = 2;
    surfaceData.wallsData = [
      {
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallE,
        direction: Directions.EAST,
        window: surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      },
      {
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallW,
        direction: Directions.WEST,
        window: surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      },
    ];
    if (type !== HouseType.TERRACED_CENTER) {
      surfaceData.wallsCount = 3;
      surfaceData.wallsData.push({
        width:
          floorSurfaceData.b * surfaceData.floorHeight * floorCount +
          floorSurfaceData.b * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.b * roofData.kneewall : 0) +
          roofWallS,
        direction: Directions.SOUTH,
        window: surfaceData.windowsSurfaceS,
        insulated: true,
      });
    }
    return surfaceData;
  };

  supportsHouseType = type => HouseTypeHelper.isTerraceType(type);
}
