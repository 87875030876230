import { BaseTypeConfig } from "./BaseTypeConfig";

export class DoorTypeConfig extends BaseTypeConfig {
  _doorTypes;

  get doorTypes() {
    return this._doorTypes;
  }

  set doorTypes(value) {
    this._doorTypes = value;
  }
}
