import { ElectricityConsumption } from "../../../constants/combo/ElectricityConsumption";

export class EnergyCalcHelper {
  static AIR_HEAT_CAPACITY = 1200;

  static calcPersonEnGain = personNumber => {
    let enGain = 0;
    if (personNumber > 2) {
      enGain =
        (personNumber * (6 * 130 + 8 * 80)) / 24 +
        ((personNumber - 2) * (6 * 100 + 8 * 60)) / 24;
    } else {
      enGain = (personNumber * (6 * 130 + 8 * 80)) / 24;
    }
    return enGain;
  };

  static calElectricityDevicesGain = (personNumber, electricityUsage) => {
    let enGain = 0;
    if (personNumber === 1) {
      enGain = 1300;
    } else if (personNumber === 2) {
      enGain = 1930;
    } else if (personNumber === 3) {
      enGain = 2480;
    } else if (personNumber === 4) {
      enGain = 2830;
    } else if (personNumber >= 5) {
      enGain = 2830 + 420 * (personNumber - 4);
    }

    if (electricityUsage === ElectricityConsumption.VERY_LOW) {
      enGain *= 0.5;
    } else if (electricityUsage === ElectricityConsumption.LOW) {
      enGain *= 0.8;
    } else if (electricityUsage === ElectricityConsumption.BIG) {
      enGain *= 1.4;
    } else if (electricityUsage === ElectricityConsumption.VERY_BIG) {
      enGain *= 2.0;
    }

    enGain = this.convertkWhPerYearToWatts(enGain);

    return enGain;
  };

  static calcHeatTransfer = (uValue, surfaceArea, tIn, tOut) =>
    uValue * surfaceArea * (tIn - tOut);

  static calcStandardVentilationVolume = totalSurface => {
    let volume = Math.max(1.25 * totalSurface, 150);
    volume = volume / 3600;
    return volume;
  };

  static calcASHRAEStandardVentilationVolume = (totalSurface, personNum) => {
    let volume = 0.54 * totalSurface + 12.6 * personNum;
    volume = volume / 3600;
    return volume;
  };

  static calcAirHeatEnergyTransfer = (airVolume, tIn, tOut, heatCoefficient) =>
    airVolume *
    EnergyCalcHelper.AIR_HEAT_CAPACITY *
    (tIn - tOut) *
    heatCoefficient;

  static calcWarmWaterEnergyLoss = personNumber =>
    ((35 * (55 - 10) * 4190) / (24 * 3600)) * personNumber;

  static convertkWhPerYearToWatts = value => (value * 1000) / 360 / 24;

  static convertkWhPerMonthToWatts = value => (value * 1000) / 30 / 24;
}
