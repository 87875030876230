import { CalcUtils } from "./CalcUtils";

export class BalanceValueHelper {
  static calcBaseBalanceValue = energyData => {
    return (
      CalcUtils.floorZero(energyData.enBasement) +
      CalcUtils.floorZero(energyData.enWalls) +
      CalcUtils.floorZero(energyData.enDoor) +
      CalcUtils.floorZero(energyData.enRoof) +
      CalcUtils.floorZero(energyData.enFloor) +
      CalcUtils.floorZero(energyData.enWindows) +
      CalcUtils.floorZero(energyData.enVent) +
      CalcUtils.floorZero(energyData.enAir) +
      CalcUtils.floorZero(energyData.enFoundations) +
      CalcUtils.floorZero(energyData.enTightness) -
      (CalcUtils.floorZero(energyData.enPersonGain) +
        CalcUtils.floorZero(energyData.enSolGain) +
        CalcUtils.floorZero(energyData.enElectricityGain))
    );
  };

  static calcBaseNormalizedBalanceValue = energyData => {
    return Math.max(BalanceValueHelper.calcBaseBalanceValue(energyData), 0);
  };

  static calcYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      let monthResult = resultData.monthlyConvertedData[i];
      monthResult.enElectricDevicesEnergyLoss =
        (resultData.yearlyAggregatedConvertedData
          .enElectricDevicesEnergyLossNormalized *
          monthResult.heatingDeviceEnergy) /
        resultData.yearlyAggregatedConvertedData.heatingDeviceEnergy;

      if (
        typeof resultData.conversionData !== "undefined" &&
        !resultData.conversionData.airConditioning
      ) {
        monthResult.airConditioningDeviceEnergy = 0;
        monthResult.airConditioningLoss = 0;
      }
      const balanceValue = monthResult.getBalanceValue();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcMonthBalanceValue = (resultData, month) => {
    let result = 0;
    let monthResult = resultData.monthlyConvertedData[month];
    monthResult.enElectricDevicesEnergyLoss =
      (resultData.yearlyAggregatedConvertedData
        .enElectricDevicesEnergyLossNormalized *
        monthResult.heatingDeviceEnergy) /
      resultData.yearlyAggregatedConvertedData.heatingDeviceEnergy;
    if (
      typeof resultData.conversionData !== "undefined" &&
      !resultData.conversionData.airConditioning
    ) {
      monthResult.airConditioningDeviceEnergy = 0;
      monthResult.airConditioningLoss = 0;
    }
    const balanceValue = monthResult.getBalanceValue();
    result += Math.max(0, balanceValue);
    return result;
  };

  static calcEfficiencyAwareYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];

      const balanceValue = monthResult.getEfficiencyAwareBalanceValue();
      result += Math.max(0, balanceValue);
      resultData.yearlyAggregatedConvertedData.enElectricDevicesEnergyLoss +=
        monthResult.enElectricDevicesEnergyLoss;
    }

    if (typeof resultData.conversionData !== "undefined") {
      let electricDevicesEnergy = 0;

      electricDevicesEnergy +=
        resultData.yearlyAggregatedConvertedData
          .enWaterElectricDevicesEnergyLoss;
      electricDevicesEnergy +=
        resultData.yearlyAggregatedConvertedData
          .enElectricDevicesEnergyLossNormalized;
      electricDevicesEnergy +=
        resultData.yearlyAggregatedConvertedData
          .enElectricVentilationEnergyLoss;
      electricDevicesEnergy +=
        resultData.yearlyAggregatedConvertedData.enRecuperator;
      electricDevicesEnergy +=
        resultData.yearlyAggregatedConvertedData.enHumidification;
      if (resultData.conversionData.airConditioning) {
        electricDevicesEnergy +=
          resultData.yearlyAggregatedConvertedData.airConditioningDeviceEnergy;
      }
      if (resultData.conversionData.isElectric) {
        electricDevicesEnergy +=
          resultData.yearlyAggregatedConvertedData.heatingDeviceEnergy;
        electricDevicesEnergy +=
          resultData.yearlyAggregatedConvertedData.heatingWaterDeviceEnergy;
      }

      result =
        result -
        Math.min(
          resultData.yearlyAggregatedConvertedData.enPhotovoltaicGainNormalized,
          electricDevicesEnergy
        );
    }

    return result;
  };

  static calcEfficiencyAndClassEnergyAwareYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue =
        Math.max(0, monthResult.getHeatingBalanceWithoutAiring()) +
        monthResult.getWarmWaterNormalizedBalance() +
        monthResult.enRecuperator +
        monthResult.enHumidification;
      result += Math.max(
        0,
        balanceValue +
          monthResult.heatingSourceLoss +
          monthResult.enWarmWaterNormalizedHeatingSourceLoss
      );
    }
    return result;
  };

  static calcEfficiencyAwareHeatingYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getEfficiencyAwareHeatingBalanceValue();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcEfficiencyAwareHeatingMonthBalanceValue = (resultData, month) => {
    let result = 0;
    let monthResult = resultData.monthlyConvertedData[month];
    const balanceValue = monthResult.getEfficiencyAwareHeatingBalanceValue();
    result += Math.max(0, balanceValue);
    return result;
  };

  static calcEfficiencyAwareWarmWaterYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getEfficiencyAwareWarmWaterBalanceValue();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcEfficiencyAwareWarmWaterMonthBalanceValue = (
    resultData,
    month
  ) => {
    let result = 0;
    let monthResult = resultData.monthlyConvertedData[month];
    const balanceValue = monthResult.getEfficiencyAwareWarmWaterBalanceValue();
    result += Math.max(0, balanceValue);
    return result;
  };

  static calcEfficiencyAwareAndClassEnergyWarmWaterYearBalanceValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getEfficiencyAndEnergyClassAwareWarmWaterBalanceValue();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcHeatingBalance = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getHeatingBalance();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcBalanceWarmWaterValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getWarmWaterBalance();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcBalanceElectricValue = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      const monthResult = resultData.monthlyConvertedData[i];
      const balanceValue = monthResult.getElectricDevices();
      result += Math.max(0, balanceValue);
    }
    return result;
  };

  static calcBalanceElectricMonthValue = (resultData, month) => {
    let result = 0;
    let monthResult = resultData.monthlyConvertedData[month];
    const balanceValue = monthResult.getElectricDevices();
    result += Math.max(0, balanceValue);
    return result;
  };

  static calcBalanceEnByProductHeat = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      result += this.calcBalanceEnByProductHeatForMonth(resultData, i);
    }
    return result;
  };

  static calcBalanceEnSolGainForMonth(resultData, month) {
    let monthResult = resultData.monthlyConvertedData[month];
    let balanceValue = monthResult.getHeatingBalance();
    if (balanceValue > 0) {
      return monthResult.enSolGain;
    } else {
      return Math.max(0, monthResult.enSolGain + balanceValue);
    }
  }

  static calcBalanceEnSolGain = resultData => {
    let result = 0;
    for (let i = 0; i < resultData.monthlyConvertedData.length; i++) {
      result += this.calcBalanceEnSolGainForMonth(resultData, i);
    }
    return result;
  };

  static calcBalanceEnByProductHeatForMonth = (resultData, month) => {
    let monthResult = resultData.monthlyConvertedData[month];
    let balanceValue = monthResult.getHeatingBalance();
    if (balanceValue > 0) {
      return (
        monthResult.enPersonGain +
        monthResult.enElectricityGain +
        monthResult.enWarmWaterGain
      );
    } else {
      return (
        monthResult.enPersonGain +
        monthResult.enElectricityGain +
        monthResult.enWarmWaterGain +
        Math.min(monthResult.enSolGain + balanceValue, 0)
      );
    }
  };
}
