import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";

export class RoofEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    const houseData = contextData.houseData;
    const hasRoof = HouseTypeHelper.hasRoof(houseData.houseType);
    if (hasRoof) {
      energyData.enRoof = EnergyCalcHelper.calcHeatTransfer(
        houseData.roofElement.uValue,
        houseData.surfaceData.roofSurface,
        houseData.tIn,
        monthInputData.tOut
      );
    }
    return energyData;
  };
}
