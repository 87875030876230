import { ICalculatedResultProvider } from "./utils/ICalculatedResultProvider";
import { MonthEnergyData } from "./model/MonthEnergyData";
import { MonthInputData } from "./model/MonthInputData";
import { ShadingHelper } from "../../util/ShadingHelper";

export class MonthlyEnergyResultProvider extends ICalculatedResultProvider {
  dataContext;
  month;
  calculator;

  monthInput;

  currentResult = new MonthEnergyData();

  ratioSum = 0;

  constructor(dataContext, month, calculator) {
    super();
    this.dataContext = dataContext;
    this.month = month;
    this.calculator = calculator;

    let shadingData = ShadingHelper.getMonthShading(
      dataContext.houseData.insolationData,
      month
    );

    this.monthInput = new MonthInputData(
      0,
      dataContext.environmentalData.groundTemperatures[month],
      dataContext.environmentalData.insolationData.groundInsolation45[month],
      dataContext.environmentalData.insolationData.groundInsolationSum,
      dataContext.environmentalData.insolationData.southInsolation[month],
      dataContext.environmentalData.insolationData.westEastInsolation[month],
      dataContext.environmentalData.insolationData.northInsolation[month],
      shadingData
    );
  }

  getCalculatedResult = () => this.currentResult;

  applyRatio = (tempM, ratio) => {
    this.monthInput.tOut = tempM;
    try {
      let energyData = this.calculator.calcEnergy(
        new MonthEnergyData(),
        this.dataContext,
        this.monthInput
      );
      this.currentResult.enWalls =
        this.currentResult.enWalls + energyData.enWalls * ratio;
      this.currentResult.enBasement =
        this.currentResult.enBasement + energyData.enBasement * ratio;
      this.currentResult.enDoor =
        this.currentResult.enDoor + energyData.enDoor * ratio;
      this.currentResult.enRoof =
        this.currentResult.enRoof + energyData.enRoof * ratio;
      this.currentResult.enFloor =
        this.currentResult.enFloor + energyData.enFloor * ratio;
      this.currentResult.enFoundations =
        this.currentResult.enFoundations + energyData.enFoundations * ratio;
      this.currentResult.enWindows =
        this.currentResult.enWindows + energyData.enWindows * ratio;
      this.currentResult.enVent =
        this.currentResult.enVent + energyData.enVent * ratio;
      this.currentResult.enAir =
        this.currentResult.enAir + energyData.enAir * ratio;
      this.currentResult.enTightness =
        this.currentResult.enTightness + energyData.enTightness * ratio;
      this.currentResult.enRecuperator =
        this.currentResult.enRecuperator + energyData.enRecuperator * ratio;
      this.currentResult.enHumidification =
        this.currentResult.enHumidification +
        energyData.enHumidification * ratio;
      this.currentResult.enWarmWater =
        this.currentResult.enWarmWater + energyData.enWarmWater * ratio;
      this.currentResult.enElectricDevicesEnergyLoss =
        this.currentResult.enElectricDevicesEnergyLoss +
        energyData.enElectricDevicesEnergyLoss * ratio;
      this.currentResult.enElectricDevicesEnergyLossNormalized =
        this.currentResult.enElectricDevicesEnergyLossNormalized +
        energyData.enElectricDevicesEnergyLossNormalized * ratio;
      this.currentResult.enElectricVentilationEnergyLoss =
        this.currentResult.enElectricVentilationEnergyLoss +
        energyData.enElectricVentilationEnergyLoss * ratio;
      this.currentResult.enWaterElectricDevicesEnergyLoss =
        this.currentResult.enWaterElectricDevicesEnergyLoss +
        energyData.enWaterElectricDevicesEnergyLoss * ratio;
      this.currentResult.electricDevicesEnergySum =
        this.currentResult.electricDevicesEnergySum +
        energyData.electricDevicesEnergySum * ratio;

      this.currentResult.enPersonGain =
        this.currentResult.enPersonGain + energyData.enPersonGain * ratio;
      this.currentResult.enLivingNeeds =
        this.currentResult.enLivingNeeds + energyData.enLivingNeeds * ratio;
      this.currentResult.enElectricityGain =
        this.currentResult.enElectricityGain +
        energyData.enElectricityGain * ratio;
      this.currentResult.enSolGain =
        this.currentResult.enSolGain + energyData.enSolGain * ratio;
      this.currentResult.enCollectorSolarGain =
        this.currentResult.enCollectorSolarGain +
        energyData.enCollectorSolarGain * ratio;
      this.currentResult.enPhotovoltaicGain =
        this.currentResult.enPhotovoltaicGain +
        energyData.enPhotovoltaicGain * ratio;
      this.currentResult.enPhotovoltaicGainNormalized =
        this.currentResult.enPhotovoltaicGainNormalized +
        energyData.enPhotovoltaicGainNormalized * ratio;
      this.ratioSum = this.ratioSum + ratio;
    } catch (e) {
      console.log(e);
    }
  };
}
