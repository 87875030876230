import { BaseComboObject } from "./BaseComboObject";

export class WarmWaterDistribution extends BaseComboObject {
  static items = [];

  _efficiency;

  constructor(id, efficiency) {
    super(id);
    this._efficiency = efficiency;
    WarmWaterDistribution.items.push(this);
  }

  static getWarmWaterDistributionById = id => {
    for (let i = 0; i < WarmWaterDistribution.items.length; i++) {
      let warmWaterDistribution = WarmWaterDistribution.items[i];
      if (warmWaterDistribution.id === id) {
        return warmWaterDistribution;
      }
    }
    return null;
  };

  get efficiency() {
    return this._efficiency;
  }
}
