import { RoofElement } from "../model/RoofElement";
import { WallElement } from "../model/WallElement";
import { FloorElement } from "../model/FloorElement";

export class FlatDataContext {
  _currencyLocaleCode;
  _localCurrency;
  _currencyName;

  costs;

  _selectedMonth;
  _selectedUnit;
  _unitName;
  _shortUnitName;
  _conversionPvPricePerKWh;
  _conversionPricePerKWh;
  _conversionWaterPricePerKWh;
  _conversionElectricityPricePerKWh;
  _conversionElectricityPricePerKWhLocal;
  _electricityEmissionCoefficient;
  _conversionIsElectric;
  _conversionIsWaterElectric;
  _houseHeatingEfficiency;
  heatingSystemEfficiency;
  _warmWaterHeatingEfficiency;
  _conversionDataFinalToPrimaryCoefficient;
  _conversionDataWarmWaterFinalToPrimaryCoefficient;
  _conversionDataEmissionCoefficient;
  _conversionDataWaterEmissionCoefficient;
  _conversionDataNaturalUnitPrice;
  _conversionDataNaturalUnitPriceLocal;
  _conversionDataNaturalUnitPriceChanged;
  _toPLNCurrencyExchangeRate;
  conversionData;

  _heatingSource;
  _heatingType;
  _heatingSystem;
  _heatingFuel;
  _heatingEfficiency;
  _coolingEfficiency;
  _subdevicesEnergyConsumption;
  _waterSubdevicesEnergyConsumption;

  _heatingTypeId;
  _waterHeatingType;
  _waterHeatingTypeId;
  _waterHeatingSource;
  _waterHeatingPlacement;
  _waterHeatingFuel;
  _pricePerKWh;
  _electricityPricePerKWh;
  _naturalUnit;
  _naturalUnitPrice;
  _calorificValue;
  _calorificValueUnit;
  _heatingValueEditable;
  _showNaturalUnit;

  _waterHeatingEfficiency;
  _waterPricePerKWh;
  _finalToPrimaryCoefficient;
  _warmWaterFinalToPrimaryCoefficient;
  _emissionCoefficient;
  _waterEmissionCoefficient;
  _includeWarmWater;

  _warmWaterDistributionId;
  _warmWaterStorageId;
  _distributorEfficiency;
  _storeEfficiency;
  _consumptionIntensityId;
  _waterSavings;

  _collectorTypeId;
  _surface;
  _count;

  _selectedCountryRegion;
  _countryCode;
  _selectedStation;

  _houseType;
  _buildingType;
  _buildingAgeId;
  _houseStandardTypeId;
  showDetailedData;
  detailedDataChanged;
  _tIn;
  _totalSurface;
  _floorCount;
  _floorHeight;
  _wallSurface;
  _insulatedWallSurface;
  _nonInsulatedWallSurface;
  _roofSurface;
  _atticSurface;
  _atticWallSurface;
  _insulatedAtticWall;
  _nonInsulatedAtticWall;
  _roofWallSurface;
  _floorSurface;
  _windowsSurfaceS;
  _windowsSurfaceWE;
  _windowsSurfaceN;
  _floorArea;
  _floorSurfaceC;
  _floorSurfaceR;
  _windowsSurface;
  surfaceData;

  walls;
  wallsData;
  roofData;
  _basement;

  _flatCollector1;
  _flatCollector2;
  _flatCollector3;
  _flatCollector4;
  _vacuumCollector1;
  _vacuumCollector2;

  _wallWidth;
  _wallUValue;
  _wallMaterial;
  _wallHeatTransfer;

  _roofWidth;
  _roofUValue;
  _roofMaterial;
  _roofHeatTransfer;
  _doorTypeId;

  _floorWidth;
  _floorUValue;
  _floorMaterial;
  _floorHeatTransfer;
  _foundationsTypeId;

  _roof = new RoofElement();
  _wall = new WallElement();
  _floor = new FloorElement();

  _basementWallWidth;
  _basementWallUValue;
  _basementWallMaterial;
  _basementWallHeatTransfer;

  _basementFloorWidth;
  _basementFloorUValue;
  _basementFloorMaterial;
  _basementFloorHeatTransfer;

  _personNumber;
  _livingNeeds;
  _livingNeedsChanged;

  _gwcSet;
  _ventilation;
  _airTightness;
  _co2Sensor;
  _isMechanical;
  _ventilationFreq;
  _humidification;
  _patency;
  _recuperator;
  _recuperatorLocation;
  _n50;

  _windowsTypeId;
  _shutters;
  _thermalBridge;

  _minCalcTemp;
  _temperatures;
  _groundTemperatures;
  _tAvg;
  _tMin;
  _tMax;
  _tSigma;

  _pvData;
  acData;
  _insolationData;

  _irrData;
  irrData2;

  get currencyLocaleCode() {
    return this._currencyLocaleCode;
  }

  set currencyLocaleCode(value) {
    this._currencyLocaleCode = value;
  }

  get localCurrency() {
    return this._localCurrency;
  }

  set localCurrency(value) {
    this._localCurrency = value;
  }

  get selectedMonth() {
    return this._selectedMonth;
  }

  set selectedMonth(value) {
    this._selectedMonth = value;
  }

  get selectedUnit() {
    return this._selectedUnit;
  }

  set selectedUnit(value) {
    this._selectedUnit = value;
  }

  get unitName() {
    return this._unitName;
  }

  set unitName(value) {
    this._unitName = value;
  }

  get shortUnitName() {
    return this._shortUnitName;
  }

  set shortUnitName(value) {
    this._shortUnitName = value;
  }

  get conversionPvPricePerKWh() {
    return this._conversionPvPricePerKWh;
  }

  set conversionPvPricePerKWh(value) {
    this._conversionPvPricePerKWh = value;
  }

  get conversionPricePerKWh() {
    return this._conversionPricePerKWh;
  }

  set conversionPricePerKWh(value) {
    this._conversionPricePerKWh = value;
  }

  get electricityPricePerKWh() {
    return this._electricityPricePerKWh;
  }

  set electricityPricePerKWh(value) {
    this._electricityPricePerKWh = value;
  }

  get conversionWaterPricePerKWh() {
    return this._conversionWaterPricePerKWh;
  }

  set conversionWaterPricePerKWh(value) {
    this._conversionWaterPricePerKWh = value;
  }

  get conversionElectricityPricePerKWh() {
    return this._conversionElectricityPricePerKWh;
  }

  set conversionElectricityPricePerKWh(value) {
    this._conversionElectricityPricePerKWh = value;
  }

  get conversionElectricityPricePerKWhLocal() {
    return this._conversionElectricityPricePerKWhLocal;
  }

  set conversionElectricityPricePerKWhLocal(value) {
    this._conversionElectricityPricePerKWhLocal = value;
  }

  get conversionIsElectric() {
    return this._conversionIsElectric;
  }

  set conversionIsElectric(value) {
    this._conversionIsElectric = value;
  }

  get conversionIsWaterElectric() {
    return this._conversionIsWaterElectric;
  }

  set conversionIsWaterElectric(value) {
    this._conversionIsWaterElectric = value;
  }

  get houseHeatingEfficiency() {
    return this._houseHeatingEfficiency;
  }

  set houseHeatingEfficiency(value) {
    this._houseHeatingEfficiency = value;
  }

  get warmWaterHeatingEfficiency() {
    return this._warmWaterHeatingEfficiency;
  }

  set warmWaterHeatingEfficiency(value) {
    this._warmWaterHeatingEfficiency = value;
  }

  get conversionDataFinalToPrimaryCoefficient() {
    return this._conversionDataFinalToPrimaryCoefficient;
  }

  set conversionDataFinalToPrimaryCoefficient(value) {
    this._conversionDataFinalToPrimaryCoefficient = value;
  }

  get conversionDataWarmWaterFinalToPrimaryCoefficient() {
    return this._conversionDataWarmWaterFinalToPrimaryCoefficient;
  }

  set conversionDataWarmWaterFinalToPrimaryCoefficient(value) {
    this._conversionDataWarmWaterFinalToPrimaryCoefficient = value;
  }

  get conversionDataEmissionCoefficient() {
    return this._conversionDataEmissionCoefficient;
  }

  set conversionDataEmissionCoefficient(value) {
    this._conversionDataEmissionCoefficient = value;
  }

  get conversionDataWaterEmissionCoefficient() {
    return this._conversionDataWaterEmissionCoefficient;
  }

  set conversionDataWaterEmissionCoefficient(value) {
    this._conversionDataWaterEmissionCoefficient = value;
  }

  get conversionDataNaturalUnitPrice() {
    return this._conversionDataNaturalUnitPrice;
  }

  set conversionDataNaturalUnitPrice(value) {
    this._conversionDataNaturalUnitPrice = value;
  }

  get conversionDataNaturalUnitPriceLocal() {
    return this._conversionDataNaturalUnitPriceLocal;
  }

  set conversionDataNaturalUnitPriceLocal(value) {
    this._conversionDataNaturalUnitPriceLocal = value;
  }

  get conversionDataNaturalUnitPriceChanged() {
    return this._conversionDataNaturalUnitPriceChanged;
  }

  set conversionDataNaturalUnitPriceChanged(value) {
    this._conversionDataNaturalUnitPriceChanged = value;
  }

  get toPLNCurrencyExchangeRate() {
    return this._toPLNCurrencyExchangeRate;
  }

  set toPLNCurrencyExchangeRate(value) {
    this._toPLNCurrencyExchangeRate = value;
  }

  get heatingTypeId() {
    return this._heatingTypeId;
  }

  set heatingTypeId(value) {
    this._heatingTypeId = value;
  }

  get heatingEfficiency() {
    return this._heatingEfficiency;
  }

  set heatingEfficiency(value) {
    this._heatingEfficiency = value;
  }

  get pricePerKWh() {
    return this._pricePerKWh;
  }

  set pricePerKWh(value) {
    this._pricePerKWh = value;
  }

  get naturalUnit() {
    return this._naturalUnit;
  }

  set naturalUnit(value) {
    this._naturalUnit = value;
  }

  get naturalUnitPrice() {
    return this._naturalUnitPrice;
  }

  set naturalUnitPrice(value) {
    this._naturalUnitPrice = value;
  }

  get calorificValue() {
    return this._calorificValue;
  }

  set calorificValue(value) {
    this._calorificValue = value;
  }

  get calorificValueUnit() {
    return this._calorificValueUnit;
  }

  set calorificValueUnit(value) {
    this._calorificValueUnit = value;
  }

  get waterHeatingTypeId() {
    return this._waterHeatingTypeId;
  }

  set waterHeatingTypeId(value) {
    this._waterHeatingTypeId = value;
  }

  get waterHeatingEfficiency() {
    return this._waterHeatingEfficiency;
  }

  set waterHeatingEfficiency(value) {
    this._waterHeatingEfficiency = value;
  }

  get waterPricePerKWh() {
    return this._waterPricePerKWh;
  }

  set waterPricePerKWh(value) {
    this._waterPricePerKWh = value;
  }

  get finalToPrimaryCoefficient() {
    return this._finalToPrimaryCoefficient;
  }

  set finalToPrimaryCoefficient(value) {
    this._finalToPrimaryCoefficient = value;
  }

  get warmWaterFinalToPrimaryCoefficient() {
    return this._warmWaterFinalToPrimaryCoefficient;
  }

  set warmWaterFinalToPrimaryCoefficient(value) {
    this._warmWaterFinalToPrimaryCoefficient = value;
  }

  get emissionCoefficient() {
    return this._emissionCoefficient;
  }

  set emissionCoefficient(value) {
    this._emissionCoefficient = value;
  }

  get waterEmissionCoefficient() {
    return this._waterEmissionCoefficient;
  }

  set waterEmissionCoefficient(value) {
    this._waterEmissionCoefficient = value;
  }

  get includeWarmWater() {
    return this._includeWarmWater;
  }

  set includeWarmWater(value) {
    this._includeWarmWater = value;
  }

  get warmWaterDistributionId() {
    return this._warmWaterDistributionId;
  }

  set warmWaterDistributionId(value) {
    this._warmWaterDistributionId = value;
  }

  get warmWaterStorageId() {
    return this._warmWaterStorageId;
  }

  set warmWaterStorageId(value) {
    this._warmWaterStorageId = value;
  }

  get distributorEfficiency() {
    return this._distributorEfficiency;
  }

  set distributorEfficiency(value) {
    this._distributorEfficiency = value;
  }

  get storeEfficiency() {
    return this._storeEfficiency;
  }

  set storeEfficiency(value) {
    this._storeEfficiency = value;
  }

  get consumptionIntensityId() {
    return this._consumptionIntensityId;
  }

  set consumptionIntensityId(value) {
    this._consumptionIntensityId = value;
  }

  get waterSavings() {
    return this._waterSavings;
  }

  set waterSavings(value) {
    this._waterSavings = value;
  }

  get collectorTypeId() {
    return this._collectorTypeId;
  }

  set collectorTypeId(value) {
    this._collectorTypeId = value;
  }

  get surface() {
    return this._surface;
  }

  set surface(value) {
    this._surface = value;
  }

  get count() {
    return this._count;
  }

  set count(value) {
    this._count = value;
  }

  get selectedCountryRegion() {
    return this._selectedCountryRegion;
  }

  set selectedCountryRegion(value) {
    this._selectedCountryRegion = value;
  }

  get countryCode() {
    return this._countryCode;
  }

  set countryCode(value) {
    this._countryCode = value;
  }

  get selectedStation() {
    return this._selectedStation;
  }

  set selectedStation(value) {
    this._selectedStation = value;
  }

  get buildingType() {
    return this._buildingType;
  }

  set buildingType(value) {
    this._buildingType = value;
  }

  get houseType() {
    return this._houseType;
  }

  set houseType(value) {
    this._houseType = value;
  }

  get houseStandardTypeId() {
    return this._houseStandardTypeId;
  }

  set houseStandardTypeId(value) {
    this._houseStandardTypeId = value;
  }

  get tIn() {
    return this._tIn;
  }

  set tIn(value) {
    this._tIn = value;
  }

  get totalSurface() {
    return this._totalSurface;
  }

  set totalSurface(value) {
    this._totalSurface = value;
  }

  get floorCount() {
    return this._floorCount;
  }

  set floorCount(value) {
    this._floorCount = value;
  }

  get floorHeight() {
    return this._floorHeight;
  }

  set floorHeight(value) {
    this._floorHeight = value;
  }

  get wallSurface() {
    return this._wallSurface;
  }

  set wallSurface(value) {
    this._wallSurface = value;
  }

  get insulatedWallSurface() {
    return this._insulatedWallSurface;
  }

  set insulatedWallSurface(value) {
    this._insulatedWallSurface = value;
  }

  get nonInsulatedWallSurface() {
    return this._nonInsulatedWallSurface;
  }

  set nonInsulatedWallSurface(value) {
    this._nonInsulatedWallSurface = value;
  }

  get roofSurface() {
    return this._roofSurface;
  }

  set roofSurface(value) {
    this._roofSurface = value;
  }

  get atticSurface() {
    return this._atticSurface;
  }

  set atticSurface(value) {
    this._atticSurface = value;
  }

  get atticWallSurface() {
    return this._atticWallSurface;
  }

  set atticWallSurface(value) {
    this._atticWallSurface = value;
  }

  get insulatedAtticWall() {
    return this._insulatedAtticWall;
  }

  set insulatedAtticWall(value) {
    this._insulatedAtticWall = value;
  }

  get nonInsulatedAtticWall() {
    return this._nonInsulatedAtticWall;
  }

  set nonInsulatedAtticWall(value) {
    this._nonInsulatedAtticWall = value;
  }

  get roofWallSurface() {
    return this._roofWallSurface;
  }

  set roofWallSurface(value) {
    this._roofWallSurface = value;
  }

  get floorSurface() {
    return this._floorSurface;
  }

  set floorSurface(value) {
    this._floorSurface = value;
  }

  get windowsSurfaceS() {
    return this._windowsSurfaceS;
  }

  set windowsSurfaceS(value) {
    this._windowsSurfaceS = value;
  }

  get windowsSurfaceWE() {
    return this._windowsSurfaceWE;
  }

  set windowsSurfaceWE(value) {
    this._windowsSurfaceWE = value;
  }

  get windowsSurfaceN() {
    return this._windowsSurfaceN;
  }

  set windowsSurfaceN(value) {
    this._windowsSurfaceN = value;
  }

  get floorArea() {
    return this._floorArea;
  }

  set floorArea(value) {
    this._floorArea = value;
  }

  get floorSurfaceC() {
    return this._floorSurfaceC;
  }

  set floorSurfaceC(value) {
    this._floorSurfaceC = value;
  }

  get floorSurfaceR() {
    return this._floorSurfaceR;
  }

  set floorSurfaceR(value) {
    this._floorSurfaceR = value;
  }

  get windowsSurface() {
    return this._windowsSurface;
  }

  set windowsSurface(value) {
    this._windowsSurface = value;
  }

  get flatCollector1() {
    return this._flatCollector1;
  }

  set flatCollector1(value) {
    this._flatCollector1 = value;
  }

  get flatCollector2() {
    return this._flatCollector2;
  }

  set flatCollector2(value) {
    this._flatCollector2 = value;
  }

  get flatCollector3() {
    return this._flatCollector3;
  }

  set flatCollector3(value) {
    this._flatCollector3 = value;
  }

  get flatCollector4() {
    return this._flatCollector4;
  }

  set flatCollector4(value) {
    this._flatCollector4 = value;
  }

  get vacuumCollector1() {
    return this._vacuumCollector1;
  }

  set vacuumCollector1(value) {
    this._vacuumCollector1 = value;
  }

  get vacuumCollector2() {
    return this._vacuumCollector2;
  }

  set vacuumCollector2(value) {
    this._vacuumCollector2 = value;
  }

  get wallWidth() {
    return this._wallWidth;
  }

  set wallWidth(value) {
    this._wallWidth = value;
  }

  get wallUValue() {
    return this._wallUValue;
  }

  set wallUValue(value) {
    this._wallUValue = value;
  }

  get wallMaterial() {
    return this._wallMaterial;
  }

  set wallMaterial(value) {
    this._wallMaterial = value;
  }

  get wallHeatTransfer() {
    return this._wallHeatTransfer;
  }

  set wallHeatTransfer(value) {
    this._wallHeatTransfer = value;
  }

  get roofWidth() {
    return this._roofWidth;
  }

  set roofWidth(value) {
    this._roofWidth = value;
  }

  get roofUValue() {
    return this._roofUValue;
  }

  set roofUValue(value) {
    this._roofUValue = value;
  }

  get roofMaterial() {
    return this._roofMaterial;
  }

  set roofMaterial(value) {
    this._roofMaterial = value;
  }

  get roofHeatTransfer() {
    return this._roofHeatTransfer;
  }

  set roofHeatTransfer(value) {
    this._roofHeatTransfer = value;
  }

  get doorTypeId() {
    return this._doorTypeId;
  }

  set doorTypeId(value) {
    this._doorTypeId = value;
  }

  get floorWidth() {
    return this._floorWidth;
  }

  set floorWidth(value) {
    this._floorWidth = value;
  }

  get floorUValue() {
    return this._floorUValue;
  }

  set floorUValue(value) {
    this._floorUValue = value;
  }

  get floorMaterial() {
    return this._floorMaterial;
  }

  set floorMaterial(value) {
    this._floorMaterial = value;
  }

  get floorHeatTransfer() {
    return this._floorHeatTransfer;
  }

  set floorHeatTransfer(value) {
    this._floorHeatTransfer = value;
  }

  get foundationsTypeId() {
    return this._foundationsTypeId;
  }

  set foundationsTypeId(value) {
    this._foundationsTypeId = value;
  }

  get basementWallWidth() {
    return this._basementWallWidth;
  }

  set basementWallWidth(value) {
    this._basementWallWidth = value;
  }

  get basementWallUValue() {
    return this._basementWallUValue;
  }

  set basementWallUValue(value) {
    this._basementWallUValue = value;
  }

  get basementWallMaterial() {
    return this._basementWallMaterial;
  }

  set basementWallMaterial(value) {
    this._basementWallMaterial = value;
  }

  get basementWallHeatTransfer() {
    return this._basementWallHeatTransfer;
  }

  set basementWallHeatTransfer(value) {
    this._basementWallHeatTransfer = value;
  }

  get basementFloorWidth() {
    return this._basementFloorWidth;
  }

  set basementFloorWidth(value) {
    this._basementFloorWidth = value;
  }

  get basementFloorUValue() {
    return this._basementFloorUValue;
  }

  set basementFloorUValue(value) {
    this._basementFloorUValue = value;
  }

  get basementFloorMaterial() {
    return this._basementFloorMaterial;
  }

  set basementFloorMaterial(value) {
    this._basementFloorMaterial = value;
  }

  get basementFloorHeatTransfer() {
    return this._basementFloorHeatTransfer;
  }

  set basementFloorHeatTransfer(value) {
    this._basementFloorHeatTransfer = value;
  }

  get personNumber() {
    return this._personNumber;
  }

  set personNumber(value) {
    this._personNumber = value;
  }

  get livingNeeds() {
    return this._livingNeeds;
  }

  set livingNeeds(value) {
    this._livingNeeds = value;
  }

  get livingNeedsChanged() {
    return this._livingNeedsChanged;
  }

  set livingNeedsChanged(value) {
    this._livingNeedsChanged = value;
  }

  get gwcSet() {
    return this._gwcSet;
  }

  set gwcSet(value) {
    this._gwcSet = value;
  }

  get ventilation() {
    return this._ventilation;
  }

  set ventilation(value) {
    this._ventilation = value;
  }

  get airTightness() {
    return this._airTightness;
  }

  set airTightness(value) {
    this._airTightness = value;
  }

  get co2Sensor() {
    return this._co2Sensor;
  }

  set co2Sensor(value) {
    this._co2Sensor = value;
  }

  get isMechanical() {
    return this._isMechanical;
  }

  set isMechanical(value) {
    this._isMechanical = value;
  }

  get ventilationFreq() {
    return this._ventilationFreq;
  }

  set ventilationFreq(value) {
    this._ventilationFreq = value;
  }

  get humidification() {
    return this._humidification;
  }

  set humidification(value) {
    this._humidification = value;
  }

  get patency() {
    return this._patency;
  }

  set patency(value) {
    this._patency = value;
  }

  get recuperator() {
    return this._recuperator;
  }

  set recuperator(value) {
    this._recuperator = value;
  }

  get recuperatorLocation() {
    return this._recuperatorLocation;
  }

  set recuperatorLocation(value) {
    this._recuperatorLocation = value;
  }

  get n50() {
    return this._n50;
  }

  set n50(value) {
    this._n50 = value;
  }

  get windowsTypeId() {
    return this._windowsTypeId;
  }

  set windowsTypeId(value) {
    this._windowsTypeId = value;
  }

  get shutters() {
    return this._shutters;
  }

  set shutters(value) {
    this._shutters = value;
  }

  get thermalBridge() {
    return this._thermalBridge;
  }

  set thermalBridge(value) {
    this._thermalBridge = value;
  }

  get minCalcTemp() {
    return this._minCalcTemp;
  }

  set minCalcTemp(value) {
    this._minCalcTemp = value;
  }

  get temperatures() {
    return this._temperatures;
  }

  set temperatures(value) {
    this._temperatures = value;
  }

  get groundTemperatures() {
    return this._groundTemperatures;
  }

  set groundTemperatures(value) {
    this._groundTemperatures = value;
  }

  get tAvg() {
    return this._tAvg;
  }

  set tAvg(value) {
    this._tAvg = value;
  }

  get tMin() {
    return this._tMin;
  }

  set tMin(value) {
    this._tMin = value;
  }

  get tMax() {
    return this._tMax;
  }

  set tMax(value) {
    this._tMax = value;
  }

  get tSigma() {
    return this._tSigma;
  }

  set tSigma(value) {
    this._tSigma = value;
  }

  get buildingAgeId() {
    return this._buildingAgeId;
  }

  set buildingAgeId(value) {
    this._buildingAgeId = value;
  }

  get currencyName() {
    return this._currencyName;
  }

  set currencyName(value) {
    this._currencyName = value;
  }

  get basement() {
    return this._basement;
  }

  set basement(value) {
    this._basement = value;
  }

  get pvData() {
    return this._pvData;
  }

  set pvData(value) {
    this._pvData = value;
  }

  get insolationData() {
    return this._insolationData;
  }

  set insolationData(value) {
    this._insolationData = value;
  }

  get heatingSource() {
    return this._heatingSource;
  }

  set heatingSource(value) {
    this._heatingSource = value;
  }

  get heatingType() {
    return this._heatingType;
  }

  set heatingType(value) {
    this._heatingType = value;
  }

  get heatingSystem() {
    return this._heatingSystem;
  }

  set heatingSystem(value) {
    this._heatingSystem = value;
  }

  get heatingFuel() {
    return this._heatingFuel;
  }

  set heatingFuel(value) {
    this._heatingFuel = value;
  }

  get coolingEfficiency() {
    return this._coolingEfficiency;
  }

  set coolingEfficiency(value) {
    this._coolingEfficiency = value;
  }

  get waterHeatingType() {
    return this._waterHeatingType;
  }

  set waterHeatingType(value) {
    this._waterHeatingType = value;
  }

  get waterHeatingSource() {
    return this._waterHeatingSource;
  }

  set waterHeatingSource(value) {
    this._waterHeatingSource = value;
  }

  get waterHeatingFuel() {
    return this._waterHeatingFuel;
  }

  set waterHeatingFuel(value) {
    this._waterHeatingFuel = value;
  }

  get waterHeatingPlacement() {
    return this._waterHeatingPlacement;
  }

  set waterHeatingPlacement(value) {
    this._waterHeatingPlacement = value;
  }

  get heatingValueEditable() {
    return this._heatingValueEditable;
  }

  set heatingValueEditable(value) {
    this._heatingValueEditable = value;
  }

  get showNaturalUnit() {
    return this._showNaturalUnit;
  }

  set showNaturalUnit(value) {
    this._showNaturalUnit = value;
  }

  get subdevicesEnergyConsumption() {
    return this._subdevicesEnergyConsumption;
  }

  set subdevicesEnergyConsumption(value) {
    this._subdevicesEnergyConsumption = value;
  }

  get irrData() {
    return this._irrData;
  }

  set irrData(value) {
    this._irrData = value;
  }

  get waterSubdevicesEnergyConsumption() {
    return this._waterSubdevicesEnergyConsumption;
  }

  set waterSubdevicesEnergyConsumption(value) {
    this._waterSubdevicesEnergyConsumption = value;
  }

  get electricityEmissionCoefficient() {
    return this._electricityEmissionCoefficient;
  }

  set electricityEmissionCoefficient(value) {
    this._electricityEmissionCoefficient = value;
  }

  get roof() {
    return this._roof;
  }

  set roof(value) {
    this._roof = value;
  }

  get wall() {
    return this._wall;
  }

  set wall(value) {
    this._wall = value;
  }

  get floor() {
    return this._floor;
  }

  set floor(value) {
    this._floor = value;
  }
}
