import { fromWattsToKwhRatio } from "../components/Helpers/ConvertToUnit";

export function getPvEnergyCalculated(
  dataResults,
  livingNeedsChanged,
  livingNeeds
) {
  const { yearlyAggregatedConvertedData, conversionData } = dataResults;
  const energyProduced = dataResults
    ? fromWattsToKwhRatio() * yearlyAggregatedConvertedData.enPhotovoltaicGain
    : 0;

  const basicEnergy = dataResults
    ? fromWattsToKwhRatio() *
      yearlyAggregatedConvertedData.enPhotovoltaicGainNormalized
    : 0;

  const enElectricDevicesEnergyLossNormalized =
    fromWattsToKwhRatio() *
    yearlyAggregatedConvertedData.enElectricDevicesEnergyLossNormalized;
  const enElectricVentilationEnergyLoss =
    fromWattsToKwhRatio() *
    yearlyAggregatedConvertedData.enElectricVentilationEnergyLoss;
  const enWaterElectricDevicesEnergyLoss =
    fromWattsToKwhRatio() *
    yearlyAggregatedConvertedData.enWaterElectricDevicesEnergyLoss;
  const airConditioningDeviceEnergy =
    fromWattsToKwhRatio() *
    yearlyAggregatedConvertedData.airConditioningDeviceEnergy;

  let enLivingNeeds = 0;
  if (!livingNeedsChanged) {
    enLivingNeeds =
      fromWattsToKwhRatio() * yearlyAggregatedConvertedData.enLivingNeeds;
  } else {
    enLivingNeeds = livingNeeds;
  }

  const heatingDeviceEnergy =
    fromWattsToKwhRatio() * yearlyAggregatedConvertedData.heatingDeviceEnergy;
  const heatingWaterDeviceEnergy =
    fromWattsToKwhRatio() *
    yearlyAggregatedConvertedData.heatingWaterDeviceEnergy;
  const enRecuperator =
    fromWattsToKwhRatio() * yearlyAggregatedConvertedData.enRecuperator;
  const enHumidification =
    fromWattsToKwhRatio() * yearlyAggregatedConvertedData.enHumidification;
  const isACOn = conversionData.airConditioning;
  const isElectric = conversionData.isElectric;
  const isWaterElectric = conversionData.isWaterElectric;
  

  const energyCo =
    enElectricDevicesEnergyLossNormalized +
    enElectricVentilationEnergyLoss +
    enWaterElectricDevicesEnergyLoss +
    enRecuperator +
    enHumidification +
    (isACOn ? airConditioningDeviceEnergy : 0) +
    (isElectric ? heatingDeviceEnergy : 0) +
    (isWaterElectric ? heatingWaterDeviceEnergy : 0);

  const sum = energyCo + enLivingNeeds;

  const energyAvailable = dataResults ? Math.max(basicEnergy - sum, 0) : 0;
  const energyToBuy = dataResults ? Math.max(sum - basicEnergy, 0) : 0;
  
  
  return {
    basicEnergy: basicEnergy,
    energyProduced: energyProduced,
    enLivingNeeds: enLivingNeeds,
    energyToBuy: energyToBuy,
    energyAvailable: energyAvailable,
    energyCo: energyCo,
    sum: sum,
  };
}
