import { BaseComboObject } from "./BaseComboObject";
import { HeatingType } from "../../../constants/HeatingType";

export class HeatingSourceType extends BaseComboObject {
  static items = [];
  _naturalUnit;
  _naturalUnitPrice;
  _heatingValueMJ;
  _calorificValueUnit;
  _pricePerKWh;
  _emissionPerKWh;
  _finalToPrimaryCoefficient;
  _showNaturalUnit;
  _heatingValueEditable;
  _heatingType;
  obsolescence;
  price;

  constructor(
    id,
    naturalUnit,
    naturalUnitPrice,
    heatingValueMJ,
    calorificValueUnit,
    emissionPerKWh,
    finalToPrimaryCoefficient,
    showNaturalUnit,
    heatingValueEditable
  ) {
    super(id);
    this._naturalUnit = naturalUnit;
    this._naturalUnitPrice = naturalUnitPrice;
    this._heatingValueMJ = heatingValueMJ;
    this._calorificValueUnit = calorificValueUnit;
    this._pricePerKWh = HeatingSourceType.toPricePerKWh(
      naturalUnitPrice,
      heatingValueMJ,
      naturalUnit,
      calorificValueUnit
    );
    this._emissionPerKWh = emissionPerKWh;
    this._finalToPrimaryCoefficient = finalToPrimaryCoefficient;
    this._showNaturalUnit = showNaturalUnit;
    this._heatingValueEditable = heatingValueEditable;
    HeatingSourceType.items.push(this);
  }

  static getHeatingSourceTypeById = id => {
    for (let i = 0; i < HeatingSourceType.items.length; i++) {
      let heatingSourceType = HeatingSourceType.items[i];
      if (heatingSourceType.id === id) {
        return heatingSourceType;
      }
    }
    return null;
  };

  static isOfGasType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.GAS;
  };

  static isOfOilType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.OIL;
  };

  static isOfElectricType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.ELECTRIC;
  };

  static isOfCoalType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.COAL;
  };

  static isOfCombinedType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.COMBINED;
  };

  static isOfPelletsType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.PELLETS;
  };

  static isOfWoodType = heatingSourceType => {
    return heatingSourceType.heatingType === HeatingType.WOOD;
  };

  static toPricePerKWh = (
    naturalUnitPrice,
    heatingValueMJ,
    naturalUnit,
    calorificValueUnit
  ) => {
    let ratio = 1;
    if (typeof calorificValueUnit !== "undefined") {
      if (
        (naturalUnit.code === "hm.natural_unit.tonne" ||
          naturalUnit === "hm.natural_unit.tonne") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 1 / 1000;
      } else if (
        (naturalUnit.code === "hm.natural_unit.kubik1" ||
          naturalUnit === "hm.natural_unit.kubik1") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 1 / 450;
      } else if (
        (naturalUnit.code === "hm.natural_unit.kubik2" ||
          naturalUnit === "hm.natural_unit.kubik2") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 1 / 320;
      }
    }
    return (naturalUnitPrice / heatingValueMJ) * 3.6 * ratio;
  };

  static toNaturalPrice = (
    pricePerKWh,
    heatingValueMJ,
    naturalUnit,
    calorificValueUnit
  ) => {
    let ratio = 1;
    if (typeof calorificValueUnit !== "undefined") {
      if (
        (naturalUnit.code === "hm.natural_unit.tonne" ||
          naturalUnit === "hm.natural_unit.tonne") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 1000;
      } else if (
        (naturalUnit.code === "hm.natural_unit.kubik1" ||
          naturalUnit === "hm.natural_unit.kubik1") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 320;
      } else if (
        (naturalUnit.code === "hm.natural_unit.kubik2" ||
          naturalUnit === "hm.natural_unit.kubik2") &&
        calorificValueUnit === "MJ/kg"
      ) {
        ratio = 450;
      }
    }
    return (heatingValueMJ / 3.6) * pricePerKWh * ratio;
  };

  get naturalUnit() {
    return this._naturalUnit;
  }

  set naturalUnit(value) {
    this._naturalUnit = value;
  }

  get naturalUnitPrice() {
    return this._naturalUnitPrice;
  }

  set naturalUnitPrice(value) {
    this._naturalUnitPrice = value;
  }

  get heatingValueMJ() {
    return this._heatingValueMJ;
  }

  set heatingValueMJ(value) {
    this._heatingValueMJ = value;
  }

  get calorificValueUnit() {
    return this._calorificValueUnit;
  }

  set calorificValueUnit(value) {
    this._calorificValueUnit = value;
  }

  get emissionPerKWh() {
    return this._emissionPerKWh;
  }

  set emissionPerKWh(value) {
    this._emissionPerKWh = value;
  }

  get finalToPrimaryCoefficient() {
    return this._finalToPrimaryCoefficient;
  }

  set finalToPrimaryCoefficient(value) {
    this._finalToPrimaryCoefficient = value;
  }

  get showNaturalUnit() {
    return this._showNaturalUnit;
  }

  set showNaturalUnit(value) {
    this._showNaturalUnit = value;
  }

  get heatingValueEditable() {
    return this._heatingValueEditable;
  }

  set heatingValueEditable(value) {
    this._heatingValueEditable = value;
  }

  get pricePerKWh() {
    return this._pricePerKWh;
  }

  set pricePerKWh(value) {
    this._pricePerKWh = value;
  }
}
