import React from "react";
import { connect } from "react-redux";
import { getPersons } from "../../redux/selectors";

import { FormattedMessage, injectIntl } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import PersonalGainInput from "./PersonalGainInput";

class PersonalGainBaloon extends HeatMaster {
  constructor(props) {
    super(props);

    this.state = {
      showBaloon: window.innerWidth >= 1024,
      show: true,
    };
  }

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  render() {
    return (
      <Card
        className={cx(
          "baloon personal-gain-baloon popover bs-popover-right  d-flex align-items-center",
          { hidden: !this.state.showBaloon }
        )}
      >
        <FormattedMessage id="hm.label.residual_heat" defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={22}
                icon="vital_heat_icon"
              />
            </span>
          )}
        </FormattedMessage>
        <div className="arrow m-0" />
        <div className={"popover-body"}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="4"
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2"}
                  iconSet={iconSet}
                  size={16}
                  icon="vital_heat_icon"
                />
                <FormattedMessage
                  id="hm.label.residual_heat"
                  defaultMessage=""
                  tagName={"h3"}
                >
                  {message => <h3 className={"mb-0"}>{message}</h3>}
                </FormattedMessage>
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1", {
                  reversed: !this.state.show,
                })}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <PersonalGainInput writeCache={() => this.props.writeCache()} />
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={"hm.tooltip.residualheat"}
            placement={"right"}
          />
        </div>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  const persons = getPersons(state);
  return { persons };
};
export default injectIntl(connect(mapStateToProps)(PersonalGainBaloon));
