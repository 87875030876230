import React from "react";

import { ShadowLevel } from "./ShadowLevel";
import cx from "classnames";
import { FormattedMessage } from "react-intl";

export function Trees(props) {
  const { showDetailedData, id, valueDistance, valueLevel } = props;
  return (
    <div
      className={cx("row align-items-center mb-1", {
        "d-none": !showDetailedData,
      })}
    >
      <div className={"offset-1 col-4 pr-0 mb-1"}>
        <FormattedMessage
          id="hm.label.shading.trees.distance"
          defaultMessage=""
        >
          {message => <p className={"mb-0 lh-100"}>{message}</p>}
        </FormattedMessage>
      </div>
      <div className={"col-7 mb-1"}>
        <select
          className={"form-control"}
          onChange={e =>
            props.insolationChanged(id + "_period", e.target.value)
          }
          value={valueDistance}
        >
          <FormattedMessage
            id="hm.label.shading.trees.distance.near"
            defaultMessage=""
          >
            {message => (
              <option key={"shading-distance-near"} value={"near"}>
                {message}
              </option>
            )}
          </FormattedMessage>
          <FormattedMessage
            id="hm.label.shading.trees.distance.far"
            defaultMessage=""
          >
            {message => (
              <option key={"shading-distance-far"} value={"far"}>
                {message}
              </option>
            )}
          </FormattedMessage>
        </select>
      </div>
      <ShadowLevel
        showDetailedData={showDetailedData}
        insolationChanged={(id, val) => props.insolationChanged(id, val)}
        id={id}
        valueLevel={valueLevel}
      />
    </div>
  );
}
