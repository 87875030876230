/**
 *
 * @param invest
 * @param energy
 * @param newEnergy
 * @param years
 * @param price
 * @param newPrice
 * @param growthRate
 * @param electricEnergyCost
 * @param waterEnergyCost
 * @returns {*}
 * @constructor
 */

export function IrrCounter(
  invest,
  energy,
  newEnergy,
  years,
  price,
  newPrice,
  growthRate,
  electricEnergyCost = 0,
  waterEnergyCost = 0
) {
  const IRR = (cashflow, initialGuess = 0.1) => {
    const maxTries = 10000;
    const delta = 0.001;
    let guess = initialGuess;
    const multiplier = NPV(cashflow, guess) > 0 ? 1 : -1;
    let i = 0;
    while (i < maxTries) {
      const guessedNPV = NPV(cashflow, guess);
      if (multiplier * guessedNPV > delta) {
        guess += multiplier * delta;
        i += 1;
      } else break;
    }

    return i === 10000 ? 0 : guess * 100;
  };

  const projectedCashFlowArray = projectedCashFlow(
    years,
    energy * price -
      newEnergy * newPrice +
      electricEnergyCost +
      waterEnergyCost,
    growthRate
  );
  const cashflow = [-1 * invest, ...projectedCashFlowArray];
  return IRR(cashflow);
}
export const projectedCashFlow = (years, invest, growthRate) => {
  return Array(years)
    .fill(0)
    .map((el, i) => invest * Math.pow(1 + growthRate / 100, i));
};

export const NPV = (cashflow, discountRate) =>
  cashflow.reduce(
    (acc, val, i) => acc + val / Math.pow(1 + discountRate, i),
    0
  );

export const NPVHM = (cashflow, discountRate) => {
  return cashflow.reduce(
    (acc, val, i) => acc + val * Math.pow(1 + discountRate / 100, i),
    0
  );
};

export const projectedCashFlowHM = (
  invest,
  years,
  profit,
  growthRate,
  discountRate
) => {
  return (
    NPVHM(
      Array(years)
        .fill(0)
        .map((el, i) => profit / Math.pow(1 + growthRate / 100, i)),
      discountRate
    ) - invest
  );
};
