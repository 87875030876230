import { HeatMaster } from "./HeatMaster";

export class HeatMasterResult extends HeatMaster {
  constructor(props) {
    super(props);

    this.state = {};
  }

  resultCalculated(resultData, month) {}

  comparingResultCalculated = (comparingResult, month) => {
    if (this.calculationStateController.isInReferenceOrInitialState()) {
      this.resultCalculated(comparingResult.refProcessingResult, month);
    } else {
      this.resultCalculated(comparingResult.newProcessingResult, month);
    }
  };

  unitChanged(resultData, month, unit) {}

  render() {}
}
