export const getWindows = store => store.windows;
export const getVentilation = store => store.ventilation;
export const getPersons = store => store.persons;
export const getInsulation = store => store.insulation;
export const getHouse = store => store.house;
export const getHeating = store => store.heating;
export const getConversion = store => store.conversion;
export const getPV = store => store.photovoltaic;
export const getAC = store => store.airconditioning;
export const getInsolation = store => store.insolation;
export const getReports = store => store.reports;
export const getCosts = store => store.costs;
export const getResults = store => store.results;