import { OptimizerResults } from "./OptimizerResults";
import { WindowOptimizer } from "./optimizers/WindowOptimizer";
import { DoorOptimizer } from "./optimizers/DoorOptimizer";
import { WallInsulationOptimizer } from "./optimizers/WallInsulationOptimizer";
import { PVOptimizer } from "./optimizers/PVOptimizer";
import { WaterStorageOptimizer } from "./optimizers/WaterStorageOptimizer";
import { ShuttersOptimizer } from "./optimizers/ShuttersOptimizer";
import { HeatingSourceOptimizer } from "./optimizers/HeatingSourceOptimizer";
import { FoundationOptimizer } from "./optimizers/FoundationOptimizer";
import { ThermalBridgesOptimizer } from "./optimizers/ThermalBridgesOptimizer";
import { VentilationOptimizer } from "./optimizers/VentilationOptimizer";
import { HouseTypeHelper } from "../../util/HouseTypeHelper";
import { AirConditioningOptimizer } from "./optimizers/AirConditioningOptimizer";
import { CollectorsOptimizer } from "./optimizers/CollectorsOptimizer";
import { RoofInsulationOptimizer } from "./optimizers/RoofInsulationOptimizer";
import { FloorInsulationOptimizer } from "./optimizers/FloorInsulationOptimizer";

export class OptimizerDataContextValidator {
  constructor(dictionary) {
    this.dictionaryConfig = dictionary;
    this.wallInsulationList =
      dictionary.insulationMaterialTypeConfig.wallInsulationMaterialTypes;
    this.floorInsulationList =
      dictionary.insulationMaterialTypeConfig.floorInsulationMaterialTypes;
    this.foundationList = dictionary.foundationConfiguration.foundationsType;
    this.roofInsulationList =
      dictionary.insulationMaterialTypeConfig.roofInsulationMaterialTypes;
    this.basementWallInsulationList =
      dictionary.insulationMaterialTypeConfig.basementWallInsulationMaterialTypes;
    this.basementFloorInsulationList =
      dictionary.insulationMaterialTypeConfig.basementFloorInsulationMaterialTypes;
    this.windowsList = dictionary.windowTypeConfiguration.windowTypes;
    this.doorList = dictionary.doorTypeConfig.doorTypes;
    this.thermalBridgesList =
      dictionary.thermalBridgesTypeConfig.thermalBridgesTypes;
    this.warmWaterStorageList =
      dictionary.waterStorageDistributionConfiguration.waterStorageTypes;
    this.heatingDevicePrices =
      dictionary.heatingDeviceTypeConfiguration.devicePricesTypesMap;
    this.ventilationList =
      dictionary.ventilationTypeConfiguration.ventilationTypes;
    this.airTightnessList = dictionary.tightnessConfiguration.tightnessItems;
    this.solarCollectorTypesList =
      dictionary.solarCollectorsTypeConfig.solarCollectorTypes;
  }
  dictionaryConfig;

  validate = (
    isInReferenceState,
    dataContext,
    newDataContext,
    refResult,
    newResult
  ) => {
    let results = new OptimizerResults();
    const refPricePerKWh = dataContext.conversionData.pricePerKWh;
    const newPricePerKWh = newDataContext.conversionData.pricePerKWh;
    const houseData = dataContext.houseData;
    const newHouseData = newDataContext.houseData;
    const heatingData = dataContext.heatingData;
    const newHeatingData = newDataContext.heatingData;
    let warmWaterData = houseData.warmWaterData;
    let newWarmWaterData = newHouseData.warmWaterData;
    const refCosts = dataContext.costs;
    const newCosts = newDataContext.costs;

    let doorOptimizer = new DoorOptimizer(
      isInReferenceState,
      this.doorList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.doorType = doorOptimizer.validate(
      "doorType",
      results.doorType,
      houseData.doorType,
      newHouseData.doorType,
      2,
      refCosts,
      newCosts
    );
    if (
      !HouseTypeHelper.hasNeighboursDown(newHouseData.houseType) &&
      houseData.basement.enabled === false &&
      newHouseData.basement.enabled === false
    ) {
      let floorInsulationOptimizer = new FloorInsulationOptimizer(
        isInReferenceState,
        this.floorInsulationList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.floorInsulation = floorInsulationOptimizer.validate(
        "floorInsulation",
        results.floorInsulation,
        houseData.floorElement,
        newHouseData.floorElement,
        newHouseData.surfaceData.floorSurface,
        refCosts,
        newCosts
      );
    }
    if (!HouseTypeHelper.hasNeighboursDown(newHouseData.houseType)) {
      let foundationOptimizer = new FoundationOptimizer(
        isInReferenceState,
        this.foundationList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.foundationType = foundationOptimizer.validate(
        "foundationType",
        results.foundationType,
        houseData.foundationType,
        newHouseData.foundationType,
        newHouseData.surfaceData.floorSurface,
        refCosts,
        newCosts
      );
    }
    let wallInsulationOptimizer = new WallInsulationOptimizer(
      isInReferenceState,
      this.wallInsulationList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.wallInsulation = wallInsulationOptimizer.validate(
      "wallInsulation",
      results.wallInsulation,
      houseData.wallElement,
      newHouseData.wallElement,
      houseData.surfaceData.insulatedWallSurface,
      newHouseData.surfaceData.insulatedWallSurface,
      refCosts,
      newCosts
    );

    if (!HouseTypeHelper.hasNeighboursUp(newHouseData.houseType)) {
      let roofInsulationOptimizer = new RoofInsulationOptimizer(
        isInReferenceState,
        this.roofInsulationList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.roofInsulation = roofInsulationOptimizer.validate(
        "roofInsulation",
        results.roofInsulation,
        houseData.roofElement,
        newHouseData.roofElement,
        newHouseData.surfaceData.roofSurface,
        refCosts,
        newCosts
      );
    }

    if (
      houseData.basement.enabled === true &&
      newHouseData.basement.enabled === true
    ) {
      const wallsData = houseData.wallsData;
      const basementData = houseData.basement;
      let basementWallInsulationOptimizer = new WallInsulationOptimizer(
        isInReferenceState,
        this.basementWallInsulationList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.basementWallInsulation = basementWallInsulationOptimizer.validate(
        results.basementWallInsulation,
        houseData.basementWallElement,
        newHouseData.basementWallElement,
        this.calcWallSurface(wallsData) * basementData.height,
        refCosts,
        newCosts
      );

      let basementFloorInsulationOptimizer = new WallInsulationOptimizer(
        isInReferenceState,
        this.basementFloorInsulationList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.basementFloorInsulation = basementFloorInsulationOptimizer.validate(
        results.basementFloorInsulation,
        houseData.basementFloorElement,
        newHouseData.basementFloorElement,
        newHouseData.surfaceData.floorSurface,
        refCosts,
        newCosts
      );
    }
    if (houseData.pvData.pv_type > 0) {
      let pvOptimizer = new PVOptimizer(
        isInReferenceState,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.pvType = pvOptimizer.validate(
        "pvType",
        results.pvType,
        houseData.pvData,
        newHouseData.pvData,
        newHouseData,
        refResult,
        newResult,
        refCosts,
        newCosts
      );
    }

    let collectorsOptimizer = new CollectorsOptimizer(
      isInReferenceState,
      this.solarCollectorTypesList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.collectors = collectorsOptimizer.validate(
      "collectors",
      results.collectors,
      dataContext.solarCollectorData,
      newDataContext.solarCollectorData,
      refResult.yearlyAggregatedConvertedData,
      newResult.yearlyAggregatedConvertedData,
      refCosts,
      newCosts
    );

    let windowOptimizer = new WindowOptimizer(
      isInReferenceState,
      this.windowsList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.windows = windowOptimizer.validate(
      "windows",
      results.windows,
      houseData.windowElement,
      newHouseData.windowElement,
      newHouseData.surfaceData.windowsSurface,
      refCosts,
      newCosts
    );

    let thermalBridgesOptimizer = new ThermalBridgesOptimizer(
      isInReferenceState,
      this.thermalBridgesList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.thermalBridges = thermalBridgesOptimizer.validate(
      "thermalBridges",
      results.thermalBridges,
      houseData.windowElement,
      newHouseData.windowElement,
      newHouseData.surfaceData.windowsSurface,
      refCosts,
      newCosts
    );

    let shuttersOptimizer = new ShuttersOptimizer(
      isInReferenceState,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.shutters = shuttersOptimizer.validate(
      "shutters",
      results.shutters,
      houseData.windowElement.shutters,
      newHouseData.windowElement.shutters,
      newHouseData.surfaceData.windowsSurface,
      refCosts,
      newCosts
    );
    let airConditioningOptimizer = new AirConditioningOptimizer(
      isInReferenceState,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.airConditioning = airConditioningOptimizer.validate(
      "airConditioning",
      results.airConditioning,
      heatingData,
      newHeatingData,
      houseData.acData.airconditioning,
      newHouseData.acData.airconditioning,
      newHouseData.surfaceData.totalSurface,
      refCosts,
      newCosts
    );

    let heatingSourceOptimizer = new HeatingSourceOptimizer(
      isInReferenceState,
      this.heatingDevicePrices,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh
    );
    results.heatingSource = heatingSourceOptimizer.validate(
      "heatingSource",
      results.heatingSource,
      heatingData,
      newHeatingData,
      houseData,
      newHouseData,
      dataContext.includeWarmWater,
      newDataContext.includeWarmWater,
      refResult.wattsEnergyResult.heatingSourceMaxPower,
      newResult.wattsEnergyResult.heatingSourceMaxPower,
      refResult.yearlyAggregatedConvertedData,
      newResult.yearlyAggregatedConvertedData,
      newHouseData.surfaceData.totalSurface,
      refCosts,
      newCosts
    );

    let ventilationOptimizer = new VentilationOptimizer(
      isInReferenceState,
      this.ventilationList,
      houseData.buildingType,
      refPricePerKWh,
      newPricePerKWh,
      newHouseData.surfaceData.totalSurface
    );
    results.ventilation = ventilationOptimizer.validate(
      "ventilation",
      results.ventilation,
      houseData.ventilationData,
      newHouseData.ventilationData,
      newHouseData.houseType,
      newHouseData.personNumber,
      newHouseData.surfaceData.totalSurface,
      refCosts,
      newCosts
    );
    if (
      dataContext.includeWarmWater === true &&
      newDataContext.includeWarmWater === true &&
      warmWaterData.warmWaterDistribution.efficiency >
        newWarmWaterData.warmWaterDistribution.efficiency
    ) {
      results.waterDistribution.state = false;
    }

    if (newDataContext.includeWarmWater === true) {
      let waterStorageOptimizer = new WaterStorageOptimizer(
        isInReferenceState,
        this.warmWaterStorageList,
        houseData.buildingType,
        refPricePerKWh,
        newPricePerKWh
      );
      results.waterStorage = waterStorageOptimizer.validate(
        "waterStorage",
        results.waterStorage,
        warmWaterData.warmWaterStorage,
        newWarmWaterData.warmWaterStorage,
        refCosts,
        newCosts
      );
    }

    if (heatingData == null) {
      throw new Error("No HeatingData");
    }
    return results;
  };

  calcWallSurface = wallsData => {
    let wallSurface = 0;
    wallsData.map(item => {
      wallSurface += item.insulated ? item.width : 0;
      return item;
    });
    return wallSurface;
  };
}
