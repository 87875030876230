import { BaseComboObject } from "./BaseComboObject";

export class WarmWaterStorage extends BaseComboObject {
  static items = [];

  _efficiency;
  _usage;

  constructor(id, efficiency, usage) {
    super(id);
    this._efficiency = efficiency;
    this._usage = usage;
    WarmWaterStorage.items.push(this);
  }

  static getWarmWaterStorageById = id => {
    for (let i = 0; i < WarmWaterStorage.items.length; i++) {
      let warmWaterStorage = WarmWaterStorage.items[i];
      if (warmWaterStorage.id === id) {
        return warmWaterStorage;
      }
    }
    return null;
  };

  get efficiency() {
    return this._efficiency;
  }

  get usage() {
    return this._usage;
  }
}
