import { BaseComboObject } from "./BaseComboObject";

export class WarmWaterConsumptionType extends BaseComboObject {
  static valuesMap = [];
  _intensity;

  constructor(id, intensity) {
    super(id);
    this._intensity = intensity;
    WarmWaterConsumptionType.valuesMap[id] = this;
  }

  get intensity() {
    return this._intensity;
  }

  static getWarmWaterConsumptionForIntensity(id) {
    return WarmWaterConsumptionType.valuesMap[id];
  }
}
