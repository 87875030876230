import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class LivingNeedsCalculator extends IMonthEnergyCalculator {
  calcEnergy(energyData, contextData, monthInputData) {
    const personNumber = contextData.houseData.personNumber;
    const livingNeeds = contextData.houseData.livingNeeds;
    const livingNeedsChanged = contextData.houseData.livingNeedsChanged;
    const houseData = contextData.houseData;

    let enLivingNeeds =
      1000 +
      (personNumber - 1) * 400 +
      (houseData.surfaceData.totalSurface - 50) * 10;
    if (livingNeedsChanged) {
      enLivingNeeds = livingNeeds;
    }
    energyData.enLivingNeeds = EnergyCalcHelper.convertkWhPerYearToWatts(
      enLivingNeeds
    );
    return energyData;
  }
}
