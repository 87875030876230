export class SolarCollectorData {

    _collectorType;
    _surface = 0;
    _count;

     static createSolarCollectorData = (collectorType,surface,count = 0) => {
        let result = new SolarCollectorData();
        result.collectorType = collectorType;
        result.surface = surface;
        result.count = count;
        return result;
    };

     static createSolarCollectorDataWithCountCalculated = (collectorType,surface) => {
        let result = new SolarCollectorData();
        result.collectorType = collectorType;
        result.surface = surface;
        result.count = surface/collectorType.unitSurface;
        return result;
    };

    get collectorType() {
        return this._collectorType;
    }

    set collectorType(value) {
        this._collectorType = value;
    }

    get surface() {
        return this._surface;
    }

    set surface(value) {
        this._surface = value;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value;
    }
}
