import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";

export class SolarCollectorEnergyGainCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    energyData.enCollectorSolarGain = 0;
    if (
      contextData.includeWarmWater &&
      HouseTypeHelper.hasRegularRoof(contextData.houseData.houseType)
    ) {
      let solarCollectorData = contextData.solarCollectorData;

      let enCollectorSolarGain =
        solarCollectorData.collectorType.efficiency *
        solarCollectorData.surface *
        monthInputData.groundInsolation45;
      energyData.enCollectorSolarGain = EnergyCalcHelper.convertkWhPerMonthToWatts(
        enCollectorSolarGain
      );
    }
    return energyData;
  };
}
