import {OptimizerResultsState} from "../OptimizerResultsState";

export class ShuttersOptimizer {
    constructor(isInReferenceState, buildingType,refPricePerKWh, newPricePerKWh){
        this.buildingType = buildingType;
        this.refPricePerKWh = refPricePerKWh;
        this.newPricePerKWh = newPricePerKWh;
        this.isInReferenceState = isInReferenceState;
    }
    elementList;

    validate = (type, result, element, newElement, surface, refCosts, newCosts) => {
        
        result.state = OptimizerResultsState.THE_SAME;

        result.refItem =  element ? {
                "id": "hm.windows.shutters",
                "labelKey": "hm.windows.shutters",
                "value": 0,
                "price": 300,
                "priceUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
                "work": 20,
                "workUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
                "obsolescence": 50
        }
        : {
            "id": "hm.windows.no_shutters",
            "labelKey": "hm.windows.no_shutters",
            "value": 0,
            "price": 0,
            "priceUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
            "work": 0,
            "workUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
            "obsolescence": 50
        };
        result.newItem =  newElement ? {
            "id": "hm.windows.shutters",
            "labelKey": "hm.windows.shutters",
            "value": 0,
            "price": 300,
            "priceUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
            "work": 20,
            "workUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
            "obsolescence": 50
        }
        : {
                "id": "hm.windows.no_shutters",
                "labelKey": "hm.windows.no_shutters",
                "value": 0,
                "price": 0,
                "priceUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
                "work": 0,
                "workUnit": "hm.optimizer.thermoderm.cost.material.unit.zlm2",
                "obsolescence": 50
            };
        result.obsolescence = 50;

        if(element === true &&  newElement === false){
            result.state = OptimizerResultsState.WORSE;
            
        } else if(element === false &&  newElement === true){
            result.state = OptimizerResultsState.BETTER;
            result.refPricePerKWh = this.refPricePerKWh;
            result.newPricePerKWh = this.newPricePerKWh;

            let materialCost = result.refItem.price;
            let newMaterialCost = result.newItem.price;
            let workCost = result.refItem.work;
            let newWorkCost = result.newItem.work;

            if(refCosts[type+'_material_changed']){
                materialCost = refCosts[type + '_material'];
            }
            if(newCosts[type+'_material_changed']){
                newMaterialCost = newCosts[type + '_material'];
            }
            if(refCosts[type+'_work_changed']){
                workCost = refCosts[type+'_work'];
            }
            if(newCosts[type+'_work_changed']){
                newWorkCost = newCosts[type+'_work'];
            }

            result.work = this.buildingType === 'current'
                ? this.calculateWork(newWorkCost, surface)
                : this.calculateWorkDifference(workCost, newWorkCost, surface);
            result.material = this.buildingType === 'current'
                ? this.calculateMaterial(newMaterialCost, surface)
                : this.calculateMaterialDifference(materialCost, newMaterialCost, surface);
            result.obsolescence = result.newItem.obsolescence;
            result.cost = result.work + result.material;
            result.service = 0;
            result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
            result.materialPrice = this.isInReferenceState ? materialCost : newMaterialCost;
            result.costView = result.workPrice + result.materialPrice;
        }

        return result;
    };

    calculateMaterial = (price, surface) => {
        return (price * surface);
    };

    calculateMaterialDifference = (price, newPrice, surface) => {
        return ((newPrice-price) * surface);
    };

    calculateWork = (work, surface) => {
        return (work * surface);
    };

    calculateWorkDifference = (work, newWork, surface) => {
        return ((newWork-work) * surface);
    };
}