import {UPDATE_INSULATION, UPDATE_INSULATION_BY_TYPE, UPDATE_INSULATION_BY_TYPE_AND_ID,
    UPDATE_INSULATION_BASEMENT_BY_TYPE} from "../actionTypes";

const initialState = {
    roof: {
        material: 'hm.insulation.material.styrofoam_standard',
        width: 17,
        uValue: 0.2,
        heatTransfer: 0.045,
        u0: 1/0.5
    },
    wall: {
        material: 'hm.insulation.material.styrofoam_standard',
        doorType: 'hm.door.standard',
        width: 14,
        uValue: 0.25,
        heatTransfer: 0.045,
        u0: 1/0.8
    },
    floor: {
        material: 'hm.insulation.material.styrofoam_xps',
        foundations: 'hm.foundations.traditional_insulated',
        width: 6,
        uValue: 0.25,
        heatTransfer: 0.031,
        u0: 1/2
    },
    basement: {
        wall: {
            material: 'hm.insulation.material.styrofoam_standard',
            width: 17,
            uValue: 0.2,
            heatTransfer: 0.045,
        },
        floor: {
            material: 'hm.insulation.material.styrofoam_standard',
            width: 12,
            uValue: 0.2,
            heatTransfer: 0.045,
        },
    }
};

export default function(state = initialState, action){

    switch(action.type){
        case UPDATE_INSULATION_BY_TYPE: {
            const {id, insulation} = action.payload;
            return {
                ...state,
                [id]: insulation
            };
        }
        case UPDATE_INSULATION_BY_TYPE_AND_ID: {
            const {id, type, value} = action.payload;
            return {
                ...state,
                [type]: {
                    ...state[type],
                    [id]: value

                }
            };
        }
        case UPDATE_INSULATION_BASEMENT_BY_TYPE: {
            const {id, insulation} = action.payload;
            return {
                ...state,
                basement: {
                    ...state.basement,
                    [id]: insulation
                }
            };
        }
        case UPDATE_INSULATION: {
            const insulationData = action.payload.insulationData;
            return {
                ...state,
                ...insulationData
            };
        }
        default:
            return state;
    }
}