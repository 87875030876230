import { ElectricityConsumption } from "../../constants/combo/ElectricityConsumption";
import { VentilationData } from "../../core/model/VentilationData";
import { WarmWaterData } from "./WarmWaterData";

export class HouseData {
  _houseType;
  _buildingType;
  _standardType;
  _buildingAge;
  showDetailedData;
  detailedDataChanged;

  _surfaceData;
  _wallElement;
  _roofElement;
  _floorElement;
  _basementWallElement;
  _basementFloorElement;
  _windowElement;
  _doorType;
  _ventilationData = new VentilationData();
  _warmWaterData = new WarmWaterData();
  _electricityConsumption = ElectricityConsumption.AVERAGE;
  _personNumber = 2;
  _livingNeeds;
  _livingNeedsChanged;

  _foundationType;

  _tIn;

  _walls;
  wallsData;
  roofData;
  _basement;

  _pvData;
  acData;
  _insolationData;

  _irrData;

  _toString = () =>
    "SurfaceData= " +
    this._surfaceData.toString() +
    "\n" +
    "wallElement: " +
    this._wallElement.toString() +
    "\n" +
    "houseType: " +
    this._houseType +
    "tIN: " +
    this._tIn +
    "\n" +
    "ventilationData: " +
    this._ventilationData.toString() +
    "\n";

  get buildingType() {
    return this._buildingType;
  }

  set buildingType(value) {
    this._buildingType = value;
  }

  get houseType() {
    return this._houseType;
  }

  set houseType(value) {
    this._houseType = value;
  }

  get standardType() {
    return this._standardType;
  }

  set standardType(value) {
    this._standardType = value;
  }

  get buildingAge() {
    return this._buildingAge;
  }

  set buildingAge(value) {
    this._buildingAge = value;
  }

  get surfaceData() {
    return this._surfaceData;
  }

  set surfaceData(value) {
    this._surfaceData = value;
  }

  get wallElement() {
    return this._wallElement;
  }

  set wallElement(value) {
    this._wallElement = value;
  }

  get roofElement() {
    return this._roofElement;
  }

  set roofElement(value) {
    this._roofElement = value;
  }

  get floorElement() {
    return this._floorElement;
  }

  set floorElement(value) {
    this._floorElement = value;
  }

  get basementWallElement() {
    return this._basementWallElement;
  }

  set basementWallElement(value) {
    this._basementWallElement = value;
  }

  get basementFloorElement() {
    return this._basementFloorElement;
  }

  set basementFloorElement(value) {
    this._basementFloorElement = value;
  }

  get windowElement() {
    return this._windowElement;
  }

  set windowElement(value) {
    this._windowElement = value;
  }

  get doorType() {
    return this._doorType;
  }

  set doorType(value) {
    this._doorType = value;
  }

  get ventilationData() {
    return this._ventilationData;
  }

  set ventilationData(value) {
    this._ventilationData = value;
  }

  get warmWaterData() {
    return this._warmWaterData;
  }

  set warmWaterData(value) {
    this._warmWaterData = value;
  }

  get electricityConsumption() {
    return this._electricityConsumption;
  }

  set electricityConsumption(value) {
    this._electricityConsumption = value;
  }

  get personNumber() {
    return this._personNumber;
  }

  set personNumber(value) {
    this._personNumber = value;
  }

  get livingNeeds() {
    return this._livingNeeds;
  }

  set livingNeeds(value) {
    this._livingNeeds = value;
  }

  get livingNeedsChanged() {
    return this._livingNeedsChanged;
  }

  set livingNeedsChanged(value) {
    this._livingNeedsChanged = value;
  }

  get foundationType() {
    return this._foundationType;
  }

  set foundationType(value) {
    this._foundationType = value;
  }

  get tIn() {
    return this._tIn;
  }

  set tIn(value) {
    this._tIn = value;
  }

  get basement() {
    return this._basement;
  }

  set basement(value) {
    this._basement = value;
  }

  get walls() {
    return this._walls;
  }

  set walls(value) {
    this._walls = value;
  }

  get pvData() {
    return this._pvData;
  }

  set pvData(value) {
    this._pvData = value;
  }

  get insolationData() {
    return this._insolationData;
  }

  set insolationData(value) {
    this._insolationData = value;
  }

  get irrData() {
    return this._irrData;
  }

  set irrData(value) {
    this._irrData = value;
  }
}
