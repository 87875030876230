import React, { useState } from "react";
import { connect } from "react-redux";

import { FormattedMessage, injectIntl } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import NumericInput from "react-numeric-input";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { getAC, getHeating } from "../../redux/selectors";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { Thermoderm } from "../Helpers/Thermoderm";

const AirConditioningBaloon = props => {
  const [showBaloon, setShowBaloon] = useState(window.innerWidth >= 1024);
  const show = true;

  const handleBaloonClick = () => {
    setShowBaloon(!showBaloon);
  };

  return (
    <Card
      className={cx(
        "baloon dropdown-baloon-air-conditioning popover bs-popover-right d-flex align-items-center",
        { hidden: !showBaloon }
      )}
    >
      <FormattedMessage id="hm.label.air_conditioning" defaultMessage="">
        {message => (
          <span
            title={message}
            className={"baloon-icon"}
            onClick={() => handleBaloonClick()}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={22}
              icon="air_condition_icon"
            />
          </span>
        )}
      </FormattedMessage>
      <div className="arrow m-0" />
      <div className={"popover-body"}>
        <div className={"content"}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="14"
            className={"d-flex align-items-center justify-content-between"}
          >
            <div className={"d-flex align-items-center"}>
              <IcomoonReact
                className={"custom-icon mr-2"}
                iconSet={iconSet}
                size={16}
                icon="air_condition_icon"
              />
              <FormattedMessage
                id="hm.label.air_conditioning"
                defaultMessage=""
                tagName={"h3"}
              >
                {message => <h3 className={"mb-0"}>{message}</h3>}
              </FormattedMessage>
            </div>
            <FontAwesomeIcon
              icon="caret-down"
              className={cx("icon-caret-toggle ml-1", {
                reversed: !show,
              })}
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="14">
            <Card.Body>
              <div className={"mb-1"}>
                <div
                  className={
                    "d-flex align-items-center justify-content-between"
                  }
                >
                  <label className={"mb-0 d-flex align-items-center mr-3"}>
                    <input
                      type="checkbox"
                      name={"air_conditioning"}
                      checked={props.ac.airconditioning}
                      onChange={e =>
                        props.acChanged("airconditioning", e.target.checked)
                      }
                    />
                    <FormattedMessage
                      id="hm.label.air_conditioning"
                      defaultMessage=""
                    >
                      {message => (
                        <p className={"mb-0 ml-1 pb-0 "}>{message}</p>
                      )}
                    </FormattedMessage>
                  </label>
                </div>
              </div>
              <div className={"mb-1"}>
                <div
                  className={
                    "d-flex align-items-center justify-content-between"
                  }
                >
                  <FormattedMessage
                    id="hm.label.air_conditioning.temp"
                    defaultMessage=""
                    tagName={"p"}
                  >
                    {message => <p className={"mb-0 mr-2"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={12}
                      max={30}
                      value={props.ac.temp}
                      onChange={e => props.acChanged("temp", e)}
                      className={"short-input"}
                    />
                    <div className={"text-small ml-2"}>
                      <sup>o</sup>C
                    </div>
                  </div>
                </div>
              </div>
              {props.invalidDataOptimizer &&
                props.invalidDataOptimizer.airConditioning.state ===
                  OptimizerResultsState.BETTER && (
                  <Thermoderm
                    data={[props.invalidDataOptimizer.airConditioning]}
                    className={"insulation-windows"}
                    writeCache={() => props.writeCache()}
                  />
                )}
            </Card.Body>
          </Accordion.Collapse>
        </div>
        <Popup
          show={show}
          message={"hm.tooltip.air_conditioning"}
          placement={"left"}
        />
      </div>
    </Card>
  );
};
const mapStateToProps = state => {
  let ac = getAC(state);
  let heating = getHeating(state);
  return { ac, heating };
};
export default injectIntl(connect(mapStateToProps)(AirConditioningBaloon));
