import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import connect from "react-redux/es/connect/connect";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import PieEnergyChartGroup from "../Charts/PieEnergyChartGroup";
import getMonthlyPlotData from "../../util/getMonthlyPlotData";

class AggregatedEnergyChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  unitName = "";

  warmWaterHeatingEfficiency;
  warmWaterIncluded = false;
  isCostUnit = false;
  yearlyCost = 0;

  getAdjustedEnergyUnitName = (value, selectedUnit) => {
    if (ConversionUnits.isGJUnit(selectedUnit)) {
      let prefixJ = "G";
      let rJ = value / 1000000;
      rJ = Math.pow(10, rJ);
      if (rJ >= 10) {
        prefixJ = "T";
      }
      if (rJ >= 1000) {
        prefixJ = "P";
      }
      return `${prefixJ}J`;
    } else {
      let prefix = "k";
      let r = value / 1000000;
      if (r >= 1) {
        prefix = "M";
      }
      if (r >= 1000) {
        prefix = "G";
      }
      if (r > 1000000) {
        prefix = "T";
      }
      return `${prefix}Wh`;
    }
  };

  getDataForChart = () => {
    let values = this.props
      .getMonthlyPlotData(this.props.dataResults, this.props.month)
      .map(value => {
        return {
          ...value,
          name:
            this.props.intl.formatMessage({
              id: "hm.dropdown.month_" + (value.name + 1),
            }) +
            " " +
            (value.electricDevices +
              value.heatingDeviceEnergy +
              value.heatingWaterDeviceEnergy +
              value.airConditioningDeviceEnergy +
              value.enPhotovoltaicGain +
              value.enCollectorsGain) +
            " " +
            this.props.getRawUnitName(
              this.props.dataResults.conversionData.selectedUnit,
              this.props.dataResults.conversionData.currencyName
            ),
        };
      });

    return values;
  };

  render() {
    const unit = this.props.getRawUnitName(
      this.props.dataResults.conversionData.selectedUnit,
      this.props.dataResults.conversionData.currencyName
    );
    const monthlyPlotData = this.props.dataResults
      ? getMonthlyPlotData(this.props.dataResults)
      : [];

    return (
      <div className={"chart aggregated-chart col-12"}>
        <ResponsiveContainer>
          <ComposedChart
            margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
            width={900}
            height={500}
            data={this.getDataForChart()}
            stackOffset="sign"
            maxBarSize={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              interval={0}
              tick={<CustomizedAxisTick unit={unit} />}
            ></XAxis>
            <YAxis interval={0} unit={unit} type="number" />
            <Tooltip />
            <ReferenceLine y={0} stroke="#000" />
            <Legend layout={"vertical"} align={"right"} verticalAlign="top" />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({
                id: "hm.label.technicalSystems",
              })}
              dataKey="_electricDevices"
              stackId="a"
              fill="#888888"
            />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({
                id: "hm.label.warm_water",
              })}
              dataKey="_heatingWaterDeviceEnergy"
              stackId="a"
              fill="#ff4b4b"
            />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({ id: "hm.label.heating" })}
              dataKey="_heatingDeviceEnergy"
              stackId="a"
              fill="#ec6d11"
            />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({ id: "hm.label.cooling" })}
              dataKey="_airConditioningDeviceEnergy"
              stackId="a"
              fill="#0088FE"
            />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({
                id: "hm.label.photovoltaic",
              })}
              dataKey="_enPhotovoltaicGain"
              stackId="a"
              fill="#ffe400"
            />
            <Bar
              unit={` ${unit}`}
              name={this.props.intl.formatMessage({
                id: "hm.label.solar_collectors",
              })}
              dataKey="_enCollectorsGain"
              stackId="a"
              fill="#ff9797"
            />
          </ComposedChart>
        </ResponsiveContainer>
        <div className={"pie-chart-groups"}>
          <PieEnergyChartGroup
            dataResults={this.props.dataResults}
            data={monthlyPlotData
              .filter(item => item.type === "loss" && item.value > 0)
              .map(item => {
                item.name = this.props.intl.formatMessage({ id: item.name });
                return item;
              })}
            type={"loss"}
            style={{ marginLeft: "-10px" }}
            legend={false}
          />
          <PieEnergyChartGroup
            dataResults={this.props.dataResults}
            data={monthlyPlotData
              .filter(item => item.type === "gain" && item.value > 0)
              .map(item => {
                item.name = this.props.intl.formatMessage({ id: item.name });
                return item;
              })}
            type={"gain"}
            legend={false}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};


export const CustomizedAxisTick = ({ x, y, payload, unit }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value.split(" ")[0]}
      </text>
      <text x={0} y={15} dy={16} textAnchor="middle" fill="#ff4b4b">
        {payload.value.split(" ")[1]} {unit}
      </text>
    </g>
  );
};
export default injectIntl(connect(mapStateToProps)(AggregatedEnergyChart));
