import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { DoorTypeConfig } from "../DoorTypeConfig";
import { DoorType } from "../model/DoorType";

export class DoorTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.doorTypeConfig = this.readDoorTypeConfig(
      dictionaryXML.door_types
    );
    return dictionaryConfig;
  };

  readDoorTypeConfig = doorTypesConfigXML => {
    let doorTypeConfig = new DoorTypeConfig();
    doorTypeConfig.selectedIndex = Number(doorTypesConfigXML.selected_index);
    let doorTypes = [];
    doorTypesConfigXML.door_type.forEach(doorTypeXML => {
      let doorType = this.readDoorType(doorTypeXML);
      doorTypes.push(doorType);
    });

    doorTypeConfig.doorTypes = doorTypes;
    return doorTypeConfig;
  };

  readDoorType = doorTypeXML => {
    let doorType = new DoorType(doorTypeXML.id, Number(doorTypeXML.u_value));
    this.fillBaseProperties(doorTypeXML, doorType);
    return doorType;
  };
}
