import {getPvEnergyCalculated} from "../../../util/getPvEnergyCalculated";

/**
 *
 * @param type
 * @param cancel
 * @param dataContext
 * @param heatMasterController
 * @param maxPower
 * @param pvType
 * @param defaultMaxPower
 * @param defaultPvType
 * @param defaultEnergyNetwork
 * @returns {{pv_power: (*), pv_type: (*), energy_network: (number|*)}}
 * @constructor
 */
export function PVOptimizer (
    type, cancel, dataContext, heatMasterController, maxPower, pvType,
    defaultMaxPower, defaultPvType, defaultEnergyNetwork
) {
    let i = maxPower;
    dataContext.houseData.pvData.pv_type = pvType > 1 ? pvType : 2;
    let energyResults;
    for (i; i < 100; i += 0.1) {
        
        dataContext.houseData.pvData.pv_power = i;
        dataContext.houseData.pvData.energy_network = (i > 10 ? 70 : 80);
        energyResults = heatMasterController.performCalculationForOptimizer(dataContext);
        const pvCalc = getPvEnergyCalculated(energyResults, dataContext.houseData.livingNeedsChanged,
            dataContext.houseData.livingNeeds);
        const enPV = pvCalc.basicEnergy;
        const energyAvailable =
            Math.max(enPV - pvCalc.sum, 0);
        if (energyAvailable > 0) {
            if (energyAvailable > 100) {
                i -= 0.5
            } else {
                break;
            }
        }
    }
    return {
        pv_type: !cancel
            ? dataContext.houseData.pvData.pv_type : defaultPvType,
        pv_power: !cancel ? i : defaultMaxPower,
        energy_network: !cancel ? (i > 10 ? 70 : 80) : defaultEnergyNetwork,
    }
}