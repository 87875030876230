export class HouseStandardTypeConfig {
  _houseStandardTypes;

  constructor(houseStandardTypes) {
    this._houseStandardTypes = houseStandardTypes;
  }

  get houseStandardTypes() {
    return this._houseStandardTypes;
  }

  set houseStandardTypes(value) {
    this._houseStandardTypes = value;
  }
}
