import { fromWattsToKwhRatio } from "../../../components/Helpers/ConvertToUnit";
import { projectedCashFlowHM } from "../../../util/IrrCounter";
import { HeatingSourceReducer } from "./HeatingSourceData";

export function HeatingSourceOptimizer(
  type,
  cancel,
  refDataContext,
  newDataContext,
  heatMasterController,
  reports,
  dictionaryConfig
) {
  const elementList =
    dictionaryConfig.heatingDeviceTypeConfiguration.devicePricesTypesMap;
  const heatingData = refDataContext.heatingData;
  const newHeatingData = newDataContext.heatingData;
  const houseData = refDataContext.houseData;
  const newHouseData = newDataContext.houseData;
  const warmWaterData = houseData.warmWaterData;
  const newWarmWaterData = newHouseData.warmWaterData;
  const refPricePerKWh = refDataContext.conversionData.pricePerKWh;
  const waterPricePerKWh = refDataContext.conversionData.waterPricePerKWh;

  let refEnergyData = heatMasterController.performCalculationForOptimizer(
    refDataContext
  );

  const { yearlyAggregatedConvertedData } = refEnergyData;

  const maxPower =
    refEnergyData.classAwareWattsEnergyResult.heatingSourceMaxPower;

  const refHeatingDeviceEnergy =
    yearlyAggregatedConvertedData["heatingDeviceEnergy"] *
    fromWattsToKwhRatio();
  const refWaterEnergyData =
    yearlyAggregatedConvertedData["heatingWaterDeviceEnergy"] *
    fromWattsToKwhRatio();
  const refElectricEnergyData =
    (yearlyAggregatedConvertedData["enElectricDevicesEnergyLoss"] +
      yearlyAggregatedConvertedData["enWaterElectricDevicesEnergyLoss"]) *
    fromWattsToKwhRatio();

  const refEnergyDataUE =
    (yearlyAggregatedConvertedData["_enRoof"] +
      yearlyAggregatedConvertedData["_enWalls"] +
      yearlyAggregatedConvertedData["_enFloor"] +
      yearlyAggregatedConvertedData["_enWindows"] +
      (yearlyAggregatedConvertedData["_enVent"] -
        yearlyAggregatedConvertedData["enRecuperator"]) +
      yearlyAggregatedConvertedData["_enTightness"] +
      yearlyAggregatedConvertedData["_enFoundations"] +
      yearlyAggregatedConvertedData["_enDoor"]) *
    fromWattsToKwhRatio();

  const refElectricEnergyDataUE = 0;

  let oldDevice = "";

  if (typeof heatingData.heatingType !== "undefined") {
    oldDevice = elementList.filter(
      item => item.name === heatingData.heatingType
    )[0];
  } else {
    oldDevice = elementList.filter(
      item => item.name === heatingData.heatingSource
    )[0];
  }

  let workCost = 0;
  let materialCost = 0;
  let systemCost = 0;
  let chimney = 0;
  let storeroom = 0;

  if (typeof oldDevice !== "undefined") {
    let system = false;
    if (typeof heatingData.heatingSystem !== "undefined") {
      system = heatingData.heatingSystem.split(".");
      system = system[system.length - 1];
    }
    if (typeof oldDevice[system] !== "undefined") {
      systemCost = oldDevice[system];
    }

    if (typeof oldDevice.storeroom !== "undefined") {
      storeroom = oldDevice.storeroom;
    }
    if (typeof oldDevice.chimney !== "undefined") {
      chimney = oldDevice.chimney;
    }

    if (typeof oldDevice.versions !== "undefined") {
      const devPrice = selectProperDevice(
        true,
        heatingData.waterHeatingSource,
        maxPower,
        oldDevice
      );
      materialCost = devPrice.dev;
      workCost = devPrice.work;
    }
  }
  const waterDevice = selectProperWaterDevice(
    true,
    heatingData._waterHeatingSource
  );

  let bestOption = {};
  let maxNPV = Number.MIN_SAFE_INTEGER;
  HeatingSourceReducer(newDataContext).map(item => {
    newDataContext.conversionData.houseHeatingEfficiency =
      item.conversionData.houseHeatingEfficiency;
    newDataContext.conversionData.warmWaterHeatingEfficiency =
      item.conversionData.waterHeatingEfficiency;
    newDataContext.conversionData.heatingSource =
      item.conversionData.heatingSource;
    newDataContext.conversionData.waterHeatingSource =
      item.conversionData.waterHeatingSource;
    newDataContext.heatingData.heatingType = item.newHeatingData.heatingType;
    newDataContext.heatingData.heatingSource =
      item.newHeatingData.waterHeatingSource;
    newDataContext.heatingData.waterHeatingSource =
      item.newHeatingData.heatingSource;
    newDataContext.conversionData.isElectric = item.conversionData.isElectric;
    newDataContext.conversionData.naturalUnitPrice =
      item.conversionData.naturalUnitPrice;
    newDataContext.conversionData.naturalUnitPriceLocal =
      item.conversionData.naturalUnitPriceLocal;
    newDataContext.conversionData.emissionCoefficient =
      item.conversionData.emissionCoefficient;
    newDataContext.conversionData.finalToPrimaryCoefficient =
      item.conversionData.finalToPrimaryCoefficient;
    newDataContext.conversionData.waterEmissionCoefficient =
      item.conversionData.waterEmissionCoefficient;
    newDataContext.conversionData.naturalUnitPriceChanged =
      item.conversionData.naturalUnitPriceChanged;
    newDataContext.conversionData.pricePerKWh = item.conversionData.pricePerKWh;
    newDataContext.conversionData.isWaterElectric =
      item.conversionData.isWaterElectric;
    newDataContext.conversionData.warmWaterFinalToPrimaryCoefficient =
      item.conversionData.warmWaterFinalToPrimaryCoefficient;
    newDataContext.conversionData.waterPricePerKWh =
      item.conversionData.waterPricePerKWh;
    const newPricePerKWh = newDataContext.conversionData.pricePerKWh;
    const newWaterPricePerKWh = newDataContext.conversionData.waterPricePerKWh;
    const newElectricityPricePerKWh =
      newDataContext.conversionData.electricityPricePerKWhLocal;
    const newEnergyData = heatMasterController.performCalculationForOptimizer(
      newDataContext
    );
    const { yearlyAggregatedConvertedData } = newEnergyData;

    newHeatingData.heatingSource = item.newHeatingData.heatingSource;
    newHeatingData.heatingType = item.newHeatingData.heatingType;
    newHeatingData.heatingSystem = item.newHeatingData.heatingSystem;
    newHeatingData.heatingEfficiency = item.newHeatingData.heatingEfficiency;
    newHeatingData.coolingEfficiency = item.newHeatingData.coolingEfficiency;
    newHeatingData.heatingSystemEfficiency =
      item.newHeatingData.heatingSystemEfficiency;
    newHeatingData.subdevicesEnergyConsumption =
      item.newHeatingData.subdevicesEnergyConsumption;
    newHeatingData.heatingFuel = item.newHeatingData.heatingFuel;
    newHeatingData.pricePerKWh =
      item.conversionData.electricityPricePerKWhLocal;
    newHeatingData.naturalUnitPrice =
      item.conversionData.electricityPricePerKWhLocal;
    newHeatingData.naturalUnit = item.newHeatingData.naturalUnit;
    newHeatingData.calorificValue = item.newHeatingData.calorificValue;
    newHeatingData.calorificValueUnit = item.newHeatingData.calorificValueUnit;
    newHeatingData.finalToPrimaryCoefficient =
      item.newHeatingData.finalToPrimaryCoefficient;
    newHeatingData.emissionCoefficient =
      item.newHeatingData.emissionCoefficient;
    newHeatingData.heatingValueEditable =
      item.newHeatingData.heatingValueEditable;
    newHeatingData.showNaturalUnit = item.newHeatingData.showNaturalUnit;
    newHeatingData.waterHeatingSource = item.newHeatingData.waterHeatingSource;
    newHeatingData.waterHeatingEfficiency =
      item.newHeatingData.waterHeatingEfficiency;
    newHeatingData.waterPricePerKWh =
      item.conversionData.electricityPricePerKWhLocal;
    newHeatingData.waterHeatingFuel = item.newHeatingData.waterHeatingFuel;
    newHeatingData.warmWaterFinalToPrimaryCoefficient =
      item.newHeatingData.warmWaterFinalToPrimaryCoefficient;
    newHeatingData.waterEmissionCoefficient =
      item.newHeatingData.waterEmissionCoefficient;
    newHeatingData.waterSubdevicesEnergyConsumption =
      item.newHeatingData.waterSubdevicesEnergyConsumption;
    newHeatingData.waterSavings = item.newHeatingData.waterSavings;

    const {
      systemCostNew,
      newMaterialCost,
      newWorkCost,
      newWaterMaterialCost,
      newWaterWorkCost,
    } = getDeviceCosts(
      elementList,
      heatingData,
      newHeatingData,
      newEnergyData,
      newHeatingData.heatingSystem
    );

    const surface = newHouseData.surfaceData.totalSurface;
    const newEnergyDataUE =
      (yearlyAggregatedConvertedData["_enRoof"] +
        yearlyAggregatedConvertedData["_enWalls"] +
        yearlyAggregatedConvertedData["_enFloor"] +
        yearlyAggregatedConvertedData["_enWindows"] +
        (yearlyAggregatedConvertedData["_enVent"] -
          yearlyAggregatedConvertedData["enRecuperator"]) +
        yearlyAggregatedConvertedData["_enTightness"] +
        yearlyAggregatedConvertedData["_enFoundations"] +
        yearlyAggregatedConvertedData["_enDoor"]) *
      fromWattsToKwhRatio();

    const newElectricEnergyDataUE = 0;

    const newHeatingDeviceEnergy =
      yearlyAggregatedConvertedData["heatingDeviceEnergy"] *
      fromWattsToKwhRatio();
    const newWaterEnergyData =
      yearlyAggregatedConvertedData["heatingWaterDeviceEnergy"] *
      fromWattsToKwhRatio();
    const newElectricEnergyData =
      (yearlyAggregatedConvertedData["enElectricDevicesEnergyLoss"] +
        yearlyAggregatedConvertedData["enWaterElectricDevicesEnergyLoss"]) *
      fromWattsToKwhRatio();

    const energySaved = (refData, newData) => Math.round(refData - newData);

    const electricEnergy =
      energySaved(refElectricEnergyData, newElectricEnergyData) -
      energySaved(refElectricEnergyDataUE, newElectricEnergyDataUE);

    let waterEnergyCost = 0;
    if (typeof refWaterEnergyData !== "undefined") {
      waterEnergyCost =
        refWaterEnergyData * waterPricePerKWh -
        newWaterEnergyData * newWaterPricePerKWh;
    }
    const electricEnergyCost = electricEnergy * newElectricityPricePerKWh;

    const pricePerKWhUE = refPricePerKWh;
    const newPricePerKWhUE = refPricePerKWh;

    let energyCost =
      refHeatingDeviceEnergy * refPricePerKWh -
      newHeatingDeviceEnergy * newPricePerKWh -
      (refEnergyDataUE * pricePerKWhUE - newEnergyDataUE * newPricePerKWhUE);

    let waterMaterialCost = waterDevice.dev;
    let waterWorkCost = waterDevice.work;
    let storageCost =
      warmWaterData.warmWaterStorage.id === "hm.water-storage.modern_storage"
        ? 800
        : 0;
    let storageCostNew =
      newWarmWaterData.warmWaterStorage.id === "hm.water-storage.modern_storage"
        ? 800
        : 0;

    let work =
      newHouseData.buildingType === "current"
        ? newWorkCost + newWaterWorkCost
        : newWorkCost + newWaterWorkCost - workCost - waterWorkCost;
    let material =
      newHouseData.buildingType === "current"
        ? newMaterialCost +
          newWaterMaterialCost +
          systemCostNew * surface +
          storageCostNew
        : newMaterialCost +
          newWaterMaterialCost -
          materialCost -
          waterMaterialCost +
          systemCostNew * surface -
          systemCost * surface +
          storageCostNew -
          storageCost -
          chimney -
          storeroom;
    let cost = material + work;
    const newNPV = projectedCashFlowHM(
      cost,
      20,
      energyCost + electricEnergyCost + waterEnergyCost,
      reports.irr,
      reports.irrPriceChange
    );

    if (maxNPV < newNPV) {
      maxNPV = newNPV;
      bestOption = item;
    }
    return item;
  });

  return {
    heatingSource: cancel
      ? heatingData.heatingSource
      : "hm.dropdown.heating.source.heat_pump",
    heatingType: cancel
      ? heatingData.heatingType
      : bestOption.newHeatingData.heatingType,
    heatingSystem: cancel
      ? heatingData.heatingSystem
      : bestOption.newHeatingData.heatingSystem,
    heatingEfficiency: cancel
      ? heatingData.heatingEfficiency
      : bestOption.newHeatingData.heatingEfficiency,
    coolingEfficiency: cancel
      ? heatingData.coolingEfficiency
      : bestOption.newHeatingData.coolingEfficiency,
    heatingSystemEfficiency: cancel
      ? heatingData.heatingSystemEfficiency
      : bestOption.newHeatingData.heatingSystemEfficiency,
    subdevicesEnergyConsumption: cancel
      ? heatingData.subdevicesEnergyConsumption
      : bestOption.newHeatingData.subdevicesEnergyConsumption,
    heatingFuel: cancel
      ? heatingData.heatingFuel
      : bestOption.newHeatingData.heatingFuel,
    pricePerKWh: cancel
      ? heatingData.pricePerKWh
      : bestOption.newHeatingData.pricePerKWh,
    naturalUnitPrice: cancel
      ? refDataContext.conversionData.naturalUnitPriceLocal
      : bestOption.newHeatingData.naturalUnitPrice,
    naturalUnit: cancel ? heatingData.naturalUnit : "hm.natural_unit.kwh",
    calorificValue: cancel
      ? heatingData.calorificValue
      : bestOption.newHeatingData.calorificValue,
    calorificValueUnit: cancel
      ? heatingData.calorificValueUnit
      : bestOption.newHeatingData.calorificValueUnit,
    finalToPrimaryCoefficient: cancel
      ? heatingData.finalToPrimaryCoefficient
      : bestOption.newHeatingData.finalToPrimaryCoefficient,
    emissionCoefficient: cancel
      ? heatingData.emissionCoefficient
      : bestOption.newHeatingData.emissionCoefficient,
    heatingValueEditable: cancel
      ? heatingData.heatingValueEditable
      : bestOption.newHeatingData.heatingValueEditable,
    showNaturalUnit: cancel
      ? heatingData.showNaturalUnit
      : bestOption.newHeatingData.showNaturalUnit,
    waterHeatingSource: cancel
      ? heatingData.waterHeatingSource
      : bestOption.newHeatingData.waterHeatingSource,
    waterHeatingEfficiency: cancel
      ? heatingData.waterHeatingEfficiency
      : bestOption.newHeatingData.waterHeatingEfficiency,
    waterPricePerKWh: cancel
      ? heatingData.waterPricePerKWh
      : bestOption.newHeatingData.waterPricePerKWh,
    waterHeatingFuel: cancel
      ? heatingData.waterHeatingFuel
      : bestOption.newHeatingData.waterHeatingFuel,
    warmWaterFinalToPrimaryCoefficient: cancel
      ? heatingData.warmWaterFinalToPrimaryCoefficient
      : bestOption.newHeatingData.warmWaterFinalToPrimaryCoefficient,
    waterEmissionCoefficient: cancel
      ? heatingData.waterEmissionCoefficient
      : bestOption.newHeatingData.waterEmissionCoefficient,
    waterSubdevicesEnergyConsumption: cancel
      ? heatingData.waterSubdevicesEnergyConsumption
      : bestOption.newHeatingData.waterSubdevicesEnergyConsumption,
    warmWaterStorageId: cancel
      ? warmWaterData.warmWaterStorage.id
      : bestOption.conversionData.warmWaterStorageId,
    waterSavings: cancel
      ? heatingData.waterSavings
      : bestOption.newHeatingData.waterSavings,
  };
}

const selectProperDevice = (
  ifWarmWater,
  waterHeatingSource,
  maxPower,
  devicePricesList
) => {
  let properPrice = false;
  if (typeof devicePricesList.versions !== "undefined") {
    for (let i = 0; i < devicePricesList.versions.length; i++) {
      const version = devicePricesList.versions[i];
      if (waterHeatingSource === "hm.dropdown.heating.source.the_same") {
        if (version.power >= maxPower / 1000) {
          if (typeof version.both !== "undefined") {
            properPrice = version.both;
            break;
          }
        }
      } else {
        if (version.power >= maxPower / 1000) {
          if (typeof version.heat !== "undefined") {
            properPrice = version.heat;
            break;
          }
        }
      }
    }
  }
  return properPrice;
};

const selectProperWaterDevice = (isOld, waterHeatingSource) => {
  let properPrice = false;
  if (waterHeatingSource === "hm.dropdown.heating.source.the_same") {
    properPrice = {
      dev: 0,
      work: 0,
    };
  } else if (
    waterHeatingSource ===
    "hm.dropdown.heating.source.boiler_gas.condensed.non_pulse"
  ) {
    properPrice = {
      dev: 3000,
      work: 1000,
    };
  } else if (
    waterHeatingSource === "hm.dropdown.heating.source.electricity.water"
  ) {
    properPrice = {
      dev: 60,
      work: 150,
    };
  } else if (
    waterHeatingSource === "hm.dropdown.heating.source.boiler_gas.non_condensed"
  ) {
    if (isOld) {
      properPrice = {
        dev: 0,
        work: 0,
      };
    }
  } else if (
    waterHeatingSource === "hm.dropdown.heating.source.heat_pump.cwu"
  ) {
    properPrice = {
      dev: 8000,
      work: 1200,
      service: 5000 * 0.05,
    };
  }
  return properPrice;
};

const getDeviceCosts = (
  elementList,
  heatingData,
  newHeatingData,
  newEnergy,
  system
) => {
  let newDevice = "";
  let systemCostNew = 0;
  let newMaterialCost = 0;
  let newWorkCost = 0;
  if (typeof newHeatingData.heatingType !== "undefined") {
    newDevice = elementList.filter(
      item => item.name === newHeatingData.heatingType
    )[0];
  } else {
    newDevice = elementList.filter(
      item => item.name === newHeatingData.heatingSource
    )[0];
  }

  if (typeof newDevice !== "undefined") {
    if (typeof newDevice[system] !== "undefined") {
      systemCostNew = newDevice[system];
    }
    const maxPower =
      newEnergy.classAwareWattsEnergyResult.heatingSourceMaxPower;
    if (typeof newDevice.versions !== "undefined") {
      const devPrice = selectProperDevice(
        true,
        newHeatingData.waterHeatingSource,
        maxPower,
        newDevice
      );
      newMaterialCost = devPrice.dev;
      newWorkCost = devPrice.work;
    }
  }

  let newWaterDevice = selectProperWaterDevice(
    false,
    newHeatingData._waterHeatingSource
  );
  if (heatingData._waterHeatingSource === newHeatingData._waterHeatingSource) {
    newWaterDevice = { dev: 0, work: 0 };
  }

  const newWaterMaterialCost = newWaterDevice.dev;
  const newWaterWorkCost = newWaterDevice.work;

  return {
    systemCostNew,
    newMaterialCost,
    newWorkCost,
    newWaterMaterialCost,
    newWaterWorkCost,
  };
};
