import React from "react";

import { FormattedMessage } from "react-intl";
import cx from "classnames";

export function ShadowLevel(props) {
  const { showDetailedData, valueLevel, id } = props;
  return (
    <div
      className={cx(
        " align-items-center mb-1 w-100",
        { "d-flex": showDetailedData },
        { "d-none": !showDetailedData }
      )}
    >
      <div className={"offset-1 col-4 pr-0"}>
        <FormattedMessage id="hm.label.shading.level" defaultMessage="">
          {message => <p className={"mb-0 lh-100"}>{message}</p>}
        </FormattedMessage>
      </div>
      <div className={"col-7"}>
        <select
          className={"form-control"}
          onChange={e => props.insolationChanged(id + "_level", e.target.value)}
          value={valueLevel}
        >
          <FormattedMessage
            id="hm.label.shading.level.strong"
            defaultMessage=""
          >
            {message => (
              <option key={"shading-level-strong"} value={"strong"}>
                {message}
              </option>
            )}
          </FormattedMessage>
          <FormattedMessage
            id="hm.label.shading.level.moderate"
            defaultMessage=""
          >
            {message => (
              <option key={"shading-level-moderate"} value={"moderate"}>
                {message}
              </option>
            )}
          </FormattedMessage>
        </select>
      </div>
    </div>
  );
}
